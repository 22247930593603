import { IdmServicePrincipalPermissionsModel } from "./GraphAppServicePrincipalPermissions";

export class GraphAppRevokePermissionDisplayModel
{
    public static scopePermissionType = "scope (delegated)";
    public static appRolePermissionType = "app role (application)";
    public static fromIdmServicePrincipalPermissionsModel(permissions: IdmServicePrincipalPermissionsModel): GraphAppRevokePermissionDisplayModel[]
    {
        let result: GraphAppRevokePermissionDisplayModel[] = [];

        permissions.appRoleAssignments.forEach((assignment) => {

            result.push({
                principalId: assignment.principalId,
                principalName: assignment.principalDisplayName,
                principalType: assignment.principalType,
                permissionId: assignment.appRoleAssignmentId,
                permissionType: this.appRolePermissionType,
                permissionValue: assignment.appRoleValue,
                resourceId: assignment.resourceServicePrincipalId,
                resourceName: assignment.resourceServicePrincipalDisplayName,
            } as GraphAppRevokePermissionDisplayModel);
        });
        permissions.scopeAssignments.forEach((scopeAssignment) => {
            scopeAssignment.scopes.forEach((scope) => {
                result.push({
                    principalId: scopeAssignment.clientServicePrincipalId,
                    principalName: scopeAssignment.clientServicePrincipalDisplayName,
                    principalType: "ServicePrincipal",
                    permissionId: scopeAssignment.scopeAssignmentId,
                    permissionType: this.scopePermissionType,
                    permissionValue: scope,
                    resourceId: scopeAssignment.resourceServicePrincipalId,
                    resourceName: scopeAssignment.resourceServicePrincipalDisplayName,
                } as GraphAppRevokePermissionDisplayModel);
            })
        });

        return result;
    }
    
    principalId: string;
    principalName: string;
    principalType: string;
    permissionId: string;
    permissionType: string;
    permissionValue: string;
    resourceId: string;
    resourceName: string;
}