export class ValidationPatternConstants {
    private static readonly MailNicknameCorePattern: string = "(?!.*\\.\\.)[a-zA-Z0-9]{1,1}[a-zA-Z0-9.+'_-]{0,62}[a-zA-Z0-9]{1,1}";
    public static readonly MailNicknamePattern: string = "^" + this.MailNicknameCorePattern + "$";
    public static readonly UserEmailIdPattern: string = "^" + this.MailNicknameCorePattern + "@(?=.{1,64}$)[a-zA-Z0-9._-]+[.][a-zA-Z0-9]{2,63}$";
    public static readonly NamePattern: string = "^[a-zA-Z0-9](?:[a-zA-Z0-9.,'_ -]*[a-zA-Z0-9])?$";
    public static readonly SourcingCompanyIdPattern: string = "^[A-Za-z\s]{1,}[\-]{0,1}[0-9\s]{0,}$";
    public static readonly CompanyNamePattern: string = "^[a-zA-Z0-9](?:[a-zA-Z0-9.,:'\\\-_ \/]*[a-zA-Z0-9])?$";
    public static readonly LocationNamePattern: string = "^[A-Za-z]{2}$";    
    public static readonly URLPattern: string = "^(http|https):\/\/(?=.{1,512}$)(localhost|[a-zA-Z0-9._-]+[.][a-zA-Z0-9]{2,63})(\:[0-9]{1,8})?(?:[-a-zA-Z0-9()@:%_\+.$~#?&\/=,;:])*$";
    public static readonly NumberPattern: string = "^[0-9]+$";
    public static readonly PhoneNumberPattern: string = "^([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$";
}