import { CampEntity } from "../models/campEntity";
import { IIdmApplication } from "../models/idm/applications/IdmApplication";

export class ToStringHelper {
    static hasDisplayName(obj: any): boolean {
        return typeof obj == 'object' && 'displayName' in obj;
    }

    static isIChevronApplication(obj: any): obj is IIdmApplication {
        return typeof obj == 'object' && (obj as IIdmApplication).serviceId !== undefined && (obj as IIdmApplication).displayName !== undefined;
    }

    static isCampEntityArray(obj: any): obj is CampEntity[] {
        return typeof obj == 'object' && (obj as CampEntity[])?.length !== undefined && (obj as CampEntity[])?.length > 0
            && (obj as CampEntity[])[0].id !== undefined && (obj as CampEntity[])[0].name !== undefined;
    }
    static isCampEntity(obj: any): obj is CampEntity {
        return typeof obj == 'object' && (obj as CampEntity).id !== undefined && (obj as CampEntity).name !== undefined;
    }

    static overrideToString<T>(obj: T): T {

        if (ToStringHelper.isIChevronApplication(obj)) {
            let o = obj as IIdmApplication;
            o.toString = () => `${o.serviceId}${o.displayName ? `: ${o.displayName}` : ''}`;
            return o as T;
        }
        else if (ToStringHelper.isCampEntityArray(obj)) {
            let o = obj as CampEntity[];
            o.toString = () => {
                if (o.length > 1) return `${o[0].id} - ${o[0].name} (${o[0].country})...`
                return `${o[0].id} - ${o[0].name} (${o[0].country})`
            };
            return o as T;
        }
        else if (ToStringHelper.isCampEntity(obj)) {
            let o = obj as CampEntity;
            o.toString = () => `${o.id} - ${o.name} (${o.country})`;
            return o as T;
        }
        else if (ToStringHelper.hasDisplayName(obj)) {
            let o = obj as any;
            o.toString = () => `${o.displayName}`;
            return o as T;
        }
        else {
            let o = obj as object;
            o.toString = () => `${obj}`;
            return o as T;
        }
    }
}