import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IIdAndDomain } from "src/app/models/directory/IIdAndDomain";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { SsSecret } from "src/app/models/secretServer/SsSecret";
import { UpdateSecretPermissionsRequestDetails } from "src/app/models/secretServer/UpdateSecretPermissionsRequestDetails";
import { UserPermissionChange } from "src/app/models/secretServer/UserPermissionChange";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-secret-update-permissions',
    templateUrl: './request-details-secret-update-permissions.component.html'
})
export class RequestDetailsSecretUpdatePermissions implements OnInit {

    @Input() requestData: UpdateSecretPermissionsRequestDetails;
    @Output() doneLoading = new EventEmitter<boolean>();

    userPermissionColumns: string[] = ['permissionChange','displayName', 'mail'];
    secretColumns: string[] = ['name', 'folder', 'id'];
    secretsUpdated: SsSecret[] = [];

    userPermissionChanges: UserPermissionChange[] = [];

    constructor(private directoryUserService: DirectoryUserService) { }

    async ngOnInit(): Promise<void> {
        this.secretsUpdated = this.requestData.secrets;
        
        if(this.requestData.justification?.length > 0) this.userPermissionColumns.push('justification');

        await this.loadUsers(this.requestData.owners, this.userPermissionChanges, 'grant owner', this.requestData.justification)
        await this.loadUsers(this.requestData.viewers, this.userPermissionChanges, 'grant viewer', this.requestData.justification)
        await this.loadUsers(this.requestData.revoke, this.userPermissionChanges, 'remove access', this.requestData.justification)
        
        this.isDoneLoading();
    }

    
    async loadUsers(ids: string[], array: UserPermissionChange[], permissionChange: string, justification: string = '') {

        let userPermChanges: UserPermissionChange[] = [];
        for (let id of ids) {
            try {
                const user = await this.directoryUserService.GetByIdAsync({ id: id, domain: DirectoryDomain.Chevron } as IIdAndDomain);
                userPermChanges.push({
                    displayName: user.displayName,
                    mail: user.mail,
                    permissionChange: permissionChange,
                    justification: justification
                } as UserPermissionChange)
            }
            catch (error) {
                userPermChanges.push({
                    displayName: `unknown user with id ${id}`,
                    mail: '',
                    permissionChange: permissionChange,
                    justification: justification
                } as UserPermissionChange)
            }
        }

        // combine & clone so change detection sees the new ref and updates the child component
        this.userPermissionChanges = [...array, ...userPermChanges]
        this.isDoneLoading();
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}