import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { GraphApplication, IGraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphAppPermissionRequestModel } from "src/app/models/graph/applications/GraphAppPermissionRequest";
import { GraphAppRequestModel } from "src/app/models/graph/applications/GraphAppRequest";
import { IGenericApiResponse } from "../../models/common/GenericApiResponse";
import { IdampHttpClient } from "../common/IdampHttpClient";
import { GraphApplicationRoleAssignmentRequestModel } from "src/app/models/graph/applications/GraphApplicationRoleAssignmentRequest";
import { AppRoleAllowedMemberType } from "src/app/models/graph/applications/enums/AppRoleAllowedMemberType";
import { GraphApplicationRequestableApplicationPermission } from "src/app/models/graph/applications/GraphApplicationRequestableApplicationPermission";
import { ServicePrincipalPermissionRole } from "src/app/models/graph/applications/enums/ServicePrincipalPermissionRole";
import { IdmServicePrincipalPermissionsModel } from "src/app/models/graph/applications/GraphAppServicePrincipalPermissions";
import { GraphAppServicePrincipalRevokeRequestModel } from "src/app/models/graph/applications/GraphAppServicePrincipalRevokeRequest";
import { IClientSecret } from "src/app/models/graph/applications/ClientSecret";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GraphApplicationClientSecretRequestModel } from "src/app/models/graph/applications/GraphApplicationClientSecretRequestModel";
import { ApplicationRegistrationTenantType } from "src/app/models/ApplicationRegistrationTenantType";
import { GraphApplicationsUpdateRequest } from "src/app/models/graph/applications/GraphApplicationsUpdateRequest";

@Injectable({ providedIn: 'root' })

export class GraphApplicationService {
    constructor(private http: IdampHttpClient) { }

    public SearchApplication(term: string): Observable<IGenericApiResponse<IGraphApplication[]>> {
        const path = `applications`;
        const query = { searchTerm: term };
        return from(this.http.GetAsync<IGenericApiResponse<IGraphApplication[]>>(path, query));
    }

    public async GetApplicationsByIdsAsync(domain: DirectoryDomain, ids: string[]): Promise<GraphApplication[]> {
        const path = `directoryObjects/${domain}/getByIds`;
        let response = await this.http.PostAsync<{ ids: string[] }, IGenericApiResponse<GraphApplication[]>>(path, { ids: ids });
        return response.data;
    }

    public GetApplication(id: string, appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.Application): Observable<IGenericApiResponse<IGraphApplication>> {
        return from(this.GetApplicationAsync(id, appRoleAllowedMemberType));
    }

    public async GetApplicationAsync(id: string, appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.Application): Promise<IGenericApiResponse<IGraphApplication>> {
        const path = `applications/${id}`;
        const query = { appRoleAllowedMemberType: appRoleAllowedMemberType };
        return await this.http.GetAsync<IGenericApiResponse<IGraphApplication>>(path, query);
    }

    public SearchServicePrincipal(term: string): Observable<IGenericApiResponse<IGraphApplication[]>> {
        return from(this.SearchServicePrincipalAsync(term));
    }

    public async SearchServicePrincipalAsync(term: string): Promise<IGenericApiResponse<IGraphApplication[]>> {
        const path = `servicePrincipals`;
        const query = { searchTerm: term };
        return await this.http.GetAsync<IGenericApiResponse<IGraphApplication[]>>(path, query);
    }

    public GetServicePrincipal(id: string, appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.Application): Observable<IGenericApiResponse<IGraphApplication>> {
        return from(this.GetServicePrincipalAsync(id, appRoleAllowedMemberType));
    }

    public async GetServicePrincipalAsync(id: string, appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.Application): Promise<IGenericApiResponse<IGraphApplication>> {
        const path = `servicePrincipals/${id}`;
        const query = { appRoleAllowedMemberType: appRoleAllowedMemberType };
        return await this.http.GetAsync<IGenericApiResponse<IGraphApplication>>(path, query);
    }

    public async GetServicePrincipalPermissionsAsync(domain: DirectoryDomain, id: string, role: ServicePrincipalPermissionRole, showAll: boolean): Promise<IGenericApiResponse<IdmServicePrincipalPermissionsModel>> {
        const path = `servicePrincipals/${domain}/${id}/permissions/${role}`;
        const query = {
            showOwnedOnly: !showAll
        };
        return await this.http.GetAsync<IGenericApiResponse<IdmServicePrincipalPermissionsModel>>(path, query);
    }

    public RevokeServicePrincipalPermissionsAsync(model: GraphAppServicePrincipalRevokeRequestModel): Observable<IGenericApiResponseWithWorkflowRequest<any>> {
        const path = `servicePrincipals/${model.domain}/${model.servicePrincipalId}/permissions/${model.servicePrincipalRole}/revoke`;
        return from(this.http.PostAsync<GraphAppServicePrincipalRevokeRequestModel, IGenericApiResponseWithWorkflowRequest<any>>(path, model));
    }

    public GetOwned(upn?: string, tenantType: ApplicationRegistrationTenantType = ApplicationRegistrationTenantType.Chevron, includeCustomSecAttributes: boolean = false): Observable<IGenericApiResponse<IGraphApplication[]>> {
        const path = upn == undefined ?
            `me/ownedObjects/applications` : // based on authenticated user
            `users/${upn}/ownedObjects/applications`; // based on upn passed in

        const query = {
            applicationRegistrationTenantType: tenantType,
            includeCustomSecAttributes: includeCustomSecAttributes
        };

        return from(this.http.GetAsync<IGenericApiResponse<IGraphApplication[]>>(path, query));
    }

    public CreateApplication(model: GraphAppRequestModel): Observable<IGenericApiResponseWithWorkflowRequest<IGraphApplication>> {
        const path = `applications`;
        return from(this.http.PostAsync<GraphAppRequestModel, IGenericApiResponseWithWorkflowRequest<IGraphApplication>>(path, model));
    }

    public CreateApplicationPermissionRequest(clientId: string, model: GraphAppPermissionRequestModel): Observable<IGenericApiResponseWithWorkflowRequest<any>> {
        const path = `applications/${clientId}/permissions`;
        return from(this.http.PatchAsync<GraphAppPermissionRequestModel, IGenericApiResponseWithWorkflowRequest<any>>(path, model));
    }

    public CreateApplicationRoleAssignment(model: GraphApplicationRoleAssignmentRequestModel): Observable<IGenericApiResponseWithWorkflowRequest<any>> {
        const path = `applications/${model.domain}/bulkAssignAppRoles`;
        return from(this.http.PostAsync<GraphApplicationRoleAssignmentRequestModel, IGenericApiResponseWithWorkflowRequest<any>>(path, model));
    }

    public CreateApplicationClientSecret(model: GraphApplicationClientSecretRequestModel): Observable<IGenericApiResponseWithWorkflowRequest<any>> {
        const path = `applications/${model.domain}/clientSecrets`;
        return from(this.http.PostAsync<GraphApplicationClientSecretRequestModel, IGenericApiResponseWithWorkflowRequest<any>>(path, model));
    }

    public GetRequestablePermissions(): Observable<IGenericApiResponse<GraphApplicationRequestableApplicationPermission[]>> {
        const path = `servicePrincipals/RequestablePermissions`;
        return from(this.http.GetAsync<IGenericApiResponse<GraphApplicationRequestableApplicationPermission[]>>(path));
    }

    public GetClientSecrets(domain: DirectoryDomain, appObjectId: string): Observable<IGenericApiResponse<IClientSecret[]>> {
        const path = `applications/${domain}/${appObjectId}/clientSecrets`;
        return from(this.http.GetAsync<IGenericApiResponse<IClientSecret[]>>(path));
    }

    public DeleteClientSecrets(domain: DirectoryDomain, appObjectId: string, clientSecretIds: string[]): Observable<IGenericApiResponseWithWorkflowRequest<any>> {
        const path = `applications/${domain}/${appObjectId}/clientSecrets`;
        const query = { clientSecretIds: clientSecretIds }
        return from(this.http.DeleteAsync<IGenericApiResponseWithWorkflowRequest<any>>(path, query));
    }

    public UpdateApplications(model: GraphApplicationsUpdateRequest): Observable<IGenericApiResponseWithWorkflowRequest<GraphApplication>> {
        const path = `applications/`;
        return from(this.http.PatchAsync<GraphApplicationsUpdateRequest, IGenericApiResponseWithWorkflowRequest<GraphApplication>>(path, model))
    }
}