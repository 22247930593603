import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { WorkflowRequestTypes } from "src/app/models/requests/enums/WorkflowRequestTypes.enum";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-user-create',
    templateUrl: './request-details-user-create.component.html'
})

export class RequestDetailsUserCreate implements OnChanges {
    @Input() requestData: any;
    @Input() workflowRequestType: WorkflowRequestTypes;

    @Output() doneLoading = new EventEmitter<boolean>();
    displayObject: any;

    constructor(
        private directoryUserService: DirectoryUserService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData) {
            this.displayObject = {
                displayName: '',
                userPrincipalName: '',
                usageLocation: this.requestData.usageLocationCode,
                applicationId: this.requestData.applicationId,
                userType: this.workflowRequestType === WorkflowRequestTypes.ServiceAccountCreate ? "Service" : "Privilege",
                steward: ""
            };
            if (!this.requestData.applicationId)
                delete this.displayObject["applicationId"]

            if (!this.requestData.usageLocationCode)
                delete this.displayObject["usageLocation"]

            await this.loadSteward(this.requestData.steward);

            this.isDoneLoading();
        }
    }

    async loadSteward(steward: string) {
        const user = await this.directoryUserService.GetByIdAsync({ id: steward, domain: DirectoryDomain.Chevron });
        const userPrincipalName: string = this.getUserPrincipalName(user);
        const createdUser = await this.directoryUserService.GetByIdAsync({ id: userPrincipalName, domain: DirectoryDomain.Chevron });
        this.displayObject = { ...this.displayObject, steward: user.displayName, displayName: createdUser.displayName, userPrincipalName };
    }

    private getUserPrincipalName(steward: any): string {
        if (this.workflowRequestType === WorkflowRequestTypes.ServiceAccountCreate) {
            return `${this.requestData.givenName}@${this.requestData.domain}`
        }
        return `${steward.mailNickname}-ps@${this.requestData.domain}`
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}
