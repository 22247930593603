import { Component } from '@angular/core';
import { PageLayout } from '@cvx/nextpage';

@Component({
    selector: 'home',
    templateUrl: 'home.component.html',
    styles: [ '.main.container section { width: 100%; }' ]
})

export class HomeComponent {
    PageLayout = PageLayout;
}