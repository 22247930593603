import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IIdAndDomain, IIdsAndDomain } from "src/app/models/directory/IIdAndDomain";
import { DirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { IdampHttpClient } from "../common/IdampHttpClient";
import { DirectoryMailboxUserCreateRequest } from "src/app/models/directory/users/DirectoryMailboxUserCreateRequest";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";

@Injectable({ providedIn: 'root' })

export class DirectoryUserService {
    CreateMailboxUser(domain: DirectoryDomain, model: DirectoryMailboxUserCreateRequest): Observable<IGenericApiResponseWithWorkflowRequest<DirectoryUser>> {

        const path = `users/${domain}/createMailboxUser`;

        return from(this.http.PostAsync<DirectoryMailboxUserCreateRequest, IGenericApiResponseWithWorkflowRequest<DirectoryUser>>(path, model))
    }
    constructor(private http: IdampHttpClient) { }

    public SearchUsers(term: string, domain: DirectoryDomain, type: UserType): Observable<IGenericApiResponse<DirectoryUser[]>> {
        const path = `users/${domain}`;
        const query = { searchTerm: term, userType: type };

        return from(this.http.GetAsync<IGenericApiResponse<DirectoryUser[]>>(path, query));
    }

    public async SearchUsersAsync(term: string, domain: DirectoryDomain, type: UserType): Promise<DirectoryUser[]> {
        const path = `users/${domain}`;
        const query = { searchTerm: term, userType: type };

        let response = await this.http.GetAsync<IGenericApiResponse<DirectoryUser[]>>(path, query);

        return response.data;
    }

    public GetById(id: string, domain: string): Observable<IGenericApiResponse<DirectoryUser>> {
        const path = `users/${domain}/${id}`;

        return from(this.http.GetAsync<IGenericApiResponse<DirectoryUser>>(path));
    }

    public async GetByIdAsync(userId: IIdAndDomain): Promise<DirectoryUser> {
        const path = `users/${userId.domain}/${userId.id}`;
        let response = await this.http.GetAsync<IGenericApiResponse<DirectoryUser>>(path);

        return response.data;
    }

    public async GetByIdsAsync(requestInfo: IIdsAndDomain): Promise<DirectoryUser[]> {
        const path = `users/${requestInfo.domain}`;
        let response = await this.http.PostAsync<string[], IGenericApiResponse<DirectoryUser[]>>(path, requestInfo.ids);

        return response.data;
    }
}