import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IGraphUser } from "src/app/models/graphUser";
import { DialogDirectorySearchComponent } from "../../_shared/dialog-directory-search/dialog-directory-search.component";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { CreateSecretRequest } from 'src/app/models/secretServer/CreateSecretRequest';
import { SsSecret } from 'src/app/models/secretServer/SsSecret';
import { CreateSecretField } from 'src/app/models/secretServer/enums/CreateSecretField.enum';
import { CreateableSecretFolder } from 'src/app/models/secretServer/enums/CreateableSecretFolder.enum';
import { SecretServerService } from 'src/app/services/secretServer/secretServer.service';
import { lastValueFrom } from "rxjs";

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent implements OnInit {

  PageLayout = PageLayout;
  currentUserProfile: ICvxClaimsPrincipal;
  isCreating: boolean = false;
  createdSecret: IGenericApiResponseWithWorkflowRequest<SsSecret> | undefined;
  displayCreatedSecret: any;
  createErrorMessage = { message: '', errors: [] };

  constructor(private authService: CalAngularService,
    private dialog: MatDialog,
    private secretService: SecretServerService,
    private formBuilder: FormBuilder
  ) { }

  passwordForm = this.formBuilder.group({
    folder: [null, Validators.required],
    name: ['', Validators.required],
    resource: ['', Validators.required],
    owners: this.formBuilder.array([], Validators.minLength(1)),
    viewers: this.formBuilder.array([], Validators.minLength(0))
  });

  get isFolderPrivilege() {
    const privilegeCreateableSecrets: string[] = [`${CreateableSecretFolder.PrivilegeGenericPassword}`]
    const folderText = `${this.passwordForm?.get('folder')?.value}`;
    return privilegeCreateableSecrets.some(o => o == folderText);
  }
  get resourceDisplayName(): string {
    const folderName = `${this.passwordForm?.get('folder')?.value}`;
    return folderName == 'SQL' ? 'Server' : 'Resource';
  }
  get owners() {
    let owners = this.passwordForm.get('owners') as FormArray
    return owners;
  }
  get viewers() { return this.passwordForm.get('viewers') as FormArray; }
  get folderTypes() { return Object.values(CreateableSecretFolder) };

  get passwordFormValues() {
    let values = this.passwordForm.value;
    let secretFields: Partial<Record<CreateSecretField, string>> = {};
    let owners = this.owners.value.map((item: IGraphUser) => item.id);
    let viewers = this.viewers.value.map((item: IGraphUser) => item.id);

    if (values.name) secretFields.UserName = `${values.name}`;
    if (values.resource) secretFields.Resource = `${values.resource}`;

    if (this.isFolderPrivilege) viewers = [];

    let payload = new CreateSecretRequest(
      values.folder!,
      secretFields,
      owners,
      viewers
    );

    return payload;
  }

  async ngOnInit(): Promise<void> {
    await this.addCurrentUserAsOwner();
  }

  // default add the current user as an owner
  async addCurrentUserAsOwner() {
    if (await lastValueFrom(this.authService.isUserSignedIn())) {
      this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      this.addUserByType('owners', this.currentUserProfile.objectId, this.currentUserProfile.name, this.currentUserProfile.email);
    }
  }

  addUserByType(type: string, id: string, name: string, email: string) {
    let listOfUser = type == 'owners' ? this.owners : this.viewers;

    let isInList = listOfUser.controls.findIndex(o => o.value.id == id) > -1;

    // viewers can't be in the owners list
    if (type == 'viewers') isInList = isInList || this.owners.controls.findIndex(o => o.value.id == id) > -1;
    else isInList = isInList || this.viewers.controls.findIndex(o => o.value.id == id) > -1;

    if (!isInList) listOfUser.push(this.formBuilder.group({
      id: [id],
      displayName: [name],
      mail: [email]
    }));
  }

  searchForPrimaryUser(type: string): void {
    const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        type: GroupMemberType.User,
        domain: DirectoryDomain.Chevron,
        userType: UserType.Primary,
        filterGroupRemoveNotAvailableAsGroupMember: false,
        filterGroupRemoveDynamicMembershipEnabled: false,
        filterGroupOnlyManagedByIdamp: false
      } as IDialogDirectoryData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 'owners') this.addUserByType(type, result.id, result.displayName, result.mail);
        if (type == 'viewers') this.addUserByType(type, result.id, result.displayName, result.mail);
      }
    });
  }

  removeOwner(i: number) {
    this.owners.removeAt(i);
  }
  removeViewer(i: number) {
    this.viewers.removeAt(i);
  }

  async onSubmit() {

    // clear error messages
    this.createErrorMessage.message = '';
    this.createErrorMessage.errors = [];

    if (this.passwordForm.valid) {

      this.isCreating = true;
      let payload = this.passwordFormValues;

      const observer = {
        next: (x: IGenericApiResponseWithWorkflowRequest<SsSecret>) => {
          this.createdSecret = x;

          let data = x.data as SsSecret;

          this.displayCreatedSecret = {
            id: data.id,
            name: data.name,
            folder: data.folder
          };
        },
        error: (err: any) => {
          this.createErrorMessage.message = err.statusText;
          this.createErrorMessage.errors = err?.error?.errors ?? [];
          this.isCreating = false;
        },
        complete: () => {
          this.isCreating = false;
        }
      };

      let createCall = this.secretService.CreatePassword(payload);
      createCall.subscribe(observer);
    }
    else {
      // form is invalid, trigger all validation fields
      this.passwordForm.markAllAsTouched();
    }
  }

  async resetForm() {
    this.passwordForm.reset();
    this.passwordForm.controls.owners.clear();
    this.passwordForm.controls.viewers.clear();
    await this.addCurrentUserAsOwner();
  }
}
