<main class="container">
  <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
    <div class="row" *ngIf="submittedRequests.length === 0">
      <div class="col col-12">
        <h1 class="title">mfa reset</h1>
        <fieldset class="pb-4">
          <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
            <p><strong>account</strong></p>
          </legend>

          <div *ngIf="!isSubmitting && !isDataLoading && dataSource.data.length == 0">
            no accounts were found
          </div>
          <div>
            <form class="branded">
              <filter-input (onKeyUp)="doFilter($event)"
                *ngIf="!isDataLoading && dataSource.data.length > 0"></filter-input>

              <table mat-table [dataSource]="dataSource" matSort matSortActive="type" matSortDirection="desc"
                [hidden]="isDataLoading || dataSource.data.length === 0" aria-describedby="user stewarded accounts">
                <caption>mfa reset requestable accounts</caption>

                <ng-container matColumnDef="checkBox">
                  <th mat-header-cell *matHeaderCellDef>
                    <input type="checkbox" (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()" />
                  </th>
                  <td mat-cell *matCellDef="let account">
                    <input MatCheckbox type="checkbox" name="formcheckbox" [value]="account.id"
                      (click)="$event.stopPropagation()" (change)="selectAccount(account)"
                      [checked]="selection.isSelected(account)" />
                  </td>
                </ng-container>
                <ng-container matColumnDef="displayName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    display name
                  </th>
                  <td mat-cell *matCellDef="let account">{{ account.displayName }}</td>
                </ng-container>
                <ng-container matColumnDef="mail">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>mail</th>
                  <td mat-cell *matCellDef="let account">{{ account.mail }}</td>
                </ng-container>
                <ng-container matColumnDef="type">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>type</th>
                  <td mat-cell *matCellDef="let account">{{ account.type }}</td>
                </ng-container>
                <ng-container matColumnDef="companyName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>company name</th>
                  <td mat-cell *matCellDef="let account">{{ account.companyName }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <mat-paginator *ngIf="!isDataLoading && dataSource.data.length > 0" [length]="dataSource.data.length"
                #searchResultsPaginator [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons>
              </mat-paginator>
            </form>
          </div>
        </fieldset>

        <fieldset class="pb-4" *ngIf="!isDataLoading && dataSource.data.length > 0">
          <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
            <p><strong>selected accounts to reset mfa</strong></p>
          </legend>
          <generic-search-results [showHeader]="false" [records]="selection.selected" [showCounter]="false" [title]="''"
            [caption]="'accounts to reset mfa'" [displayedColumns]="selectedUserDisplayedColumns"
            [selectableRecords]="false" [deletableRecords]="true" [showPagination]="false" [allowLocalFilter]="false"
            (recordDeleted)="selectAccount($event)">
          </generic-search-results>
        </fieldset>


        <div *ngIf="!isDataLoading; else loading" class="text-center pt-1 pb-4">
          <button class="button" *ngIf="!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0"
            (click)="onRetireClick()">
            reset mfa
          </button>

          <display-http-error [message]="submitErrorMessage.message"
            [errors]="submitErrorMessage.errors"></display-http-error>
          <mat-spinner [diameter]="30" *ngIf="isSubmitting"
            style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
        </div>
        <ng-template #loading>
          <div class="container">
            <div class="row justify-content-center col-sm-12">
              <p>searching for accounts...</p>
            </div>
            <div class="row justify-content-center col-sm-12">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </ng-template>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="row" *ngIf="submittedRequests.length > 0">
      <div class="col col-12">
        <a class="close-button" [routerLink]="['/account/mfa/reset']"><span class="icon-remove close-button"></span></a>
        <h1>request submitted</h1>
        <display-http-error [message]="submitErrorMessage.message"
          [errors]="submitErrorMessage.errors"></display-http-error>
        <display-requests [requests]="submittedRequests"></display-requests>
      </div>
    </div>
  </section>
</main>