<mat-dialog-content>
    <div>
        <div class="col pt-3 pb-3">
    
            <div *ngIf="completedCount < total">
                <div class="d-flex justify-content-center">
                    <mat-spinner class="cxv-spinner-dark-blue"></mat-spinner>                
                </div>
                <div class="d-flex justify-content-center">
                    <p class="pt-3">Progress: {{ completedCount }}/{{ total }}</p>   
                </div>   
            </div>
    
            <div *ngIf="completedCount == total">
                <h2 class="text-dark-blue"><mat-icon class="customIconSize">done</mat-icon></h2>
                <div>
                    <p class="text-center">Success: {{ completedCount - failedCount }}</p>
                    <p class="text-center">Failed: {{ failedCount }}</p>   
                </div>
                <div *ngIf="failedCount > 0">
                    <hr>
                    <h4 class="text-dark-red">failed</h4>
                    <p class="text-medium-red text-center" *ngFor="let fail of failedReason">{{fail}}</p>
                </div>  
            </div>
    
            <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
                <div class="btn-group mr-2" role="group">
                    <button class="button" type="button" [disabled]="completedCount < total" mat-dialog-close>close</button>                                 
                </div>    
            </div>
        </div>
    </div>
</mat-dialog-content>