<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if (submittedRequests.length === 0 && userTypeToggleOptions.length > 1){
        <div class="row">
            <div class="col col-12">
                <generic-button-toggles [options]="userTypeToggleOptions" [title]="title" [initialSelection]="initialSelection"
                    (selectionChange)="handleToggleChange($event)"></generic-button-toggles>
            </div>
        </div>
        }

        @if (submittedRequests.length === 0){
        <div class="row">
            <div class="col col-12">
                <h1>delete {{userTypeString}} account</h1>
                <fieldset class="pb-4">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>{{userTypeString}} account</strong></p>
                    </legend>
                    @if (!gettingUsers && usersDataSource.data.length == 0){
                    <div>
                        <display-http-error [message]="errorMessage" [errors]="[]"></display-http-error>
                    </div>
                    }
                    @if (gettingUsers){
                    <div class="container">
                        <div class="row justify-content-center col-sm-12">
                            <p>searching for {{userTypeString}} accounts...</p>
                        </div>
                        <div class="row justify-content-center col-sm-12">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    }
                    <div class="container">
                        <form class="branded">
                            @if (!gettingUsers && usersDataSource.data.length > 0){
                            <filter-input (onKeyUp)="filter($event)"></filter-input>
                            }

                            <table id="users" [hidden]="gettingUsers || usersDataSource.data.length == 0" mat-table
                                [dataSource]="usersDataSource" matSort matSortActive="displayName"
                                matSortDirection="asc" aria-describedby="users you have access to">

                                <caption>{{userTypeString}} accounts that can be deleted</caption>
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <input type="checkbox" (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()" />
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <input type="checkbox" name="formcheckbox" (click)="$event.stopPropagation()"
                                            (change)="select(row)" [checked]="selection.isSelected(row)" />
                                    </td>
                                </ng-container>

                                @for (disCol of selectedDisplayCols; track disCol;let colIndex = $index) {
                                <ng-container matColumnDef="{{disCol}}">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol |
                                        convertDisplayLabels}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <text-formatter [content]="element[disCol]"></text-formatter>
                                    </td>
                                </ng-container>
                                }

                                <tr mat-header-row *matHeaderRowDef="cols"></tr>
                                <tr mat-row *matRowDef="let row; columns: cols;"></tr>

                            </table>

                            <mat-paginator [length]="usersDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]"
                                showFirstLastButtons></mat-paginator>
                        </form>
                    </div>
                </fieldset>
                @if (!gettingUsers && usersDataSource.data.length > 0){
                <fieldset class="pb-4">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>selected {{userTypeString}} accounts to delete</strong></p>
                    </legend>
                    <generic-search-results [showHeader]="false" [records]="selection.selected" [showCounter]="false"
                        [title]="''" [caption]="userTypeString + ' accounts to delete'"
                        [displayedColumns]="selectedDisplayCols" [selectableRecords]="false" [deletableRecords]="true"
                        [showPagination]="false" [allowLocalFilter]="false" (recordDeleted)="select($event)">
                    </generic-search-results>
                </fieldset>
                }

                <mat-divider></mat-divider>

                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>

                @if (!gettingUsers){
                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">

                    @if (isSubmitting){
                    <mat-spinner [diameter]="30" class="mat-spinner-search"></mat-spinner>
                    }

                    @if (!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0){
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="submit" (click)="onSubmit()">delete</button>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
        }

        @if (submittedRequests.length > 0){
        <div class="row">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/account/' + route + '/delete']"><span
                        class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>
        }

    </section>
</main>