@if (appSearchComplete) {
    <generic-search-results
        [showHeader]="false"
        [showCounter]="false"
        [title]="''"
        [records]="requestData.applicationClientSecrets"
        [displayedColumns]="displayColumns"
        [selectableRecords]="false"
        [allowLocalFilter]="false"
    ></generic-search-results>
}