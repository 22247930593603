<mat-dialog-content>
    <form class="branded p-2" [formGroup]="searchform" *ngIf="!IsSearching && SearchResults.length === 0">
        <div>
            <div class="col-12">
                <h2>camp entity search</h2>
    
                <p>Please use any number of the search fields below:</p>
    
                <div class="row">
    
                    <div class="col-12 col-sm-6 col-lg-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">name</mat-label>
                            <input matInput formControlName="name" (keyup.enter)="search()">
                        </mat-form-field>
                    </div>
    
                    <div class="col-12 col-sm-6 col-lg-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">country</mat-label>
                            <mat-select formControlName="country">
                                <mat-option [value]="" disabled selected>Select Country</mat-option>
                                <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>            
                    </div>
    
                    <div class="col-12 col-sm-6 col-lg-4">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label class="font-gotham-narrow-bold text-dark-blue">id</mat-label>
                            <input matInput formControlName="id" (keyup.enter)="search()">
                            <mat-hint>ID must be an exact match, partial isn't accepted.</mat-hint>
                        </mat-form-field>
                    </div>
    
                </div>
    
                <p class="text-danger text-center">{{screenMessage}}</p>
    
                <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="button" (click)="search()">search</button>
                    </div>
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="button" (click)="reset()">reset</button>                                 
                    </div>
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="button" mat-dialog-close>close</button>                                 
                    </div>        
                </div>
                
            </div>
        </div>
    </form>
    
    <div class="container p-2" *ngIf="IsSearching">
        <div class="row justify-content-center col-sm-12">
            <p>Searching for camp entities.</p>                               
        </div>
        <div class="row justify-content-center col-sm-12">
            <mat-spinner></mat-spinner>                               
        </div>                                
    </div>
    
    <div class="col-12 p-2" [hidden]="SearchResults.length === 0">
    
        <h4 id="resultCount">{{ replyMessage }}</h4>
    
        <table mat-table [dataSource]="dataSource" aria-describedby="resultCount" matSort>
    
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> name </th>
                <td mat-cell *matCellDef="let campEntity"> {{campEntity.name}}</td>
            </ng-container>
    
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                <td mat-cell *matCellDef="let campEntity"> {{campEntity.id}} </td>
            </ng-container>
    
            <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> country </th>
                <td mat-cell *matCellDef="let campEntity"> {{campEntity.country}} </td>
            </ng-container>
    
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> email </th>
                <td mat-cell *matCellDef="let campEntity"> {{campEntity.email}} </td>
            </ng-container>
    
            <ng-container matColumnDef="intranetAccessProfile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> intranetAccessProfile </th>
                <td mat-cell *matCellDef="let campEntity"> {{campEntity.intranetAccessProfile}} </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="selectRow(row)" *matRowDef="let row; columns: displayedColumns;"></tr>        
    
        </table>
    
        <mat-paginator #searchResultsPaginator [pageSizeOptions]="[10, 20, 40]" showFirstLastButtons></mat-paginator>
    
    
        <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
            <div class="btn-group mr-2" role="group">
                <button class="button" type="button" (click)="back()">back</button>
            </div>
            <div class="btn-group mr-2" role="group">
                <button class="button" type="button" mat-dialog-close>close</button>                                 
            </div>
        </div>
    
    
    </div>
</mat-dialog-content>