import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DataProtectionClassification } from 'src/app/models/directory/enums/DataProtectionClassification.enum';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { DirectoryGroupUpdateRequest } from 'src/app/models/directory/groups/DirectoryGroupUpdateRequest';
import { DirectoryGroupUpdates } from 'src/app/models/directory/groups/DirectoryGroupUpdates';
import { DirectoryGroupService } from 'src/app/services/directory/DirectoryGroup.service';


@Component({
  selector: 'request-details-group-update',
  templateUrl: './request-details-group-update.component.html'
})

export class RequestDetailsGroupUpdate implements OnChanges {
  @Input() requestData: DirectoryGroupUpdateRequest;
  @Output() doneLoading = new EventEmitter<boolean>();

  displayColumns: string[] = ['displayName', 'newServiceId', 'newDataProtection', 'newWriteBackEnabled'];
  displayObjects: any[] = [];
  constructor(
    private directoryGroupService: DirectoryGroupService,
  ) { }


  async ngOnChanges(): Promise<void> {
    var updatedGroups = await this.loadUpdatedGroups(this.requestData.groupUpdates.map(x => x.id));
    this.displayObjects = this.requestData.groupUpdates.map((group: DirectoryGroupUpdates) => {
      const updatedGroup = updatedGroups.find(x => x.id === group.id);
      if (updatedGroup) {
        group.displayName = updatedGroup.displayName;
      }
      else {
        group.displayName = group.id;
      }

      return {
        displayName: group.displayName,
        newServiceId: group.newApplicationId,
        newDataProtection: `${group.newDataProtection}` as DataProtectionClassification,
        newWriteBackEnabled: group.newWritebackEnabled,
        copyId: group.id,
      }
    });
    this.doneLoading.emit(true);
  }

  async loadUpdatedGroups(ids: string[]) {
    return await this.directoryGroupService.GetGroupsByIdsAsync(DirectoryDomain.Chevron, ids);
  }
  isDoneLoading() {
    this.doneLoading.emit(true);
  }
}
