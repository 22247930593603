import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'download-button',
    templateUrl: './download-button.component.html',
    styleUrls: ['./download-button.component.css']
})
export class DownloadButtonComponent {
    @Input() toolTip: string = 'download';
    @Input() class: string = 'float-right';
}