import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { UpdateGuestUserRequest } from "src/app/models/guestAccounts/UpdateGuestUserRequest";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-guest-update',
    templateUrl: './request-details-guest-update.component.html'
})

export class RequestDetailsGuestUpdate implements OnChanges {
    @Input() requestData: UpdateGuestUserRequest;
    @Output() doneLoading = new EventEmitter<boolean>();
    userColumns: string[] = ['displayName', 'mail', 'firstName', 'lastName', 'steward', 'copyId'];
    usersUpdateDetails: any = [];

    constructor(
        private directoryUserService: DirectoryUserService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData) {
            const guestUsers = await this.loadUpdatedGuests(this.requestData.guestUsers.map(x => x.userId));
            const stewards = this.requestData.guestUsers
                .filter(x => x.stewardUserId).map(x => x.stewardUserId);
            const uniqueStewardIds = [... new Set(stewards)]

            let stewardUsers: IDirectoryUser[] = []
            if (uniqueStewardIds && uniqueStewardIds.length > 0) {
                stewardUsers = await this.getStewardUsers(uniqueStewardIds as string[])
            }

            this.usersUpdateDetails = [...this.requestData.guestUsers.map((user: any) => {
                const updatedUserInfo = guestUsers.find(x => x.id === user.userId);
                if (updatedUserInfo) {
                    user.displayName = updatedUserInfo.displayName;
                    user.mail = updatedUserInfo.mail;
                    user.id = updatedUserInfo.id;
                }
                else {
                    user.displayName = `unknown guest (${user.userId})`;
                    user.mail = "unknown";
                    user.id = user.userId;
                }
                user.firstName = user.givenName;
                user.lastName = user.surname;
                const stewardUser = stewardUsers.find(steward => steward.id === user.stewardUserId);
                if (stewardUser) {
                    user.steward = stewardUser.displayName;
                }

                // adjust for copyId
                return {
                    displayName: user.displayName,
                    mail: user.mail,
                    copyId: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    steward: user.steward
                };
            })];
            this.isDoneLoading();
        }
    }

    async loadUpdatedGuests(ids: string[]) {
        return await this.directoryUserService.GetByIdsAsync({ ids, domain: DirectoryDomain.Chevron });
    }

    async getStewardUsers(stewardIds: string[]) {
        return await this.directoryUserService.GetByIdsAsync({ ids: stewardIds, domain: DirectoryDomain.Chevron });
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}