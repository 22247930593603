/**
 * Patch payload for endpoint /api/users/{domain}/updateGuest
 */
export class UpdateGuestUserRequest {
    constructor(
        guestUsers: GuestUser[]
    ) {
        this.guestUsers = guestUsers;
    }
    guestUsers: GuestUser[] = [];
}

export interface GuestUser {
    userId: string,
    givenName: string,
    surname: string,
    stewardUserId?: string,
}