<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <h2>{{ data.dialogTitle }}</h2>
    
            <generic-search-results 
                [showHeader]="false" 
                [records]="data.searchResults" 
                [title]="data.title"
                [showCounter]="data.showCounter"
                [selectableRecords]="data.selectableRecords"
                [displayedColumns]="data.displayColumns" 
                [allowLocalFilter]="data.allowLocalFilter"
                (recordSelected)="recordSelected($event)">
            </generic-search-results>
    
            <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
                <div class="btn-group mr-2" role="group">
                    <button class="button" type="button" mat-dialog-close>close</button>                                 
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>