import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DeleteSecretRequestDetails } from "src/app/models/secretServer/DeleteSecretRequestDetails";
import { SsSecret } from "src/app/models/secretServer/SsSecret";

@Component({
    selector: 'request-details-secret-delete',
    templateUrl: './request-details-secret-delete.component.html'
})

export class RequestDetailsSecretDelete implements OnInit {

    @Input() requestData:DeleteSecretRequestDetails;
    @Output() doneLoading = new EventEmitter<boolean>();

    secretColumns:string[] = ['name','folder','id'];
    secretsDeleted:SsSecret[] = [];

    async ngOnInit(): Promise<void> {
        this.secretsDeleted = this.requestData.secrets
        this.isDoneLoading();
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}