import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PageLayout } from "@cvx/nextpage";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { CalAngularService, ICvxClaimsPrincipal } from "@cvx/cal-angular";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { DialogAppSearchChevronComponent } from "../../_shared/dialog-app-search-chevron/dialog-app-search-chevron.component";
import { IDialogAppSearchChevronData } from "src/app/models/idm/applications/IDialogAppSearchChevronData";
import { IIdmApplication, IdmApplication } from "src/app/models/idm/applications/IdmApplication";
import { MatDialog } from "@angular/material/dialog";
import { ToStringHelper } from "src/app/helpers/ToStringHelper";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { GraphApplicationUpdate, GraphApplicationUpdateForm } from "src/app/models/graph/applications/GraphApplicationUpdate";
import { GraphApplicationsUpdateRequest } from "src/app/models/graph/applications/GraphApplicationsUpdateRequest";

@Component({
  selector: 'app-update-application',
  templateUrl: './update-application.component.html'
})
export class UpdateApplicationComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.applicationsDataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.applicationsDataSource.sort = value;
  }

  @Input() onlyApplicationToUpdate: GraphApplication | undefined = undefined;
  @Input() requestSubmittedEndRoute: string = '/applications/update';
  chevronApp: IIdmApplication | null = null;

  domain = DirectoryDomain.Chevron;
  updatingPassedApplications: boolean = false;
  gettingapplications: boolean = true;
  isSubmitting: boolean = false;
  selection: SelectionModel<GraphApplication> = new SelectionModel<GraphApplication>(true, []);;
  applicationUpdatesDataSource = new MatTableDataSource<GraphApplicationUpdateForm>([]);

  private commonColumns: string[] = ['displayName', 'appId', 'serviceId'];
  applicationsDisplayedColumns: string[] = ['select', ...this.commonColumns];
  applicationsFormDisplayedColumns: string[] = [...this.commonColumns];
  applicationsFormDataColumns: string[] = [...this.applicationsFormDisplayedColumns, 'del'];
  PageLayout = PageLayout;
  submitErrorMessage = { message: '', errors: [] };
  submittedRequests: IWorkflowRequest[] = [];

  currentUserProfile: ICvxClaimsPrincipal;
  applicationsDataSource = new MatTableDataSource<GraphApplication>([]);

  constructor(private graphApplicationService: GraphApplicationService, private authService: CalAngularService, private dialog: MatDialog) {
  }

  async ngOnInit(): Promise<void> {
    console.log(this.onlyApplicationToUpdate);
    if (this.onlyApplicationToUpdate != undefined) {

      this.applicationsDataSource.data = [this.onlyApplicationToUpdate];
      this.applicationsDataSource.paginator = this.paginator
      this.applicationsDataSource.sort = this.sort
      this.select(this.onlyApplicationToUpdate);

      this.gettingapplications = false;
      this.updatingPassedApplications = true;
    }
    else {
      //this.graphApplicationService.GetOwned(undefined, true).subscribe({
      this.graphApplicationService.GetOwned().subscribe({
        next: (x: IGenericApiResponse<GraphApplication[]>) => {
          this.applicationsDataSource.data = x.data;
          this.applicationsDataSource.paginator = this.paginator
          this.applicationsDataSource.sort = this.sort
        },
        error: (e) => {
          console.error(e);
          this.gettingapplications = false;
        },
        complete: () => this.gettingapplications = false
      });
    }


  }
  deleteRow(row: GraphApplicationUpdate) {
    this.applicationUpdatesDataSource.data = [...this.applicationUpdatesDataSource.data.filter(g => g.id != row.id)];
    this.selection.deselect(row);
  }
  addapplicationUpdate(row: GraphApplication) {
    let applicationUpdate = row as GraphApplicationUpdateForm;

    applicationUpdate.applicationId = row.id;
    applicationUpdate.adminAppRolesToAdd = [];
    applicationUpdate.adminAppRolesToRemove = [];

    if (this.chevronApp != null) {
      applicationUpdate.chevronApp = this.chevronApp;
      applicationUpdate.newServiceId = this.chevronApp.serviceId;
    }
    else if (applicationUpdate.serviceId?.length > 0 && !Number.isNaN(parseInt(applicationUpdate.serviceId))) {
      let serviceIdOnlyChevronApp = new IdmApplication();
      serviceIdOnlyChevronApp.serviceId = applicationUpdate.serviceId;
      serviceIdOnlyChevronApp = ToStringHelper.overrideToString(serviceIdOnlyChevronApp);
      applicationUpdate.chevronApp = serviceIdOnlyChevronApp;
    }
    else {
      applicationUpdate.chevronApp = null;
    }

    this.applicationUpdatesDataSource.data = [...this.applicationUpdatesDataSource.data, applicationUpdate];
  }

  select(row: GraphApplication) {
    this.selection.toggle(row);

    // If the row is already in the application updates, remove it, otherwise add it
    if (this.applicationUpdatesDataSource.data.find(g => g.id == row.id)) {
      this.deleteRow(row as GraphApplicationUpdate);
    } else {
      this.addapplicationUpdate(row);
    }
  }
  public doFilter = (value: any) => {
    this.applicationsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
  }

  isValid(applicationUpdates: GraphApplicationUpdate) {
    return applicationUpdates.id !== null && (applicationUpdates.newServiceId !== null || (applicationUpdates.adminAppRolesToAdd?.length ?? 0) > 0 || (applicationUpdates.adminAppRolesToRemove?.length ?? 0) > 0);
  }
  async onSubmit() {

    // clear error messages
    this.submitErrorMessage.message = '';
    this.submitErrorMessage.errors = [];

    if (this.selection.selected.length > 0 && this.applicationUpdatesDataSource.data.some(g => this.isValid(g))) {
      this.isSubmitting = true;
      let payload = new GraphApplicationsUpdateRequest(this.domain, this.applicationUpdatesDataSource.data.filter(g => this.isValid(g)));

      const observer = {
        next: (x: IGenericApiResponseWithWorkflowRequest<GraphApplication>) => {
          this.submittedRequests = x.requests;
        },
        error: (err: any) => {
          this.submitErrorMessage.message = err.statusText;
          this.submitErrorMessage.errors = err?.error?.errors ?? [];
          this.isSubmitting = false;
        },
        complete: () => {
          this.isSubmitting = false;
        }
      };

      console.log(payload);
      let updateCall = this.graphApplicationService.UpdateApplications(payload);
      updateCall.subscribe(observer);
    }
  }

  searchForChevronApplication(id: string = ""): void {
    const appDialogRef = this.dialog.open(DialogAppSearchChevronComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        includeEnvironments: false
      } as IDialogAppSearchChevronData
    });

    appDialogRef.afterClosed().subscribe(result => {
      if (result) {
        let chevronApp = result as IIdmApplication;

        // override toString method to display displayName (as form control value will display [object Object] without this)
        chevronApp = ToStringHelper.overrideToString(chevronApp);

        if (id.length > 0) {
          this.applicationUpdatesDataSource.data = [...this.applicationUpdatesDataSource.data.map(g => {
            if (g.id === id) {
              g.newServiceId = chevronApp.serviceId;
              g.chevronApp = chevronApp;
            }
            return g;
          })];
        }
        else {
          this.chevronApp = chevronApp;

          this.applicationUpdatesDataSource.data = this.applicationUpdatesDataSource.data.map(g => {
            g.newServiceId = chevronApp.serviceId;
            g.chevronApp = chevronApp;
            return g;
          });
        }
      }
    });
  }
}
