export const appRegDomains: string[] = [
    'pasadenarefining.com',
    'chevronstores.com',
    'caltexgo.com',
    'caltex.com.vn',
    'chevronpac.com',
    'chevronlummusglobal.com',
    'chevronuktr.com',
    'xponentcard.com',
    'chevronenergy.com',
    'chevronlubricants.com',
    'cvxarea52.com',
    'deepstar.org',
    'chevronpartnershipprograms.com',
    'chevronadvocacynetwork.com',
    'anlngservices.com',
    'catchlightenergy.com',
    'sasolchevron.com',
    'caltex.com',
    'richmondrefinery.com',
    'chevronapc.onmicrosoft.com',
    'chevroneur.onmicrosoft.com',
    'chevrontexaco.net',
    'chevrontexaco.com',
    'tengizchevroil.net',
    'tengizchevroil.com',
    'tco.tengizchevroil.net',
    'chevron.mail.onmicrosoft.com',
    'chevron.onmicrosoft.com',
    'ct.chevrontexaco.net',
    'chevron.net',
    'chevron.com',
    'email.chevron.com',
    'unityvm.chevron.com',
    'wafrajo.com',
    'chevron.tech',
    'migratechevron.com',
    'o365.migratechevron.com',
    'beyond6.com'
]