import {
  Component,
  OnInit,
} from '@angular/core';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { RouteConstants } from 'src/app/models/common/enums/RouteConstants.enum';

@Component({
  selector: 'app-update-guest',
  templateUrl: './update-guest.component.html',
})
export class UpdateGuestComponent {
  userType: UserType = UserType.Guest;
  displayedColumns: string[] = ['displayName', 'mail', 'companyName'];
  route: string = RouteConstants.Guest;
}
