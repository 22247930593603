<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if(submittedRequests.length === 0) {

        @if (hasAdminRole && showAdminToggleOptions){
        <div class="row">
            <div class="col col-12">
                <fieldset class="pb-4">

                    <generic-button-toggles [options]="adminToggleOptions" title="permission role"
                        [initialSelection]="defaultRole"
                        (selectionChange)="handleToggleChange($event)"></generic-button-toggles>

                    <div class="toggle-description">
                        @if (!usingAdminRole)
                        {
                        only {{userTypeString}} accounts {{defaultRole}}ed by you will be displayed
                        }
                        @else
                        {
                        all {{userTypeString}} accounts regardless of {{defaultRole}}ship and status will be displayed
                        <div class="text-warning">this may take up to a minute to retrieve</div>
                        }
                    </div>
                </fieldset>
            </div>
        </div>
        }

        <div class="row">
            <div class="col col-12">
                <h1>update {{userTypeString}} account</h1>

                <form class="branded">
                    <fieldset class="pb-4">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>{{userTypeString}} accounts</strong></p>
                        </legend>

                        @if(!gettingUsers && usersDataSource.data.length == 0){
                        <div>
                            <display-http-error [message]="errorMessage" [errors]="[]"></display-http-error>
                        </div>}
                        @if(gettingUsers) {
                        <div class="container">
                            <div class="row justify-content-center col-sm-12">
                                <p>searching for {{userTypeString}} accounts...</p>
                            </div>
                            <div class="row justify-content-center col-sm-12">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div> }
                        <div class="container">
                            <div>
                                @if (!gettingUsers && usersDataSource.data.length > 0){
                                <filter-input (onKeyUp)="filter($event)"></filter-input>
                                }

                                <table id="users" [hidden]="gettingUsers || usersDataSource.data.length == 0" mat-table
                                    [dataSource]="usersDataSource" matSort matSortActive="displayName"
                                    matSortDirection="asc" aria-describedby="users you have access to">
                                    <caption>{{userTypeString}} accounts that can be updated</caption>
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            @if(showSelectAllCheckbox && !usingAdminRole) {
                                            <input type="checkbox" (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()" />
                                            }
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <input MatCheckbox type="checkbox" name="formcheckbox"
                                                (click)="$event.stopPropagation()" (change)="select(row)"
                                                [checked]="selection.isSelected(row)" />
                                        </td>
                                    </ng-container>

                                    @for (disCol of selectedDisplayCols; track disCol; let colIndex = $index) {
                                    <ng-container matColumnDef="{{disCol}}">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{disCol | convertDisplayLabels}}
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            @if (isArrayWithElements(element[disCol])) {
                                            <ng-container>

                                                @for(item of element[disCol]; track item){
                                                <div><text-formatter [content]="item"></text-formatter></div>
                                                }
                                            </ng-container>
                                            } @else {
                                            <ng-container>
                                                <text-formatter [content]="element[disCol]"></text-formatter>
                                            </ng-container>
                                            }
                                        </td>
                                    </ng-container>
                                    }
                                    <tr mat-header-row *matHeaderRowDef="cols"></tr>
                                    <tr mat-row *matRowDef="let row; columns: cols;"></tr>
                                </table>
                                <mat-paginator [hidden]="gettingUsers || usersDataSource.data.length == 0"
                                    [length]="usersDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]"
                                    showFirstLastButtons></mat-paginator>
                            </div>
                        </div>
                    </fieldset>
                    @if(!gettingUsers && selection.selected.length > 0) {
                    <fieldset class="pb-4">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>selected {{userTypeString}} accounts to update</strong></p>
                        </legend>

                        <app-user-update-list (onRecordDeleted)="select($event)"
                            [caption]="userTypeString + ' accounts to update'" [records]="selection.selected"
                            [userType]="userType" [displayedColumns]="updateUserDisplayedColumns"
                            (onUserInfoChange)="onUserInfoChange($event)"
                            (onRecordDeleted)="onRecordDeleted($event)"></app-user-update-list>
                    </fieldset>

                    @if (usingAdminRole){
                    <mat-divider></mat-divider>

                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>justification</strong></p>
                        </legend>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100"
                                    matTooltip="Justification for permissions request" subscriptSizing="dynamic">
                                    <mat-label>justification</mat-label>
                                    <input matInput [formControl]="justification">
                                    <mat-error>Justification is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>
                    }

                    }

                    <mat-divider></mat-divider>

                    <display-http-error [message]="submitErrorMessage.message"
                        [errors]="submitErrorMessage.errors"></display-http-error>
                    @if(!gettingUsers) {
                    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                        @if(isSubmitting) {
                        <mat-spinner [diameter]="30" class="mat-spinner-search"></mat-spinner>}
                        @if(!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0) {
                        <div class="btn-group mr-2" role="group">
                            <button class="button" type="submit" (click)="onUpdateClick()">update</button>
                        </div>}
                    </div>}
                </form>
            </div>
        </div> }
        @if(submittedRequests.length > 0) {
        <div class="row">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/account/' + route + '/update']"><span
                        class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>}
    </section>
</main>