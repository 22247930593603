import { AbstractControl, ValidationErrors } from '@angular/forms';

export class NameValidators {
  static allCaps(control: AbstractControl): ValidationErrors | null {
    const value: string = control.value;

    if (!value) {
      return null;
    }

    const trimed = value.replace(/'/g, '')
      .replace(/,/g, '')
      .replace(/-/g, '')
      .replace(/ /g,"");

    if (trimed.length === 1) {
      return null;
    } else if (new RegExp(/^[A-Z]+$/).test(trimed)) {
      return {
        allCaps: true,
      };
    } else {
      return null;
    }
  }
}