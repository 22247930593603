import { GraphConstants } from "../Graph.constants";
import { GraphApplicationPermissions } from "./GraphApplicationPermissions";
import { GraphAppPermissionRequestModel } from "./GraphAppPermissionRequest";

/**
 * API model for
 *   POST: /api/applications
 */
export class GraphAppRequestModel {

    constructor(type:string,name:string,uri:string,owners:string[],urls:string[],requestedPermissions:GraphApplicationPermissions[],serviceId?:number) {
        this.type = type;
        this.name = name;
        this.uri = uri;
        this.owners = owners;
        this.serviceId = serviceId;
        this.urls = urls;

        this.requestedPermissions = requestedPermissions.map((item)=>{
            let scopes = item.permissions.filter(perm=> perm.type === GraphConstants.PermissionsScope).map(perm=>perm.id);
            let roles = item.permissions.filter(perm=> perm.type === GraphConstants.PermissionsApp).map(perm=>perm.id);
            
            return new GraphAppPermissionRequestModel(item.application.servicePrincipalId,scopes,roles,'');
        });
    }

    type: string;
    name: string;
    uri: string;
    owners: string[];
    serviceId?: number;
    urls: string[];
    requestedPermissions: GraphAppPermissionRequestModel[];
}