<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if(hasPermissions())
        {
            <div class="row">
                <div class="col col-12">
                    @if (submittedRequests.length == 0)
                    {
                        <h1>little green button</h1>
                        <h2>compromised sourcing company event</h2>
                        <fieldset class="pb-4">
                        
                            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                <p><strong> active events </strong></p>
                            </legend>
                            @if (doneLoading && relatedRequests.length > 0) {
                                <app-workflow-request-display 
                                [requests]="relatedRequests"
                                [includeCheckbox]="true"
                                [includeFilter]="true"
                                [includePaginator]="true"
                                [includeDelete]="false"
                                [(selectedRequests)]="selectedRequests">
                                </app-workflow-request-display>
                            }
                            @else if(doneLoading && relatedRequests.length == 0) {
                                <div class="row justify-content-center col-sm-12">
                                    <p>no active events</p>
                                </div>
                            }
                            @else {
                                <div class="row justify-content-center col-sm-12">
                                    <p>getting requests...</p>
                                </div>
                                <div class="row justify-content-center col-sm-12">
                                    <mat-spinner></mat-spinner>
                                </div>
                            }

                            @if (selectedRequests.length > 0) {
                                <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                    <p><strong> selected events </strong></p>
                                </legend>
                                <app-workflow-request-display 
                                [requests]="selectedRequests"
                                [includeCheckbox]="false"
                                [includeFilter]="false"
                                [includePaginator]="false"
                                [includeDelete]="true"
                                [(selectedRequests)]="selectedRequests">
                                </app-workflow-request-display>

                                <div class="row justify-content-center col-sm-12">

                                    <div class="container">
                                        <display-http-error [message]="submitErrorMessage" [errors]="[]"></display-http-error>
                                    </div>

                                    <div>
                                        @if(isSubmitting) {
                                            <mat-spinner [diameter]="30" class="mat-spinner-search"></mat-spinner>
                                        }
                                        @else { 
                                        <div class="container">
                                            <div class="btn-group mr-2" role="group">
                                                <button class="button" type="submit" (click)="onSubmit()" style="background-color:#769231;border-color:#769231;">submit</button>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                </div>
                            }
                        </fieldset>
                        
                    }
                    @else {
                        <div class="row">
                            <div class="col col-12">
                                <a class="close-button" [routerLink]="['/cyber/little-green-button']"><span
                                        class="icon-remove close-button"></span></a>
                                <h1>request submitted</h1>
                                <display-http-error [message]="submitErrorMessage"
                                    [errors]="[]"></display-http-error>
                                <display-requests [requests]="submittedRequests"></display-requests>
                            </div>
                        </div>}
                </div>
            </div>
        }   
        @else {
            <div class="row">
                <div class="col col-12">
                    <h1>access denied</h1>
                    <p style="text-align:center;color:red;">you do not have permission to access this page.</p>
                </div>
            </div>}
    </section>
</main>