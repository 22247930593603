import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { CalAngularService } from "@cvx/cal-angular";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { GraphApplication, IGraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { IGraphAppExposedPermissions } from "src/app/models/graph/applications/GraphAppExposedPermissions";
import { GraphApplicationPermissions, IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";
import { GraphConstants } from "src/app/models/graph/Graph.constants";
import { AppRoleAllowedMemberType } from "src/app/models/graph/applications/enums/AppRoleAllowedMemberType";
import { ApplicationRegistrationTenantType } from "src/app/models/ApplicationRegistrationTenantType";

@Component({
    selector: 'app-search',
    templateUrl: './app-search.component.html',
    styleUrls: ['./app-search.component.css']
})

export class AppSearchComponent implements OnInit {

    private permissionsPaginator!: MatPaginator;

    constructor(private authService: CalAngularService, private graphAppService: GraphApplicationService) { }

    @ViewChild('permissionsPaginator') set matPaginator(mp: MatPaginator) {
        this.permissionsPaginator = mp;
        this.setDataSourceAttributes();
    }
    setDataSourceAttributes() {
        this.permissionsDataSource.paginator = this.permissionsPaginator;
        this.permissionsDataSource.sort = this.sort;

        if (this.permissionsPaginator && this.sort) {
            this.doFilterPermissions('');
        }
    }
    @ViewChild(MatSort) sort!: MatSort;
    @Input() showTitle: boolean = true;
    @Input() isDialog: boolean = false;
    @Input() appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.Application;
    @Input() searchPermissions: boolean = true;
    // change search endpoint to one of the search by owners
    @Input() ownedApps: boolean = false;
    // determines whether to search by current user or box for user search
    @Input() allowOwnerSearch: boolean = false;
    @Input() servicePrincipals: boolean = false;
    @Input() includeNumberOfExposedPermissions: boolean = false;
    @Input() sortable: boolean = false;
    @Input() makeSticky: boolean = false;
    @Input() tenantType: ApplicationRegistrationTenantType = ApplicationRegistrationTenantType.Chevron;

    @Output() appSelected = new EventEmitter<IGraphApplicationPermissions>();
    @Output() searchCancelled = new EventEmitter<{ searchCancelled: boolean }>();
    @Output() searchFoundNoExposedPermissions = new EventEmitter<boolean>();

    isSearching: boolean = false;
    screenMessage: string = '';
    objectType: string = '';

    searchTerm = new UntypedFormControl('');
    searchResults: IGraphApplication[] = [];
    displayedColumns: string[] = ['appId', 'displayName', 'serviceId'];

    permissionsDataSource = new MatTableDataSource<IGraphAppExposedPermissions>();
    permissionsDisplayedColumns: string[] = ['select', 'value', 'description', 'type'];

    selectedApp: IGraphApplication = new GraphApplication();
    selection = new SelectionModel<IGraphAppExposedPermissions>(true, []);

    appPermissionDisplayValues = GraphConstants.AppPermissionsDisplayValues;

    appSearchObserver = {
        next: (x: IGenericApiResponse<GraphApplication[]>) => {
            this.searchResults = x.data;
        },
        error: (err: any) => {
            this.screenMessage = err.statusText;
            this.isSearching = false;
        },
        complete: () => {
            this.screenMessage = this.searchResults.length === 0 ? 'no results found' : '';
            this.isSearching = false;
        }
    };

    async ngOnInit(): Promise<void> {
        if (this.includeNumberOfExposedPermissions) {
            this.displayedColumns.push("numberOfExposedPermissions");
        }

        if (this.ownedApps && !this.allowOwnerSearch) {
            this.isSearching = true;
            this.graphAppService.GetOwned(undefined, this.tenantType).subscribe(this.appSearchObserver);
        }

        this.objectType = this.servicePrincipals ? 'service principal' : 'application';
    }

    async search() {
        this.isSearching = true;
        this.screenMessage = ''; //hide the no records found

        let apiCall = this.servicePrincipals ?
            this.graphAppService.SearchServicePrincipal(this.searchTerm.value) :
            this.graphAppService.SearchApplication(this.searchTerm.value);

        apiCall.subscribe(this.appSearchObserver);
    }

    async selectApp(selected: IGraphApplication) {
        this.isSearching = true;

        const observer = {
            next: (x: IGenericApiResponse<IGraphApplication>) => {
                this.selectedApp = x.data;
            },
            error: (err: any) => {
                this.screenMessage = err.statusText;
                this.isSearching = false;
            },
            complete: () => {
                if (!this.searchPermissions) {
                    this.appSelected.emit(new GraphApplicationPermissions(this.selectedApp, []));
                    this.reset();
                }
                else {
                    if (this.selectedApp.exposedPermissions.length == 0) {
                        // no exposed permissions found after search, emit so components can react
                        this.searchFoundNoExposedPermissions.emit(true);
                    }

                    this.permissionsDataSource.paginator = this.permissionsPaginator;
                    this.isSearching = false;
                    this.permissionsDataSource.data = this.selectedApp.exposedPermissions;

                }
            }
        }
        let apiCall;
        if (!selected.id) {
            apiCall = this.graphAppService.GetServicePrincipal(selected.servicePrincipalId);
        }
        else {
            apiCall = this.servicePrincipals ?
                this.graphAppService.GetServicePrincipal(selected.id) :
                this.graphAppService.GetApplication(selected.id, this.appRoleAllowedMemberType);
        }
        apiCall.subscribe(observer);
    }

    selectPermission(row: IGraphAppExposedPermissions) {
        this.selection.toggle(row);
    }

    public doFilterPermissions = (value: any) => {
        this.permissionsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    reset() {
        this.searchTerm.setValue('');
        this.searchResults = [];
        this.selectedApp = new GraphApplication();
        this.selection.clear();
    }

    savePermission() {
        this.appSelected.emit(new GraphApplicationPermissions(this.selectedApp, this.selection.selected));
        this.reset();
    }
}