import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ICvxClaimsPrincipal } from "@cvx/cal";
import { CalAngularService } from "@cvx/cal-angular";
import { PageLayout } from "@cvx/nextpage";
import { lastValueFrom } from "rxjs";
import { csvHelper } from "src/app/helpers/exporter/csvHelper";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { IIdAndDomain } from "src/app/models/directory/IIdAndDomain";
import { DirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { WorkflowRequestTypes } from "src/app/models/requests/enums/WorkflowRequestTypes.enum";
import { WorkflowStates } from "src/app/models/requests/enums/WorkflowStates.enum";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { WorkflowRequestService } from "src/app/services/workflow/WorkflowRequestService";

@Component({
    selector: 'details-requests',
    templateUrl: './details-requests.component.html'
})

export class DetailsRequestsComponent implements OnInit {

    cancellableRequestStates: WorkflowStates[] = [
        WorkflowStates.PendingApproval
    ];
    /**
     * page rendering controls
     */
    PageLayout = PageLayout;
    canCancel: boolean = false;;
    isCancelling: boolean = false;
    cancellErrorMessage = { message: '', errors: [] };
    isSearching: boolean = false;
    screenMessage: string = '';
    loggedIn: boolean;
    currentUserProfile: ICvxClaimsPrincipal;
    doneLoading: boolean = false;    // all elements are done loading
    doneLoadingDetails: boolean = false;
    doneLoadingApprovals: boolean = false;

    /**
     * route parameters
     */
    @Input() type: WorkflowRequestTypes;
    @Input() id: string;
    /**
     * loaded data
     */
    workflowRequest: IWorkflowRequest;
    requestor: DirectoryObjectBase[] = []; // this doesn't technically need to be an array
    approvers: DirectoryObjectBase[] = [];
    affectedObjectsDisplayColumns = ['id', 'displayName', 'idmManagedObjectType', 'idmPrincipalName'];

    requestDisplayObject: IRequestDetailsDisplayObject;
    requestDisplayProperties: string[] = [];

    get WorkflowRequestTypes() { return WorkflowRequestTypes; }

    constructor(
        private route: ActivatedRoute,
        private authService: CalAngularService,
        private workflowRequestService: WorkflowRequestService,
        private directoryUserService: DirectoryUserService,
        private graphAppService: GraphApplicationService) { }

    async ngOnInit(): Promise<void> {
        this.isSearching = true;

        if (await lastValueFrom(this.authService.isUserSignedIn())) {
            this.currentUserProfile = this.authService.cvxClaimsPrincipal;
        }

        this.route.params.subscribe(params => {
            this.type = this.type ?? params['type'];
            this.id = this.id ?? params['id'];

            if (this.type == WorkflowRequestTypes.CyberEventCompromisedSourcingCompanyCreate) {
                this.affectedObjectsDisplayColumns = [...this.affectedObjectsDisplayColumns, 'accountEnabled'];
            }

            this.loadWorkflowRequest();
        });
    }

    workflowObserver = {
        next: (x: IGenericApiResponse<IWorkflowRequest>) => {
            this.workflowRequest = x.data;
            this.workflowRequest.affectedObjects = this.workflowRequest.affectedObjects ?? [];
            this.checkCanCancel()
        },
        error: (err: any) => { },
        complete: () => {
            switch (this.workflowRequest.requestor.type) {
                case GroupMemberType.ServicePrincipal:
                    this.loadServicePrincipal(this.workflowRequest.requestor.id, this.requestor);
                    break;
                case GroupMemberType.User:
                    this.loadUsers([{ id: this.workflowRequest.requestor.id, domain: DirectoryDomain.Chevron } as IIdAndDomain], this.requestor);
                    break;
            }

            let approvers = this.workflowRequest.authorizedApprovers;
            this.loadUsers(approvers.map(aa => { return { id: aa.id, domain: DirectoryDomain.Chevron }; }), this.approvers);
            this.isDoneLoading();
        }
    }

    // cancel request
    checkCanCancel()
    {
        this.canCancel = this.cancellableRequestStates.some(x => x == this.workflowRequest.state) && this.workflowRequest.requestor.id == this.authService.cvxClaimsPrincipal.objectId
    }

    cancelRequest()
    {
        this.isCancelling = true;
        this.cancellErrorMessage.message = "";
        this.cancellErrorMessage.errors = [];

        const observer = {
            next: (x: IGenericApiResponse<IWorkflowRequest>) => {
              this.workflowRequest = x.data;
              this.checkCanCancel()
              this.isDoneLoading();
            },
            error: (err: any) => {
              console.log(err)
              this.cancellErrorMessage.message = err?.error ?? err.statusText;
              this.cancellErrorMessage.errors = err?.error?.errors ?? [];
              this.isCancelling = false;
            },
            complete: () => {
              this.isCancelling = false;
            }
          };
        this.workflowRequestService.CancelRequest(this.type, this.id).subscribe(observer);
    }
    // load the initial workflow request
    loadWorkflowRequest() {
        this.workflowRequestService.GetById(this.type, this.id).subscribe(this.workflowObserver);
    }

    async loadUsers(ids: IIdAndDomain[], array: DirectoryObjectBase[]): Promise<void> {
        let users: DirectoryUser[] = [];
        for (let id of ids) {
            try {
                const user = await this.directoryUserService.GetByIdAsync(id);
                users.push(user);
            }
            catch (error) {
                users.push({ displayName: `unknown user with id ${id.id}`, mail: '' } as DirectoryUser);
            }
        }
        array.push.apply(array, users);
        this.isDoneLoading();
    }

    async loadServicePrincipal(id: string, array: DirectoryObjectBase[]): Promise<void> {
        try {
            let spResponse = await this.graphAppService.GetServicePrincipalAsync(id);
            let sp = { id: spResponse.data.id, displayName: spResponse.data.displayName } as DirectoryObjectBase;
            array.push(sp);
        }
        catch (error) {
            array.push({ displayName: `unknown service principal with id ${id}` } as DirectoryObjectBase);
        }
    }

    /**
     * handler for when the sub components have completed loading
     * @param val 
     * @param comp 
     */
    componentLoaded(val: boolean, comp: string) {
        switch (comp) {
            case 'approvals': this.doneLoadingApprovals = true; break;
            case 'details': this.doneLoadingDetails = true; break;
        }

        this.isDoneLoading();
    }

    /**
     * check if this and all sub-components have completed loading
     */
    isDoneLoading() {
        let doneLoadingRequestInfo = this.workflowRequest !== undefined &&
            this.workflowRequest.authorizedApprovers.length === this.approvers.length &&
            this.requestor.length > 0;

        if (doneLoadingRequestInfo) {
            this.requestDisplayObject = {
                id: this.workflowRequest?.id,
                type: this.workflowRequest?.type,
                state: this.workflowRequest?.state,
                comments: this.workflowRequest?.comments,
                adminJustification: this.workflowRequest?.usingAdminRole ? this.workflowRequest?.adminJustification : '',
                createdDate: this.workflowRequest?.createDate,
                requestors: this.requestor.map(r => r.displayName),
                approvers: this.approvers.map(a => a.displayName),
                successes: this.workflowRequest?.successes ?? [],
                failures: this.workflowRequest?.failures ?? []
            };

            let properties = ['id', 'type', 'state'];

            if (this.workflowRequest?.usingAdminRole) {
                properties.push('adminJustification');
            }

            if (this.workflowRequest?.autoApproved) {
                properties.push('comments');
            }

            properties.push('createdDate');
            properties.push('requestors');

            if (this.requestDisplayObject.approvers.length !== 0) {
                properties.push('approvers');
            }

            if (this.requestDisplayObject.failures.length !== 0) {
                properties.push('failures');
            }

            this.requestDisplayProperties = properties;
        }

        if (doneLoadingRequestInfo && this.doneLoadingDetails && this.doneLoadingApprovals) {
            this.isSearching = false;
            this.doneLoading = true;
        }
    }
    exportToCsv() {
        const csvData = csvHelper.ConvertToCSV(this.workflowRequest.affectedObjects, this.affectedObjectsDisplayColumns);
        csvHelper.DownloadFile(csvData, `${this.workflowRequest?.id}-affected-objects-${new Date().toISOString()}`);
    }
}

interface IRequestDetailsDisplayObject {
    id: string;
    type: string;
    state: string;
    comments: string;
    adminJustification: string;
    createdDate: Date;
    requestors: string[];
    approvers: string[];
    successes: string[];
    failures: string[];
    
}