<display-form [displayObject]="displayObject"></display-form>

<generic-search-results
    [showHeader]="false"
    [showCounter]="false"
    [title]="'revoked permissions'"
    [records]="revokePermissionsDetails"
    [displayedColumns]="revokePermissionsDetailsColumns"
    [selectableRecords]="false"
    [allowLocalFilter]="false"
></generic-search-results>