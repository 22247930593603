<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if (createdSecret === undefined) {
        <div class="col col-12">
            <h1>register password</h1>
            <form class="branded" [formGroup]="passwordForm">

                <mat-error></mat-error>

                <fieldset>

                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>password information</strong></p>
                    </legend>

                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline" class="w-100" matTooltip="password type"
                                subscriptSizing="dynamic">
                                <mat-label>folder</mat-label>
                                <mat-select formControlName="folder">
                                    @for(folderType of folderTypes; track folderType)
                                    {
                                    <mat-option [value]="folderType">{{ folderType }}</mat-option>
                                    }
                                </mat-select>
                                @if (passwordForm.controls.folder.errors?.required ?? false) {
                                <mat-error>Folder is required</mat-error>
                                }
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6" style="margin-top: 10px;">
                            @if (isFolderPrivilege) { <i>Privilege</i> passwords cannot be shared with others }
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline" class="w-100" matTooltip="login name"
                                subscriptSizing="dynamic">
                                <mat-label>login name</mat-label>
                                <input matInput formControlName="name">
                                @if (passwordForm.controls.name.errors?.required ?? false) {
                                <mat-error>Login Name is required</mat-error> }
                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline" class="w-100"
                                matTooltip="{{ resourceDisplayName.toLowerCase() }} name" subscriptSizing="dynamic">
                                <mat-label>{{ resourceDisplayName.toLowerCase() }} name</mat-label>
                                <input matInput formControlName="resource">
                                @if (passwordForm.controls.resource.errors?.required ?? false) {
                                <mat-error>{{ resourceDisplayName }} Name is required</mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>

                </fieldset>

                <fieldset>
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>owners</strong></p>
                    </legend>
                    @if (!isFolderPrivilege || (isFolderPrivilege && owners.length == 0)) {
                    <div style="margin-bottom: 10px;">
                        <button mat-flat-button color="primary" (click)="searchForPrimaryUser('owners')"
                            class="add-button" type="button"><mat-icon>add</mat-icon> owner</button>
                    </div>
                    }
                    @if (passwordForm.controls.owners.invalid && passwordForm.controls.owners.touched) {
                    <mat-error>Requires at least 2 owners</mat-error>
                    }

                    <div class="row" formGroupName="owners">
                        <div class="col-12">
                            @for(owner of owners.controls; track owner; let i = $index)
                            {
                            <mat-form-field appearance="outline" class="w-100" [formGroupName]="i">
                                <mat-label>owner</mat-label>
                                <input matInput formControlName="displayName" [readonly]="true">
                                <button mat-icon-button matSuffix (click)="removeOwner(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-form-field>
                            }
                        </div>
                    </div>
                </fieldset>

                @if (!isFolderPrivilege) {
                <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                    <p><strong>viewers</strong></p>
                </legend>
                <div style="margin-bottom: 10px;">
                    <button mat-flat-button color="primary" (click)="searchForPrimaryUser('viewers')" class="add-button"
                        type="button"><mat-icon>add</mat-icon> viewer</button>
                </div>
                <div class="row" formGroupName="viewers">
                    <div class="col-12">
                        @for(viewer of viewers.controls;track viewer; let i = $index) {
                        <mat-form-field appearance="outline" class="w-100" [formGroupName]="i">
                            <mat-label>viewer</mat-label>
                            <input matInput formControlName="displayName" [readonly]="true">
                            <button mat-icon-button matSuffix (click)="removeViewer(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </mat-form-field>
                        }
                    </div>
                </div>
                }

                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                    @if (isCreating) {
                    <mat-spinner [diameter]="30"
                        style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    }
                    @else {
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="submit" (click)="onSubmit()">register</button>
                    </div>
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="button" [disabled]="isCreating"
                            (click)="resetForm()">cancel</button>
                    </div>
                    }
                </div>

                <display-http-error [message]="createErrorMessage.message"
                    [errors]="createErrorMessage.errors"></display-http-error>

            </form>
        </div>
        }
        @else
        {
        <div class="col col-12">
            <a class="close-button" (click)="createdSecret = undefined"><span
                    class="icon-remove close-button"></span></a>
            <h1>password registered</h1>
            <display-form [displayObject]="displayCreatedSecret"></display-form>

            <display-requests [requests]="createdSecret.requests"></display-requests>
        </div>
        }
    </section>
</main>