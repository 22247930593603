import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

export interface AppConfig {
    idampUserDefaultDomain: string;
    idampApiBaseUrl: string;
    primaryUserApiBaseUrl: string;
    idampOtherApiBaseUrl: string;
    alertInfoMessage: string;
    tokenScopes: string[];
}

@Injectable({ providedIn: 'root' })
export class AppConfigService {
    private config: AppConfig;
    loaded: boolean = false;

    constructor(private http: HttpClient) { }

    loadConfig(): Promise<void> {
        return lastValueFrom(this.http.get<AppConfig>('/assets/config.json')).then(data => {
            this.config = data;
            this.loaded = true;
        });
    }

    getConfig(): AppConfig { return this.config; }
}