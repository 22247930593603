
/**
 * API model for:
 *   POST: /api/applications
 */
export class GraphAppPermissionRequestModel {

    constructor(resourceId:string,scopes:string[],roles:string[],justification:string){
        this.resourceId = resourceId;
        this.scopes = scopes;
        this.roles = roles;
        this.justification = justification;
    }

    resourceId:string;
    scopes:string[];
    roles:string[];
    justification:string;
}