export enum WorkflowStates {
    None = 'None',
    PendingApproval = 'Pending Approval',
    Completed = 'Completed',
    PartiallyCompleted = 'Partially Completed',
    Failed = 'Failed',
    Cancelled = 'Cancelled',
    Rejected = 'Rejected',
    Approved = 'Approved',
    Submitted = 'Submitted'
}