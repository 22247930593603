import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { UpdateCocoUserRequest } from "src/app/models/cocoUser/UpdateCocoUserRequest";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-coco-update',
    templateUrl: './request-details-coco-update.component.html'
})
export class RequestDetailsCocoUpdate implements OnChanges {
    @Input() requestData: UpdateCocoUserRequest;
    @Output() doneLoading = new EventEmitter<boolean>();
    userColumns: string[] = ['displayName', 'mail', 'title', 'stationNumber', 'telephoneNumber', 'mobilePhone', 'steward', 'copyId'];
    usersUpdateDetails: any = [];

    constructor(
        private directoryUserService: DirectoryUserService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData) {
            const cocoUsers: IDirectoryUser[] = await this.loadUpdatedCocoUsers(this.requestData.cocoUsers.map(x => x.userId));
            const stewards = this.requestData.cocoUsers
                .filter(x => x.stewardUserId).map(x => x.stewardUserId);
            const uniqueStewardIds = [... new Set(stewards)]

            let stewardUsers: IDirectoryUser[] = []
            if (uniqueStewardIds && uniqueStewardIds.length > 0) {
                stewardUsers = await this.getStewardUsers(uniqueStewardIds as string[])
            }

            this.usersUpdateDetails = [...this.requestData.cocoUsers.map((user: any) => {
                const updatedUserInfo = cocoUsers.find(x => x.id === user.userId);
                if (updatedUserInfo) {
                    user.displayName = updatedUserInfo.displayName;
                    user.mail = updatedUserInfo.mail;
                    user.id = updatedUserInfo.id;
                }
                else {
                    user.displayName = `unknown coco user (${user.userId})`;
                    user.mail = "unknown";
                    user.id = user.userId;
                }
                const stewardUser = stewardUsers.find(steward => steward.id === user.stewardUserId);
                if (stewardUser) {
                    user.steward = stewardUser.displayName;
                }

                // adjust for copyId
                return {
                    displayName: user.displayName,
                    mail: user.mail,
                    copyId: user.id,
                    title: user.jobTitle,
                    telephoneNumber: user.telephoneNumber,
                    mobilePhone: user.mobilePhone,
                    stationNumber: user.stationNumber,
                    steward: user.steward
                };
            })];
            this.isDoneLoading();
        }
    }

    async loadUpdatedCocoUsers(ids: string[]) {
        return await this.directoryUserService.GetByIdsAsync({ ids, domain: DirectoryDomain.Chevron });
    }

    async getStewardUsers(stewardIds: string[]) {
        return await this.directoryUserService.GetByIdsAsync({ ids: stewardIds, domain: DirectoryDomain.Chevron });
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}