import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CloseCyberEventCompromisedSourcingCompanyRequest } from "src/app/models/cyber/CloseCyberEventCompromisedSourcingCompanyRequest";
import { WorkflowRequestTypes } from "src/app/models/requests/enums/WorkflowRequestTypes.enum";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { WorkflowRequestService } from "src/app/services/workflow/WorkflowRequestService";

@Component({
    selector: 'request-details-compromised-sourcing-company-close',
    templateUrl: './request-details-compromised-sourcing-company-close.component.html'
})

export class RequestDetailsCompromisedSourcingCompanyClose implements OnInit {

    @Input() requestData: CloseCyberEventCompromisedSourcingCompanyRequest;
    @Output() doneLoading = new EventEmitter<boolean>();
    requestDisplayColumns: string[] = ['ids'];
    requests: IWorkflowRequest[] = [];

    constructor(private workflowRequestService: WorkflowRequestService){}

    ngOnInit() {
        this.getRequests();
    }

    getRequests() {
        this.requestData.ids.forEach(id => this.workflowRequestService.GetById(WorkflowRequestTypes.CyberEventCompromisedSourcingCompanyCreate, id).subscribe({
            next: (response) => {
                this.requests = [...this.requests, response.data];
            },
            error: (e) => {
                console.error(e);
            },
            complete: () => {
                if (this.requests.length === this.requestData.ids.length) {
                    this.doneLoading.emit(true);
                }
            }
        }))
    }
}