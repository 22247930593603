import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { DialogRequestDetailsComponent } from '../dialog-request-details/dialog-request-details.component';
import { SelectionModel } from '@angular/cdk/collections';
import { matTableSelectionHelper } from 'src/app/helpers/matTableSelectionHelper';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-workflow-request-display',
    templateUrl: './workflow-request-display.component.html'
})
export class WorkflowRequestDisplayComponent implements OnInit, OnChanges{
    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
        this.requestsDataSource.paginator = value;
    }
    @ViewChild(MatSort, { static: false })
    set sort(value: MatSort) {
        this.requestsDataSource.sort = value;
    }
    
    @Input() requests: IWorkflowRequest[] = [];
    @Input() includeCheckbox: boolean = false;
    @Input() includeFilter: boolean = false;
    @Input() includeDelete: boolean = false;
    @Input() includePaginator: boolean = false;
    @Input() selectedRequests: IWorkflowRequest[] = [];
    @Output() selectedRequestsChange: EventEmitter<IWorkflowRequest[]> = new EventEmitter<IWorkflowRequest[]>();
    
    coreCols: string[] = ['id', 'type', 'state', 'createDate'];
    displayCols: string[] = [...this.coreCols, 'info'];
    requestsDataSource = new MatTableDataSource<IWorkflowRequest>([]);
    selection = new SelectionModel<IWorkflowRequest>(true, []);
    
    constructor(private dialog: MatDialog) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.requests) {
            this.requestsDataSource.data = this.requests;
        }
        if (changes.selectedRequests) {
            this.selection.setSelection(...this.selectedRequests);
        }
    }

    ngOnInit(): void {
        this.requestsDataSource.paginator = this.paginator
        this.requestsDataSource.sort = this.sort;
        this.requestsDataSource.data = this.requests;
        this.displayCols = [...this.coreCols, 'info'];
        if (this.includeCheckbox) {
            this.displayCols = ['select', ...this.displayCols];
        }
        if (this.includeDelete) {
            this.displayCols = [...this.displayCols, 'del']
        }
    }

    filter = (value: any) => {
        this.requestsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    selectRow(row: IWorkflowRequest) {
        // open dialog with request details
        this.dialog.open(DialogRequestDetailsComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: { workflowRequest: row }
        });
    }

    deleteRow(row: IWorkflowRequest) {
        this.requestsDataSource.data = [...this.requestsDataSource.data.filter(g => g.id != row.id)];
        this.selection.deselect(row);
        this.selectedRequestsChange.emit(this.selection.selected);
    }

    select(row: IWorkflowRequest) {
        this.selection.toggle(row);
        this.selectedRequestsChange.emit(this.selection.selected);
    }

    //for checkbox select all use
    isAllSelected() {
        return matTableSelectionHelper.isAllSelected(this.selection, this.requestsDataSource);
    }
    masterToggle() {
        matTableSelectionHelper.toggleSelectAll(this.selection, this.requestsDataSource);
        this.selectedRequestsChange.emit(this.selection.selected);
    }
}
