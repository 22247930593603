<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <div class="stickyHeader">
                <a class="close-button stickyHeader" (click)="searchCancelled()"><span
                        class="icon-remove close-button"></span></a>
                <h2>chevron application search</h2>
            </div>

            <div class="dialog-wrapper adjustStickyHeader">
                <app-search-chevron [makeSticky]="true" [isDialog]="true"
                    [includeEnvironments]="data.includeEnvironments" (appSelected)="appSelected($event)"
                    (searchCancelled)="searchCancelled()">
                </app-search-chevron>
            </div>
        </div>
    </div>
</mat-dialog-content>