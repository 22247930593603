import { Component } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { IBulkInviteGuestRequest } from 'src/app/models/guestAccounts/IBulkInviteGuestRequest';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, map, of, startWith } from 'rxjs';
import { GroupMemberType } from 'src/app/models/directory/enums/GroupMemberType.enum';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { IDialogDirectoryData } from 'src/app/models/components/IDialogDirectoryData';
import { ValidationPatternConstants } from 'src/app/constants/ValidationPattern.constants';
import { InviteGuestUserRequest } from 'src/app/models/guestAccounts/InviteGuestUserRequest';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogDirectorySearchComponent } from 'src/app/components/_shared/dialog-directory-search/dialog-directory-search.component';
import CountriesList from '../invite-guest/CountriesList';
import { CampEntity } from 'src/app/models/campEntity';
import { DialogCampEntitySearchComponent } from 'src/app/components/_shared/dialog-camp-entity-search/dialog-camp-entity-search.component';
import { BatchAddGuestsComponent, MaxAllowedBulkInviteGuestBatchSize } from './batch-add-guests/batch-add-guests.component';
import { UserHelper } from 'src/app/helpers/userHelper';
import { FormHelper } from 'src/app/helpers/formHelper';

@Component({
  selector: 'app-invite-guest',
  templateUrl: './invite-guest.component.html',
  styleUrls: ['./invite-guest.component.css'],
})
export class InviteGuestComponent {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  isInviteProcessActive: boolean = false;
  hasPermission: boolean = false;
  invitedGuests:
    | IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>
    | undefined;
  displayInvitedGuests: any[] = [];
  createErrorMessage = { message: '', errors: [] };

  permanentAddresses = CountriesList;
  inviteUserForm: FormGroup = new FormGroup({});
  filterOptions: Observable<any[]> = of(CountriesList);
  isFormSubmitted: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly authService: CalAngularService,
    private readonly graphUserService: GraphUserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.hasPermission = this.graphUserService
      .CheckUserGuestSponsorshipPermission(this.authService.cvxClaimsPrincipal);
    if (this.hasPermission) {
      this.init();
    }
  }

  init(): void {
    this.initForm();
    this.filterOptions = this.inviteUserForm.controls[
      'residence_name'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string)
          : this.permanentAddresses.slice();
      })
    );
  }

  get groups() {
    return this.inviteUserForm.get('groups') as FormArray;
  }

  get guests() {
    return this.inviteUserForm.get('guests') as FormArray;
  }

  onBatchAddGuests() {
    const dialogRef = this.dialog.open(BatchAddGuestsComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%'
    });
    dialogRef.afterClosed().subscribe((guestsToAdd: any[]) => {
      if (guestsToAdd) {
        guestsToAdd.forEach((guest: any) =>
          this.addGuestToForm(guest.givenName, guest.surname, guest.emailId)
        );
      }
    });
  }

  onRemoveGuest(guestIndex: number) {
    this.guests.removeAt(guestIndex);
  }

  onAddGuest() {
    this.addGuestToForm('', '', '');
  }

  addGuestToForm(givenName: string, surname: string, emailId: string) {
    this.guests.insert(
      0,
      this.formBuilder.group({
        givenName: [
          givenName,
          Validators.compose([
            Validators.required,
            Validators.maxLength(64),
            Validators.pattern(ValidationPatternConstants.NamePattern),
          ]),
        ],
        surname: [
          surname,
          Validators.compose([
            Validators.required,
            Validators.maxLength(64),
            Validators.pattern(ValidationPatternConstants.NamePattern),
          ]),
        ],
        emailId: [
          emailId,
          Validators.compose([
            Validators.required,
            Validators.maxLength(129),
            Validators.pattern(ValidationPatternConstants.UserEmailIdPattern),
          ]),
        ],
      })
    );
  }

  removeGroups(i: number) {
    this.groups.removeAt(i);
  }

  searchForGroups() {
    const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        groupOwned: true,
        type: GroupMemberType.Group,
        domain: DirectoryDomain.Chevron,
        filterGroupRemoveNotAvailableAsGroupMember: false,
        filterGroupRemoveDynamicMembershipEnabled: true,
        filterGroupOnlyManagedByIdamp: false,
        filterGroupRemoveNonAzureGroups: true,
      } as IDialogDirectoryData,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.addGroup(result.id, result.displayName);
      }
    });
  }

  addGroup(id: string, name: string) {
    let listOfGroups = this.groups;
    let isInList =
      listOfGroups.controls.findIndex((o: any) => o.value.id == id) > -1;
    isInList =
      isInList ||
      this.groups.controls.findIndex((o: any) => o.value.id == id) > -1;

    if (!isInList)
      listOfGroups.push(
        this.formBuilder.group({
          id: [id],
          displayName: [name],
        })
      );
  }

  private _filter(value: string): Array<{ name: string; code: string }> {
    const filterValue = value.toLowerCase();
    return this.permanentAddresses.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(data: any): string {
    return data?.name ?? '';
  }

  onValueSelected = (data: any) => {
    this.inviteUserForm.patchValue({ locationName: data.code });
    this.inviteUserForm.get('residence_name')?.setErrors(null);
  };

  initForm() {
    this.inviteUserForm = this.formBuilder.group({
      companyName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.CompanyNamePattern),
        ]),
      ],
      sourcingCompanyDisplay: [""],
      sourcingCompanyId: [""],
      locationName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(ValidationPatternConstants.LocationNamePattern),
        ]),
      ],
      residence_name: new FormControl("", Validators.required),
      inviteRedirectUrl: [
        '',
        Validators.compose([
          Validators.maxLength(256),
          Validators.pattern(ValidationPatternConstants.URLPattern),
        ]),
      ],
      groups: this.formBuilder.array([], Validators.minLength(0)),
      guests: this.formBuilder.array([], Validators.minLength(0)),
      responsibilitiesBox: [false, Validators.compose([Validators.required])],
    });
  }

  resetForm(clearAll: boolean = true) {
    //When guest is invited clearAll is passed false so we clear the guests information and we retain everything else
    //When we click on Cancel button clearAll is true and we clear everything
    if (clearAll) {
      this.inviteUserForm.controls['companyName'].reset();
      this.inviteUserForm.controls['companyName'].setErrors(null);
      this.inviteUserForm.controls['sourcingCompanyDisplay'].reset();
      this.inviteUserForm.controls['sourcingCompanyDisplay'].setErrors(null);
      this.inviteUserForm.controls['sourcingCompanyId'].reset();
      this.inviteUserForm.controls['sourcingCompanyId'].setErrors(null);
      this.inviteUserForm.controls['locationName'].setErrors(null);
      this.inviteUserForm.controls['residence_name'].reset();
      this.inviteUserForm.controls['residence_name'].setValue('');
      this.inviteUserForm.controls['residence_name'].setErrors(null);
      this.inviteUserForm.controls['inviteRedirectUrl'].reset();
      this.inviteUserForm.controls['responsibilitiesBox'].reset();
      this.inviteUserForm.controls['responsibilitiesBox'].setErrors(null);
      // retain the groups, as it is easier to remove than search again
      (this.inviteUserForm.controls['groups'] as FormArray).clear();
      (this.inviteUserForm.controls['guests'] as FormArray).clear();
    } else {
      (this.inviteUserForm.controls['guests'] as FormArray).clear();
    }

    // clear the invited guests last, as we want as smooth of a transition as possible after a successful invite request
    this.invitedGuests = undefined;
  }

  onSubmit(_inviteUserForm?: FormGroup) {
    this.isFormSubmitted = true;
    this.createErrorMessage.message = '';
    this.createErrorMessage.errors = [];

    const formData = this.inviteUserForm?.value;
    //handle empty fields if required
    let formFilled = true;
    const statement = document.getElementById('acceptStatement');
    if (!formData.responsibilitiesBox) {
      if (statement != null) {
        statement.style.color = 'red';
        formFilled = false;
      }
    } else {
      if (statement != null) {
        statement.style.color = 'black';
      }
    }
    FormHelper.validateFormAndUpdateValueAndValidity(this.inviteUserForm);
    if (this.inviteUserForm?.valid && formFilled === true) {
      if (this.guests?.value?.length === 0) {
        return;
      }
      else if (this.guests?.value?.length > MaxAllowedBulkInviteGuestBatchSize) {
        this.createErrorMessage.message = `Current list of ${this.guests?.value?.length} guests exceeds the max allowed batch size of ${MaxAllowedBulkInviteGuestBatchSize}`;
        return;
      }

      const duplicateEmailIds = UserHelper.findAndGetDuplicateEmailIds(this.guests?.value);
      if (duplicateEmailIds.length > 0) {
        this.createErrorMessage.message = `Current list contains duplicate email id(s) [${duplicateEmailIds.join(", ")}]`
        return;
      }

      const groupIdsToAssign = this.groups.value.map((item: any) => item.id);
      const guestsToInvite: InviteGuestUserRequest[] = this.guests.value.map((item: any) => {
        return new InviteGuestUserRequest(
          item.givenName,
          item.surname,
          formData.companyName,
          item.emailId,
          formData.inviteRedirectUrl,
          formData.locationName,
          formData.sourcingCompanyId,
          this.authService.cvxClaimsPrincipal.objectId,
          groupIdsToAssign
        );
      });
      this.bulkInviteGuest({ guestUsers: guestsToInvite });
    }
  }

  private bulkInviteGuest(bulkInviteGuestRequest: IBulkInviteGuestRequest) {
    this.isInviteProcessActive = true;

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>) => {
        this.invitedGuests = x;
        let data = x.data as IDirectoryUser[];
        this.displayInvitedGuests = data.map((guest) => {
          return {
            id: guest.id,
            name: guest.displayName,
            mail: guest.mail,
          };
        });
        this.createErrorMessage = { message: '', errors: [] };
      },
      error: (err: any) => {
        this.createErrorMessage.message = err.statusText;
        this.createErrorMessage.errors = err?.error?.errors ?? [];
        this.isFormSubmitted = false;
        this.isInviteProcessActive = false;
      },
      complete: () => {
        this.isFormSubmitted = false;
        this.isInviteProcessActive = false;
      },
    };

    this.graphUserService
      .BulkInviteGuests(bulkInviteGuestRequest)
      .subscribe(observer);
  }

  searchForCampEntity() {
    const dialogRef = this.dialog.open(DialogCampEntitySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%'
    });
    dialogRef.afterClosed().subscribe(result => {
      let model = result as CampEntity;
      if (model.id !== undefined) {
        this.patchCampEntityData(model);
      }
    });
  }

  patchCampEntityData(data: CampEntity) {
    if (data.id === "") {
      this.inviteUserForm.patchValue({
        sourcingCompanyDisplay: "invalid data"
      });
    } else {
      this.inviteUserForm.patchValue({
        companyName: data.name,
        sourcingCompanyId: data.id,
        sourcingCompanyDisplay: `${data.name} [${data.id}]`,
      });
    }
    this.inviteUserForm.get("sourcingCompanyDisplay")?.markAsTouched();
  }

  getCampError() {
    const control = this.inviteUserForm.get("sourcingCompanyDisplay");
    let returnMessage = ""
    if (control?.hasError("required")) {
      returnMessage = "This is a required field."
    }
    if (control?.hasError("pattern")) {
      if (control.value === "validation error") {
        returnMessage = "An unknown error occured when trying to validate the location id."
      }
      if (control.value === "invalid data") {
        returnMessage = "Invalid location data found, please select a new location."
      }
    }
    return returnMessage;
  }
}
