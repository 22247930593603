import { Injectable } from '@angular/core';
import { IdampHttpClient } from '../common/IdampHttpClient';
import { Observable, from } from 'rxjs';
import { IIdmApplication } from 'src/app/models/idm/applications/IdmApplication';
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';

@Injectable({
  providedIn: 'root'
})
export class ChevronApplicationService {

  constructor(private http: IdampHttpClient) { }

  public SearchApplication(term: string, includeEnvironments: boolean = false): Observable<IGenericApiResponse<IIdmApplication[]>> {
    const path = `chevronApplications`;
    const query = { searchTerm: term, filterActive: true, includeEnvironmentsInfo: includeEnvironments };
    return from(this.http.GetAsync<IGenericApiResponse<IIdmApplication[]>>(path, query));
  }

  public GetApplication(id: string, includeEnvironments: boolean = false): Observable<IGenericApiResponse<IIdmApplication>> {
    return from(this.GetApplicationAsync(id, includeEnvironments));
  }
  public async GetApplicationAsync(id: string, includeEnvironments: boolean = false): Promise<IGenericApiResponse<IIdmApplication>> {
    const path = `chevronApplications/${id}`;
    const query = { includeEnvironments: includeEnvironments };
    return await this.http.GetAsync<IGenericApiResponse<IIdmApplication>>(path, query);
  }
}
