<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12 ">
                <h2>authorization code validation</h2>

                <form class="branded" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                    <div class="row d-flex justify-content-center">

                        <div class="col-sm-12 col-md-3">
                            <mat-form-field appearance="outline" class="w-100"
                            subscriptSizing="dynamic">
                                <mat-label class="font-gotham-narrow-bold text-dark-blue">email</mat-label>
                                <input matInput formControlName="email" type="text" class="customInputTypeStyleOverride">
                            </mat-form-field>
                            <small class="text-light-red" *ngIf="email?.invalid && email?.touched">Invalid email address</small>
                        </div>
                        <div class="col-sm-12 col-md-3">
                            <mat-form-field appearance="outline" class="w-100"
                            subscriptSizing="dynamic">
                                <mat-label class="font-gotham-narrow-bold text-dark-blue">provisioning id</mat-label>
                                <input matInput formControlName="provisioningid" type="text" class="customInputTypeStyleOverride">
                            </mat-form-field>
                            <small class="text-light-red" *ngIf="provisioningid?.invalid && provisioningid?.touched">Invalid provisioning id</small>
                        </div>                    
                                              
                        <div class="col-sm-12 alert text-center" *ngIf="searchForm.errors?.['checkSingleFieldPopulated'] && (searchForm.touched || searchForm.dirty)">
                            <hr class="w-50" />
                            <p class="text-light-red">Exactly 1 of the fields above are required</p>
                        </div>

                    </div>

                    <hr class="w-50" />

                    <div class="d-flex justify-content-center">
                        <div class="col-sm-12 col-md-3">
                            <mat-form-field appearance="outline" class="w-100"
                            subscriptSizing="dynamic"> 
                                <mat-label class="font-gotham-narrow-bold text-dark-blue">authorization code</mat-label>
                                <input matInput [type]="hide? 'password':'text'" style="border: none;box-shadow: none;padding: 0px;" formControlName="authcode">
                                <button mat-icon-button matSuffix (click)="passwordHide()" type="button">
                                    <mat-icon>{{hide?'visibility_off':'visibility'}}</mat-icon>
                                </button>
                            </mat-form-field>
                            <small class="text-light-red" *ngIf="authcode?.invalid && authcode?.touched">This is a required field</small>
                        </div>
                    </div>

                    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4">
                        <div class="btn-group mr-2" role="group">
                            <mat-spinner diameter="40" *ngIf="IsChecking"></mat-spinner>
                            <button class="button button-group-spaced" type="submit" [disabled]="!searchForm.valid" [ngClass]="{'disabled-button': !searchForm.valid}" *ngIf="!IsChecking">validate</button>
                            <button class="button" type="reset">reset</button>
                        </div>
                    </div>

                    <div class="text-center" *ngIf="resultMessage.length > 0">
                        <p class="resultMessageColor">{{ resultMessage }}</p>
                    </div>                    

                </form>

            </div>
        </div>
    </section>
</main>
