<mat-form-field appearance="outline" class="col-12" subscriptSizing="dynamic">
  <mat-label class="font-gotham-narrow-bold text-dark-blue">Filter</mat-label>
  <input matInput placeholder="Enter search filter" (keyup)="applyTableFilter($event)">
</mat-form-field>

<mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="select" *ngIf="showCheckboxes">
    <mat-header-cell *matHeaderCellDef>
      <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="toggleRow(row)"
        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container *ngFor="let column of columns" [matColumnDef]="column.key">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="font-gotham-bold text-black"> {{column.header}}
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element[column.key]}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions" *ngIf="showActions">
    <mat-header-cell *matHeaderCellDef mat-sort-header class="font-gotham-bold text-black"> Actions </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <ng-container [ngTemplateOutlet]="rowActionsTemplate"
        [ngTemplateOutletContext]="{$implicit: element}"></ng-container>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

</mat-table>

<mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>