import { Component, OnInit, ViewChild } from "@angular/core";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GraphApplicationClientSecretRequestModel } from "src/app/models/graph/applications/GraphApplicationClientSecretRequestModel";

@Component({
  selector: 'app-register-client-secret',
  templateUrl: './register-client-secret.component.html',
  styleUrls: ['./register-client-secret.component.css']
})
export class RegisterClientSecretComponent implements OnInit{
    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
      this.appsDataSource.paginator = value;
    }
    @ViewChild(MatSort, { static: false })
    set sort(value: MatSort) {
      this.appsDataSource.sort = value;
    }

    gettingApps: boolean = true;
    isSubmitting: boolean = false;
    selection = new SelectionModel<GraphApplication>(true, []);
    appsDataSource = new MatTableDataSource<GraphApplication>([]);
    selectedAppsDisplayedColumns: string[] = ['displayName', 'appId'];
    appsDisplayedColumns: string[] = ['select', ...this.selectedAppsDisplayedColumns];
    PageLayout = PageLayout;
    submitErrorMessage = { message: '', errors: [] };
    submittedRequests: IWorkflowRequest[] = [];

    constructor(
        private graphApplicationService: GraphApplicationService,
    ) { }

  async ngOnInit(): Promise<void> {
    this.graphApplicationService.GetOwned().subscribe({
      next: (x: IGenericApiResponse<GraphApplication[]>) => {
        this.appsDataSource.data = x.data
        this.appsDataSource.paginator = this.paginator
        this.appsDataSource.sort = this.sort
      },
      error: (e) => {
        console.error(e);
        this.gettingApps = false;
      },
      complete: () => this.gettingApps = false
    });
  }

  select(row: GraphApplication) {
    this.selection.toggle(row);
  }

  async onSubmit() {

    // clear error messages
    this.submitErrorMessage.message = '';
    this.submitErrorMessage.errors = [];

    if (this.selection.selected.length > 0) {
      this.registerClientSecret(this.selection.selected);
    }
  }

  async registerClientSecret(apps: GraphApplication[]) {
    this.isSubmitting = true;

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<any>) => {
        this.submittedRequests = x.requests;
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      }
    };

    let requestCall = this.graphApplicationService.CreateApplicationClientSecret(new GraphApplicationClientSecretRequestModel(DirectoryDomain.Chevron, apps.map(a => a.id)));
    requestCall.subscribe(observer);
  }

  public doFilter = (value: any) => {
    this.appsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
  }
}
