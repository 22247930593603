<mat-dialog-content>
    <h2>batch add guests</h2>

    <div class="row">
        <div class="col-12">
            <div class="alert alert-info">
                <p>List of guests with guest details separated by semi-colons as [First Name];[Last Name];[Email]</p>
                <p class="p-0 m-0">Example</p>
                <p class="p-0 m-0" *ngFor="let sampleGuest of sampleGuests;">{{sampleGuest}}</p>
            </div>

            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                <mat-label>list of guests</mat-label>
                <textarea [(ngModel)]="guestDetails" matInput cdkTextareaAutosize cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="5"></textarea>
            </mat-form-field>

            @if(errorMessages.length>0){
            <div class="text-danger mt-3">
                <p class="p-0 m-0">The following guest details are invalid:</p>
                <p class="p-0 m-0" *ngFor="let error of errorMessages;">{{error}}</p>
            </div>
            }
        </div>
    </div>
    <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
        @if(guestDetails)
        {
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" (click)="onAdd()">add</button>
        </div>
        }
        <div class="btn-group mr-2" role="group">
            <button class="button" type="button" mat-dialog-close>close</button>
        </div>
    </div>
</mat-dialog-content>