import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { UserBulkRequestDetails } from "src/app/models/graph/UserBulkRequestDetails";

@Component({
    selector: 'request-details-account-unlock',
    templateUrl: './request-details-account-unlock.component.html'
})

export class RequestDetailsAccountUnlock implements OnInit {

    @Input() requestData: UserBulkRequestDetails;
    @Output() doneLoading = new EventEmitter<boolean>();

    accountColumns: string[] = ['userPrincipalName', 'type', 'domain', 'id'];
    accountsUnlocked: IDirectoryUser[] = [];

    async ngOnInit(): Promise<void> {
        this.accountsUnlocked = this.requestData.users
        this.isDoneLoading();
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}