import { GraphApplicationRoleAssignmentModel } from "./GraphApplicationRoleAssignment";

/**
 * API model for:
 *   POST: /api/applications/{{domain}}/bulkAssignAppRoles
 */
export class GraphApplicationRoleAssignmentRequestModel {

    constructor(domain: string, assignments: GraphApplicationRoleAssignmentModel[]){
        this.domain = domain;
        this.assignments = assignments;
    }

    domain: string;
    assignments: GraphApplicationRoleAssignmentModel[];
}