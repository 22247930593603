<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12">
                <h1>search primary user</h1>
                <app-primary-user-search (userSelected)="whenUserSelected($event)" *ngIf="selectedUser === undefined"></app-primary-user-search>
                <app-primary-user-details [user]="selectedUser" (cancelMe)="formCancelled()" *ngIf="selectedUser !== undefined"></app-primary-user-details>
            </div>
        </div>
    </section>
</main>
