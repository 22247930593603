import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { PageLayout } from "@cvx/nextpage";
import { RequestResultsType } from "src/app/models/components/enums/RequestResultsType";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";

@Component({
    selector: 'my-approval-requests',
    templateUrl: './my-approval-requests.component.html'
})

export class MyApprovalRequestsComponent {

    PageLayout = PageLayout;
    get RequestResultsType() { return RequestResultsType; };

    constructor(private router:Router){}
    
    selected(request:IWorkflowRequest) {
        this.router.navigate([`/requests/${request.type}/${request.id}`]);
    }

}