import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CreateCyberEventCompromisedSourcingCompanyRequest } from "src/app/models/cyber/CyberEventCompromisedSourcingCompanyRequest";

@Component({
    selector: 'request-details-compromised-sourcing-company-create',
    templateUrl: './request-details-compromised-sourcing-company-create.component.html'
})

export class RequestDetailsCompromisedSourcingCompanyCreate implements OnInit {

    @Input() requestData: CreateCyberEventCompromisedSourcingCompanyRequest;

    @Output() doneLoading = new EventEmitter<boolean>();
    criteriaDisplayColumns: string[] = ['userType', 'criteriaType', 'value'];

    ngOnInit() {
        this.doneLoading.emit(true);
    }
}