import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import { ValidationPatternConstants } from 'src/app/constants/ValidationPattern.constants';
import { CocoUserRequest } from 'src/app/models/cocoUser/CocoUserRequest';
import { CocoUserTitle } from 'src/app/models/cocoUser/CocoUserTitle.enum';

@Component({
  selector: 'app-create-coco-user',
  templateUrl: './create-coco-user.component.html',
  styleUrls: ['./create-coco-user.component.css'],
})
export class CreateCocoUserComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  isCreateProcessActive: boolean = false;

  createdUser: IGenericApiResponseWithWorkflowRequest<IDirectoryUser> | undefined;
  displayCreatedUser: any;
  createErrorMessage = { message: '', errors: [] };

  createUserForm: FormGroup = new FormGroup({});
  hasPermission: boolean = false;

  get titles() { return Object.values(CocoUserTitle) };

  constructor(
    private authService: CalAngularService,
    private formBuilder: FormBuilder,
    private graphUserService: GraphUserService
  ) {
  }

  ngOnInit() {
    this.hasPermission = this.graphUserService
      .CheckCocoUserSponsorshipPermission(this.authService.cvxClaimsPrincipal);
    if (this.hasPermission) {
      this.initForm();
    }
  }

  validateAllFields(formGroup: FormGroup) {
    formGroup.updateValueAndValidity();
  }

  initForm() {
    this.createUserForm = this.formBuilder.group({
      givenName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      surname: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      mailNickname: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(
            ValidationPatternConstants.MailNicknamePattern
          ),
        ]),
      ],
      stationNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(20),
          Validators.pattern(ValidationPatternConstants.NumberPattern),
        ]),
      ],
      telephoneNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(ValidationPatternConstants.PhoneNumberPattern),
        ]),
      ],
      mobilePhone: ['',
        Validators.compose([
          Validators.pattern(ValidationPatternConstants.PhoneNumberPattern),
        ]),],
      title: [
        '',
        Validators.compose([Validators.required]),
      ],
    });
  }

  onSubmit(_createUserForm?: FormGroup) {
    this.createErrorMessage.message = '';
    this.createErrorMessage.errors = [];
    this.validateAllFields(this.createUserForm);

    if (this.createUserForm?.valid) {
      this.createUser(this.getNewRequest(_createUserForm));
    }
  }

  private getNewRequest(_createUserForm?: FormGroup): CocoUserRequest {
    const formData = this.createUserForm?.value;
    const cocoUserRequest = new CocoUserRequest();
    cocoUserRequest.givenName = formData.givenName;
    cocoUserRequest.surname = formData.surname;
    cocoUserRequest.mailNickname = formData.mailNickname;
    cocoUserRequest.stationNumber = formData.stationNumber;
    cocoUserRequest.mobilePhone = formData.mobilePhone;
    cocoUserRequest.telephoneNumber = formData.telephoneNumber;
    cocoUserRequest.title = formData.title;
    cocoUserRequest.steward = this.authService.cvxClaimsPrincipal.objectId;
    return cocoUserRequest;
  }

  async createUser(cocoUserRequest: CocoUserRequest) {
    this.isCreateProcessActive = true;

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<IDirectoryUser>) => {
        this.createdUser = x;
        let data = x.data as IDirectoryUser;
        this.displayCreatedUser = {
          id: data.id,
          name: data.displayName,
          mail: data.userPrincipalName
        };
        this.createErrorMessage = { message: '', errors: [] };
      },
      error: (err: any) => {
        this.createErrorMessage.message = err.statusText;
        this.createErrorMessage.errors = err?.error?.errors ?? [];
        this.isCreateProcessActive = false;
      },
      complete: () => {
        this.isCreateProcessActive = false;
      }
    };

    this.graphUserService.CreateCoco(cocoUserRequest).subscribe(observer);
  }

  resetForm() {
    this.createUserForm.reset();
  }

  getMobileNumberErrorMessage(createUserForm: FormGroup<any>) {
    if (createUserForm.controls.mobilePhone.errors?.pattern) {
      return "Mobile Number is invalid";
    }
    return "";
  }

  getTelephoneNumberErrorMessage(createUserForm: FormGroup<any>) {
    if (createUserForm.controls.telephoneNumber.errors?.required) {
      return "Telephone Number is required";
    }
    else if (createUserForm.controls.telephoneNumber.errors?.pattern) {
      return "Telephone Number is invalid";
    }
    return "";
  }

  getStationNumberErrorMessage(createUserForm: FormGroup<any>) {
    if (createUserForm.controls.stationNumber.errors?.required) {
      return "Station Number is required";
    }
    else if (createUserForm.controls.stationNumber.errors?.pattern) {
      return "Station Number is invalid";
    }
    else if (createUserForm.controls['stationNumber'].hasError('minlength')) {
      return "Station Number must have at least 2 characters";
    }
    else if (createUserForm.controls['stationNumber'].hasError('maxlength')) {
      return `Station Number must be no more than ${createUserForm.controls['stationNumber'].errors?.maxlength.requiredLength} characters`;
    }
    return "";
  }
}
