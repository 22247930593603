<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12">
                <h1>manage primary user</h1>

                <form class="branded" *ngIf="IsAuthorised">

                    <app-primary-user-search (userSelected)="whenUserSelected($event)" [fromManageUser]="true" *ngIf="selectedUser === undefined"></app-primary-user-search>

                    <app-primary-user-details (cancelMe)="cancel()" [user]="selectedUser" [operationType]="'edit'" *ngIf="selectedUser !== undefined"></app-primary-user-details>

                    <button class="button" *ngIf="searchResults.length !== 0" (click)="cancel()">cancel</button>
                    
                </form>

                <div class="container" *ngIf="!IsAuthorised">
                    <div class="row justify-content-center col-sm-12">
                        <p>You are not authorised to manage users.</p>                               
                    </div>
                </div>

            </div>
        </div>
    </section>
</main>
