import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import {
  map,
  Observable,
  of,
  startWith,
} from 'rxjs';

import CountriesList from './CountriesList';
import { DialogDirectorySearchComponent } from 'src/app/components/_shared/dialog-directory-search/dialog-directory-search.component';
import { GroupMemberType } from 'src/app/models/directory/enums/GroupMemberType.enum';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { IDialogDirectoryData } from 'src/app/models/components/IDialogDirectoryData';
import { MatDialog } from '@angular/material/dialog';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { InviteGuestUserRequest } from 'src/app/models/guestAccounts/InviteGuestUserRequest';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import { ValidationPatternConstants } from 'src/app/constants/ValidationPattern.constants';

@Component({
  selector: 'app-invite-guest',
  templateUrl: './invite-guest.component.html',
  styleUrls: ['./invite-guest.component.css'],
})

export class InviteGuestComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  currentUserProfile: any = null;
  isInviteProcessActive: boolean = false;

  invitedGuest: IGenericApiResponseWithWorkflowRequest<IDirectoryUser> | undefined;
  displayInvitedGuest: any;
  createErrorMessage = { message: '', errors: [] };

  permanentAddresses = CountriesList;
  inviteUserForm: FormGroup = new FormGroup({});
  filterOptions: Observable<any[]> = of(CountriesList);

  constructor(
    private authService: CalAngularService,
    private formBuilder: FormBuilder,
    private graphUserService: GraphUserService,
    private dialog: MatDialog,
  ) {
  }

  hasPermission: boolean = false;
  ngOnInit() {
    this.hasPermission = this.graphUserService
      .CheckUserGuestSponsorshipPermission(this.authService.cvxClaimsPrincipal);
    if (this.hasPermission) {
      this.init();
    }
  }

  init(): void {
    this.initForm();
    this.filterOptions = this.inviteUserForm.controls[
      'residence_name'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => {
        const name = typeof value === 'string' ? value : value?.name;
        return name
          ? this._filter(name as string)
          : this.permanentAddresses.slice();
      })
    );
  }

  get groups() {
    return this.inviteUserForm.get('groups') as FormArray
  }

  removeGroups(i: number) {
    this.groups.removeAt(i);
  }

  searchForGroups() {
    const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        type: GroupMemberType.Group,
        domain: DirectoryDomain.Chevron,
        filterGroupRemoveNotAvailableAsGroupMember: false,
        filterGroupRemoveDynamicMembershipEnabled: true,
        filterGroupOnlyManagedByIdamp: false,
        filterGroupRemoveNonAzureGroups: true,
      } as IDialogDirectoryData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addGroup(result.id, result.displayName);
      }
    });
  }

  addGroup(id: string, name: string) {
    let listOfGroups = this.groups;
    let isInList = listOfGroups.controls.findIndex(o => o.value.id == id) > -1;
    isInList = isInList || this.groups.controls.findIndex(o => o.value.id == id) > -1;

    if (!isInList) listOfGroups.push(this.formBuilder.group({
      id: [id],
      displayName: [name],
    }));
  }

  private _filter(value: string): Array<{ name: string; code: string }> {
    const filterValue = value.toLowerCase();
    return this.permanentAddresses.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  displayFn(data: any): string {
    return data.name;
  }

  onValueSelected = (data: any) => {
    this.inviteUserForm.patchValue({ locationName: data.code });
    this.inviteUserForm.get('residence_name')?.setErrors(null);
  };

  validateAllFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  initForm() {
    this.inviteUserForm = this.formBuilder.group({
      givenName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      surname: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.NamePattern),
        ]),
      ],
      emailId: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(129),
          Validators.pattern(
            ValidationPatternConstants.UserEmailIdPattern
          ),
        ]),
      ],
      companyName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(ValidationPatternConstants.CompanyNamePattern),
        ]),
      ],
      locationName: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(ValidationPatternConstants.LocationNamePattern),
        ]),
      ],
      residence_name: ['',
        Validators.compose([
          Validators.required,
          Validators.pattern(ValidationPatternConstants.LocationNamePattern),
        ]),],
      inviteRedirectUrl: [
        '',
        Validators.compose([Validators.maxLength(256),
        Validators.pattern(ValidationPatternConstants.URLPattern)]),
      ],
      groups: this.formBuilder.array([], Validators.minLength(0)),
      responsibilitiesBox: [false, Validators.compose([Validators.required])],
    });
  }

  onCloseClick() {
    this.invitedGuest = undefined;
    this.inviteUserForm.patchValue({ givenName: "", surname: "", emailId: "" });
    setTimeout(() => {
      this.inviteUserForm.get('givenName')?.setErrors(null);
      this.inviteUserForm.get('surname')?.setErrors(null);
      this.inviteUserForm.get('emailId')?.setErrors(null);
      this.inviteUserForm.get('residence_name')?.setErrors(null);
    }, 10);
  }

  onSubmit(_inviteUserForm?: FormGroup) {
    this.createErrorMessage.message = '';
    this.createErrorMessage.errors = [];
    this.validateAllFields(this.inviteUserForm);
    const formData = this.inviteUserForm?.value;

    //handle empty fields if required
    let formFilled = true;

    const statement = document.getElementById('acceptStatement');

    if (!formData.responsibilitiesBox) {
      if (statement != null) {
        statement.style.color = 'red';
        formFilled = false;
      }
    } else {
      if (statement != null) {
        statement.style.color = 'black';
      }
    }

    let groupIdsToAssign = this.groups.value.map((item: any) => item.id);

    if (this.inviteUserForm?.valid && formFilled === true) {
      this.inviteGuest(new InviteGuestUserRequest(
        formData.givenName,
        formData.surname,
        formData.companyName,
        formData.emailId,
        formData.inviteRedirectUrl,
        formData.locationName,
        "",
        this.authService.cvxClaimsPrincipal.objectId,
        groupIdsToAssign
      ));
    }
  }

  async inviteGuest(inviteGuestUserRequest: InviteGuestUserRequest) {
    this.isInviteProcessActive = true;

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<IDirectoryUser>) => {
        this.invitedGuest = x;
        let data = x.data as IDirectoryUser;
        this.displayInvitedGuest = {
          id: data.id,
          name: data.displayName,
          mail: data.mail
        };
        this.createErrorMessage = { message: '', errors: [] };
      },
      error: (err: any) => {
        this.createErrorMessage.message = err.statusText;
        this.createErrorMessage.errors = err?.error?.errors ?? [];
        this.isInviteProcessActive = false;
      },
      complete: () => {
        this.isInviteProcessActive = false;
      }
    };

    this.graphUserService.InviteGuest(inviteGuestUserRequest).subscribe(observer);
  }

  resetForm() {
    this.inviteUserForm.reset();
    (this.inviteUserForm.controls.groups as FormArray).clear();
  }
}
