<form class="branded" *ngIf="!isSearching && searchResults.length === 0">
    <div class="row">
        <div class="col-12 col-sm-6 col-lg-6">
            <mat-form-field appearance="outline" class="w-100"
            subscriptSizing="dynamic">
                <mat-label>search term</mat-label>
                <input matInput [formControl]="searchTerm" (keydown.enter)="search()" />
            </mat-form-field>
        </div>
    </div>
</form>

<div class="container mb-3 mt-3" *ngIf="screenMessage !== ''">
    <p class="text-danger text-center">{{screenMessage}}</p>
</div>

<div class="container" *ngIf="isSearching">
    <div class="row justify-content-center col-sm-12">
        <p>Searching for group members...</p>
    </div>
    <div class="row justify-content-center col-sm-12">
        <mat-spinner></mat-spinner>                               
    </div>
</div>

<div class="col-12" [hidden]="searchResults.length === 0 || isSearching">

    <generic-search-results 
        [showHeader]="false" 
        [title]="'member(s) found that match'" 
        [records]="searchResults" 
        [displayedColumns]="displayColumns" 
        (recordSelected)="selectObject($event)">
    </generic-search-results>

</div>

<div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar" [hidden]="isSearching">
    <div class="btn-group mr-2" role="group" [hidden]="isSearching || searchResults.length > 0">
        <button class="button" type="button" (click)="search()">search</button>
    </div>
    <div class="btn-group mr-2" role="group" [hidden]="!isSearching && searchResults.length === 0">
        <button class="button" type="button" (click)="reset()">reset</button>                                 
    </div>
    <div class="btn-group mr-2" role="group" [hidden]="!isDialog">
        <button class="button" type="button" mat-dialog-close>close</button>                                 
    </div>
</div>