<fieldset class="pb-4">
  <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
    <p><strong>{{title}}</strong></p>
  </legend>
  <mat-button-toggle-group class="mat-button-toggle-group" name="permissionRole" aria-label="Type"
    [(ngModel)]="initialSelection" (change)="onToggleChange($event)">
    @for(option of options;track option;){
    <mat-button-toggle class="mat-button-toggle" [value]="option" [checked]="option === initialSelection">
      {{ option }}
    </mat-button-toggle>
    }
  </mat-button-toggle-group>
</fieldset>