import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeleteClientSecretRequestDetails } from 'src/app/models/graph/applications/DeleteClientSecretRequestDetails';
import { GraphApplicationService } from 'src/app/services/graph/GraphApplication.service';

@Component({
  selector: 'request-details-app-delete-client-secret',
  templateUrl: './request-details-app-delete-client-secret.component.html'
})
export class RequestDetailsAppDeleteClientSecret implements OnInit {
  @Input() requestData: DeleteClientSecretRequestDetails
  @Output() doneLoading = new EventEmitter<boolean>();

  appSearchComplete: boolean = false;
  displayColumns: string[] = ['displayName', 'applicationId', 'clientSecretId'];

  constructor(private graphAppService: GraphApplicationService) {

  }
  
  async ngOnInit(){
    if (this.requestData.applicationClientSecrets.length > 0) {
      // assume all apps for this request are the same since delete page only lets you select 1 app
      this.graphAppService.GetApplicationAsync(this.requestData.applicationClientSecrets[0].applicationId).then(r => {
        this.requestData.applicationClientSecrets.forEach(a => a.displayName = r.data.displayName);
      }).catch(ex => {
        this.requestData.applicationClientSecrets.forEach(a => a.displayName = 'Unknown Application');
        console.log(ex);
      }).finally(() => {
        this.appSearchComplete = true;
        this.doneLoading.emit(true);
      });
    }
  }
}
