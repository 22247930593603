import { Component } from '@angular/core';
import { RouteConstants } from 'src/app/models/common/enums/RouteConstants.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';

@Component({
  selector: 'app-retire-service-privilege-account',
  templateUrl: './retire-service-privilege-account.component.html',
})
export class RetireServicePrivilegeAccountComponent {
  userType: UserType = UserType.Service;
  displayedColumns: string[] = ['displayName', 'userPrincipalName', 'type'];
  route: string = RouteConstants.ServicePrivilege
  userTypeToggleOptions = [UserType.Service, UserType.Privilege]
}
