import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapEnvironmentToColorClass',
  standalone: true
})
export class MapEnvironmentToColorClassPipe implements PipeTransform {

  transform(value: string): string {
    value = value.toLowerCase();

    if (value.startsWith('prod')) {
      return 'env-prod';
    }
    else if (value.startsWith('stage')) {
      return 'env-stage';
    }
    else if (value.startsWith('qa')) {
      return 'env-qa';
    }
    else if (value.startsWith('test')) {
      return 'env-test';
    }
    else if (value.startsWith('dev')) {
      return 'env-dev';
    }
    else {
      return '';
    }
  }
}
