<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <div class="stickyHeader">
                <a class="close-button stickyHeader" (click)="searchCancelled()"><span
                        class="icon-remove close-button"></span></a>
                <h2>directory search</h2>
            </div>

            <div class="dialog-wrapper adjustStickyHeader">
                <directory-search [makeSticky]="true" [isDialog]="true" [type]="data.type" [domain]="data.domain"
                    [groupOwned]="data.groupOwned ?? false" [userType]="data.userType"
                    [aadGroupSearchScenario]="data.aadGroupSearchScenario"
                    [filterGroupRemoveNonAzureGroups]="data.filterGroupRemoveNonAzureGroups"
                    [filterGroupRemoveNotAvailableAsGroupMember]="data.filterGroupRemoveNotAvailableAsGroupMember"
                    [filterGroupRemoveDynamicMembershipEnabled]="data.filterGroupRemoveDynamicMembershipEnabled"
                    [filterGroupOnlyManagedByIdamp]="data.filterGroupOnlyManagedByIdamp"
                    (objectSelected)="objectSelected($event)" (searchCancelled)="searchCancelled()">
                </directory-search>
            </div>
        </div>
    </div>
</mat-dialog-content>