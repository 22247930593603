import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { firstValueFrom, from } from "rxjs";
import { IAppCreateRequestData } from "src/app/models/components/IAppCreateRequestData";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GraphAppPermissionRequestModel } from "src/app/models/graph/applications/GraphAppPermissionRequest";
import { GraphAppRequestModel } from "src/app/models/graph/applications/GraphAppRequest";
import { GraphConstants } from "src/app/models/graph/Graph.constants";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";

@Component({
    selector: 'request-details-app-create',
    templateUrl: './request-details-app-create.component.html'
})

export class RequestDetailsAppCreate implements OnInit {

    @Input() requestData: GraphAppRequestModel;
    @Output() doneLoading = new EventEmitter<boolean>();

    displayObject: IAppCreateRequestData;

    constructor(
        private directoryUserService: DirectoryUserService,
        private graphApplicationService: GraphApplicationService
    ) { }

    async ngOnInit(): Promise<void> {
        this.displayObject = {
            type: this.requestData.type,
            name: this.requestData.name,
            uri: this.requestData.uri,
            owners: [],
            urls: this.requestData.urls,
            requestedPermissions: []
        } as IAppCreateRequestData;

        await this.loadUsers(this.requestData.owners);
        await this.loadRequestedPermissions(this.requestData.requestedPermissions);

        this.isDoneLoading();
    }

    async loadUsers(ids: string[]) {
        for (let id of ids) {
            let user = await this.directoryUserService.GetByIdAsync({ id: id, domain: DirectoryDomain.Chevron });
            this.displayObject.owners.push(user.displayName);
        }
    }

    async loadRequestedPermissions(perms: GraphAppPermissionRequestModel[]) {
        for (let perm of perms) {
            let resource = await firstValueFrom(this.graphApplicationService.GetServicePrincipal(perm.resourceId));
            console.log(resource);
            let requestedScopes = resource.data.exposedPermissions.filter(ep => ep.type === GraphConstants.PermissionsScope && perm.scopes.includes(ep.id));
            for (let scope of requestedScopes.map(s => s.value)) {
                this.displayObject.requestedPermissions.push(`${resource.data.displayName} -- ${scope} (delegated)`);
            }

            let requestedRoles = resource.data.exposedPermissions.filter(ep => ep.type === GraphConstants.PermissionsApp && perm.roles.includes(ep.id));
            for (let role of requestedRoles.map(s => s.value)) {
                this.displayObject.requestedPermissions.push(`${resource.data.displayName} -- ${role} (application)`);
            }
        }
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}