<main class="container">
  <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
    <div class="row" *ngIf="createdUser === undefined">
      <div class="col col-12">
        <h1>create coco user</h1>
        <h5 *ngIf="!hasPermission" class="text-center text-danger">
          Only authorized users can manage coco users
        </h5>
        <ng-container *ngIf="hasPermission">
          <form *ngIf="createUserForm" [formGroup]="createUserForm" class="branded text-left" novalidate
            (ngSubmit)="onSubmit(createUserForm)">
            <mat-error></mat-error>
            <fieldset>
              <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                <p><strong>coco user information</strong></p>
              </legend>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="First Name">
                    <mat-label>first name</mat-label>
                    <input matInput formControlName="givenName" required>
                    <mat-error *ngIf="createUserForm.controls.givenName.errors?.required ?? false">
                      First Name is required
                    </mat-error>
                    <mat-error *ngIf="createUserForm.controls.givenName.errors?.pattern ?? false">
                      First Name is invalid
                    </mat-error>
                    <mat-error *ngIf="createUserForm.controls.givenName.errors?.maxlength ?? false">
                      First Name must be no more than {{ createUserForm.controls.givenName.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Last Name">
                    <mat-label>last name</mat-label>
                    <input matInput formControlName="surname" required>
                    <mat-error *ngIf="createUserForm.controls.surname.errors?.required ?? false">
                      Last Name is required
                    </mat-error>
                    <mat-error *ngIf="createUserForm.controls.surname.errors?.pattern ?? false">
                      Last Name is invalid
                    </mat-error>
                    <mat-error *ngIf="createUserForm.controls.surname.errors?.maxlength ?? false">
                      Last Name must be no more than {{ createUserForm.controls.surname.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Mail Nickname should be the first part of the email address prior to the @ sign">
                    <mat-label>mail nickname</mat-label>
                    <input matInput formControlName="mailNickname" required>
                    <mat-error *ngIf="createUserForm.controls.mailNickname.errors?.required ?? false">
                      Mail Nickname is required
                    </mat-error>
                    <mat-error *ngIf="createUserForm.controls.mailNickname.errors?.pattern ?? false">
                      Mail Nickname is invalid
                    </mat-error>
                    <mat-error *ngIf="createUserForm.controls.mailNickname.errors?.maxlength ?? false">
                      Mail Nickname must be no more than {{ createUserForm.controls.mailNickname.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Title">
                    <mat-label>title</mat-label>
                    <mat-select formControlName="title" required>
                      <mat-option *ngFor="let title of titles" [value]="title">{{ title }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="createUserForm.controls.title.errors?.required ?? false">Title is
                      required</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Telephone Number should contain numbers only; no spaces, special characters, or country codes">
                    <mat-label>telephone number</mat-label>
                    <input matInput formControlName="telephoneNumber" required>
                    <mat-error *ngIf="createUserForm.controls.telephoneNumber.errors">
                      {{getTelephoneNumberErrorMessage(createUserForm)}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Station Number should contain only numbers">
                    <mat-label>station number</mat-label>
                    <input matInput formControlName="stationNumber" required>
                    <mat-error *ngIf="createUserForm.controls.stationNumber.errors">
                      {{getStationNumberErrorMessage(createUserForm)}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic" matTooltip="Mobile Number should contain numbers only; no spaces, special characters, or country codes">
                    <mat-label>mobile number</mat-label>
                    <input matInput formControlName="mobilePhone">
                    <mat-error *ngIf="createUserForm.controls.mobilePhone.errors">
                      {{getMobileNumberErrorMessage(createUserForm)}}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                </div>
              </div>
            </fieldset>
            <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
              <mat-spinner [diameter]="30" *ngIf="isCreateProcessActive"></mat-spinner>
              <div class="btn-group mr-2" role="group" *ngIf="!isCreateProcessActive">
                <button class="button" type="submit">create</button>
              </div>
              <div class="btn-group mr-2" role="group" *ngIf="!isCreateProcessActive">
                <button class="button" type="button" [disabled]="isCreateProcessActive"
                  (click)="resetForm()">cancel</button>
              </div>
            </div>

            <display-http-error [message]="createErrorMessage.message"
              [errors]="createErrorMessage.errors"></display-http-error>

          </form>
        </ng-container>
      </div>
    </div>

    <div class="row" *ngIf="createdUser !== undefined">
      <div class="col col-12">
        <a class="close-button" [routerLink]="['/account/cocouser/create']"><span
            class="icon-remove close-button"></span></a>
        <h1>coco user created</h1>
        <display-form [displayObject]="displayCreatedUser"></display-form>

        <display-requests [requests]="createdUser.requests"></display-requests>
      </div>
    </div>

  </section>
</main>