import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { SsSecret } from 'src/app/models/secretServer/SsSecret';
import { SecretServerService } from 'src/app/services/secretServer/secretServer.service';
import { IPagedResponse } from 'src/app/models/common/PagedResponse';
import { Subject, Subscription, debounceTime, distinctUntilChanged, filter, finalize, lastValueFrom, switchMap, tap } from 'rxjs';
import { RequestSecretPermissionsRequest } from 'src/app/models/secretServer/RequestSecretPermissionsRequest';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-request-password-permissions',
  templateUrl: './request-password-permissions.component.html',
  styleUrls: ['./request-password-permissions.component.css']
})
export class RequestPasswordPermissionsComponent implements OnInit, AfterViewInit, OnDestroy {

  secretsDataSource = new MatTableDataSource<SsSecret>([]);
  @ViewChild(MatPaginator)
  paginator!: MatPaginator;

  subscriptions: Subscription[] = []
  observer = {
    next: (v: IPagedResponse<SsSecret>) => {

      this.currentPagedResponse = v;
      this.secretsDataSource.data = this.currentPagedResponse.items
    },
    error: (e: any) => {
      console.error(e);
      this.gettingSecrets = false;
    },
    complete: () => this.gettingSecrets = false
  };

  PageLayout = PageLayout;
  currentUserProfile: ICvxClaimsPrincipal;
  gettingSecrets: boolean = true;
  isSubmitting: boolean = false;
  submittedRequests: IWorkflowRequest[] = [];
  submitErrorMessage = { message: '', errors: [] };
  selection = new SelectionModel<SsSecret>(true, []);
  requestableRoles: string[] = ["viewer", "owner"]
  requestOwner: SsSecret[] = []
  requestViewer: SsSecret[] = []
  justification= new UntypedFormControl('', { validators: [Validators.required] });
  currentPagedResponse: IPagedResponse<SsSecret> | null = null
  pageSizeOptions: number[] = [5, 10, 20, 30, 40];
  searchTerm: string = "";
  searchTermChanged: Subject<string> = new Subject<string>();
  searchTermDebounceSeconds: number = 0.5;
  searchTermMinLength: number = 2;
  searchTermToolTip: string = `search requires a minumum of ${this.searchTermMinLength} characters`;
  secretsDisplayedColumns: string[] = ['name', 'folder', 'selectPermission'];
  selectedSecretsDisplayedColumns: string[] = ['name', 'folder'];


  constructor(private authService: CalAngularService,
    private secretService: SecretServerService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  async ngOnInit(): Promise<void> {
    if (await lastValueFrom(this.authService.isUserSignedIn())) {
      this.currentUserProfile = this.authService.cvxClaimsPrincipal;
      this.setupSearch()
      this.pageChanged(new PageEvent);
    }
  }

  ngAfterViewInit(): void {
    this.secretsDataSource.paginator = this.paginator
  }

  setupSearch() {
    const sub = this.searchTermChanged.pipe(
      filter(res => {
        return res !== null &&
          (res.length >= this.searchTermMinLength)
      }),
      distinctUntilChanged(),
      debounceTime(1000 * this.searchTermDebounceSeconds),
      tap(() => {
        // minimum length required after initial load
        this.gettingSecrets = true;
      }),
      switchMap(value =>
        this.loadData(value as string)
          .pipe(
            finalize(() => {
              this.gettingSecrets = false
            })
          )
      )
    )
    .subscribe(this.observer);

    // setup for cleanup
    this.subscriptions.push(sub);
  }

  loadData(searchTerm: string) {
    const pageNumber = (this.paginator?.pageIndex ?? 0) + 1
    const pageSize = (this.paginator?.pageSize ?? this.pageSizeOptions[0])
    return this.secretService.GetRequestablePasswords(searchTerm, pageNumber, pageSize)
  }

  pageChanged(event: PageEvent) {
    const sub = this.loadData(this.searchTerm as string).subscribe(this.observer);
    
    // setup for cleanup
    this.subscriptions.push(sub);
  }

  roleChanged(event: any)
  {
    const selectedRole = event.value.role as string
    const secret = event.value.secret as SsSecret
    switch (selectedRole) {
      case 'owner':
        if(this.requestOwner.findIndex(s=> s.id == secret.id) == -1) this.requestOwner = [...this.requestOwner,secret]

        // remove
        this.requestViewer = this.requestViewer.filter(s => s.id != secret.id)
        break;

        case 'viewer':
        if(this.requestViewer.findIndex(s=> s.id == secret.id) == -1) this.requestViewer = [...this.requestViewer,secret]

        // remove
        this.requestOwner = this.requestOwner.filter(s => s.id != secret.id)
        break;
    }
  }

  removeOwner(secret: SsSecret) {
    this.requestOwner = this.requestOwner.filter(s=> s.id != secret.id)
  }
  removeViewer(secret: SsSecret) {
    this.requestViewer = this.requestViewer.filter(s=> s.id != secret.id)
  }

  public doFilterPermissions = (event: any) => {
    const newSearchTerm: string = (event.target as HTMLInputElement)?.value;
    this.searchTermChanged.next(newSearchTerm)
  }

  async onSubmit() {
    this.submitErrorMessage.message = '';
    this.submitErrorMessage.errors = [];

    if (this.requestOwner.length > 0 || this.requestViewer.length > 0 && this.justification.valid) {
      this.submitRequest();
    }
  }

  async submitRequest() {
    this.isSubmitting = true;
    let payload = new RequestSecretPermissionsRequest(this.requestOwner.map(s => s.id),this.requestViewer.map(s => s.id), this.justification.value)

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<SsSecret[]>) => {
        this.submittedRequests = x.requests;
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      }
    };

    let requestCall = this.secretService.RequestPasswordPermissionsAsync(payload);
    requestCall.subscribe(observer);
  }
}