
/**
 * Perform a case insensitive string compare against 2 strings
 * @param a 
 * @param b 
 * @returns returns true if both string match case insensitive, false if either is undefined
 */
export function CaseInsensitiveComparer(a?:string,b?:string):boolean {
    if (a === undefined || b === undefined || a === null || b === null) return false;

    return a.localeCompare(b, undefined, {sensitivity: 'base'}) === 0;
}