import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { lastValueFrom } from 'rxjs';
import { IMyProfileApiResponse } from 'src/app/models/myProfileApiResponse';
import { PrimaryUser } from 'src/app/models/primaryUser';
import { PrimaryUserService } from 'src/app/services/primaryUser/primaryUser.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html'
})
export class MyProfileComponent implements OnInit {

  PageLayout = PageLayout;
  PrimaryUser!: PrimaryUser;
  ErrorMessage!: string;
  isAllowedToEditName: boolean = false;

  constructor(private authService: CalAngularService, private primaryUserService: PrimaryUserService) { }

  async ngOnInit(): Promise<void> {

    const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));

    const observer = {
      next: (x: IMyProfileApiResponse) => {
        this.PrimaryUser = x.data as PrimaryUser;
        this.isAllowedToEditName = x.isAllowedToEditName
      },
      error: (err: any) => {
        this.ErrorMessage = err.error.message
        console.log(this.ErrorMessage)
      }
    };

    const apiCall = this.primaryUserService.GetMe(token);

    if(apiCall)
      apiCall.subscribe(observer)
  }

  formCancelled() {
    window.location.reload();
  }
}

