import { Component, OnInit } from '@angular/core';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { PrimaryUser } from 'src/app/models/primaryUser';
import {ActivatedRoute} from '@angular/router';
import { PrimaryUserService } from 'src/app/services/primaryUser/primaryUser.service';
import { CalAngularService } from '@cvx/cal-angular';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search-primary-user',
  templateUrl: './search-primary-user.component.html',
})
export class SearchPrimaryUserComponent implements OnInit {

  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  searchResults: PrimaryUser[] = [];
  selectedUser?: PrimaryUser;
   
  constructor(private route: ActivatedRoute,
  private primaryUserService: PrimaryUserService, 
  private authService: CalAngularService) {}

  async ngOnInit(): Promise<void> {
    const cai = this.route.snapshot.queryParamMap.get('cai')
    const email = this.route.snapshot.queryParamMap.get('email')
    const provisioningId = this.route.snapshot.queryParamMap.get('provisioningid')
    const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));
    
    if (cai != null) {
      this.primaryUserService.GetPrimaryUsers(`cai=${cai}`, token, "active")
          .subscribe({
              next: (user) => {
                  this.selectedUser=user.data[0];
              },
              error: (err: any) =>{console.log(err)}
          });          
    }

    if (email != null) {
      this.primaryUserService.GetPrimaryUsers(`email=${email}`, token, "active")
          .subscribe({
              next: (user) => {
                  this.selectedUser=user.data[0];
              },
              error: (err: any) =>{console.log(err)}
          });           
    }

    if (provisioningId != null) {
      this.primaryUserService.GetPrimaryUsers(`provisioningId=${provisioningId}`, token, "active")
          .subscribe({
              next: (user) => {
                  this.selectedUser=user.data[0];
              },
              error: (err: any) =>{console.log(err)}
          });           
    }
  }

  whenUserSelected(eventData: { selectedUser: PrimaryUser }) {
    this.selectedUser = eventData.selectedUser
  }

  formCancelled() {
    this.selectedUser = undefined;
  }

}
