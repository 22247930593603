import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { PrimaryUserAuthService } from 'src/app/services/primaryUserAuth/primaryUserAuth.service';

@Component({
  selector: 'app-create-primary-user',
  templateUrl: './create-primary-user.component.html'
})
export class CreatePrimaryUserComponent implements OnInit {

  PageLayout = PageLayout;
  IsAuthorised = false;

  constructor(private authService: CalAngularService, private primaryUserAuthService: PrimaryUserAuthService) { }

  async ngOnInit(): Promise<void> {
    const cvxClaimsPrincipal = this.authService.cvxClaimsPrincipal;
    this.IsAuthorised = this.primaryUserAuthService.GetPageAuthorization(cvxClaimsPrincipal);
  }
}
