<mat-dialog-content>
    <div [hidden]="!doneLoading">
        <div class="api-status-compact h-100 d-flex justify-content-center align-items-center mb-2" *ngIf="compactMode">
    
            <div>Directory Status:</div>
    
            <div class="api-status-service" *ngFor="let service of apiAdstatus">
                <div class="badge badge-success" *ngIf="service.validConnection">
                    <div class="h-100 d-flex justify-content-center align-items-center">
                        <mat-icon fontIcon="check-small"></mat-icon>
                        {{ service.displayName }}
                    </div>
                </div>
                <div class="badge badge-danger" *ngIf="!service.validConnection">
                    <div class="h-100 d-flex justify-content-center align-items-center">
                        <mat-icon fontIcon="close"></mat-icon>
                        {{ service.displayName }}
                    </div>
                </div> 
            </div>
    
        </div>
    
        <div class="api-status" *ngIf="!compactMode">
    
        </div>
    </div>
</mat-dialog-content>