<div *ngIf="currentUserIsApprover && !isSubmitted">
    <form class="branded" [formGroup]="approvalForm">
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="outline" class="w-100"
                subscriptSizing="dynamic">
                    <mat-label>decision</mat-label>
                    <mat-select formControlName="decision">
                        <mat-option [value]="true">approved</mat-option>
                        <mat-option [value]="false">denied</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="outline" class="w-100"
                subscriptSizing="dynamic">
                    <mat-label>comments</mat-label>
                    <textarea matInput
                        formControlName="comments"
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"
                        style="border: none; box-shadow: none;"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="btn-toolbar d-flex pt-1" role="toolbar">
            <mat-spinner [diameter]="30" *ngIf="isSubmitted" style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
            <div class="btn-group mr-2" role="group" *ngIf="!isSubmitted">
                <button class="button" type="submit" (click)="onSubmit()">submit</button>
            </div>
        </div>  
    </form>
</div>

<div class="mt-4">
    <generic-search-results
        [showHeader]="false"
        [showCounter]="false"
        [title]="'approval history'"
        [records]="reviews"
        [displayedColumns]="reviewerCols"
        [selectableRecords]="false"
        *ngIf="reviews.length > 0"
    ></generic-search-results>

    <div class="alert alert-primary" *ngIf="workflowRequest.autoApproved">requested permissions have been auto-approved</div>

    <div class="alert alert-primary" *ngIf="!(workflowRequest.state === WorkflowStates.Completed && workflowRequest.autoApproved) && reviews.length === 0">no approval reviews completed yet</div>
</div>