import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CalAngularService } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { ResultSizeConstants } from 'src/app/models/common/enums/ResultSizeConstants.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import Swal from 'sweetalert2';
import { matTableSelectionHelper } from 'src/app/helpers/matTableSelectionHelper';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.css']
})
export class DeleteUserComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.usersDataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.usersDataSource.sort = value;
  }

  @Input() userTypeToggleOptions: UserType[] = [];
  @Input() userType: UserType | undefined;
  @Input() displayColumns: string[] = [];
  @Input() route: string;
  userTypeString: string | undefined;
  selectedDisplayCols: string[];
  cols: string[]
  PageLayout = PageLayout;
  errorMessage: string;
  gettingUsers: boolean = true;
  isSubmitting: boolean = false;
  submittedRequests: IWorkflowRequest[] = [];
  submitErrorMessage = { message: '', errors: [] };
  selection = new SelectionModel<IDirectoryUser>(true, []);
  retireResults: string[] = [];
  usersDataSource = new MatTableDataSource<IDirectoryUser>([]);

  constructor(
    private userService: GraphUserService,
    private authService: CalAngularService
  ) { }

  async ngOnInit(): Promise<void> {
    this.cols = ['select', ...this.displayColumns];
    this.selectedDisplayCols = [...this.displayColumns];
    this.usersDataSource.paginator = this.paginator
    this.usersDataSource.sort = this.sort

    this.search();
  }

  hasPermissions(): boolean {
    if (this.userType == UserType.Coco) {
      return this.userService.CheckCocoUserSponsorshipPermission(this.authService.cvxClaimsPrincipal);
    }
    return true;
  }
  select(row: IDirectoryUser) {
    this.selection.toggle(row);
  }

  public filter = (value: any) => {
    this.usersDataSource.filter = value.target.value.trim().toLocaleLowerCase();
  }
  async onSubmit() {

    // clear error messages
    this.submitErrorMessage.message = '';
    this.submitErrorMessage.errors = [];

    if (this.selection.selected.length > 0) {

      let messageText = `${this.userTypeString} accounts`;

      if (this.selection.selected.length === 1) {
        messageText = `${this.userTypeString} account`;
      }

      Swal.fire({
        icon: 'warning',
        iconColor: '#97002E',
        title: '<h3>are you sure?</h3>',
        html:
          `<p>You are about to delete ${this.selection.selected.length} ${messageText}, ` +
          "this operation can't be undone.</p>",
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'button',
          cancelButton: 'button',
        },
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.retireAccounts(this.selection.selected.map(s => s.id));
        }
      });
    }
  }

  async retireAccounts(userIds: string[]) {
    this.isSubmitting = true;

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>) => {
        this.submittedRequests = x.requests;
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      }
    };

    let requestCall = this.userService.DeleteUsers(userIds);
    requestCall.subscribe(observer);
  }
  search() {
    this.userTypeString = this.userType?.toLowerCase();
    if (!this.userType) {
      this.errorMessage = 'Woops, looks like this component was used without providing a user type.';
      this.gettingUsers = false;
    }
    else if (this.hasPermissions()) {
      this.selection.clear();
      this.gettingUsers = true;
      const stewardId = this.userType == UserType.Coco ? undefined : this.authService.cvxClaimsPrincipal.objectId;
      this.userService.SearchUser("", this.userType, stewardId, ResultSizeConstants.GetAll).subscribe({
        next: (r) => {
          this.usersDataSource.data = r.data
        },
        error: (e) => {
          console.error(e);
          this.errorMessage = `encountered unexpected error while searching for ${this.userTypeString} accounts`;
          this.gettingUsers = false;
        },
        complete: () => {
          this.errorMessage = this.usersDataSource.data.length == 0 ? `no ${this.userTypeString} accounts were found` : "";
          this.gettingUsers = false;
        }
      });
    }
    else {
      this.errorMessage = `only authorized users can manage ${this.userTypeString} accounts.`;
      this.gettingUsers = false;
    }
  }
  userTypeChange(newUserType: UserType) {
    this.userType = newUserType
    this.search()
  }

  //for checkbox select all use
  isAllSelected() {
    return matTableSelectionHelper.isAllSelected(this.selection, this.usersDataSource);
  }
  masterToggle() {
    return matTableSelectionHelper.toggleSelectAll(this.selection, this.usersDataSource);
  }

}