import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: 'generic-button-toggles',
  styleUrls: ['./generic-button-toggles.component.css'],
  templateUrl: './generic-button-toggles.component.html',
})
export class GenericButtonTogglesComponent {
  @Input() title: string = '';
  @Input() options: string[] = [];
  @Input() initialSelection: string = '';
  @Output() selectionChange = new EventEmitter<string>();

  constructor() { }

  onToggleChange(event: any) {
    this.selectionChange.emit(event.value);
  }
}