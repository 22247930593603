@if (showHeader){
<div class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
    <p>
        <strong>{{ headerText }}</strong>
    </p>
</div>
}

@if (showTitle) {
<h4 id="title">
    @if (showCounter) {
    <span>{{ records.length }}</span>
    }
    {{ title }}
</h4>
}

@if (allowLocalFilter){
<div [class]="makeSticky ? 'stickyHeader' : ''">
    <filter-input (onKeyUp)="doFilter($event)"></filter-input>
</div>
}
<form class="branded">
    <table mat-table [class]="makeSticky ? 'stickyTableHeaders' : ''" aria-describedby="title" [dataSource]="dataSource"
        matSort>

        <caption>{{ caption }}</caption>

        <!-- Checkbox Column -->
        @if (emitRecordsChecked){
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <input MatCheckbox type="checkbox" (change)="onCheckboxChange(dataSource.data, $event, true)"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" />
            </th>
            <td mat-cell *matCellDef="let row">
                <input MatCheckbox type="checkbox" name="formcheckbox" [value]="row.id"
                    (click)="$event.stopPropagation()"
                    (change)="onCheckboxChange(row, $event, false); selection.toggle(row)"
                    [checked]="selection.isSelected(row)">
            </td>
        </ng-container>
        }

        @for (disCol of displayedColumns; track $index) {
        <ng-container ngSwitch="{{disCol}}">
            <ng-container *ngSwitchCase="'select'"></ng-container> <!-- Skip the 'select' column in the loop -->
            <ng-container *ngSwitchDefault matColumnDef="{{disCol}}">
                <th mat-header-cell *matHeaderCellDef>
                    @if (sortable) {
                    <span mat-sort-header>{{disCol | convertDisplayLabels}}</span>
                    } @else {
                    {{disCol | convertDisplayLabels}}
                    }
                </th>
                <td mat-cell *matCellDef="let element; let rowIndex = index"
                    [style.cursor]="selectableRecords ? 'pointer' : 'default'">
                    @if (disCol != 'copyId') {
                    <text-formatter [content]="element[disCol]"></text-formatter>
                    } @else {
                    <div [cdkCopyToClipboard]="element[disCol]"
                        (cdkCopyToClipboardCopied)="onClipboardCopy($event, rowIndex)"
                        (mouseleave)="resetClipBoardStatus()" style="text-align:center;">
                        <button class="button small icon no-arrow"
                            [style]="copiedToClipboardId == rowIndex ? 'background-color: #769231;' : ''">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </div>
                    }
                </td>
            </ng-container>
        </ng-container>
        }

        @if (deletableRecords)
        {
        <ng-container matColumnDef="del">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="text-align:center;">
                <button mat-icon-button matSuffix (click)="deleteRow(element)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="cols"></tr>
        <tr mat-row (click)="selectableRecords ? selectRow(row) : null" *matRowDef="let row; columns: cols;"></tr>

    </table>

</form>

@if (showPagination){
<mat-paginator [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons></mat-paginator>
}
@if (isDialog){
<div class="btn-toolbar d-flex justify-content-center pt-4 padTop5" role="toolbar">
    @if (emitRecordsChecked){
    <div class="btn-group mr-2" role="group">
        <button class="button" type="button" [disabled]="selectedItems.length === 0"
            (click)="emitSelectedItems()">select</button>
    </div>
    }
    <div class="btn-group mr-2" role="group">
        <button class="button" type="button" mat-dialog-close>close</button>
    </div>
</div>
}