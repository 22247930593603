import { Component, Input } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { GraphApplicationRequestableApplicationPermission } from 'src/app/models/graph/applications/GraphApplicationRequestableApplicationPermission';
import { GraphApplicationService } from 'src/app/services/graph/GraphApplication.service';

@Component({
  selector: 'app-requestable-permissions',
  templateUrl: './requestable-permissions.component.html',
  styleUrls: ['./requestable-permissions.component.css']
})
export class RequestablePermissionsComponent {


  constructor(private graphAppService: GraphApplicationService) { }

  @Input() isDialog: boolean = false;

  isLoading: boolean = false;
  requestablePermissions: GraphApplicationRequestableApplicationPermission[] = [];
  displayedColumns: string[] = ['appDisplayName', 'permissionType', 'permissionValue', 'permissionDescription'];

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    await lastValueFrom(this.graphAppService.GetRequestablePermissions()).then((response) => {
      this.requestablePermissions = response.data;
    }).catch(ex => {
      console.log(ex);
    })
      .finally(() => {
        this.isLoading = false;
      });
  }
}