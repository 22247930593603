import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-progress',
  templateUrl: './dialog-progress.component.html',
  styleUrls: ['./dialog-progress.component.css']
})
export class DialogProgressComponent implements OnInit {

  completedCount = 0;
  failedCount = 0;
  failedReason: string[] = []
  total = 0;

  constructor(private dialogRef: MatDialogRef<DialogProgressComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.total = this.data.promises.length;
    this.trackProgress(this.data.promises).then(results => {
    });
  }

  trackProgress(promiseArray: Promise<any>[]): Promise<any[]> {
    return Promise.allSettled(promiseArray.map(p => 
      p.then(
        value => {
          this.completedCount++;
        },
        reason => {
          this.completedCount++;
          this.failedCount++;
          this.failedReason.push(reason.error)
        }
      )
    ));
  }
}
