import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IInviteGuestUserRequestData } from "src/app/models/guestAccounts/IInviteGuestUserRequest";
import { InviteGuestUserRequest } from "src/app/models/guestAccounts/InviteGuestUserRequest";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";

@Component({
    selector: 'request-details-guest-invite',
    templateUrl: './request-details-guest-invite.component.html'
})

export class RequestDetailsGuestInvite implements OnChanges {
    @Input() requestData: InviteGuestUserRequest[];
    @Output() doneLoading = new EventEmitter<boolean>();
    displayObjects: IInviteGuestUserRequestData[] = [];
    private groupIdAndGroupNameMap = new Map();

    constructor(
        private directoryGroupService: DirectoryGroupService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData) {
            await this.getAllGroupNames();
            for (let index = 0; index < this.requestData.length; index++) {
                const guestRequestData = this.requestData[index];

                const displayObject = {
                    firstName: guestRequestData.givenName,
                    lastName: guestRequestData.surname,
                    emailId: guestRequestData.emailId,
                    applicationUrl: guestRequestData.inviteRedirectUrl,
                    steward: "",
                    permanentLegalResidence: guestRequestData.usageLocationCode,
                    companyName: guestRequestData.companyName,
                    groupNames: []
                } as IInviteGuestUserRequestData;
                if (guestRequestData.groupIdsToAssign?.length > 0) {
                    displayObject.groupNames = this.getGroupNames(guestRequestData.groupIdsToAssign);
                }
                displayObject.steward = (guestRequestData.steward as any)?.displayName;

                this.displayObjects.push(displayObject);
            }
            this.isDoneLoading();
        }
    }

    async getAllGroupNames() {
        let groupIds: string[] = [];
        this.requestData.filter(rd => rd.groupIdsToAssign?.length > 0).forEach(rd => {
            groupIds = groupIds.concat(rd.groupIdsToAssign);
        });
        let uniqueGroupIds = [... new Set(groupIds)];
        for (let id of uniqueGroupIds) {
            const group = await this.directoryGroupService.GetByIdAsync(id, DirectoryDomain.Chevron);
            if (group?.data)
                this.groupIdAndGroupNameMap.set(id, group.data.displayName);
            else
                this.groupIdAndGroupNameMap.set(id, `unknown group with id=${id}`);
        }
    }

    getGroupNames(ids: string[]) {
        const groupNames: string[] = [];
        for (let id of ids) {
            groupNames.push(this.groupIdAndGroupNameMap.get(id));
        }
        return groupNames;
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}
