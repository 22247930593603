import { Component } from '@angular/core';
import { RouteConstants } from 'src/app/models/common/enums/RouteConstants.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';

@Component({
  selector: 'app-retire-guest',
  templateUrl: './retire-guest.component.html',
})
export class RetireGuestComponent {
  userType: UserType = UserType.Guest;
  displayedColumns: string[] = ['displayName', 'mail', 'companyName'];
  route: string = RouteConstants.Guest;
}
