<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if(hasPermissions())
        {
            @if(submittedRequests.length === 0) {
                <div class="row">
                    <div class="col col-12">
                        <h1>big red button</h1>
                        <h2>compromised sourcing company event</h2>
                        <fieldset class="pb-4">
                            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                <p><strong> add criteria </strong></p>
                            </legend>
                            <form [formGroup]="userForm" class="branded text-left" novalidate>
                                <div class="row">
                                    <div class="col-12 col-md-6 col-lg-4">
                                        <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                                            <mat-label>user type</mat-label>
                                            <mat-select formControlName="userType">
                                                @for(userType of userTypes; track userType)
                                                {
                                                    <mat-option [value]="userType">{{ userType }}</mat-option>
                                                }
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-4">
                                        <mat-form-field appearance="outline" class="w-100"> 
                                            <mat-label class="font-gotham-narrow-bold text-dark-blue">sourcing company</mat-label>
                                            <input (click)="searchForCampEntity()" matInput [readonly]="true" formControlName="sourcingCompany">
                                            <button mat-icon-button matSuffix (click)="searchForCampEntity()">
                                                <mat-icon>search</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>
                                    @if (userForm.controls.userType.value !== 'Contractor') {
                                        <div class="col-12 col-md-6 col-lg-4">
                                            <mat-form-field appearance="outline" class="w-100"> 
                                                <mat-label class="font-gotham-narrow-bold text-dark-blue">email domain</mat-label>
                                                <input matInput formControlName="emailDomain" matTooltip="Do not include the @">
                                            </mat-form-field>
                                        </div>
                                    }
                                </div>
                                <div class="row">
                                    @if(formErrorMessage){
                                        <div>
                                            <display-http-error [message]="formErrorMessage" [errors]="[]"></display-http-error>
                                        </div>}
                                    @if (userForm.controls.userType.value === 'Both') {
                                        <div class="col-12 col-md-6 col-lg-8">
                                            <p><span style="color: blue;">Email domain entry will only apply to the guests when criteria is created for both user types.</span></p>
                                        </div>
                                    }
                                    <div class="col-12 col-md-6 col-lg-3" style="margin-left:auto; margin-right:0px;">
                                        <button mat-flat-button color="primary" class="add-button" type="button" style="float: right;" (click)="addCriteria()">
                                            <mat-icon>add</mat-icon>criteria
                                        </button>
                                    </div>
                                </div>  
                            </form>
                            
                            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                <p><strong> compromised company criteria </strong></p>
                            </legend>
                            <table id="users" mat-table
                                [dataSource]="criteriaDataSource" aria-describedby="users impacted by criteria">
                                <caption> defined criteria </caption>
        
                                @for(disCol of criteriaDataCols; track disCol; let colIndex = $index){
                                <ng-container matColumnDef="{{disCol}}">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{disCol | convertDisplayLabels}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <text-formatter [content]="element[disCol]"></text-formatter>
                                    </td>
                                </ng-container>}
        
                                <ng-container matColumnDef="delete">
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element" style="text-align:center;">
                                        <button mat-icon-button matSuffix (click)="deleteRow(element)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="criteriaCols"></tr>
                                <tr mat-row *matRowDef="let row; columns: criteriaCols;"></tr>
                            </table>
                            <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                                @if(criteriaDataSource.data.length != 0 && !gettingUsers) {
                                <div class="btn-group mr-2" role="group">
                                    <button class="button" (click)="search()">evaluate</button>
                                </div>
                                <div class="btn-group mr-2" role="group">
                                    <button class="button" type="button" (click)="resetForm()">reset</button>                                 
                                </div>
                                }
                            </div>
                            @if(evaluating){
                            <div>
                                <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                    <p><strong> impacted accounts </strong></p>
                                </legend>
                                @if(!gettingUsers && usersDataSource.data.length == 0){
                                    <div>
                                        <display-http-error [message]="errorMessage" [errors]="[]"></display-http-error>
                                    </div>}
                                @if(gettingUsers) {
                                <div class="container">
                                    <div class="row justify-content-center col-sm-12">
                                        <p>searching for accounts...</p>
                                    </div>
                                    <div class="row justify-content-center col-sm-12">
                                        <mat-spinner></mat-spinner>
                                    </div>
                                </div> }
                                <div class="container">
                                    <form class="branded">
                                        @if (!gettingUsers && usersDataSource.data.length > 0) {
                                            <p style="text-align:center;"><span style="color: rgb(228, 102, 71);">Privileged accounts associated with these users are not listed here but will also be disabled.</span></p>
                                            <filter-input (onKeyUp)="filter($event)"></filter-input>
                                            <download-button [class]="'float-right'" toolTip="download permissions"
                                            (click)="exportToCsv()"> </download-button>
                                        }
                                        <table id="users" [hidden]="gettingUsers || usersDataSource.data.length == 0" mat-table
                                            [dataSource]="usersDataSource" matSort matSortActive="displayName"
                                            matSortDirection="asc" aria-describedby="users impacted by criteria">
                                            <caption> impacted accounts </caption>
                                            @for(disCol of displayCols; track disCol; let colIndex = $index){
                                                <div matColumnDef="{{disCol}}">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol | convertDisplayLabels}}</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <text-formatter [content]="element[disCol]"></text-formatter>
                                                    </td>
                                                </div>
                                            }
                                            <tr mat-header-row *matHeaderRowDef="displayCols"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayCols;"></tr>
                                        </table>
                                        <mat-paginator [hidden]="gettingUsers || usersDataSource.data.length == 0"
                                            [length]="usersDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]"
                                            showFirstLastButtons></mat-paginator>
                                    </form>
                                </div>
                            </div>
                            }
                        </fieldset>
        
                        <mat-divider></mat-divider>
        
                        <display-http-error [message]="submitErrorMessage"
                            [errors]="[]"></display-http-error>
                        @if(!gettingUsers) {
                        <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                            @if(isSubmitting) {
                            <mat-spinner [diameter]="30" class="mat-spinner-search"></mat-spinner>}
                            @if (!isSubmitting && evaluating && hasWritePermissions() && (errorMessage == "" || errorMessage == "no accounts were found")) {
                            <div class="btn-group mr-2" role="group">
                                <button class="button" type="submit" (click)="onSubmit()" style="background-color:red;border-color:red;">submit</button>
                            </div>
                            }
                        </div>}
                    </div>
                </div> }
            @else {
            <div class="row">
                <div class="col col-12">
                    <a class="close-button" [routerLink]="['/cyber/big-red-button']"><span
                            class="icon-remove close-button"></span></a>
                    <h1>request submitted</h1>
                    <display-http-error [message]="submitErrorMessage"
                        [errors]="[]"></display-http-error>
                    <display-requests [requests]="submittedRequests"></display-requests>
                </div>
            </div>}
        }
        @else {
            <div class="row">
                <div class="col col-12">
                    <h1>access denied</h1>
                    <p style="text-align:center;color:red;">you do not have permission to access this page.</p>
                </div>
            </div>}
    </section>
</main>