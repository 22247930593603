import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { IDialogDirectoryGroupMemberSearchData } from "src/app/models/components/IDialogDirectoryGroupMemberSearchData";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";

@Component({
    selector: 'dialog-directory-group-member-search',
    templateUrl: './dialog-directory-group-member-search.component.html'
})

export class DialogDirectoryGroupMemberSearchComponent {
    constructor(private dialogRef:MatDialogRef<DialogDirectoryGroupMemberSearchComponent>,
        @Inject(MAT_DIALOG_DATA) public data:IDialogDirectoryGroupMemberSearchData) {}

    objectSelected(data:DirectoryObjectBase) {
        this.dialogRef.close(data);
    }

    searchCancelled() {
        this.dialogRef.close();
    }
}