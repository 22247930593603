<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <div class="stickyHeader">
                <a class="close-button stickyHeader" (click)="searchCancelled()"><span
                        class="icon-remove close-button"></span></a>
                <h2>{{ data.dialogTitle }}</h2>
            </div>

            <div class="dialog-wrapper adjustStickyHeader">
                <generic-search-results [showHeader]="false" [records]="data.searchResults" [title]="data.title"
                    [showCounter]="data.showCounter" [selectableRecords]="data.selectableRecords"
                    [displayedColumns]="data.displayColumns" [allowLocalFilter]="data.allowLocalFilter"
                    [emitRecordsChecked]="data.emitRecordsChecked" [isDialog]="true" [sortable]="data.sortable"
                    (recordSelected)="recordSelected($event)" (recordsChecked)="recordsChecked($event)">
                </generic-search-results>
            </div>
        </div>
    </div>
</mat-dialog-content>