<display-form [displayObject]="displayGroup"></display-form>

<div *ngIf="membersAdded.length > 0" class="mt-4">
    <generic-search-results
        [showHeader]="false"
        [showCounter]="false"
        [title]="'members added'"
        [records]="membersAdded"
        [displayedColumns]="memberColumns"
        [selectableRecords]="false"
        [allowLocalFilter]="false"
    ></generic-search-results>
</div>

<div *ngIf="membersRemoved.length > 0" class="mt-4">
    <generic-search-results
        [showHeader]="false"
        [showCounter]="false"
        [title]="'members removed'"
        [records]="membersRemoved"
        [displayedColumns]="memberColumns"
        [selectableRecords]="false"
        [allowLocalFilter]="false"
    ></generic-search-results>
</div>