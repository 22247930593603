import { IGraphAppExposedPermissions } from "./GraphAppExposedPermissions";
import { GraphApplication, IGraphApplication } from "./GraphApplication";

export interface IGraphApplicationPermissions {

    application: GraphApplication;

    permissions: IGraphAppExposedPermissions[];

}

export class GraphApplicationPermissions implements IGraphApplicationPermissions {

    constructor(app: IGraphApplication, permissions: IGraphAppExposedPermissions[]) {
        this.application = app;
        this.permissions = permissions;
    }

    application: IGraphApplication = new GraphApplication();

    permissions: IGraphAppExposedPermissions[] = [];
}