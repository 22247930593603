import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

export class matTableSelectionHelper {

    static isAllSelected(selection: SelectionModel<any>, dataSource: MatTableDataSource<any, MatPaginator>): boolean {
        return dataSource.filteredData.filter(row => selection.selected.includes(row)).length === dataSource.filteredData.length;
    }
    static toggleSelectAll(selection: SelectionModel<any>, dataSource: MatTableDataSource<any, MatPaginator>) {
        matTableSelectionHelper.isAllSelected(selection, dataSource) ?
            selection.clear() :
            dataSource.filteredData.forEach((row: any) => selection.select(row));
    }

}