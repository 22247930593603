import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationClientSecretRequestModel } from "src/app/models/graph/applications/GraphApplicationClientSecretRequestModel";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";

@Component({
    selector: 'request-details-app-register-client-secret',
    templateUrl: './request-details-app-register-client-secret.html'
})

export class RequestDetailsAppRegisterClientSecret implements OnInit {

    @Input() requestData: GraphApplicationClientSecretRequestModel;
    @Input() successes: string[];
    @Output() doneLoading = new EventEmitter<boolean>();

    appsUsed: GraphApplicationWithClientSecret[] = [];
    displayColumns: string[] = ['displayName', 'appId', 'clientSecret'];

    constructor(private graphAppService: GraphApplicationService){
        
    }

    async ngOnInit(): Promise<void> {
        let index = 0;
        if(this.requestData.applications.length > 0){
            this.requestData.applications.forEach(a => 
            {
                let clientSecret = this.successes.find(s => s.includes(a))?.split('\'')[1];
                this.graphAppService.GetApplicationAsync(a).then(r => {
                    let knownApp = new GraphApplicationWithClientSecret();
                    knownApp.appId = r.data.appId;
                    knownApp.displayName = r.data.displayName;
                    knownApp.clientSecret = clientSecret ?? 'N/A';
                    this.appsUsed = [...this.appsUsed, knownApp];
                }).catch(ex => {
                    let unknownApp = new GraphApplicationWithClientSecret();
                    
                    unknownApp.displayName = `Unknown Application (ApplicationObjectId=${a})`;
                    unknownApp.appId = 'N/A';
                    unknownApp.clientSecret = clientSecret ?? 'N/A';
                    this.appsUsed = [...this.appsUsed, unknownApp];
                    console.log(ex);
                }).finally(() => {
                    if(index >= this.requestData.applications.length - 1)
                        this.isDoneLoading();
                    else
                        index++;
                });
            })
        }
        else{
            this.isDoneLoading();
        }
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}

class GraphApplicationWithClientSecret extends GraphApplication{
    clientSecret: string | undefined      
}