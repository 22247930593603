import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IUserUpdate } from "src/app/models/userUpdate";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { PageLayout } from "@cvx/nextpage";
import { SelectionModel } from "@angular/cdk/collections";
import { ResultSizeConstants } from "src/app/models/common/enums/ResultSizeConstants.enum";
import { GraphUserService } from "src/app/services/graph/GraphUser.service";
import { CalAngularService } from "@cvx/cal-angular";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { GuestUser, UpdateGuestUserRequest } from "src/app/models/guestAccounts/UpdateGuestUserRequest";
import { CocoUser, UpdateCocoUserRequest } from "src/app/models/cocoUser/UpdateCocoUserRequest";
import { FormService } from "src/app/services/form.service";
import { ServiceAccount, UpdateServiceAccountRequest } from "src/app/models/servicePrivilegeAccounts/UpdateServiceAccountRequest";
import { matTableSelectionHelper } from "src/app/helpers/matTableSelectionHelper";

@Component({
  selector: 'app-user-update',
  templateUrl: './user-update.component.html',
})
export class UserUpdateComponent implements OnInit {
  @Input() displayedColumns: string[] = [];
  @Input() userType: UserType | undefined;
  @Input() displayColumns: string[] = [];
  @Input() route: string;

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.usersDataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.usersDataSource.sort = value;
  }

  usersToUpdate: IUserUpdate[];
  userTypeString: string | undefined;
  selectedDisplayCols: string[];
  cols: string[]
  PageLayout = PageLayout;
  errorMessage: string;
  gettingUsers: boolean = true;
  isSubmitting: boolean = false;
  submittedRequests: IWorkflowRequest[] = [];
  submitErrorMessage = { message: '', errors: [] };
  selection = new SelectionModel<IUserUpdate>(true, []);
  retireResults: string[] = [];
  usersDataSource = new MatTableDataSource<IDirectoryUser>([]);
  updateUserDisplayedColumns: string[] = [];
  showSelectAllCheckbox: boolean = false;

  constructor(
    private readonly userService: GraphUserService,
    private readonly formService: FormService,
    private readonly authService: CalAngularService) {
  }

  ngOnInit(): void {
    this.cols = ['select', ...this.displayColumns];
    this.selectedDisplayCols = [...this.displayColumns];
    this.updateUserDisplayedColumns = [...this.displayColumns];
    this.usersDataSource.paginator = this.paginator
    this.usersDataSource.sort = this.sort

    this.showSelectAllCheckbox = this.userType !== UserType.Coco;

    this.search();
  }

  public onUserInfoChange(usersToUpdate: IUserUpdate[]) {
    this.submitErrorMessage = { message: '', errors: [] };
    this.usersToUpdate = usersToUpdate;
  }
  public onRecordDeleted(usersToDelete: IUserUpdate) {
    this.usersToUpdate = this.usersToUpdate.filter(user => user.id !== usersToDelete.id);
  }

  public select(row: IDirectoryUser) {
    this.selection.toggle(row);
  }

  public filter = (value: any) => {
    this.usersDataSource.filter = value.target.value.trim().toLocaleLowerCase();
  }

  public onUpdateClick() {
    if (this.usersToUpdate.some(user => !user.isValid)) {
      this.formService.setIsValidateUpdateUserForms(true);
      return;
    }
    this.updateUsers(this.getUpdateRequest());
  }

  private getUpdateRequest() {
    switch (this.userType) {
      case UserType.Guest:
        return this.getUpdateGuestRequest();
      case UserType.Coco:
        return this.getUpdateCocoRequest();
      case UserType.Service:
        return this.getUpdateServiceAccountRequest();
    }
    return undefined;
  }

  private getUpdateCocoRequest(): UpdateCocoUserRequest {
    const filteredUsers = this.usersToUpdate.filter((user) => {
      if (user.updateUserInfo) {
        const cocoUser = user.updateUserInfo as CocoUser;
        return cocoUser.givenName || cocoUser.surname || cocoUser.telephoneNumber || cocoUser.jobTitle || cocoUser.mobilePhone || cocoUser.stationNumber || cocoUser.stewardUserId;
      }
      return false;
    });
    const cocoUsers: CocoUser[] = filteredUsers.map(user => {
      const udpatedCocoUser = user.updateUserInfo as CocoUser;
      const stewardUserId = this.getStewardId(udpatedCocoUser.stewardUserId);
      return {
        userId: user.id,
        givenName: udpatedCocoUser.givenName ?? "",
        surname: udpatedCocoUser.surname ?? "",
        jobTitle: udpatedCocoUser.jobTitle ?? "None",
        telephoneNumber: udpatedCocoUser.telephoneNumber ?? "",
        mobilePhone: udpatedCocoUser.mobilePhone ?? "",
        stationNumber: udpatedCocoUser.stationNumber ?? "",
        stewardUserId
      }
    });
    return new UpdateCocoUserRequest(cocoUsers);
  }

  private getUpdateGuestRequest(): UpdateGuestUserRequest {
    const filteredUsers = this.usersToUpdate.filter((user) => {
      if (user.updateUserInfo) {
        const guestUser = user.updateUserInfo as GuestUser;
        return guestUser.givenName || guestUser.stewardUserId || guestUser.surname;
      }
      return false;
    });
    const guestUsers: GuestUser[] = filteredUsers.map((user) => {
      const udpatedGuestUser = user.updateUserInfo as GuestUser;
      const stewardUserId = this.getStewardId(udpatedGuestUser.stewardUserId);
      const guestUser: GuestUser = {
        userId: user.id,
        givenName: udpatedGuestUser.givenName ?? "",
        surname: udpatedGuestUser.surname ?? "",
        stewardUserId
      }
      return guestUser;
    });
    return new UpdateGuestUserRequest(guestUsers);
  }

  private getUpdateServiceAccountRequest(): UpdateServiceAccountRequest {
    const filteredUsers = this.usersToUpdate.filter((user) => {
      if (user.updateUserInfo) {
        const serviceAccount = user.updateUserInfo as ServiceAccount;
        return serviceAccount.applicationId || serviceAccount.stewardUserId;
      }
      return false;
    });
    const serviceAccounts: ServiceAccount[] = filteredUsers.map((user) => {
      const udpatedServiceAccount = user.updateUserInfo as ServiceAccount;
      const stewardUserId = this.getStewardId(udpatedServiceAccount.stewardUserId);
      const serviceAccount: ServiceAccount = {
        userId: user.id,
        applicationId: udpatedServiceAccount.applicationId ?? "",
        stewardUserId
      }
      return serviceAccount;
    });
    return new UpdateServiceAccountRequest(serviceAccounts);
  }

  private getStewardId(stewardUserId: string | undefined) {
    if (stewardUserId && stewardUserId !== "")
      return stewardUserId
    return undefined;
  }

  private updateUsers(updateUserRequest: UpdateGuestUserRequest | UpdateCocoUserRequest | UpdateServiceAccountRequest | undefined) {
    if (!updateUserRequest) return;
    this.isSubmitting = true;
    const updateObserver = {
      next: (x: IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>) => {
        this.submitErrorMessage = { message: '', errors: [] };
        this.submittedRequests = x.requests;
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      }
    };
    switch (this.userType) {
      case UserType.Guest:
        this.userService.UpdateGuests(updateUserRequest as UpdateGuestUserRequest).subscribe(updateObserver);
        break;
      case UserType.Coco:
        this.userService.UpdateCocoUsers(updateUserRequest as UpdateCocoUserRequest).subscribe(updateObserver);
        break;
      case UserType.Service:
        this.userService.UpdateSevricesAccounts(updateUserRequest as UpdateServiceAccountRequest).subscribe(updateObserver);
        break;
    }
  }

  private search() {
    this.userTypeString = this.userType?.toLowerCase();
    if (!this.userType) {
      this.errorMessage = 'Woops, looks like this component was used without providing a user type.';
      this.gettingUsers = false;
    }
    else if (this.hasPermissions()) {
      this.selection.clear();
      this.gettingUsers = true;
      const stewardId = this.userType == UserType.Coco ? undefined : this.authService.cvxClaimsPrincipal.objectId;
      this.userService.SearchUser("", this.userType, stewardId, ResultSizeConstants.GetAll).subscribe({
        next: (r) => {
          this.usersDataSource.data = r.data
        },
        error: (e) => {
          console.error(e);
          this.errorMessage = `encountered unexpected error while searching for ${this.userTypeString} accounts`;
          this.gettingUsers = false;
        },
        complete: () => {
          this.errorMessage = this.usersDataSource.data.length == 0 ? `no ${this.userTypeString} accounts were found` : "";
          this.gettingUsers = false;
        }
      });
    }
    else {
      this.errorMessage = `only authorized users can manage ${this.userTypeString} accounts.`;
      this.gettingUsers = false;
    }
  }

  private hasPermissions(): boolean {
    if (this.userType == UserType.Coco) {
      return this.userService.CheckCocoUserSponsorshipPermission(this.authService.cvxClaimsPrincipal);
    }
    return true;
  }

  //for checkbox select all use
  isAllSelected() {
    return matTableSelectionHelper.isAllSelected(this.selection, this.usersDataSource);
  }
  masterToggle() {
    return matTableSelectionHelper.toggleSelectAll(this.selection, this.usersDataSource);
  }
}
