<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12" >

                <request-results [type]="RequestResultsType.Approver" (recordSelected)="selected($event)"></request-results>

            </div>
        </div>
    </section>
</main>