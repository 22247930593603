<form class="branded">
    @if (includeFilter) {
        <filter-input (onKeyUp)="filter($event)"></filter-input>
    }
    <table id="users" mat-table
        [dataSource]="requestsDataSource" aria-describedby="workflow requests">
        <caption> requests </caption>

        @if (includeCheckbox) {
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <input MatCheckbox type="checkbox" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()" />
                </th>
                <td MatCheckbox mat-cell *matCellDef="let row">
                    <input type="checkbox" name="formcheckbox" (click)="$event.stopPropagation()"
                        (change)="select(row)" [checked]="selection.isSelected(row)" />
                </td>
            </ng-container>
        }

        @for (disCol of coreCols; track disCol; let colIndex = $index){
        <ng-container matColumnDef="{{disCol}}">
            <th mat-header-cell *matHeaderCellDef>
                {{disCol | convertDisplayLabels}}</th>
            <td mat-cell *matCellDef="let element">
                <text-formatter [content]="element[disCol]"></text-formatter>
            </td>
        </ng-container>}

        <ng-container matColumnDef="info">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
                <button mat-icon-button (click)="selectRow(row)">
                    <mat-icon  style="color:#0066B2">info</mat-icon>
                </button>
            </td>
        </ng-container>

        @if (includeDelete) {
            <ng-container matColumnDef="del">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" style="text-align:center;">
                    <button mat-icon-button matSuffix (click)="deleteRow(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>
        }

        <tr mat-header-row *matHeaderRowDef="displayCols"></tr>
        <tr mat-row *matRowDef="let row; columns: displayCols;"></tr>

    </table>
    @if (includePaginator) {
        <mat-paginator [hidden]="requestsDataSource.data.length == 0"
        [length]="requestsDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]"
        showFirstLastButtons></mat-paginator>
    }
</form>