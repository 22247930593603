import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';
import { RequestResultsType } from 'src/app/models/components/enums/RequestResultsType';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { WorkflowRequestService } from 'src/app/services/workflow/WorkflowRequestService';

@Component({
  selector: 'request-results',
  templateUrl: './request-results.component.html',
})
export class RequestResultsComponent implements OnInit {
  @Input() type: RequestResultsType;
  @Output() recordSelected = new EventEmitter<IWorkflowRequest>();

  searchResults: IWorkflowRequest[];
  displayColumns: string[] = ['id', 'type', 'state', 'createDate'];

  screenMessage: string = '';
  isSearching: boolean = false;

  constructor(
    private workflowService: WorkflowRequestService
  ) { }

  get RequestResultsType() {
    return RequestResultsType;
  }
  searchObserver = {
    next: (x: IGenericApiResponse<IWorkflowRequest[]>) => {
      this.searchResults = x.data;
    },
    error: (err: any) => {
      this.screenMessage = err.statusText;
      this.isSearching = false;
    },
    complete: () => {
      this.screenMessage = this.searchResults.length === 0 ? 'no results found' : '';
      this.isSearching = false;
    },
  };
  async ngOnInit(): Promise<void> {
    this.isSearching = true;
    switch (this.type) {
      case RequestResultsType.Approver:
        this.workflowService.GetByApprover().subscribe(this.searchObserver);
        break;
      case RequestResultsType.Requestor:
        this.workflowService.GetByRequestor().subscribe(this.searchObserver);
        break;
    }
  }

  selected(request: IWorkflowRequest) {
    this.recordSelected.emit(request);
  }
}
