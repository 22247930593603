import { CyberEventRequestType } from "./enums/CyberEventRequestType";

/**
 * POST payload for endpoint /api/users/{domain}/cyberEvents
 */
export class CyberEventRequest<T> {
    constructor(eventType: CyberEventRequestType, eventDetails: T) {
        this.eventType = eventType;
        this.eventDetails = eventDetails;
    }
    eventType: CyberEventRequestType;
    eventDetails: T;
}