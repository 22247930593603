import { Component } from '@angular/core';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';

@Component({
  selector: 'app-bulk-manage-primary-user',
  templateUrl: './bulk-manage-primary-user.component.html'
})
export class BulkManagePrimaryUserComponent {

  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
}
