import { MailDomain } from "../enums/MailDomain.enum";

export class DirectoryMailboxUserCreateRequestDetails {
    displayName: string;
    businessPurpose: string;
    mailNickName: string;
    mailDomain: string;
    steward: string;
    delegate: string;
    chainOfCommand: string[];
}

export class DirectoryMailboxUserCreateRequest {
    displayName: string;
    businessPurpose: string;
    mailNickname: string;
    mailDomain: MailDomain;
    stewardProvisioningId: string;
    delegateProvisioningId: string;
    chainOfCommandProvisioningIds: string[];

    constructor(displayName: string, businessPurpose: string,
        mailNickname: string, mailDomain: MailDomain,
        steward: string, delegate: string, chainOfCommands: string[]) {
        this.displayName = displayName;
        this.businessPurpose = businessPurpose;
        this.mailNickname = mailNickname;
        this.mailDomain = mailDomain;
        this.stewardProvisioningId = steward;
        this.delegateProvisioningId = delegate;
        this.chainOfCommandProvisioningIds = chainOfCommands;
    }
}