import { Component, ViewChild } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { CamEntity } from 'src/app/models/camEntity';
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';
import { TableColumn } from 'src/app/models/common/TableColumn';
import { MatDatepicker } from '@angular/material/datepicker';
import { IdampOtherHttpClient } from 'src/app/services/common/IdampOtherHttpClient';
import { MatDialog } from '@angular/material/dialog';
import { DialogProgressComponent } from 'src/app/components/_shared/dialog-progress/dialog-progress.component';

@Component({
  selector: 'app-contractor-access-management',
  templateUrl: './contractor-access-management.component.html',
  styleUrls: ['./contractor-access-management.component.css']
})


export class ContractorAccessManagementComponent { 

  @ViewChild(MatDatepicker) picker: MatDatepicker<Date>;

  PageLayout = PageLayout;

  CamUsers: CamEntity[] = [];
  DisplayedCamUsers: CamEntity[] = [];
  SelectedCamUsers: CamEntity[] = [];

  MaxDate: Date = new Date()
  IsLoading = false;
  IsErrored = false;

  CamColumns: TableColumn[] = [
    { key: 'cvxdisplayname', header: 'DisplayName' },
    { key: 'cvxcai', header: 'CAI' },
    { key: 'employeenumber', header: 'ProvId' },
    { key: 'cvxsourcingcompany', header: 'SourcingCompany' },
    { key: 'cvxaccountenddate', header: 'EndDate' },
    { key: 'newcvxaccountenddate', header: 'NewEndDate' },
  ];

  constructor(private authService: CalAngularService, private http: IdampOtherHttpClient, private dialog: MatDialog, ) {
  }

  async ngOnInit() {
    this.MaxDate.setFullYear(this.MaxDate.getFullYear() + 1);

    await this.GetUsers();
  }

  async GetUsers() {
    this.IsLoading = true;  
    let apiCall = await this.http.GetAsync<IGenericApiResponse<CamEntity[]>>("cam")
    this.CamUsers = apiCall.data;
    this.DisplayedCamUsers = apiCall.data;
    this.IsLoading = false;  
  }

  CheckAndSetEndDate(date: Date, provId: number) {
    if (date > this.MaxDate) {
      date = this.MaxDate;
    }

    let index = this.CamUsers.findIndex(item => item.employeenumber === provId);

    if (index !== -1) {
      this.CamUsers[index].newcvxaccountenddate = date.toISOString().split('T')[0];
    }
  }

  SetEndDateByMonths(row:any, months:number){
    let endDate = new Date(row.cvxaccountenddate)
    endDate.setMonth(endDate.getMonth() + months);

    this.CheckAndSetEndDate(endDate, row.employeenumber)
  }

  SetEndDateByDate(event: any, row: any) {
    this.CheckAndSetEndDate(event.value, row.employeenumber)
  }

  RetireAccount(row: any) {
    let endDate = new Date()
    endDate.setDate(endDate.getDate() - 1);

    this.CheckAndSetEndDate(endDate, row.employeenumber)
  }

  ResetAccount(row: any) {
    let index = this.CamUsers.findIndex(item => item.employeenumber === row.employeenumber);

    if (index !== -1) {
      this.CamUsers[index].newcvxaccountenddate = null;
    }
  }

  openDatePicker(picker: MatDatepicker<Date>) {
    picker.open();
  }

  handleSelection(selectedRows: any[]) {
    this.SelectedCamUsers = selectedRows;
  }

  setEndDateOnSelected(event: any) {
    this.SelectedCamUsers.forEach(user => {
      this.CheckAndSetEndDate(event.value, user.employeenumber)
    });
  }

  filterOnEndDate(event: any) {

    if (event.value == null) {
      this.DisplayedCamUsers = this.CamUsers
    } else {
      this.DisplayedCamUsers = this.CamUsers.filter(
        user => new Date(user.cvxaccountenddate) <= event.value)      
    }
  }

  resetPage() {
    window.location.reload()
  }

  noUpdates() {
    return this.CamUsers.filter(user => user.newcvxaccountenddate != null).length == 0
  }

  async sendUpdates() {

    let apiCalls: any[] = [];
    let updates = this.CamUsers.filter( user => user.newcvxaccountenddate != null )
    updates.forEach( user => { user.cvxaccountenddate = user.newcvxaccountenddate! })

    console.log(updates)

    updates.forEach(user => {
      apiCalls.push(
        this.http.PatchAsync<CamEntity, IGenericApiResponse<string>>("cam", user)
      )
    })

    let data = {
      promises: apiCalls
   }

    const dialogRef = this.dialog.open(DialogProgressComponent, {
      disableClose: true,
      autoFocus: true,
      width: '400px',
      data: data,
    });

    dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    })
  }
}