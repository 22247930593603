import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CalAngularService } from '@cvx/cal-angular';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { ResultSizeConstants } from 'src/app/models/common/enums/ResultSizeConstants.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import Swal from 'sweetalert2';
import { matTableSelectionHelper } from 'src/app/helpers/matTableSelectionHelper';

@Component({
  selector: 'app-mfa-reset',
  templateUrl: './mfa-reset.component.html',
})
export class MfaResetComponent implements OnInit {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  currentUserProfile: any = null;
  accounts: IDirectoryUser[] = [];
  isDataLoading: boolean = false;
  dataSource = new MatTableDataSource<IDirectoryUser>();

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  displayedColumns: string[] = ["checkBox", 'displayName', 'mail', 'type', 'companyName'];
  navSubscription = new Subscription();
  constructor(
    private authService: CalAngularService,
    private graphUserService: GraphUserService
  ) {
  }

  isSubmitting: boolean = false;
  submittedRequests: IWorkflowRequest[] = [];
  submitErrorMessage = { message: '', errors: [] };

  selectedUserDisplayedColumns: string[] = ['displayName', 'mail', 'companyName'];

  retireAccounts(accounts: IDirectoryUser[]) {
    this.isSubmitting = true;

    const retireObserver = {
      next: (x: IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>) => {
        this.submittedRequests = x.requests;
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      }
    };

    this.graphUserService.ResetMFA(accounts.map(x => x.id)).subscribe(retireObserver);
  }

  selection = new SelectionModel<IDirectoryUser>(true, []);
  selectAccount(row: IDirectoryUser) {
    this.selection.toggle(row);
  }

  public doFilter = (event: any) => {
    const { value } = event.target
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  onRetireClick() {
    this.submittedRequests = [];
    this.submitErrorMessage = { message: '', errors: [] };
    if (this.selection.selected.length > 0) {
      let messageText = this.selection.selected.length === 1 ? 'account' : 'accounts';

      Swal.fire({
        icon: 'warning',
        iconColor: '#97002E',
        title: '<h3>are you sure?</h3>',
        html:
          `<p>You are about to reset mfa for ${this.selection.selected.length} ${messageText}, ` +
          "this operation can't be undone.</p>",
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'button',
          cancelButton: 'button',
        },
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.retireAccounts(this.selection.selected);
        }
      });
    }
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.authService.isUserSignedIn()) {
      this.getAllAccounts();
    } else {
      console.log('user not yet signed in');
    }
  }

  getAllAccounts() {
    this.isDataLoading = true;
    const observer = {
      next: (x: IDirectoryUser[]) => {
        this.isDataLoading = false;
        this.accounts = x;
      },
      error: (err: any) => {
        this.isDataLoading = false;
        console.log(err);
      },
      complete: () => {
        this.dataSource.data = this.accounts;
      }
    };
    const requests: Observable<IGenericApiResponse<IDirectoryUser[]>>[] = [];
    requests.push(this.graphUserService.SearchUser(this.authService.cvxClaimsPrincipal.objectId));

    requests.push(this.graphUserService.SearchUser("", UserType.Guest, this.authService.cvxClaimsPrincipal.objectId, ResultSizeConstants.GetAll));

    if (this.graphUserService.CheckIsCocoUserAdmin(this.authService.cvxClaimsPrincipal)) {
      requests.push(this.graphUserService.SearchUser("", UserType.Coco, undefined, ResultSizeConstants.GetAll))
    }
    forkJoin(requests)
      .pipe(map(data => data.reduce((result: IDirectoryUser[], apiResponse: IGenericApiResponse<IDirectoryUser[]>) => [...result, ...apiResponse.data], [])))
      .subscribe(observer);
  }

  ngOnDestroy(): void {
    this.navSubscription.unsubscribe();
  }

  //for checkbox select all use
  isAllSelected() {
    return matTableSelectionHelper.isAllSelected(this.selection, this.dataSource);
  }
  masterToggle() {
    return matTableSelectionHelper.toggleSelectAll(this.selection, this.dataSource);
  }

}