/**
 * POST payload for endpoint /api/secrets/bulkpermissionschange
 */
export class UpdateSecretPermissionsRequest {
    
    constructor(secretIds: number[],
        owners:string[],
        viewers:string[],
        revoke: string[]
        ) {
        this.secretIds = secretIds;
        this.owners = owners;
        this.viewers = viewers;
        this.revoke = revoke;
    }

    secretIds: number[] = []
    owners: string[] = []
    viewers: string[] = []
    revoke: string[] = []
}