import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDialogGenericSearchResultsData } from "src/app/models/components/IDialogGenericSearchResultsData";

@Component({
    selector: 'dialog-generic-search-results',
    templateUrl: './dialog-generic-search-results.component.html'
})

export class DialogGenericSearchResultsComponent<TData> {
    constructor(private dialogRef: MatDialogRef<DialogGenericSearchResultsComponent<TData>>, 
        @Inject(MAT_DIALOG_DATA) public data:IDialogGenericSearchResultsData<TData>,
        ){}

    recordSelected(data:TData) {
        this.dialogRef.close(data);
    }

    searchCancelled() {
        this.dialogRef.close();
    }
}