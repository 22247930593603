import { Component, OnInit, Input, ViewChild, ViewContainerRef, TemplateRef, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { TableColumn } from 'src/app/models/common/TableColumn';
import { SelectionModel } from '@angular/cdk/collections';


@Component({
  selector: 'app-generic-reusable-table',
  templateUrl: './generic-reusable-table.component.html',
})
export class GenericReusableTableComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() showActions: boolean = false;
  @Input() showCheckboxes = false;
  @Input() columnBreakpoints: { [breakpoint: string]: string[] } | null = null;
  @Input() rowActionsTemplate: TemplateRef<any> | null = null;


  @Output() selectionChanged = new EventEmitter<any[]>();

  displayedColumns: string[] = [];
  selection = new SelectionModel<any>(true, []);
  dataSource!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('actionsColumn', { read: ViewContainerRef }) actionsColumnContainer!: ViewContainerRef;

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.data);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(() => {
      this.updateDisplayedColumns();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
  }

  private updateDisplayedColumns() {
    const baseColumns = this.columns.map(c => c.key);

    if (this.showCheckboxes) {
      baseColumns.unshift('select');
    }

    if (this.showActions) {
      baseColumns.push('actions');
    }

    if (this.columnBreakpoints) {
      if (this.breakpointObserver.isMatched(Breakpoints.XLarge) && this.columnBreakpoints['XLarge']) {
        this.displayedColumns = this.columnBreakpoints['XLarge'];
      } else if (this.breakpointObserver.isMatched(Breakpoints.Large) && this.columnBreakpoints['Large']) {
        this.displayedColumns = this.columnBreakpoints['Large'];
      } else if (this.breakpointObserver.isMatched(Breakpoints.Medium) && this.columnBreakpoints['Medium']) {
        this.displayedColumns = this.columnBreakpoints['Medium'];
      } else if (this.breakpointObserver.isMatched(Breakpoints.Small) && this.columnBreakpoints['Small']) {
        this.displayedColumns = this.columnBreakpoints['Small'];
      } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall) && this.columnBreakpoints['XSmall']) {
        this.displayedColumns = this.columnBreakpoints['XSmall'];
      } else {
        // default to showing all columns
        this.displayedColumns = [...baseColumns];
      }
    } else {
      // If no specific columns defined for breakpoints, display all.
      this.displayedColumns = [...baseColumns];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.showCheckboxes) {
      this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
    }

    this.selectionChanged.emit(this.selection.selected);
  }

  toggleRow(row: any) {
    this.selection.toggle(row);

    this.selectionChanged.emit(this.selection.selected);
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  applyTableFilter(filterValue: any) {
    filterValue = filterValue.target.value
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
}

