import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { UserHelper } from "src/app/helpers/userHelper";

export const MaxAllowedBulkInviteGuestBatchSize: number = 50;

@Component({
    selector: 'batch-add-guests',
    templateUrl: './batch-add-guests.component.html'
})
export class BatchAddGuestsComponent {
    errorMessages: string[] = []
    guestDetails: string;
    sampleGuests: string[] = [
        'John;Smith;John.Smith@ChevronGuestCompany.com',
        "Mary Jane;O'Connor;MJOConnor@chevronguestcompany.com"
    ]
    constructor(private dialogRef: MatDialogRef<BatchAddGuestsComponent>) { }

    onAdd() {
        if (!this.guestDetails) {
            return;
        }
        this.errorMessages = [];
        const guests: string[] = this.guestDetails?.trim()?.split('\n');

        if (guests.length > MaxAllowedBulkInviteGuestBatchSize) {
            this.errorMessages.push(`Current list of ${guests.length} guests exceeds the max allowed batch size of ${MaxAllowedBulkInviteGuestBatchSize}`)
            return;
        }

        const guestsToAdd: any[] = [];
        if (guests?.length > 0) {
            guests.forEach((guestItem: string) => {
                const guestInfo: string[] = guestItem?.trim()?.split(';');
                if (guestInfo?.length !== 3) {
                    this.errorMessages.push(guestItem);
                } else {
                    guestsToAdd.push({
                        givenName: guestInfo[0],
                        surname: guestInfo[1],
                        emailId: guestInfo[2],
                    });
                }
            });
            const duplicateEmailIds = UserHelper.findAndGetDuplicateEmailIds(guestsToAdd);
            if (duplicateEmailIds.length > 0) {
                this.errorMessages.push(`Current list contains duplicate email id(s) [${duplicateEmailIds.join(", ")}]`);
                return;
            }

            if (this.errorMessages.length === 0) {
                this.dialogRef.close(guestsToAdd);
            }
        }
    }
}