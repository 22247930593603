import { Countries } from "../models/countryModel";

export const countries: Countries [] = [
    { code: "AO", code3: "AGO", name: "Angola", number: "024" },
    { code: "AR", code3: "ARG", name: "Argentina", number: "032" },
    { code: "AU", code3: "AUS", name: "Australia", number: "036" },
    { code: "BS", code3: "BHS", name: "Bahamas", number: "044" },
    { code: "BH", code3: "BHR", name: "Bahrain", number: "048" },
    { code: "BD", code3: "BGD", name: "Bangladesh", number: "050" },
    { code: "BY", code3: "BLR", name: "Belarus", number: "112" },
    { code: "BE", code3: "BEL", name: "Belgium", number: "056" },
    { code: "BZ", code3: "BLZ", name: "Belize", number: "084" },
    { code: "BM", code3: "BMU", name: "Bermuda", number: "060" },
    { code: "BO", code3: "BOL", name: "Bolivia ", number: "068" },
    { code: "BW", code3: "BWA", name: "Botswana", number: "072" },
    { code: "BR", code3: "BRA", name: "Brazil", number: "076" },
    { code: "KH", code3: "KHM", name: "Cambodia", number: "116" },
    { code: "CM", code3: "CMR", name: "Cameroon", number: "120" },
    { code: "CA", code3: "CAN", name: "Canada", number: "124" },
    { code: "KY", code3: "CYM", name: "Cayman Islands", number: "136" },
    { code: "CN", code3: "CHN", name: "China", number: "156" },
    { code: "CO", code3: "COL", name: "Colombia", number: "170" },
    { code: "KM", code3: "COM", name: "Comoros (the)", number: "174" },
    { code: "CD", code3: "COD", name: "Congo, Democratic Republic Of The", number: "180" },
    { code: "CG", code3: "COG", name: "Congo", number: "178" },
    { code: "CR", code3: "CRI", name: "Costa Rica", number: "188" },
    { code: "CY", code3: "CYP", name: "Cyprus", number: "196" },
    { code: "DK", code3: "DNK", name: "Denmark", number: "208" },
    { code: "EC", code3: "ECU", name: "Ecuador", number: "218" },
    { code: "EG", code3: "EGY", name: "Egypt", number: "818" },
    { code: "SV", code3: "SLV", name: "El Salvador", number: "222" },
    { code: "GQ", code3: "GNQ", name: "Equatorial Guinea", number: "226" },
    { code: "FI", code3: "FIN", name: "Finland", number: "246" },
    { code: "FR", code3: "FRA", name: "France", number: "250" },
    { code: "GE", code3: "GEO", name: "Georgia", number: "268" },
    { code: "DE", code3: "DEU", name: "Germany", number: "276" },
    { code: "GH", code3: "GHA", name: "Ghana", number: "288" },
    { code: "GI", code3: "GIB", name: "Gibraltar", number: "292" },
    { code: "GR", code3: "GRC", name: "Greece", number: "300" },
    { code: "GP", code3: "GLP", name: "Guadeloupe", number: "312" },
    { code: "GT", code3: "GTM", name: "Guatemala", number: "320" },
    { code: "HN", code3: "HND", name: "Honduras", number: "340" },
    { code: "HK", code3: "HKG", name: "Hong Kong", number: "344" },
    { code: "IN", code3: "IND", name: "India", number: "356" },
    { code: "ID", code3: "IDN", name: "Indonesia", number: "360" },
    { code: "IQ", code3: "IRQ", name: "Iraq", number: "368" },
    { code: "IE", code3: "IRL", name: "Ireland", number: "372" },
    { code: "IL", code3: "ISR", name: "Israel", number: "376" },
    { code: "IT", code3: "ITA", name: "Italy", number: "380" },
    { code: "JP", code3: "JPN", name: "Japan", number: "392" },
    { code: "JO", code3: "JOR", name: "Jordan", number: "400" },
    { code: "KZ", code3: "KAZ", name: "Kazakhstan", number: "398" },
    { code: "KR", code3: "KOR", name: "Korea, Republic Of", number: "410" },
    { code: "KW", code3: "KWT", name: "Kuwait", number: "414" },
    { code: "LA", code3: "LAO", name: "Lao People's Democratic Republic", number: "418" },
    { code: "LR", code3: "LBR", name: "Liberia", number: "430" },
    { code: "LY", code3: "LBY", name: "Libya", number: "434" },
    { code: "MY", code3: "MYS", name: "Malaysia", number: "458" },
    { code: "MX", code3: "MEX", name: "Mexico", number: "484" },
    { code: "MM", code3: "MMR", name: "Myanmar", number: "104" },
    { code: "NA", code3: "NAM", name: "Namibia", number: "516" },
    { code: "NR", code3: "NRU", name: "Nauru", number: "520" },
    { code: "NP", code3: "NPL", name: "Nepal", number: "524" },
    { code: "NL", code3: "NLD", name: "Netherlands", number: "528" },
    { code: "NI", code3: "NIC", name: "Nicaragua", number: "558" },
    { code: "NG", code3: "NGA", name: "Nigeria", number: "566" },
    { code: "NO", code3: "NOR", name: "Norway", number: "578" },
    { code: "PK", code3: "PAK", name: "Pakistan", number: "586" },
    { code: "PA", code3: "PAN", name: "Panama", number: "591" },
    { code: "PG", code3: "PNG", name: "Papua New Guinea", number: "598" },
    { code: "PY", code3: "PRY", name: "Paraguay", number: "600" },
    { code: "PE", code3: "PER", name: "Peru", number: "604" },
    { code: "PH", code3: "PHL", name: "Philippines", number: "608" },
    { code: "PL", code3: "POL", name: "Poland", number: "616" },
    { code: "PT", code3: "PRT", name: "Portugal", number: "620" },
    { code: "PR", code3: "PRI", name: "Puerto Rico", number: "630" },
    { code: "RO", code3: "ROU", name: "Romania", number: "642" },
    { code: "RU", code3: "RUS", name: "Russia", number: "643" },
    { code: "VC", code3: "VCT", name: "Saint Vincent and the Grenadines", number: "670" },
    { code: "SA", code3: "SAU", name: "Saudi Arabia", number: "682" },
    { code: "SL", code3: "SLE", name: "Sierra Leone", number: "694" },
    { code: "SG", code3: "SGP", name: "Singapore", number: "702" },
    { code: "SK", code3: "SVK", name: "Slovakia", number: "703" },
    { code: "SI", code3: "SVN", name: "Slovenia", number: "705" },
    { code: "ZA", code3: "ZAF", name: "South Africa", number: "710" },
    { code: "KR", code3: "KOR", name: "South Korea", number: "410" },
    { code: "ES", code3: "ESP", name: "Spain", number: "724" },
    { code: "SZ", code3: "SWZ", name: "Swaziland", number: "748" },
    { code: "SE", code3: "SWE", name: "Sweden", number: "752" },
    { code: "TW", code3: "TWN", name: "Taiwan", number: "158" },
    { code: "TZ", code3: "TZA", name: "Tanzania, United Republic of", number: "834" },
    { code: "TH", code3: "THA", name: "Thailand", number: "764" },
    { code: "TT", code3: "TTO", name: "Trinidad and Tobago", number: "780" },
    { code: "TC", code3: "TCA", name: "Turks and Caicos Islands", number: "796" },
    { code: "UA", code3: "UKR", name: "Ukraine", number: "804" },
    { code: "AE", code3: "ARE", name: "United Arab Emirates", number: "784" },
    { code: "GB", code3: "GBR", name: "United Kingdom", number: "826" },
    { code: "US", code3: "USA", name: "United States of America", number: "840" },
    { code: "US", code3: "USA", name: "United States", number: "840" },
    { code: "VE", code3: "VEN", name: "Venezuela", number: "862" },
    { code: "VN", code3: "VNM", name: "Vietnam", number: "704" },
    { code: "VI", code3: "VIR", name: "Virgin Islands (U.S.)", number: "850" },
    { code: "ZM", code3: "ZMB", name: "Zambia", number: "894" },
    { code: "ZW", code3: "ZWE", name: "Zimbabwe", number: "716" },
];