import { Injectable } from "@angular/core";
import { IdampHttpClient } from "../common/IdampHttpClient";
import { GraphConstants } from "src/app/models/graph/Graph.constants";
import { CyberEventRequest } from "src/app/models/cyber/CyberEventRequest";
import { CreateCyberEventCompromisedSourcingCompanyRequest } from "src/app/models/cyber/CyberEventCompromisedSourcingCompanyRequest";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { from, Observable } from "rxjs";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { CloseCyberEventCompromisedSourcingCompanyRequest } from "src/app/models/cyber/CloseCyberEventCompromisedSourcingCompanyRequest";

@Injectable({ providedIn: 'root' })

export class CyberEventService {
    constructor(private http: IdampHttpClient) { }

    public GetCyberEventCompromisedSourcingCompanyImpactedUsers(cyberEventLockdownRequest: CyberEventRequest<CreateCyberEventCompromisedSourcingCompanyRequest>): Observable<IGenericApiResponse<IDirectoryUser[]>> {
        const path = `cyberEvents/${GraphConstants.DefaultGraphDomain}`;
        const query: any = { whatIf : true };
        return from(this.http.PostAsync<CyberEventRequest<CreateCyberEventCompromisedSourcingCompanyRequest>, IGenericApiResponse<IDirectoryUser[]>>(path, cyberEventLockdownRequest, query));
    }

    public CreateCyberEventCompromisedSourcingCompany(cyberEventLockdownRequest: CyberEventRequest<CreateCyberEventCompromisedSourcingCompanyRequest>): Observable<IGenericApiResponseWithWorkflowRequest<CreateCyberEventCompromisedSourcingCompanyRequest>> {
        const path = `cyberEvents/${GraphConstants.DefaultGraphDomain}`;
        const query: any = { whatIf : false };
        return from(this.http.PostAsync<CyberEventRequest<CreateCyberEventCompromisedSourcingCompanyRequest>, IGenericApiResponseWithWorkflowRequest<CreateCyberEventCompromisedSourcingCompanyRequest>>(path, cyberEventLockdownRequest, query));
    }

    public GetActiveCyberEvents() : Observable<IGenericApiResponse<IWorkflowRequest[]>> {
        const path = `cyberEvents/${GraphConstants.DefaultGraphDomain}`;
        return from(this.http.GetAsync<IGenericApiResponse<IWorkflowRequest[]>>(path));
    }

    public CloseCyberEventCompromisedSourcingCompany(request: CyberEventRequest<CloseCyberEventCompromisedSourcingCompanyRequest>): Observable<IGenericApiResponseWithWorkflowRequest<CloseCyberEventCompromisedSourcingCompanyRequest>> {
        const path = `cyberEvents/${GraphConstants.DefaultGraphDomain}/close`;
        return from(this.http.PostAsync<CyberEventRequest<CloseCyberEventCompromisedSourcingCompanyRequest>, IGenericApiResponseWithWorkflowRequest<CloseCyberEventCompromisedSourcingCompanyRequest>>(path, request));
    }
}