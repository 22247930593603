import { Injectable } from "@angular/core";
import { CvxClaimsPrincipal, ICvxClaimsPrincipal } from "@cvx/cal-angular";
import { PersonRelationshipType } from "src/app/models/directory/enums/PersonRelationshipType.enum";
import { AppRoleConstants } from "src/app/constants/AppRole.constants";
import { IManageUserAuthorization, IManageableUser } from "src/app/models/primaryUser";
import { DelegatedAdminType } from "src/app/models/primaryUser/admin/DelegatedAdminType";


@Injectable({
    providedIn: 'root'
})

export class PrimaryUserAuthService {

  public GetPageAuthorization(cvxClaimsPrincipal: ICvxClaimsPrincipal): boolean {
    const adminRoleFound = cvxClaimsPrincipal.roles.find(role =>
      role === AppRoleConstants.PrimaryUserGlobalAdmin)

    if (adminRoleFound !== undefined) {
      return true;
    }
    
    const delegatedAdminRoleFound = cvxClaimsPrincipal.roles.find(role =>
      role.includes(AppRoleConstants.PrimaryUserDelegatedAdminPrefix))

    if (delegatedAdminRoleFound !== undefined) {
      return true;
    }

    if (cvxClaimsPrincipal.personRelationshipType === PersonRelationshipType.Employee || 
      cvxClaimsPrincipal.personRelationshipType === PersonRelationshipType.JvPartner) {
      return true;
    }

    return false;
  }

  public CheckManageUserAuthorization (cvxClaimsPrincipal: ICvxClaimsPrincipal, user: IManageableUser): IManageUserAuthorization {
    let isAllowedToManageUser = this.GetManageAuthorizationStatus(cvxClaimsPrincipal, user);
    let isAllowedToEditName = false;

    if (isAllowedToManageUser) {
      isAllowedToEditName = user.workcompany === "0444"
    }

    return {
      isAllowedToManageUser,
      isAllowedToEditName
    }
  }

  private GetManageAuthorizationStatus(cvxClaimsPrincipal: ICvxClaimsPrincipal, user: IManageableUser): boolean {
    const adminRoleFound = cvxClaimsPrincipal.roles.find(role => 
      role === AppRoleConstants.PrimaryUserGlobalAdmin)

    if (adminRoleFound !== undefined) {
      return true;
    }

    const isPrincipalDelegatedAdmin = this.GetPrincipalDelegateAdminStatus(cvxClaimsPrincipal, user);
    
    if (isPrincipalDelegatedAdmin) {
      return true;
    }

    const provId = parseInt(cvxClaimsPrincipal.provisioningId)
    if (provId === user.sponsor) return true;
    if (provId === user.supervisor) return true;
    
    return false;
  }

  private GetPrincipalDelegateAdminStatus(cvxClaimsPrincipal: ICvxClaimsPrincipal, user: IManageableUser): boolean {
    const roles = cvxClaimsPrincipal.roles;

    const workCompanyAdminRole = this.GetDelegatedAdminRole(DelegatedAdminType.WorkCompany, user.workcompany);
    const hrl2CodeAdminRole = this.GetDelegatedAdminRole(DelegatedAdminType.HRL2Code, user.hrLevel2code);
    const hrl5CodeAdminRole = this.GetDelegatedAdminRole(DelegatedAdminType.HRL5Code, user.hrLevel5code);

    const delegatedAdminRoleFound = roles.find(role =>
      role === workCompanyAdminRole ||
      role === hrl2CodeAdminRole || 
      role === hrl5CodeAdminRole)

    return delegatedAdminRoleFound !== undefined;
  }

  private GetDelegatedAdminRole(delegatedAdminType: DelegatedAdminType, value?: string | null): string {
    if (value === null) {
      return "";
    }

    return `${AppRoleConstants.PrimaryUserDelegatedAdminPrefix}.${delegatedAdminType.valueOf()}.${value}`;
  }
}