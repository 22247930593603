import { Injectable } from '@angular/core';
import { IdampHttpClient } from '../common/IdampHttpClient';
import { CreateSecretRequest } from 'src/app/models/secretServer/CreateSecretRequest';
import { SsSecret } from 'src/app/models/secretServer/SsSecret';
import { Observable, from } from 'rxjs';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { UpdateSecretPermissionsRequest } from 'src/app/models/secretServer/UpdateSecretPermissionsRequest';
import { IPagedResponse } from 'src/app/models/common/PagedResponse';
import { RequestSecretPermissionsRequest } from 'src/app/models/secretServer/RequestSecretPermissionsRequest';

@Injectable({
  providedIn: 'root'
})
export class SecretServerService {

  constructor(private http: IdampHttpClient) { }

  public CreatePassword(model: CreateSecretRequest): Observable<IGenericApiResponseWithWorkflowRequest<SsSecret>> {
    const path = `secrets`;
    return from(this.http.PostAsync<CreateSecretRequest, IGenericApiResponseWithWorkflowRequest<SsSecret>>(path, model));
  }

  public GetUserOwnedPasswords(term:string): Observable<SsSecret[]> {
    const path = `me/ownedObjects/secrets`;
    const query = { searchTerm: term };
    return from(this.http.GetAsync<SsSecret[]>(path,query));
  }

  public GetRequestablePasswords(term:string, pageNumber: number = 1, pageSize: number = 10): Observable<IPagedResponse<SsSecret>> {
    const path = `secrets/requestable`;
    const query = { 
      searchTerm: term,
      pageNumber: pageNumber,
      pageSize: pageSize
     };
    return from(this.http.GetAsync<IPagedResponse<SsSecret>>(path,query));
  }

  public DeletePasswordAsync(model: SsSecret[]): Observable<IGenericApiResponseWithWorkflowRequest<SsSecret[]>> {
    const path = `secrets/bulkdelete`;
    
    return from(this.http.PostAsync<number[],IGenericApiResponseWithWorkflowRequest<SsSecret[]>>(path, model.map(s => s.id)));
  }

  public UpdatePasswordPermissionsAsync(model: UpdateSecretPermissionsRequest): Observable<IGenericApiResponseWithWorkflowRequest<SsSecret[]>> {
    const path = `secrets/bulkpermissionschange`;
    
    return from(this.http.PostAsync<UpdateSecretPermissionsRequest,IGenericApiResponseWithWorkflowRequest<SsSecret[]>>(path, model));
  }

  public RequestPasswordPermissionsAsync(model: RequestSecretPermissionsRequest): Observable<IGenericApiResponseWithWorkflowRequest<SsSecret[]>> {
    const path = `secrets/bulkpermissionsrequest`;
    
    return from(this.http.PostAsync<RequestSecretPermissionsRequest,IGenericApiResponseWithWorkflowRequest<SsSecret[]>>(path, model));
  }
}
