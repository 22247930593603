import { Component } from '@angular/core';
import {
    NextPageService,
    NavigationLocation,
    PageLayout,
    SearchType,
    SliderType
} from '@cvx/nextpage';

@Component({
    selector: 'default',
    templateUrl: 'default.component.html',
    styles: [ '.main.container section { width: 100%; }' ]
    
})

export class DefaultComponent {
    NavigationLocation = NavigationLocation;
    PageLayout = PageLayout;
    SearchType = SearchType;
    SliderType = SliderType;


    constructor(private NP: NextPageService) { }
}