import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { CocoUserRequest } from "src/app/models/cocoUser/CocoUserRequest";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-coco-create',
    templateUrl: './request-details-coco-create.component.html'
})

export class RequestDetailsCocoCreate implements OnChanges {
    @Input() requestData: CocoUserRequest;
    @Output() doneLoading = new EventEmitter<boolean>();
    displayObject: any;

    constructor(
        private directoryUserService: DirectoryUserService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData) {
            this.displayObject = {
                firstName: this.requestData.givenName,
                lastName: this.requestData.surname,
                mailNickname: this.requestData.mailNickname,
                stationNumber: this.requestData.stationNumber,
                title: this.requestData.title,
                telephoneNumber: this.requestData.telephoneNumber,
                mobilePhone: this.requestData.mobilePhone,
                steward: ""
            };

            await this.loadSteward(this.requestData.steward);

            this.isDoneLoading();
        }
    }

    async loadSteward(steward: string) {
        const user = await this.directoryUserService.GetByIdAsync({ id: steward, domain: DirectoryDomain.Chevron });
        this.displayObject = { ...this.displayObject, steward: user.displayName };
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}
