import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CalAngularService } from '@cvx/cal-angular';
import { lastValueFrom } from 'rxjs';
import { countries } from 'src/app/data/country-data-store';
import { matTableSelectionHelper } from 'src/app/helpers/matTableSelectionHelper';
import { CampEntity } from 'src/app/models/campEntity';
import { CampService } from 'src/app/services/campService/campService';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-dialog-camp-entity-search',
    templateUrl: './dialog-camp-entity-search.component.html',
    styleUrls: ['./dialog-camp-entity-search.component.css']
})
export class DialogCampEntitySearchComponent {

    @ViewChild('searchResultsPaginator') paginator!: MatPaginator;
    @ViewChild(MatSort) sort!: MatSort;

    dataSource = new MatTableDataSource<CampEntity>();
    displayedColumns: string[] = ['name', 'id', 'country', 'email', 'intranetAccessProfile'];
    selectDisplayedColumns: string[] = ['select', ...this.displayedColumns];
    SearchResults: CampEntity[] = []
    screenMessage: string = ""
    replyMessage: string = ""
    IsSearching: boolean = false;
    includeCheckbox: boolean = false;
    selection = new SelectionModel<CampEntity>(true, []);
  
    public countries:any = countries.filter(item => (item.name !== "United States of America"));
    searchform = new UntypedFormGroup({
        name: new UntypedFormControl('', []),
        country: new UntypedFormControl('', []),
        id: new UntypedFormControl('', [])
    });

    constructor(private authService: CalAngularService, private campService: CampService, private dialogRef: MatDialogRef<DialogCampEntitySearchComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
        this.includeCheckbox = data?.includeCheckbox ?? false;
    }

    async search() {

        this.screenMessage = ""
        this.replyMessage = ""

        const name = this.searchform.get('name') as UntypedFormControl
        const country = this.searchform.get('country') as UntypedFormControl
        const id = this.searchform.get('id') as UntypedFormControl

        if (name.value === "" && country.value === "" && id.value === "") {
            this.screenMessage = "You have not entered any search parameters.";
            return
        }

        if (name.invalid || country.invalid || id.invalid) {
            this.screenMessage = "You have entered invalid search parameters.";
            return
        }

        this.IsSearching = true;
        const token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));

        let searchParams = [];

        if (name.value !== "" && name.value !== null) {
            searchParams.push(`name=${name.value}`);
        }

        if (country.value !== "" && country.value !== null) {
            searchParams.push(`country=${country.value}`);
        }

        if (id.value !== "" && id.value !== null) {
            searchParams.push(`id=${id.value}`);
        }

        const observer = {
            next: (x: any) => {
                this.SearchResults = x as CampEntity[]
            },
            error: (err: any) => {
                if (err.error.message !== undefined) {
                    this.screenMessage = err.error.message;
                } else {
                    this.screenMessage = err.message;
            }

            this.IsSearching = false;
            },
            complete: () =>  {
                this.dataSource.data = this.SearchResults;
                this.dataSource.data = this.SearchResults; 
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.IsSearching = false;  
            }
        };

        let apiCall = this.campService.GetCampEntity(searchParams.join("&"), token);
        if(apiCall)
            apiCall.subscribe(observer);  
        }

    back() {
        this.SearchResults = [];
    }

    reset () {
        this.searchform.get('name')?.reset();
        this.searchform.get('country')?.reset();
        this.searchform.get('id')?.reset();
    }

    confirm() {
        this.dialogRef.close(this.selection.selected)
    }

    selectRow(selected: CampEntity) {
        this.dialogRef.close(selected);
    }

    select(row: CampEntity) {
        this.selection.toggle(row);
    }

    //for checkbox select all use
    isAllSelected() {
        return matTableSelectionHelper.isAllSelected(this.selection, this.dataSource);
    }
    masterToggle() {
        return matTableSelectionHelper.toggleSelectAll(this.selection, this.dataSource);
    }

    //setter method for dialogRef used for unit testing
    setDialogRef(dialogRef: MatDialogRef<DialogCampEntitySearchComponent>){
        this.dialogRef = dialogRef;
    }
}
