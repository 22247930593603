/**
 * Patch payload for endpoint /api/users/{domain}/updateServiceAccounts
 */
export class UpdateServiceAccountRequest {
    constructor(
        serviceAccounts: ServiceAccount[],
        usingAdminRole: boolean,
        adminJustification: string
    ) {
        this.serviceAccounts = serviceAccounts;
        this.usingAdminRole = usingAdminRole;
        this.adminJustification = adminJustification;
    }
    serviceAccounts: ServiceAccount[] = [];
    usingAdminRole: boolean = false;
    adminJustification: string = '';
}

export interface ServiceAccount {
    userId: string,
    stewardUserId?: string,
    applicationId?: string,
}