import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { WorkflowRequestTypes } from 'src/app/models/requests/enums/WorkflowRequestTypes.enum';

@Component({
    selector: 'app-dialog-request-details',
    templateUrl: './dialog-request-details.component.html'
})
export class DialogRequestDetailsComponent {
    @Input() workflowRequest: IWorkflowRequest;

    get WorkflowRequestTypes() { return WorkflowRequestTypes; }
    
    constructor(private dialogRef: MatDialogRef<DialogRequestDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { 
        this.workflowRequest = data?.workflowRequest;
    }

    getWorkflowRequestType(type: string): WorkflowRequestTypes {
        return WorkflowRequestTypes[type as keyof typeof WorkflowRequestTypes] ?? WorkflowRequestTypes.None;
    }
}
