import { environment } from "src/environments/environment";

export class GraphConstants {
    public static readonly PermissionsApp:string = 'microsoft.graph.appRole';
    public static readonly PermissionsScope:string = 'microsoft.graph.permissionScope';

    public static readonly DefaultGraphDomain:string = `${environment.idampUserDefaultDomain}`;

    public static readonly AppPermissionsDisplayValues:Map<string,string> = new Map([
        [this.PermissionsApp, 'application'],
        [this.PermissionsScope, 'delegated']
    ]);
}