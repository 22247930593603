import { Component } from '@angular/core';
import { RouteConstants } from 'src/app/models/common/enums/RouteConstants.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';

@Component({
  selector: 'app-retire-coco-user',
  templateUrl: './retire-coco-user.component.html',
})
export class RetireCocoUserComponent {
  userType: UserType = UserType.Coco;
  displayedColumns: string[] = ['displayName', 'mail', 'department'];
  route: string = RouteConstants.Coco;
}
