/**
 * POST payload for endpoint /api/users/{domain}/inviteGuest
 */
export class InviteGuestUserRequest {

    constructor(
        givenName: string,
        surname: string,
        companyName: string,
        emailId: string,
        inviteRedirectUrl: string,
        usageLocationCode: string,
        sourcingCompanyId: string,
        steward: string,
        groupIdsToAssign: string[] = []) {
        this.companyName = companyName;
        this.givenName = givenName;
        this.surname = surname;
        this.emailId = emailId;
        this.steward = steward;
        this.inviteRedirectUrl = inviteRedirectUrl;
        this.usageLocationCode = usageLocationCode;
        this.groupIdsToAssign = groupIdsToAssign;
        this.sourcingCompanyId = sourcingCompanyId;
    }

    steward: string;
    companyName: string;
    emailId: string;
    givenName: string;
    surname: string;
    inviteRedirectUrl: string;
    usageLocationCode: string;
    sourcingCompanyId: string;
    groupIdsToAssign: string[] = [];
}