import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IIdAndDomain } from "src/app/models/directory/IIdAndDomain";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";

@Component({
    selector: 'dialog-batch-directory-search',
    templateUrl: './dialog-batch-directory-search.component.html',
    styleUrls: ['dialog-batch-directory-search.component.css']
})

export class DialogBatchDirectorySearchComponent {

    constructor(
        private directoryUserService:DirectoryUserService, 
        private directoryGroupService:DirectoryGroupService,
        private directoryAppService:GraphApplicationService,
        private dialogRef: MatDialogRef<DialogBatchDirectorySearchComponent>,
        @Inject(MAT_DIALOG_DATA) public data:IDialogDirectoryData) {}

    isSearching:boolean = false;
    screenMessage:string = '';
    numLoadedRecordsProgress:number = 0;

    searchTerms = new FormControl<string>('');

    resolvedObjects:DirectoryObjectBase[] = [];
    resolvedCols:string[] = ['id','displayName'];

    emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    guidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$', 'i');

    async add() {
        if (this.resolvedObjects.length > 0) {
            this.dialogRef.close(this.resolvedObjects);
        }
    }

    async resolve() {
        if (this.searchTerms.value !== null) {

            this.isSearching = true;
            this.numLoadedRecordsProgress = 0;

            let ids = this.searchTerms.value.split(';');
            let resolved:DirectoryObjectBase[] = [];
            let unresolved:string[] = [];

            for(let id of ids.map(x => x.trim())) {
                if (id) {
                    switch(this.data.type){
                        case GroupMemberType.Group: { 
                            let group = await this.resolveGroup(id);
                            if (group !== null) {
                                resolved.push(group);
                            }
                            else {
                                unresolved.push(id);
                            }
                            break; 
                        }
                        case GroupMemberType.ServicePrincipal: { 
                            let sp = await this.resolveServicePrincipal(id);
                            if (sp !== null) {
                                resolved.push(sp);
                            }
                            else {
                                unresolved.push(id);
                            }
                            break; 
                        }
                        case GroupMemberType.User: { 
                            let user = await this.resolveUser(id);
                            if (user !== null) {
                                resolved.push(user);
                            } else {
                                unresolved.push(id);
                            }
                            break; 
                        }
                    }                    
                }

                this.numLoadedRecordsProgress = resolved.length;
            }

            let alreadyResolvedIds = this.resolvedObjects.map(x => x.id);
            let merged = this.resolvedObjects.concat(resolved.filter((item) => alreadyResolvedIds.indexOf(item.id) < 0))
            let numAdded = merged.length - this.resolvedObjects.length;

            this.screenMessage = `${numAdded} ${this.data.type}(s) loaded`;
            this.searchTerms.setValue(unresolved.join(';'));
            this.resolvedObjects = merged;
            this.isSearching = false;
        }
    }

    async resolveGroup(term:string):Promise<DirectoryObjectBase | null> {
        if (this.guidRegex.test(term)) {
            try {
                let group = await this.directoryGroupService.GetByIdAsync(term,this.data.domain);
                return group.data;
            }
            catch {
                // means failure to retrive and is unresolved
            }
        }
        else {
            try {
                let groupResults = await this.directoryGroupService.SearchGroupsAsync(term,this.data.domain);
                if (groupResults.data.length === 1) {
                    return groupResults.data[0];
                } 
            }
            catch {
                // means failure to retrive and is unresolved
            }
        }

        return null;
    }

    async resolveServicePrincipal(term:string):Promise<DirectoryObjectBase | null> {
        if (this.guidRegex.test(term)) {
            try
            {
                let sp = await this.directoryAppService.GetServicePrincipalAsync(term);
                return {
                    id: sp.data.id,
                    displayName:sp.data.displayName,
                    domain: DirectoryDomain.Chevron,
                    directoryObjectType: GroupMemberType.ServicePrincipal 
                } as DirectoryObjectBase;
            }
            catch {
                // means failure to retrive and is unresolved
            }
        }
        else {
            try
            {
                let spResults = await this.directoryAppService.SearchServicePrincipalAsync(term);
                if (spResults.data.length === 1)
                {
                    let sp = spResults.data[0];
                    return {
                        id: sp.id,
                        displayName:sp.displayName,
                        domain: DirectoryDomain.Chevron,
                        directoryObjectType: GroupMemberType.ServicePrincipal 
                    } as DirectoryObjectBase;
                }
            }
            catch {
                // means failure to retrive and is unresolved
            }
        }

        return null;
    }

    async resolveUser(term:string):Promise<DirectoryObjectBase | null> {
        if (this.guidRegex.test(term)) {
            try {
                let user = await this.directoryUserService.GetByIdAsync({ id: term, domain: this.data.domain } as IIdAndDomain);
                return user;
            }
            catch {
                // means failure to retrive and is unresolved
            }
        }
        else {
            try {
                let userResults = await this.directoryUserService.SearchUsersAsync(term,this.data.domain, this.data.userType != null ? this.data.userType : UserType.Primary);
                if (userResults.length === 1) {
                    return userResults[0];
                }
            }
            catch {
                // means failure to retrieve and is unresolved
            }
        }
        return null;
    }

    reset() {
        this.searchTerms.setValue('');
        this.resolvedObjects = [];
        this.numLoadedRecordsProgress = 0;
    }

}