import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IGraphUser } from 'src/app/models/graphUser';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { SsSecret } from 'src/app/models/secretServer/SsSecret';
import { SecretServerService } from 'src/app/services/secretServer/secretServer.service';
import { DialogDirectorySearchComponent } from '../../_shared/dialog-directory-search/dialog-directory-search.component';
import { GroupMemberType } from 'src/app/models/directory/enums/GroupMemberType.enum';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { IDialogDirectoryData } from 'src/app/models/components/IDialogDirectoryData';
import { MatDialog } from '@angular/material/dialog';
import { UpdateSecretPermissionsRequest } from 'src/app/models/secretServer/UpdateSecretPermissionsRequest';
import { lastValueFrom } from 'rxjs';
import { matTableSelectionHelper } from 'src/app/helpers/matTableSelectionHelper';

@Component({
  selector: 'app-update-password-permissions',
  templateUrl: './update-password-permissions.component.html',
  styleUrls: ['./update-password-permissions.component.css']
})
export class UpdatePasswordPermissionsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.secretsDataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.secretsDataSource.sort = value;
  }

  addSelfErrorMessage = "you can't change your own permissions";

  ownersErrorMessage = "";
  viewersErrorMessage = "";
  revokeErrorMessage = "";

  PageLayout = PageLayout;
  currentUserProfile: ICvxClaimsPrincipal;
  gettingSecrets: boolean = true;
  isSubmitting: boolean = false;
  submittedRequests: IWorkflowRequest[] = [];
  submitErrorMessage = { message: '', errors: [] };
  selection = new SelectionModel<SsSecret>(true, []);
  retireResults: string[] = [];

  secretsDataSource = new MatTableDataSource<SsSecret>([]);
  secretsDisplayedColumns: string[] = ['select', 'name', 'folder'];
  selectedSecretsDisplayedColumns: string[] = ['name', 'folder'];


  constructor(private authService: CalAngularService,
    private dialog: MatDialog,
    private secretService: SecretServerService,
    private formBuilder: FormBuilder) { }

  passwordForm = this.formBuilder.group({
    folder: [null, Validators.required],
    name: ['', Validators.required],
    resource: ['', Validators.required],
    owners: this.formBuilder.array([], Validators.minLength(0)),
    viewers: this.formBuilder.array([], Validators.minLength(0)),
    revokeUsers: this.formBuilder.array([], Validators.minLength(0))
  });

  get owners() {
    let owners = this.passwordForm.get('owners') as FormArray
    return owners;
  }
  get viewers() { return this.passwordForm.get('viewers') as FormArray; }
  get revokeUsers() { return this.passwordForm.get('revokeUsers') as FormArray; }

  get passwordFormValues(): UpdateSecretPermissionsRequest {
    let owners = this.owners.value.map((item: IGraphUser) => item.id);
    let viewers = this.viewers.value.map((item: IGraphUser) => item.id);
    let revokeUsers = this.revokeUsers.value.map((item: IGraphUser) => item.id);

    let payload = new UpdateSecretPermissionsRequest(
      this.selection.selected.map(s => s.id),
      owners,
      viewers,
      revokeUsers
    );

    return payload;
  }

  async ngOnInit(): Promise<void> {

    if (await lastValueFrom(this.authService.isUserSignedIn())) {
      this.currentUserProfile = this.authService.cvxClaimsPrincipal;
    }

    this.secretService.GetUserOwnedPasswords("").subscribe({
      next: (v) => {
        this.secretsDataSource.data = v.filter(s => s.isShareable)
        this.secretsDataSource.paginator = this.paginator
        this.secretsDataSource.sort = this.sort
      },
      error: (e) => {
        console.error(e);
        this.gettingSecrets = false;
      },
      complete: () => this.gettingSecrets = false
    });
  }

  selectPermission(row: SsSecret) {
    this.selection.toggle(row);
  }

  public doFilterPermissions = (value: any) => {
    this.secretsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
  }

  resetAddUserRoleErrorMessages() {
    this.ownersErrorMessage = "";
    this.viewersErrorMessage = "";
    this.revokeErrorMessage = "";
  }

  addUserByType(type: string, id: string, name: string, email: string) {
    this.resetAddUserRoleErrorMessages();
    let listOfUser = type == 'owners' ? this.owners : type == 'viewers' ? this.viewers : this.revokeUsers;

    const inOwners = this.owners.controls.findIndex(o => o.value.id == id) > -1;
    const inViewers = this.viewers.controls.findIndex(o => o.value.id == id) > -1;
    const inRevokeUsers = this.revokeUsers.controls.findIndex(o => o.value.id == id) > -1;
    const isCurrentUser = this.currentUserProfile.objectId == id;

    this.ownersErrorMessage = isCurrentUser && type == 'owners' ? this.addSelfErrorMessage : "";
    this.viewersErrorMessage = isCurrentUser && type == 'viewers' ? this.addSelfErrorMessage : "";
    this.revokeErrorMessage = isCurrentUser && type == 'revoke' ? this.addSelfErrorMessage : "";

    let isInList = inOwners || inViewers || inRevokeUsers || isCurrentUser;

    if (!isInList) {
      listOfUser.push(this.formBuilder.group({
        id: [id],
        displayName: [name],
        mail: [email]
      }));
    }
    else if (!isCurrentUser) {
      const errorMessage = `${name} can only have one permission change per request`;
      switch (type) {
        case 'owners':
          this.ownersErrorMessage = errorMessage
          break;
        case 'viewers':
          this.viewersErrorMessage = errorMessage
          break;
        case 'revoke':
          this.revokeErrorMessage = errorMessage
          break;
      }
    }
  }

  searchForPrimaryUser(type: string): void {
    const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 1000,
      width: '100%',
      data: {
        type: GroupMemberType.User,
        domain: DirectoryDomain.Chevron,
        userType: UserType.Primary,
        filterGroupRemoveNotAvailableAsGroupMember: false,
        filterGroupRemoveDynamicMembershipEnabled: false,
        filterGroupOnlyManagedByIdamp: false
      } as IDialogDirectoryData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (type == 'owners') this.addUserByType(type, result.id, result.displayName, result.mail);
        if (type == 'viewers') this.addUserByType(type, result.id, result.displayName, result.mail);
        if (type == 'revoke') this.addUserByType(type, result.id, result.displayName, result.mail);
      }
    });
  }

  removeOwner(i: number) {
    this.owners.removeAt(i);
  }
  removeViewer(i: number) {
    this.viewers.removeAt(i);
  }
  removeRevokeUsers(i: number) {
    this.revokeUsers.removeAt(i);
  }

  async onSubmit() {
    // clear error messages
    this.resetAddUserRoleErrorMessages();
    this.submitErrorMessage.message = '';
    this.submitErrorMessage.errors = [];

    if (this.selection.selected.length > 0 &&
      (this.owners.length > 0 || this.viewers.length > 0 || this.revokeUsers.length > 0)) {
      this.submitRequest();
    }
  }

  async submitRequest() {
    this.isSubmitting = true;
    let payload = this.passwordFormValues;

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<SsSecret[]>) => {
        this.submittedRequests = x.requests;
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      }
    };

    let requestCall = this.secretService.UpdatePasswordPermissionsAsync(payload);
    requestCall.subscribe(observer);
  }

  //for checkbox select all use
  isAllSelected() {
    return matTableSelectionHelper.isAllSelected(this.selection, this.secretsDataSource);
  }
  masterToggle() {
    return matTableSelectionHelper.toggleSelectAll(this.selection, this.secretsDataSource);
  }
}
