/**
 * Patch payload for endpoint /api/users/{domain}/updateServiceAccounts
 */
export class UpdateServiceAccountRequest {
    constructor(
        serviceAccounts: ServiceAccount[]
    ) {
        this.serviceAccounts = serviceAccounts;
    }
    serviceAccounts: ServiceAccount[] = [];
}

export interface ServiceAccount {
    userId: string,
    stewardUserId?: string,
    applicationId?: string,
}