import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CamEntity } from "../../models/camEntity";
import { IDefaultApiResponse } from "../../models/defaultApiResponse";
import { IPrimaryUserApiResponse } from "../../models/primaryUser";
import { UserSearchResult } from "../../models/primaryUser/userSearchResult";
import { AppConfigService } from "../common/AppConfigService";
import { IMyProfileApiResponse } from "../../models/myProfileApiResponse";

@Injectable({
    providedIn: 'root'
})

export class PrimaryUserService {

  readonly idampOtherApiBaseUrl: string;
  readonly primaryUserApiBaseUrl: string;

  constructor(private http: HttpClient, private configService: AppConfigService)
  { 
    const config = configService.getConfig();
    this.primaryUserApiBaseUrl = config?.primaryUserApiBaseUrl || ""
    this.idampOtherApiBaseUrl = config?.idampOtherApiBaseUrl || ""
  }

  public GetMe(token: any) {

    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user/me`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.get<IMyProfileApiResponse>(fullApiEndPointUrl,{headers: headers});
  }    

  // valid searchTypes are active or inactive or not providing the paramater
  public GetPrimaryUser(id: string, token: any, searchType?: string) {

    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user/${id}`;
    let headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    if (searchType !== undefined) {
      headers = headers.append("searchType",searchType)
    }

    return this.http.get<IDefaultApiResponse>(fullApiEndPointUrl,{headers: headers});
  }

  public GetPrimaryUsers(searchParams: string, token: any, searchType?: string) {

    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user?${searchParams}`;
    let headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    if (searchType !== undefined) {
      headers = headers.append("searchType",searchType)
    }

    return this.http.get<IPrimaryUserApiResponse>(fullApiEndPointUrl,{headers: headers});
  }

  public CreateUser(userPayload: string, token: any) {

    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.post<IPrimaryUserApiResponse>(fullApiEndPointUrl,userPayload,{headers: headers});
  }

  public DeactivateUser(provisioningId: string, token: any) {
  
    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user/${provisioningId}`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.delete<IDefaultApiResponse>(fullApiEndPointUrl,{headers: headers});

  }

  public ReactivateUser(userPayload: string, token: any) {

    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.patch<IDefaultApiResponse>(fullApiEndPointUrl,userPayload, {headers: headers});
  }

  public UpdateUser(userPayload: string, token: any) {

    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.put<IPrimaryUserApiResponse>(fullApiEndPointUrl,userPayload,{headers: headers});
  }

  public UpdateMe(userPayload: string, token: any) {

    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user/me`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.put<IPrimaryUserApiResponse>(fullApiEndPointUrl,userPayload,{headers: headers});
  }

  public SearchUsers(queryString: string, token: any) {

    const fullApiEndPointUrl = `${this.idampOtherApiBaseUrl}/primaryusers?${queryString}`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.get<UserSearchResult[]>(fullApiEndPointUrl,{headers: headers});
  }
  
  public ValidateAuthCode(payload: string, token: any) {

    const fullApiEndPointUrl = `${this.idampOtherApiBaseUrl}/primaryuser/authcode`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.post<boolean>(fullApiEndPointUrl,payload, {headers: headers});
  }  

  public GetCamUsers(token: any) {

    const fullApiEndPointUrl = `${this.idampOtherApiBaseUrl}/cam`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.get<CamEntity[]>(fullApiEndPointUrl,{headers: headers});
  }

  public UpdateCamUser(user: CamEntity, token: any) {

    const fullApiEndPointUrl = `${this.idampOtherApiBaseUrl}/primaryuser/cam`;
    const headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    return this.http.patch(fullApiEndPointUrl, user, {headers: headers});
  } 
  
  public CheckUserDuplicate(userForm:Record<any, string>, selectedFields: string[], token: any, searchType?: string) {
    const searchParams = this.buildSearchParams(userForm, selectedFields)
    const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/user/check-duplicate?${searchParams}`;
    let headers = new HttpHeaders().append("Authorization",`Bearer ${token}`)

    if (searchType !== undefined) {
      headers = headers.append("searchType",searchType)
    }

    return this.http.get<IPrimaryUserApiResponse>(fullApiEndPointUrl,{headers: headers});
  }

  public buildSearchParams(formValues: Record<any, string>, selectedFields: string[]){
    let searchParam: string[] = [];
    selectedFields.forEach(field => {
      const value = formValues[field];
      if(value !== '' && value !== null) {
        searchParam.push(`${field}=${value}`)
      }
    });
    return searchParam.join("&");
  }
}