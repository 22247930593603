import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { UserType } from 'src/app/models/directory/enums/UserType.enum';
import { DirectoryMailboxUserCreateRequestDetails } from 'src/app/models/directory/users/DirectoryMailboxUserCreateRequest';
import { DirectoryUserService } from 'src/app/services/directory/DirectoryUser.service';

@Component({
  selector: 'request-details-mailbox-create',
  templateUrl: './request-details-mailbox-create.component.html',
})
export class RequestDetailsMailboxCreateComponent {
  @Input() requestData: DirectoryMailboxUserCreateRequestDetails;
  @Output() doneLoading = new EventEmitter<boolean>();
  displayObject: any;

  constructor(
    private directoryUserService: DirectoryUserService,
  ) { }

  async ngOnChanges(): Promise<void> {
    if (this.requestData) {
      this.displayObject = {
        displayName: this.requestData.displayName,
        businessPurpose: this.requestData.businessPurpose,
        mailNickname: this.requestData.mailNickName,
        mailDomain: this.requestData.mailDomain,
        steward: '',
        delegate: '',
        chainOfCommands: [],
      };

      await this.loadSteward(this.requestData.steward);
      await this.loadDelegate(this.requestData.delegate);
      await this.loadChainOfCommands(this.requestData.chainOfCommand);

      this.isDoneLoading();
    }
  }

  async loadSteward(id: string) {
    let user: any;
    try {
      user = (await this.directoryUserService.SearchUsersAsync(id, DirectoryDomain.Chevron, UserType.Primary)).find(x => x.provisioningId === id);
    }
    catch (e) {
      // missing
    }
    this.displayObject = { ...this.displayObject, steward: user?.displayName ?? `user with provisioning id ${id}` };
  }

  async loadDelegate(id: string) {
    let user: any;
    try {
      user = (await this.directoryUserService.SearchUsersAsync(id, DirectoryDomain.Chevron, UserType.Primary)).find(x => x.provisioningId === id);
    }
    catch (e) {
      // missing
    }
    this.displayObject = { ...this.displayObject, delegate: user?.displayName ?? `user with provisioning id ${id}` };
  }

  async loadChainOfCommands(chainOfCommandIds: string[]) {
    const roleUsersPromises = chainOfCommandIds.map(async (id) => {
      let user: any;
      try {
        user = (await this.directoryUserService.SearchUsersAsync(id, DirectoryDomain.Chevron, UserType.Primary)).find(x => x.provisioningId === id);
      }
      catch (e) {
        // missing
      }

      return user.displayName ?? `user with provisioning id ${id}`;
    });

    const roleUsers = await Promise.all(roleUsersPromises);
    this.displayObject = { ...this.displayObject, chainOfCommands: roleUsers };
  }

  isDoneLoading() {
    this.doneLoading.emit(true);
  }
}
