import { Component, Input } from "@angular/core";

@Component({
    selector: 'display-http-error',
    templateUrl: './display-http-error.component.html'
})

export class DisplayHttpErrorComponent {
    @Input() message:string = '';
    @Input() errors:string[] = [];
}