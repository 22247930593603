import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IInviteGuestUserRequestData } from "src/app/models/guestAccounts/IInviteGuestUserRequest";
import { InviteGuestUserRequest } from "src/app/models/guestAccounts/InviteGuestUserRequest";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-guest-invite',
    templateUrl: './request-details-guest-invite.component.html'
})

export class RequestDetailsGuestInvite implements OnChanges {
    @Input() requestData: any;
    @Output() doneLoading = new EventEmitter<boolean>();

    displayObject: IInviteGuestUserRequestData;

    constructor(
        private directoryUserService: DirectoryUserService,
        private directoryGroupService: DirectoryGroupService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData) {
            this.displayObject = {
                firstName: this.requestData.givenName,
                lastName: this.requestData.surname,
                emailId: this.requestData.emailId,
                applicationUrl: this.requestData.inviteRedirectUrl,
                steward: this.requestData.steward.displayName,
                permanentLegalResidence: this.requestData.usageLocationCode,
                companyName: this.requestData.companyName,
                groupNames: []
            } as IInviteGuestUserRequestData;

            await this.loadGroups(this.requestData.groupIdsToAssign);
            this.isDoneLoading();
        }
    }

    async loadGroups(ids: string[]) {
        for (let id of ids) {
            const group = await this.directoryGroupService.GetByIdAsync(id, DirectoryDomain.Chevron);
            this.displayObject.groupNames.push(group.data.displayName);
        }
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}
