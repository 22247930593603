<mat-dialog-content>
    <div>
        <div class="col-12">
            <h2 class="{{ data.color }}"><mat-icon>{{ data.icon }}</mat-icon></h2>
            <p class="text-center">{{ data.message }}</p>
    
            <div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
                <div class="btn-group mr-2" role="group">
                    <button class="button" type="button" mat-dialog-close>close</button>                                 
                </div>    
            </div>
        </div>
    </div>
</mat-dialog-content>
