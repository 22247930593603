<form class="branded" *ngIf="!isSearching && searchResults.length === 0 && !ownedApps">

    <div class="row">
        <div class="col col-12 col-sm-6 col-lg-6">
            <mat-form-field appearance="outline" [class]="isDialog ? 'w-100 padTop5' : 'w-100'"
                subscriptSizing="dynamic">
                <mat-label>name or app id</mat-label>
                <input matInput [formControl]="searchTerm" (keydown.enter)="search()" />
            </mat-form-field>
        </div>
    </div>

</form>

<form class="branded" *ngIf="!isSearching && searchResults.length === 0 && ownedApps && allowOwnerSearch">

    <div class="row">
        <div class="col-12 col-sm-6 col-lg-4">
            TODO: Seach by user here
        </div>
    </div>

</form>

<div class="container" *ngIf="screenMessage !== ''">
    <p class="text-danger text-center">{{screenMessage}}</p>
</div>

<div class="container" *ngIf="isSearching">
    <div class="row justify-content-center col-sm-12">
        <p *ngIf="searchResults.length === 0">Searching for {{objectType}}...</p>
        <p *ngIf="searchResults.length > 0">Loading {{objectType}}...</p>
    </div>
    <div class="row justify-content-center col-sm-12">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="col col-12"
    *ngIf="!(searchResults.length === 0 || isSearching || selectedApp.id !== '' || selectedApp.servicePrincipalId !== '')">
    <generic-search-results [makeSticky]="makeSticky" [showTitle]="showTitle" [title]="objectType + '(s) found'"
        [sortable]="sortable" [records]="searchResults" [displayedColumns]="displayedColumns"
        (recordSelected)="selectApp($event)"></generic-search-results>
</div>

<div class="col col-12" *ngIf="!(selectedApp.id === '' && selectedApp.servicePrincipalId === '')">
    <h4 id="resultCount">Permissions for {{ selectedApp.displayName }}</h4>
    <div>
        <form [class]="makeSticky ? 'branded stickyHeader' : 'branded'">
            <filter-input (onKeyUp)="doFilterPermissions($event)"></filter-input>

            <table [class]="makeSticky ? 'stickyTableHeaders' : ''" id="perms-results" mat-table
                [dataSource]="permissionsDataSource" aria-describedby="permissions results">

                <caption>exposed permissions from a {{objectType}} that can be requested</caption>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <input MatCheckbox type="checkbox" (click)="$event.stopPropagation()"
                            (change)="selectPermission(row)" [checked]="selection.isSelected(row)" />
                    </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> value </th>
                    <td mat-cell *matCellDef="let perm">{{ perm.value }}</td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> description </th>
                    <td mat-cell *matCellDef="let perm">{{ perm.description }}</td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> type </th>
                    <td mat-cell *matCellDef="let perm">
                        {{ appPermissionDisplayValues.get(perm.type) }}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="permissionsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: permissionsDisplayedColumns;"></tr>

            </table>

            <mat-paginator #permissionsPaginator [pageSizeOptions]="[10, 20, 40]" showFirstLastButtons></mat-paginator>
        </form>
    </div>
</div>

<div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">
    <div class="btn-group mr-2" role="group" [hidden]="searchResults.length !== 0 || isSearching">
        <button class="button" type="button" (click)="search()">search</button>
    </div>
    <div class="btn-group mr-2" role="group" *ngIf="searchPermissions && selection.selected.length > 0">
        <button class="button" type="button" (click)="savePermission()">add</button>
    </div>
    <div class="btn-group mr-2" role="group" *ngIf="!ownedApps" [hidden]="searchResults.length === 0">
        <button class="button" type="button" (click)="reset()">reset</button>
    </div>
    <div class="btn-group mr-2" role="group" [hidden]="!isDialog">
        <button class="button" type="button" mat-dialog-close>close</button>
    </div>
</div>