import { Component, Input, OnInit } from "@angular/core";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";

@Component({
    selector: 'display-requests',
    templateUrl: './display-requests.component.html',
    styleUrls: ['./display-requests.component.css']
})

export class DisplayRequestComponent{

    @Input() requests:IWorkflowRequest[] = [];

    displayProperties:string[] = ['id', 'type', 'state', 'comments', 'createDate'];

}