import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrimaryUser } from 'src/app/models/primaryUser';

@Component({
  selector: 'app-dialog-primary-user-search',
  templateUrl: './dialog-primary-user-search.component.html',
})
export class DialogPrimaryUserSearchComponent {

  selectedUser!: PrimaryUser;

  constructor(private dialogRef: MatDialogRef<DialogPrimaryUserSearchComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  userSelected(eventData: { selectedUser: PrimaryUser }) {
    this.dialogRef.close({data:eventData.selectedUser});
  }

  searchCancelled() {
    this.dialogRef.close();
  }

   //setter method for dialogRef used for unit testing
   setDialogRef(dialogRef: MatDialogRef<DialogPrimaryUserSearchComponent>){
    this.dialogRef = dialogRef;
   }

}
