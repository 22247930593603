<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if(submittedRequests.length === 0)
        {
        <div class="row">
            <div class="col col-12">
                <h1>update group</h1>

                <fieldset class="pb-4">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>{{ domain }} groups</strong></p>
                    </legend>
                    @if(gettingGroups)
                    {
                    <div class="container searching-container">
                        <div class="row justify-content-center col-12">
                            searching for groups...
                        </div>
                        <div class="row justify-content-center col-12 text-warning">
                            this may take up to a minute to retrieve
                        </div>
                        <div class="row justify-content-center col-12">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    }
                    @else if(!gettingGroups && groupsDataSource.data.length == 0)
                    {
                    <div>
                        <display-http-error message="no groups owned by you were found"
                            [errors]="[]"></display-http-error>
                    </div>
                    }
                    @else
                    {
                    <div class="container">
                        <form class="branded">
                            <filter-input (onKeyUp)="doFilter($event)"></filter-input>

                            <table id="groups" mat-table [dataSource]="groupsDataSource" matSort
                                matSortActive="displayName" matSortDirection="asc" aria-describedby="user owned groups">

                                <caption>azure groups you own</caption>

                                <tr mat-header-row *matHeaderRowDef="groupsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: groupsDisplayedColumns;"></tr>

                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let row">
                                        <input MatCheckbox type="checkbox" name="formcheckbox"
                                            (click)="$event.stopPropagation()" (change)="select(row)"
                                            [checked]="selection.isSelected(row)" />
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="displayName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> display name </th>
                                    <td mat-cell *matCellDef="let group">{{ group.displayName }}</td>
                                </ng-container>

                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                                    <td mat-cell *matCellDef="let group">{{ group.id }}</td>
                                </ng-container>

                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> group type </th>
                                    <td mat-cell *matCellDef="let group">{{ group.type }}</td>
                                </ng-container>

                                <ng-container matColumnDef="dataProtection">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> data protection </th>
                                    <td mat-cell *matCellDef="let group">{{ group.dataProtection }}</td>
                                </ng-container>

                                <ng-container matColumnDef="applicationId">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> service id </th>
                                    <td mat-cell *matCellDef="let group">{{ group.applicationId }}</td>
                                </ng-container>

                            </table>

                            <mat-paginator [length]="groupsDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]"
                                showFirstLastButtons></mat-paginator>
                        </form>
                    </div>
                    }


                </fieldset>

                @if(groupUpdatesDataSource.data.length > 0)
                {
                <fieldset class="pb-3">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>selected groups to update</strong></p>
                    </legend>
                    <!-- bulk operation -->
                    <form class="branded">
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                    matTooltip="new service Id for all selected groups">
                                    <mat-label>bulk service id</mat-label>
                                    <input matInput (click)="searchForChevronApplication()" [readonly]="true"
                                        [value]="chevronApp">
                                    <button mat-icon-button matSuffix (click)="searchForChevronApplication()">
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>

                    <!-- display + form table -->
                    <table aria-describedby="title" #table mat-table [dataSource]="groupUpdatesDataSource"
                        multiTemplateDataRows>
                        <caption>groups to update</caption>
                        @for(disCol of groupsFormDisplayedColumns; track disCol; let colIndex = $index){
                        <ng-container matColumnDef="{{disCol}}">
                            <th mat-header-cell *matHeaderCellDef>{{disCol | convertDisplayLabels}}</th>
                            <td mat-cell *matCellDef="let element; let rowIndex = index">
                                {{element[disCol]}}
                            </td>
                        </ng-container>}

                        <ng-container matColumnDef="del">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element" style="text-align:center;">
                                <button mat-icon-button matSuffix (click)="deleteRow(element)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <!-- Secondary Column -->
                        <ng-container matColumnDef="secondary">
                            <td mat-cell [attr.colspan]="groupsFormDataColumns.length"
                                *matCellDef="let element; let rowIndex = dataIndex">
                                <form class="branded padTop5">
                                    <div class="row">
                                        <!-- service id -->
                                        <div class="col-4">
                                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                                matTooltip="new service Id">
                                                <mat-label>new service id</mat-label>
                                                <input matInput (click)="searchForChevronApplication(element.id)"
                                                    [readonly]="true" [value]="element.chevronApp">
                                                <button mat-icon-button matSuffix
                                                    (click)="searchForChevronApplication(element.id)">
                                                    <mat-icon>search</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <!-- data protection -->
                                        <div class="col-4">
                                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                                matTooltip="Affects group review frequency. When in doubt choose the more restrictive rating (e.g. less | Tier3>Tier2>Tier1 | more)">
                                                <mat-label>new data protection</mat-label>
                                                <mat-select (selectionChange)="dataProtectionChanged($event,element.id)"
                                                    [value]="element.newDataProtection">
                                                    <mat-option
                                                        [matTooltip]="dataProtectionClassification | mapDataProtectionToDescription"
                                                        *ngFor="let dataProtectionClassification of dataProtectionClassifications"
                                                        [value]="dataProtectionClassification">{{
                                                        dataProtectionClassification }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>

                                        <!-- group writeback-->
                                         @if(element.chevronApp)
                                         {
                                            <div class="col-4">
                                                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                                    matTooltip="Enable group writeback to onPrem ONLY IF NEEDED. NOTE: service id is required for this feature">
                                                    <mat-label>new group writeback</mat-label>
                                                    <mat-select (selectionChange)="groupWriteBackChanged($event,element.id)"
                                                        [value]="element.writeBackEnabled">
                                                        <mat-option
                                                            *ngFor="let groupWriteBackOption of groupWriteBackOptions"
                                                            [value]="groupWriteBackOption == 'Enabled' ? true : false">{{
                                                                groupWriteBackOption }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                         }
                                    </div>
                                </form>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="groupsFormDataColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: groupsFormDataColumns;"></tr>
                        <tr mat-row *matRowDef="let row; columns: ['secondary']"></tr>
                    </table>

                </fieldset>
                }

                <mat-divider></mat-divider>

                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>

                @if(!gettingGroups)
                {
                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                    @if(isSubmitting)
                    {
                    <mat-spinner [diameter]="30" *ngIf="isSubmitting"
                        style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    }
                    @else if(!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0)
                    {
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="submit" (click)="onSubmit()">update</button>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
        }
        @else
        {
        <div class="col col-12">
            <a class="close-button" [routerLink]="['/groups/update']"><span class="icon-remove close-button"></span></a>
            <h1>request submitted</h1>
            <display-http-error [message]="submitErrorMessage.message"
                [errors]="submitErrorMessage.errors"></display-http-error>
            <display-requests [requests]="submittedRequests"></display-requests>
        </div>
        }
    </section>
</main>