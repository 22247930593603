import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { WorkflowRequestTypes } from "src/app/models/requests/enums/WorkflowRequestTypes.enum";
import { IWorkflowReview, IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { IdampHttpClient } from "../common/IdampHttpClient";

@Injectable({providedIn: 'root'})

export class WorkflowRequestService {

    constructor(private http:IdampHttpClient) {}

    public GetByApprover():Observable<IGenericApiResponse<IWorkflowRequest[]>> {
        const path = `me/approvals`;
        return from(this.http.GetAsync<IGenericApiResponse<IWorkflowRequest[]>>(path));
    }

    public GetByRequestor():Observable<IGenericApiResponse<IWorkflowRequest[]>> {
        const path = `me/requests`;
        return from(this.http.GetAsync<IGenericApiResponse<IWorkflowRequest[]>>(path));
    }

    public GetById(type:WorkflowRequestTypes,id:string):Observable<IGenericApiResponse<IWorkflowRequest>> {
        const path = `request/${type}/${id}`;
        return from(this.http.GetAsync<IGenericApiResponse<IWorkflowRequest>>(path));
    }

    public ReviewRequest(type:WorkflowRequestTypes,id:string,approved:boolean,comment:string):Observable<IGenericApiResponse<IWorkflowReview>> {
        const path = `request/${type}/${id}/review`;
        const payload:IWorkflowReview = { approved, comment };
        return from(this.http.PatchAsync<IWorkflowReview, IGenericApiResponse<IWorkflowReview>>(path, payload));
    }

    public CancelRequest(type:WorkflowRequestTypes,id:string):Observable<IGenericApiResponse<IWorkflowRequest>> {
        const path = `request/${type}/${id}/cancel`;
        return from(this.http.PostAsync<null, IGenericApiResponse<IWorkflowRequest>>(path,null));
    }

}