import { CocoUserTitle } from "./CocoUserTitle.enum";

/**
 * Patch payload for endpoint /api/users/{domain}/updateCocoUsers
 */
export class UpdateCocoUserRequest {
    constructor(
        cocoUsers: CocoUser[]
    ) {
        this.cocoUsers = cocoUsers;
    }
    cocoUsers: CocoUser[] = [];
}

export interface CocoUser {
    userId: string,
    givenName:string,
    surname:string,
    mobilePhone: string;
    telephoneNumber: string;
    stationNumber: string;
    jobTitle: CocoUserTitle;
    stewardUserId?: string,
}