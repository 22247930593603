import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { GraphAppRevokePermissionDisplayModel } from "src/app/models/graph/applications/GraphAppRevokePermissionDisplay";
import { IdmServicePrincipalPermissionsModel } from "src/app/models/graph/applications/GraphAppServicePrincipalPermissions";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";

@Component({
    selector: 'request-details-app-permission-revoke',
    templateUrl: './request-details-app-permission-revoke.component.html'
})

export class RequestDetailsAppPermissionRevoke implements OnInit {

    @Input() requestData: IdmServicePrincipalPermissionsModel;
    @Output() doneLoading = new EventEmitter<boolean>();

    displayObject: any = {};

    revokePermissionsDetailsColumns: string[] = ['principalName','principalType','permissionType','permissionValue','resourceName'];
    revokePermissionsDetails: GraphAppRevokePermissionDisplayModel[] = [];

    resourceApp: GraphApplication;
    resourceAppLoaded = false;

    constructor(private graphAppService: GraphApplicationService) { }
    
    ngOnInit() 
    {   
        if(this.requestData) {  
            const displayData = GraphAppRevokePermissionDisplayModel.fromIdmServicePrincipalPermissionsModel(this.requestData);
            this.revokePermissionsDetails = [...displayData];
        }
        this.loadResourceApp();
        this.isDone();
    }

    loadResourceApp() {
        this.graphAppService.GetServicePrincipal(this.requestData.servicePrincipalId).subscribe({
            next: (x: IGenericApiResponse<GraphApplication>) => {
                this.resourceApp = x.data;
                this.resourceAppLoaded = true;
                this.isDone();
            },
            error: (err: any) => {
                this.resourceAppLoaded = true;
                console.error(err);
                this.isDone();
            }
        });
    }

    isDone() {
        if (this.resourceAppLoaded) {
            this.displayObject =  {
                servicePrincipalId: this.resourceApp?.id ?? this.requestData.servicePrincipalId,
                servicePrincipalName: this.resourceApp?.displayName ?? this.requestData.servicePrincipalId,
                permissionRole: this.requestData.servicePrincipalRole
            };

            this.doneLoading.emit(true);
        }
    }
}