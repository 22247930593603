<main class="container">
  <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
    <div class="row" *ngIf="invitedGuest === undefined">
      <div class="col col-12">
        <h1>invite guest</h1>
        <h5 *ngIf="!hasPermission" class="text-center text-danger">
          Only Chevron employees and authorized users can be guest sponsors.
        </h5>
        <ng-container *ngIf="hasPermission">
          <form [formGroup]="inviteUserForm" class="branded text-left" novalidate (ngSubmit)="onSubmit(inviteUserForm)">

            <mat-error></mat-error>
            <fieldset>
              <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                <p><strong>guest information</strong></p>
              </legend>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <p style="margin: 0px">
                    
                    <a target="_blank" rel="noopener"
                      href="https://go.chevron.com/idampGuestSponsorResponsibilities">
                      Guest Sponsor Roles and Responsibilities
                    </a>
                  </p>
                  <div id="sponsorContainer">
                    <input id="accept" type="checkbox" name="responsibilitiesBox"
                      formControlName="responsibilitiesBox" class="form-control" />
                    <span id="acceptStatement" class="text-left">
                      I Accept the Roles and Responsibilities as sponsor for this
                      guest*</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="First Name" 
                  subscriptSizing="dynamic">
                    <mat-label>first name</mat-label>
                    <input matInput formControlName="givenName" required>
                    <mat-error *ngIf="inviteUserForm.controls.givenName.errors?.required ?? false">
                      First Name is required
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls.givenName.errors?.pattern ?? false">
                      First Name is invalid
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls['givenName'].hasError('maxlength')">
                      First Name must be no more than {{ inviteUserForm.controls.givenName.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="Last Name" 
                  subscriptSizing="dynamic">
                    <mat-label>last name</mat-label>
                    <input matInput formControlName="surname" required>
                    <mat-error *ngIf="inviteUserForm.controls.surname.errors?.required ?? false">
                      Last Name is required
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls.surname.errors?.pattern ?? false">
                      Last Name is invalid
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls['surname'].hasError('maxlength')">
                      Last Name must be no more than {{ inviteUserForm.controls.surname.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="company authorized email address registered with the Microsoft account" 
                  subscriptSizing="dynamic">
                    <mat-label>email</mat-label>
                    <input matInput formControlName="emailId" required>
                    <mat-error *ngIf="inviteUserForm.controls.emailId.errors?.required ?? false">
                      Email is required
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls.emailId.errors?.pattern ?? false">
                      Email is invalid
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls['emailId'].hasError('maxlength')">
                      Email must be no more than {{ inviteUserForm.controls.emailId.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="Company Name" 
                  subscriptSizing="dynamic">
                    <mat-label>company name</mat-label>
                    <input matInput formControlName="companyName" required>
                    <mat-error *ngIf="inviteUserForm.controls.companyName.errors?.required ?? false">
                      Company Name is required
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls.companyName.errors?.pattern ?? false">
                      Company Name is invalid
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls['companyName'].hasError('maxlength')">
                      Company Name must be no more than {{ inviteUserForm.controls.companyName.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="Permanent Legal Residence is the country where the person has a right to work without requirements for additional authorization such as a work visa or other temporary authorization. This country may be different from work location or where services are provided. Do not ask for citizenship."
                  subscriptSizing="dynamic">
                    <mat-label>permanent legal residence</mat-label>
                    <input name="residence_name" matInput required
                      formControlName="residence_name" [matAutocomplete]="auto" />
                    <input type="hidden" name="locationName" formControlName="locationName" />
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                      (optionSelected)="onValueSelected($event.option.value)">
                      <mat-option *ngFor="let address of filterOptions | async" [value]="address">{{ address.name
                        }}</mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="inviteUserForm.controls.residence_name.errors?.required ?? false">
                      Permanent Legal Residence is required
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls.residence_name.errors?.pattern ?? false">
                      Permanent Legal Residence is invalid
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="Application URL"
                  subscriptSizing="dynamic">
                    <mat-label>application url</mat-label>
                    <input matInput formControlName="inviteRedirectUrl">
                    <mat-error *ngIf="inviteUserForm.controls.inviteRedirectUrl.errors?.pattern ?? false">
                      Application URL is invalid
                    </mat-error>
                    <mat-error *ngIf="inviteUserForm.controls['inviteRedirectUrl'].hasError('maxlength')">
                      Application URL must be no more than {{ inviteUserForm.controls.inviteRedirectUrl.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                <p><strong>groups</strong></p>
              </legend>
              <div style="margin-bottom: 10px;">
                <button mat-flat-button color="primary" (click)="searchForGroups()" class="add-button"
                  type="button"><mat-icon>add</mat-icon> group</button>
              </div>

              <div class="row" formGroupName="groups" *ngFor="let group of groups.controls; let i=index">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100" [formGroupName]="i"
                  subscriptSizing="dynamic">
                    <mat-label>group</mat-label>
                    <input matInput formControlName="displayName" [readonly]="true">
                    <button mat-icon-button matSuffix (click)="removeGroups(i)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
              </div>
            </fieldset>

            <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
              <mat-spinner [diameter]="30" *ngIf="isInviteProcessActive"></mat-spinner>
              <div class="btn-group mr-2" role="group" *ngIf="!isInviteProcessActive">
                <button class="button" type="submit">invite</button>
              </div>
              <div class="btn-group mr-2" role="group" *ngIf="!isInviteProcessActive">
                <button class="button" type="button" [disabled]="isInviteProcessActive"
                  (click)="resetForm()">cancel</button>
              </div>
            </div>

            <display-http-error [message]="createErrorMessage.message"
              [errors]="createErrorMessage.errors"></display-http-error>

          </form>
        </ng-container>
      </div>
    </div>

    <div class="row" *ngIf="invitedGuest !== undefined">
      <div class="col col-12">
        <a class="close-button" (click)="onCloseClick()"><span class="icon-remove close-button"></span></a>
        <h1>guest invited</h1>
        <display-form [displayObject]="displayInvitedGuest"></display-form>

        <display-requests [requests]="invitedGuest.requests"></display-requests>
      </div>
    </div>

  </section>
</main>