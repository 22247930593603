import { UserType } from "../directory/enums/UserType.enum";
import { CompromisedSourcingCompanyCriteriaType } from "./enums/CyberEventCriteriaType";
import { CyberEventUserType } from "./enums/CyberEventUserType";

export class CreateCyberEventCompromisedSourcingCompanyRequest {
    constructor(criteria: CompromisedSourcingCompanyCriteria[]) {
        this.criteria = criteria;
    }
    criteria: CompromisedSourcingCompanyCriteria[];
}
export class CompromisedSourcingCompanyCriteria {
    constructor(userType: CyberEventUserType, criteriaType: CompromisedSourcingCompanyCriteriaType, value: string) {
        this.userType = userType;
        this.criteriaType = criteriaType;
        this.value = value;
    }
    
    userType: CyberEventUserType;
    criteriaType: CompromisedSourcingCompanyCriteriaType;
    value: string;
};

export class DisplayCompromisedSourcingCompanyCriteria extends CompromisedSourcingCompanyCriteria{
    constructor(userType: CyberEventUserType, criteriaType: CompromisedSourcingCompanyCriteriaType, value: string, displayName: string) {
        super(userType, criteriaType, value);
        this.criteriaType = criteriaType;
        this.value = value;
        this.displayName = displayName;
    }

    displayName: string;
};