import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { IGraphAppExposedPermissions } from "src/app/models/graph/applications/GraphAppExposedPermissions";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphAppPermissionRequestModel } from "src/app/models/graph/applications/GraphAppPermissionRequest";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";

@Component({
    selector: 'request-details-app-permissions-change',
    templateUrl: './request-details-app-permissions-change.component.html'
})

export class RequestDetailsAppPermissionsChange implements OnInit {

    @Input() clientAppId: string;
    @Input() requestData: GraphAppPermissionRequestModel;

    @Output() doneLoading = new EventEmitter<boolean>();

    clientAppScreenMessage: string = '';
    resourceAppScreenMessage: string = '';

    clientApp: GraphApplication;
    resourceApp: GraphApplication;
    roles: IGraphAppExposedPermissions[];
    scopes: IGraphAppExposedPermissions[];

    displayFields: string[] = ['clientApplication'];
    displayObject: any = {};

    constructor(private graphAppService: GraphApplicationService) { }

    async ngOnInit(): Promise<void> {
        this.loadClientApp();
        this.loadResourceApp();
    }

    /**
     * loads an application by the application object id
     * @param token 
     */
    loadClientApp() {
        this.graphAppService.GetApplication(this.clientAppId).subscribe({
            next: (x: IGenericApiResponse<GraphApplication>) => {
                this.clientApp = x.data;
            },
            error: (err: any) => {
                this.clientAppScreenMessage = err.statusText;
            },
            complete: () => {
                this.isDone();
            }
        });
    }

    /**
     * loads the service principal by service principal id
     * @param token 
     */
    loadResourceApp() {
        this.graphAppService.GetServicePrincipal(this.requestData.resourceId).subscribe({
            next: (x: IGenericApiResponse<GraphApplication>) => {
                this.resourceApp = x.data;
            },
            error: (err: any) => {
                this.resourceAppScreenMessage = err.statusText;
            },
            complete: () => {
                this.isDone();
            }
        });
    }

    isDone() {
        if (this.clientApp !== undefined && this.resourceApp !== undefined) {
            let requestedScopes: string[] = [];
            this.requestData.scopes.forEach(scopeId => {
                let match = this.resourceApp.exposedPermissions.filter(ep => ep.id === scopeId);
                requestedScopes.push(
                    match.length === 0 ?
                        `unknown scope with id: ${scopeId}` :
                        match[0].value
                );
            });

            let requestedRoles: string[] = [];
            this.requestData.roles.forEach(roleId => {
                let match = this.resourceApp.exposedPermissions.filter(ep => ep.id === roleId);
                requestedRoles.push(
                    match.length === 0 ?
                        `unknown role with id: ${roleId}` :
                        match[0].value
                );
            });

            this.displayObject = {
                clientApplication: this.clientApp.displayName,
                clientAppId: this.clientApp.appId,
                resourceApplication: this.resourceApp.displayName,
                requestedDelegatedPermissions: requestedScopes.length > 0 ? requestedScopes : ['n/a'],
                requestedApplicationPermissions: requestedRoles.length > 0 ? requestedRoles : ['n/a'],
                justification: this.requestData.justification
            };

            this.doneLoading.emit(true);
        }
    }
}