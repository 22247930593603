import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ICvxClaimsPrincipal } from "@cvx/cal";
import { CaseInsensitiveComparer } from "src/app/helpers/caseInsensitiveComparer";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { IApprovalReviews } from "src/app/models/components/IApprovalReviews";
import { WorkflowRequestTypes } from "src/app/models/requests/enums/WorkflowRequestTypes.enum";
import { WorkflowStates } from "src/app/models/requests/enums/WorkflowStates.enum";
import { IWorkflowRequest, IWorkflowReview } from "src/app/models/requests/WorkflowRequest";
import { WorkflowRequestService } from "src/app/services/workflow/WorkflowRequestService";

@Component({
    selector: 'request-details-approvals',
    templateUrl: './request-details-approvals.component.html'
})

export class RequestDetailsApprovals implements OnInit {

    @Input() workflowRequest:IWorkflowRequest;
    @Input() principal:ICvxClaimsPrincipal;
    @Output() doneLoading = new EventEmitter<boolean>();

    /**
     * page rendering controls
     */
    isSubmitted:boolean = false;
    approvalForm = this.formBuilder.group({
        decision: [true, Validators.required],
        comments: ['']
    });
    reviewerCols = ['approvedBy','reviewDate','approved','comments'];

    /**
     * loaded data
     */
    reviews:IApprovalReviews[] = [];
    currentUserIsApprover:boolean = false;

    get WorkflowStates() { return WorkflowStates; }

    constructor(private workflowRequestService:WorkflowRequestService, 
        private formBuilder: FormBuilder) {}

    ngOnInit(): void {

        for(let r of this.workflowRequest.reviews) {
            this.addReviews(r);
        }

        if (
            this.workflowRequest.state === WorkflowStates.PendingApproval &&
            this.workflowRequest.authorizedApprovers.some(aa => CaseInsensitiveComparer(aa.id, this.principal.objectId)) &&
            !this.workflowRequest.reviews.some(review => CaseInsensitiveComparer(review.reviewer?.id, this.principal.objectId))
            ) {
            this.currentUserIsApprover = true;
        }

        this.isDoneLoading();
    }

    addReviews(review:IWorkflowReview):void {
        this.reviews.push({
            approvedBy: review.reviewer?.email ?? '',
            reviewDate: review.reviewedDate?.toLocaleString('en-us') ?? '',
            approved: review.approved,
            comments: review.comment ?? ''
        });
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }

    onSubmit(){
        this.workflowRequestService.ReviewRequest(
                this.workflowRequest.type as WorkflowRequestTypes, 
                this.workflowRequest.id, 
                this.approvalForm.controls.decision.value ?? false, this.approvalForm.controls.comments.value ?? ''
            ).subscribe({
                next:(x:IGenericApiResponse<IWorkflowReview>) => {
                    this.addReviews(x.data);
                },
                error:(err:any) => {},
                complete: () => {
                    this.isSubmitted = true;
                }
            });
    }
}