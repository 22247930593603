<form class="branded" *ngIf="!isSearching && searchResults.length === 0 && manualSearch">
    <div class="row">
        <div class="col-12 col-sm-6 col-lg-6">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                <mat-label>search term</mat-label>
                <input matInput [formControl]="searchTerm" (keydown.enter)="search()" />
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                <mat-label>domain</mat-label>
                <mat-select [formControl]="searchDomain">
                    <mat-option *ngFor="let domain of searchDomainOptions" [value]="domain">{{ domain }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-lg-6">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                <mat-label>type</mat-label>
                <mat-select [formControl]="searchType">
                    <mat-option *ngFor="let type of searchTypeOptions" [value]="type">{{ type }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-lg-6" *ngIf="showUserTypeSearchField">
            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                <mat-label>user type</mat-label>
                <mat-select [formControl]="searchUserType">
                    <mat-option *ngFor="let type of searchUserTypeOptions" [value]="type">{{ type }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</form>

<div class="container mb-3" *ngIf="screenMessage !== ''">
    <p class="text-danger text-center">{{screenMessage}}</p>
</div>

<div class="container" *ngIf="isSearching">
    <div class="row justify-content-center col-sm-12">
        <p>Searching for {{searchType.value}}...</p>
    </div>
    <div class="row justify-content-center col-sm-12">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="col-12" *ngIf="!(searchResults.length === 0 || isSearching)">

    <generic-search-results [makeSticky]="makeSticky" [showHeader]="false" [title]="searchType.value+'(s) found'"
        [records]="searchResults" [displayedColumns]="displayColumns" (recordSelected)="selectObject($event)">
    </generic-search-results>

</div>

<div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar" [hidden]="isSearching">
    <div class="btn-group mr-2" role="group" [hidden]="!showSearchButton">
        <button class="button" type="button" (click)="search()">search</button>
    </div>
    <div class="btn-group mr-2" role="group" [hidden]="!showResetButton">
        <button class="button" type="button" (click)="reset()">reset</button>
    </div>
    <div class="btn-group mr-2" role="group" [hidden]="!showCloseButton">
        <button class="button" type="button" mat-dialog-close>close</button>
    </div>
</div>