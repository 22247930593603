export class csvHelper {
    static DownloadFile(data: any, filename = 'data') {
        let blob = new Blob(['\ufeff' + data], {
            type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf(
            'Safari') != -1 &&
            navigator.userAgent.indexOf('Chrome') == -1;

        // If Safari open in new window to
        // save file with random filename.
        if (isSafariBrowser) {
            dwldLink.setAttribute("target", "_blank");
        }
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }


    static ConvertToCSV(objArray: any[], headerList: string[]): string {
        let array = typeof objArray !=
            'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = 'rowNumber, ';
        for (let index in headerList) {
            row += headerList[index] + ', ';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = "\"" + (i + 1);
            for (let index in headerList) {
                let head = headerList[index];
                line += '","' + array[i][head];
            }
            str += line + "\"\r\n";
        }
        return str;
    }
}

