import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';
import { GraphApplicationsUpdateRequest } from 'src/app/models/graph/applications/GraphApplicationsUpdateRequest';
import { GraphApplicationUpdate } from 'src/app/models/graph/applications/GraphApplicationUpdate';
import { GraphApplicationService } from 'src/app/services/graph/GraphApplication.service';


@Component({
  selector: 'request-details-application-update',
  templateUrl: './request-details-application-update.component.html'
})

export class RequestDetailsApplicationUpdate implements OnChanges {
  @Input() requestData: GraphApplicationsUpdateRequest;
  @Output() doneLoading = new EventEmitter<boolean>();

  displayColumns: string[] = ['id', 'displayName', 'newServiceId', 'copyId'];
  displayObjects: any[] = [];
  constructor(
    private graphApplicationService: GraphApplicationService,
  ) { }


  async ngOnChanges(): Promise<void> {
    var updatedApplications = await this.loadUpdatedApplications(this.requestData.domain, this.requestData.applicationUpdates.map(x => x.applicationId) as string[]);
    this.displayObjects = this.requestData.applicationUpdates.map((application: GraphApplicationUpdate) => {
      const updatedApplication = updatedApplications.find(x => x.id === application.applicationId);
      if (updatedApplication) {
        application.displayName = updatedApplication.displayName;
        application.id = updatedApplication.id;
      }
      else {
        application.displayName = application.id;
      }

      return {
        id: application.applicationId,
        displayName: application.displayName,
        newServiceId: application.newServiceId,
        copyId: application.applicationId,
      }
    });
    this.doneLoading.emit(true);
  }

  async loadUpdatedApplications(domain: DirectoryDomain, ids: string[]) {
    return await this.graphApplicationService.GetApplicationsByIdsAsync(domain, ids);
  }
  isDoneLoading() {
    this.doneLoading.emit(true);
  }
}
