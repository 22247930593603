import { Component, Input, OnInit } from "@angular/core";
import { ErrorMessageConstants } from "src/app/constants/ErrorMessage.constants";
import { DataFormatter } from "src/app/services/common/DataFormatter";

@Component({
    selector: 'text-formatter',
    templateUrl: './text-formatter.component.html'
})
export class TextFormatterComponent implements OnInit {

    @Input() content: string;

    formatted: string;
    formattedTextClass: string = 'formatted-text';


    constructor(private formatter: DataFormatter) { }

    ngOnInit(): void {
        if (this.content == ErrorMessageConstants.MissingData) {
            this.formattedTextClass += ' missing-data-color';
        }
        this.formatted = this.formatter.format(this.content);
    }
}