import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { CloseCyberEventCompromisedSourcingCompanyRequest } from 'src/app/models/cyber/CloseCyberEventCompromisedSourcingCompanyRequest';
import { CyberEventRequest } from 'src/app/models/cyber/CyberEventRequest';
import { CyberEventRequestType } from 'src/app/models/cyber/enums/CyberEventRequestType';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { CyberEventService } from 'src/app/services/cyberEvents/CyberEvent.service';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-close-compromised-sourcing-company-event',
    templateUrl: './close-compromised-sourcing-company-event.component.html',
    styleUrl: './close-compromised-sourcing-company-event.component.css'
})
export class CloseCompromisedSourcingCompanyEventComponent implements OnInit {
    PageLayout = PageLayout;
    relatedRequests: IWorkflowRequest[] = [];
    doneLoading: boolean = false;
    isSubmitting: boolean = false;
    selectedRequests: IWorkflowRequest[] = [];
    submitErrorMessage: string = "";
    submittedRequests: IWorkflowRequest[] = [];

    constructor(
        private readonly cyberService: CyberEventService,
        private readonly userService: GraphUserService,
        private readonly authService: CalAngularService
    ) {}

    ngOnInit(): void {
        this.cyberService.GetActiveCyberEvents().subscribe({
            next: (requests: IGenericApiResponse<IWorkflowRequest[]>) => {
                this.relatedRequests = requests.data;
            },
            error: (e) => {
                console.error(e);
                this.doneLoading = true;
            },
            complete: () => this.doneLoading = true
        });
        
    }

    onSubmit(): void {
        if (this.hasWritePermissions()) {
            Swal.fire({
                icon: 'warning',
                iconColor: '#97002E',
                title: '<h3>are you sure?</h3>',
                html:
                "<p>Submitting this request will remove the conditional access policy blocking logins from the accounts disabled and reenable guest invitations from blocked companies.",
                showCancelButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'button',
                cancelButton: 'button',
                },
            }).then((result: any) => {
                if (result.isConfirmed) {
                    this.isSubmitting = true;
                    let reqDetails = new CloseCyberEventCompromisedSourcingCompanyRequest(this.selectedRequests.map(r => r.id));
                    let request = new CyberEventRequest(CyberEventRequestType.CloseCompromisedSourcingCompany, reqDetails);
                    this.cyberService.CloseCyberEventCompromisedSourcingCompany(request).subscribe({
                        next: (response: IGenericApiResponseWithWorkflowRequest<CloseCyberEventCompromisedSourcingCompanyRequest>) => {
                            this.submittedRequests = response.requests;
                        },
                        error: (e) => {
                            console.error(e);
                            this.submitErrorMessage = "An error occurred while submitting the request. Please try again.";
                            this.isSubmitting = false;
                        },
                        complete: () => {
                            this.isSubmitting = false;
                            this.submitErrorMessage = "";
                        }
                    });
                }
            });
        }
    }

    hasWritePermissions(): boolean {
        return this.userService.CheckIsCompromisedSourcingCompanyManager(this.authService.cvxClaimsPrincipal);
    }

    hasReadPermissions(): boolean {
        return this.userService.CheckIsCompromisedSourcingCompanyReader(this.authService.cvxClaimsPrincipal);
    }

    hasPermissions(): boolean {
        return this.hasWritePermissions() || this.hasReadPermissions();
    }
}
