<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12">
                <img src="/assets/img/under-construction.jpg" class="img-fluid" alt="Responsive image">
            </div>
        </div>
        <div class="row">
            <div class="col col-12">
                Apologies, this page isn't ready yet!
            </div>
        </div>
    </section>
</main>