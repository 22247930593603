import { Component, Input, OnInit } from "@angular/core";
import { IStatusApiResponse } from "src/app/models/common/IStatusApiResponse";
import { ApiStatusService } from "src/app/services/common/ApiStatusService";

@Component({
    selector: 'api-status',
    templateUrl: './api-status.component.html',
    styleUrls: ['./api-status.component.css']
})

export class ApiStatusComponent implements OnInit {

    @Input() compactMode:boolean=true;

    doneLoading:boolean = false;
    apiAdstatus:IStatusApiResponse[] = [];

    constructor(private apiStatusService:ApiStatusService){}
    async ngOnInit(): Promise<void> {
        this.apiAdstatus = await this.apiStatusService.getADStatus();
        this.doneLoading = true;
    }

}