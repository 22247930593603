<div class="col-12">
    <h4 id="resultCount">requestable permissions</h4>
    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="isLoading">
        <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar"
        *ngIf="!isLoading && requestablePermissions.length == 0">
        no requestable permissions found
    </div>
    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar"
        *ngIf="!isLoading && requestablePermissions.length > 0">

        <generic-search-results class="w-100" [showHeader]="false" [records]="requestablePermissions"
            [showCounter]="false" [title]="''" [caption]="'requestable permissions'"
            [displayedColumns]="displayedColumns" [selectableRecords]="false" [deletableRecords]="false"
            [showPagination]="true" [allowLocalFilter]="true" [sortable]="false">
        </generic-search-results>
    </div>
</div>