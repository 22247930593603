import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDialogAppSearchChevronData } from "src/app/models/idm/applications/IDialogAppSearchChevronData";
import { IIdmApplication } from "src/app/models/idm/applications/IdmApplication";

@Component({
    selector: 'dialog-app-search-chevron',
    templateUrl: './dialog-app-search-chevron.component.html'
})

export class DialogAppSearchChevronComponent {

    constructor(private dialogRef: MatDialogRef<DialogAppSearchChevronComponent>, @Inject(MAT_DIALOG_DATA) public data: IDialogAppSearchChevronData) { }

    appSelected(data: IIdmApplication) {
        this.dialogRef.close(data);
    }

    searchCancelled() {
        this.dialogRef.close();
    }
}