<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <div class="stickyHeader">
                <a class="close-button stickyHeader" (click)="searchCancelled()"><span
                        class="icon-remove close-button"></span></a>
                <h2>app permissions search</h2>
            </div>

            <div class="dialog-wrapper adjustStickyHeader">
                <app-search [makeSticky]="true" [isDialog]="true" [searchPermissions]="data.searchPermissions"
                    [ownedApps]="data.ownedApps" [allowOwnerSearch]="data.allowOwnerSearch"
                    [servicePrincipals]="data.servicePrincipals" (appSelected)="appSelected($event)"
                    (searchCancelled)="searchCancelled()">
                </app-search>
            </div>
        </div>
    </div>
</mat-dialog-content>