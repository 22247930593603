import { DirectoryDomain } from "../../directory/enums/DirectoryDomain.enum";
import { GraphApplicationUpdate } from "./GraphApplicationUpdate";
export class GraphApplicationsUpdateRequest {

    constructor(domain: DirectoryDomain, applicationUpdates: GraphApplicationUpdate[]) {
        this.domain = domain;
        this.applicationUpdates = applicationUpdates;
    }

    domain: DirectoryDomain = DirectoryDomain.Chevron;
    applicationUpdates: GraphApplicationUpdate[] = [];
}