import { Injectable } from "@angular/core";
import { from, Observable } from "rxjs";
import { IGenericApiResponse } from "src/app/models/common/GenericApiResponse";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { GraphConstants } from "src/app/models/graph/Graph.constants";
import { IdampHttpClient } from "../common/IdampHttpClient";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { UpdateGuestUserRequest } from "src/app/models/guestAccounts/UpdateGuestUserRequest";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { UpdateServiceAccountRequest } from "src/app/models/servicePrivilegeAccounts/UpdateServiceAccountRequest";
import { CocoUserRequest } from "src/app/models/cocoUser/CocoUserRequest";
import { ICvxClaimsPrincipal } from "@cvx/cal";
import { AppRoleConstants } from "src/app/constants/AppRole.constants";
import { CreateServiceAccountRequest } from "src/app/models/servicePrivilegeAccounts/CreateServiceAccountRequest";
import { CreatePrivilegeAccountRequest } from "src/app/models/servicePrivilegeAccounts/CreatePrivilegeAccountRequest";
import { ResultSizeConstants } from "src/app/models/common/enums/ResultSizeConstants.enum";
import { UpdateCocoUserRequest } from "src/app/models/cocoUser/UpdateCocoUserRequest";
import { PersonRelationshipType } from "src/app/models/directory/enums/PersonRelationshipType.enum";
import { IBulkInviteGuestRequest } from "src/app/models/guestAccounts/IBulkInviteGuestRequest";

@Injectable({ providedIn: 'root' })

export class GraphUserService {

    constructor(private http: IdampHttpClient) { }

    public SearchUser(term?: string, type?: UserType, stewardedByUserId?: string, resultSize?: number, enabledAccountsOnly?: boolean): Observable<IGenericApiResponse<IDirectoryUser[]>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}`;
        let query: any = { searchTerm: term ?? "", userType: type ?? UserType.Primary, stewardedByUserId: stewardedByUserId ?? "", resultSize: resultSize ?? ResultSizeConstants.DefaultSearch, enabledAccountsOnly: enabledAccountsOnly ?? true };

        return from(this.http.GetAsync<IGenericApiResponse<IDirectoryUser[]>>(path, query));
    }

    public DeleteUsers(userIds: string[]): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/bulkDelete`;
        return from(this.http.PostAsync<string[], IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>(path, userIds));
    }

    public UpdateGuests(updateGuestUserRequest: UpdateGuestUserRequest): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/updateGuests`;
        return from(this.http.PatchAsync<UpdateGuestUserRequest, IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>(path, updateGuestUserRequest));
    }

    public UnlockAccounts(domain: DirectoryDomain, accountIds: string[]): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>> {
        const path = `users/${domain}/bulkUnlock`;
        return from(this.http.PostAsync<string[], IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>(path, accountIds));
    }

    public GetLockedAccounts(domain: DirectoryDomain, stewardedByUserId: string): Observable<IGenericApiResponse<IDirectoryUser[]>> {
        const path = `users/${domain}`;
        const query: any = { stewardedByUserId, lockedAccountsOnly: true };
        return from(this.http.GetAsync<IGenericApiResponse<IDirectoryUser[]>>(path, query));
    }

    public CreateCoco(cocoUserRequest: CocoUserRequest): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/createCoco`;
        return from(this.http.PostAsync<CocoUserRequest, IGenericApiResponseWithWorkflowRequest<IDirectoryUser>>(path, cocoUserRequest));
    }

    public CheckCocoUserSponsorshipPermission(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
        return cvxClaimsPrincipal.roles?.some(x => x === AppRoleConstants.CocoUserManageAll || x === AppRoleConstants.NonPrimaryUserManageAll)
    }

    public CheckUserGuestSponsorshipPermission(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
        return cvxClaimsPrincipal.roles?.some(x => x === AppRoleConstants.GuestUserInviteAll || x === AppRoleConstants.NonPrimaryUserManageAll) || (cvxClaimsPrincipal.userType === 'Member' && cvxClaimsPrincipal.personRelationshipType === PersonRelationshipType.Employee)
    }

    public CheckIsCocoUserAdmin(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
        return cvxClaimsPrincipal.roles?.some(x => x == AppRoleConstants.CocoUserManageAll);
    }

    public CheckIsNonChevronReader(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
        return cvxClaimsPrincipal.roles?.some(x => x == AppRoleConstants.NonChevronApplicationPermissionReadAll)
    }

    public CheckIsCompromisedSourcingCompanyManager(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
        return cvxClaimsPrincipal.roles?.some(x => x == AppRoleConstants.CyberEventCompromisedSourcingCompanyManageAll)
    }

    public CheckIsCompromisedSourcingCompanyReader(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
        return cvxClaimsPrincipal.roles?.some(x => x == AppRoleConstants.CyberEventCompromisedSourcingCompanyReadAll)
    }

    public CheckIsUserAdmin(cvxClaimsPrincipal: ICvxClaimsPrincipal) {
        return cvxClaimsPrincipal.roles?.some(x => x == AppRoleConstants.NonPrimaryUserManageAll)
    }

    public UpdateSevricesAccounts(updateServiceAccountRequest: UpdateServiceAccountRequest): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/updateServiceAccounts`;
        return from(this.http.PatchAsync<UpdateServiceAccountRequest, IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>(path, updateServiceAccountRequest));
    }

    public ResetMFA(userIds: string[]): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/bulkResetMFA`;
        return from(this.http.PostAsync<string[], IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>(path, userIds));
    }

    public CreateServiceAccount(createServiceAccountRequest: CreateServiceAccountRequest): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/createServiceAccount`;
        return from(this.http.PostAsync<CreateServiceAccountRequest, IGenericApiResponseWithWorkflowRequest<IDirectoryUser>>(path, createServiceAccountRequest));
    }

    public CreatePrivilegeAccount(createPrivilegeAccountRequest: CreatePrivilegeAccountRequest): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/createPrivilegeAccount`;
        return from(this.http.PostAsync<CreatePrivilegeAccountRequest, IGenericApiResponseWithWorkflowRequest<IDirectoryUser>>(path, createPrivilegeAccountRequest));
    }

    public UpdateCocoUsers(updateCocoUserRequest: UpdateCocoUserRequest): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/updateCocoUsers`;
        return from(this.http.PatchAsync<UpdateCocoUserRequest, IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>(path, updateCocoUserRequest));
    }

    public BulkInviteGuests(bulkInviteGuestRequest: IBulkInviteGuestRequest): Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>> {
        const path = `users/${GraphConstants.DefaultGraphDomain}/bulkInviteGuest`;
        return from(this.http.PostAsync<IBulkInviteGuestRequest, IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>(path, bulkInviteGuestRequest));
    }
}