import { Dictionary } from "../common/types/Dictionary";
import { CreateSecretField } from "./enums/CreateSecretField.enum";
import { CreateableSecretFolder } from "./enums/CreateableSecretFolder.enum";

/**
 * POST payload for endpoint /api/secrets
 */
export class CreateSecretRequest {
    
    constructor(folder: CreateableSecretFolder,
        secretFields: Dictionary<CreateSecretField,string> = {},
        owners:string[],
        viewers:string[]) {
        this.folder = folder;
        this.secretFields = secretFields;
        this.owners = owners;
        this.viewers = viewers;
    }

    folder: CreateableSecretFolder
    secretFields: Dictionary<CreateSecretField, string> = {};
    owners: string[] = []
    viewers: string[] = []
}