<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">

        <div class="row">
            <div class="col col-12">
                <h2>contractor access management</h2>

                <div class="container" *ngIf="IsLoading">
                    <div class="row justify-content-center col-sm-12">
                        <p>loading your users</p>                               
                    </div>                    
                    <div class="row justify-content-center col-sm-12">
                        <mat-spinner class="cxv-spinner-dark-blue"></mat-spinner>                               
                    </div>                                
                </div>
                <div class="row justify-content-center col-sm-12" *ngIf="errMessage !== ''">
                    <p>{{errMessage}}</p>                               
                </div>

                <div class="container" [ngClass]="IsLoading || errMessage !== '' ? 'd-none' : ''">

                    <mat-form-field appearance="outline" class="col-12 col-md-6 pl-0">
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">Filter by end date ({{DisplayedCamUsers.length}} users)</mat-label>
                        <input matInput [matDatepicker]="filterPicker" (dateChange)="filterOnEndDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="filterPicker" class="text-dark-blue"></mat-datepicker-toggle>
                        <mat-datepicker #filterPicker></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="col-12 col-md-6">
                        <mat-label class="font-gotham-narrow-bold text-dark-blue">Set end date on selected ({{SelectedCamUsers.length}} selected)</mat-label>
                        <input matInput [matDatepicker]="picker" (dateChange)="setEndDateOnSelected($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker" class="text-dark-blue"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    
                    <ng-template #rowActions let-row>
                        <button mat-icon-button [matMenuTriggerFor]="menu">
                            <mat-icon>menu</mat-icon>
                        </button>                    
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="SetEndDateByMonths(row, 6)">
                                <mat-icon color="primary">person_add_alt_1</mat-icon>
                                <span>Add 6 Months</span>
                            </button>
                            <button mat-menu-item (click)="SetEndDateByMonths(row, 12)">
                                <mat-icon color="primary">person_add_alt_1</mat-icon>
                                <span>Add 12 Months</span>
                            </button>
                            <button mat-menu-item (click)="openDatePicker(picker)">
                                <mat-icon color="primary">calendar_today</mat-icon>
                                <span>Pick Date</span>
                            </button>
                            <input class="hidden-input" matInput [matDatepicker]="picker" (dateChange)="SetEndDateByDate($event, row)">
                            <mat-datepicker #picker></mat-datepicker>
                            <button mat-menu-item (click)="RetireAccount(row)">
                                <mat-icon color="primary">person_remove</mat-icon>
                                <span>Retire Account</span>
                            </button>
                                <button mat-menu-item (click)="ResetAccount(row)">
                                <mat-icon color="primary">change_circle</mat-icon>
                                <span>Reset</span>
                            </button>
                        </mat-menu>
                    </ng-template>

                    <app-generic-reusable-table
                        [data]="DisplayedCamUsers" 
                        [columns]="CamColumns"
                        [showActions]="true"
                        [showCheckboxes]="true"
                        [rowActionsTemplate]="rowActions"
                        (selectionChanged)="handleSelection($event)">
                    </app-generic-reusable-table>


                    
                    <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4">
                        <div class="btn-group mr-2" role="group">
                            <button class="button button-group-spaced" [disabled]="noUpdates()" (click)="sendUpdates()">submit</button>
                            <button class="button" (click)="resetPage()">reset</button>
                        </div>
                    </div>

                </div>



            </div>
        </div>

    </section>
</main>
