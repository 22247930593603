import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { UserBulkRequestDetails } from "src/app/models/graph/UserBulkRequestDetails";

@Component({
    selector: 'request-details-user-delete',
    templateUrl: './request-details-user-delete.component.html'
})

export class RequestDetailsUserDelete implements OnInit {

    @Input() requestData: UserBulkRequestDetails;
    @Output() doneLoading = new EventEmitter<boolean>();

    userColumns: string[] = ['displayName', 'mail', 'domain', 'type', 'id'];
    usersDeleted: IDirectoryUser[] = [];

    async ngOnInit(): Promise<void> {
        this.usersDeleted = this.requestData.users
        this.isDoneLoading();
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}