import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDisplayLabels',
  standalone: true
})
export class ConvertDisplayLabelsPipe implements PipeTransform {

  transform(value: string): string {
    const result = value.replace(/([A-Z])/g, " $1");
    return result.toLowerCase();
  }

}
