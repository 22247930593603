import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { IUserUpdate } from "src/app/models/userUpdate";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { PageLayout } from "@cvx/nextpage";
import { GraphUserService } from "src/app/services/graph/GraphUser.service";
import { CalAngularService } from "@cvx/cal-angular";
import { DialogCampEntitySearchComponent } from "../../_shared/dialog-camp-entity-search/dialog-camp-entity-search.component";
import { CampEntity } from "src/app/models/campEntity";
import { MatDialog } from "@angular/material/dialog";
import { CyberEventRequest } from "src/app/models/cyber/CyberEventRequest";
import { CreateCyberEventCompromisedSourcingCompanyRequest, CompromisedSourcingCompanyCriteria, DisplayCompromisedSourcingCompanyCriteria } from "src/app/models/cyber/CyberEventCompromisedSourcingCompanyRequest";
import { CyberEventRequestType } from "src/app/models/cyber/enums/CyberEventRequestType";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToStringHelper } from "src/app/helpers/ToStringHelper";
import { CyberEventUserType } from "src/app/models/cyber/enums/CyberEventUserType";
import { CompromisedSourcingCompanyCriteriaType } from "src/app/models/cyber/enums/CyberEventCriteriaType";
import Swal from "sweetalert2";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { csvHelper } from "src/app/helpers/exporter/csvHelper";
import { CyberEventService } from "src/app/services/cyberEvents/CyberEvent.service";

@Component({
    selector: 'app-compromised-sourcing-company',
    templateUrl: './create-compromised-sourcing-company-event.component.html',
    styleUrls: ['./create-compromised-sourcing-company-event.component.css']
})
export class CreateCompromisedSourcingCompanyEventComponent implements OnInit {
    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
        this.usersDataSource.paginator = value;
    }
    @ViewChild(MatSort, { static: false })
    set sort(value: MatSort) {
        this.usersDataSource.sort = value;
    }

    usersToUpdate: IUserUpdate[];
    displayCols: string[] = ['displayName', 'mail', 'companyName', 'sourcingCompanyId', 'accountEnabled'];
    criteriaDataCols: string[] = ['userType', 'criteriaType', 'displayName', 'value'];
    criteriaCols: string[] = [...this.criteriaDataCols, 'delete'];
    userTypes: CyberEventUserType[] = [CyberEventUserType.Contractor, CyberEventUserType.Guest, CyberEventUserType.Both];
    PageLayout = PageLayout;
    formErrorMessage: string;
    errorMessage: string;
    submitErrorMessage: string;
    gettingUsers: boolean = false;
    isSubmitting: boolean = false;
    evaluating: boolean = false;
    submittedRequests: IWorkflowRequest[] = [];
    usersDataSource = new MatTableDataSource<IDirectoryUser>([]);
    criteriaDataSource = new MatTableDataSource<DisplayCompromisedSourcingCompanyCriteria>([]);
    showSelectAllCheckbox: boolean = false;
    userForm = this.formBuilder.group({
        userType: [CyberEventUserType.Guest, Validators.required],
        emailDomain: [""],
        sourcingCompany: [null as CampEntity[] | null],
    }, { validators: this.formValidator });

    formValidator(formGroup: FormGroup) {
        const userType = formGroup.get('userType')?.value;
        const emailDomain = formGroup.get('emailDomain');
        const sourcingCompany = formGroup.get('sourcingCompany');

        if (userType == CyberEventUserType.Guest && !emailDomain?.value && !sourcingCompany?.value && (emailDomain?.touched || sourcingCompany?.touched)) {
            emailDomain?.setErrors({ requireOneField: true });
            emailDomain?.markAsTouched();
            sourcingCompany?.setErrors({ requireOneField: true });
            sourcingCompany?.markAsTouched();
            return { requireOneField: true };
        }
        else if (sourcingCompany?.touched && userType != CyberEventUserType.Guest && !sourcingCompany?.value) {
            sourcingCompany?.setErrors({ requireSourcingCompanyWhenNotGuest: true });
            sourcingCompany?.markAsTouched();
        }
        else{
            emailDomain?.setErrors(null);
            sourcingCompany?.setErrors(null);
        }
        return null;
    }

    constructor(
        private readonly userService: GraphUserService,
        private readonly cyberService: CyberEventService,
        private dialog: MatDialog,
        private readonly authService: CalAngularService,
        private formBuilder: FormBuilder) {
    }

    ngOnInit(): void {
        this.usersDataSource.paginator = this.paginator
        this.usersDataSource.sort = this.sort;
    }

    public filter = (value: any) => {
        this.usersDataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    searchForCampEntity() {
        const dialogRef = this.dialog.open(DialogCampEntitySearchComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: { includeCheckbox: true }
        });
        
        dialogRef.afterClosed().subscribe(result => {

            let model = result as CampEntity[];

            if (model) {
                this.userForm.patchValue({sourcingCompany: ToStringHelper.overrideToString(model)});
            }

        });
    }

    addCriteria() {
        if (this.userForm.invalid) {
            this.formErrorMessage = "please fill out all required fields";
            return;
        }
        if (this.userForm.controls.userType.value != CyberEventUserType.Both) {
            if (this.userForm.controls.emailDomain.value && this.userForm.controls.sourcingCompany.value) {
                this.createNewCriteria(this.userForm.controls.userType.value!, CompromisedSourcingCompanyCriteriaType.EmailDomain, this.userForm.controls.emailDomain.value!, this.userForm.controls.emailDomain.value!);
                this.userForm.controls.sourcingCompany.value.forEach((c: CampEntity) => this.createNewCriteria(this.userForm.controls.userType.value!, CompromisedSourcingCompanyCriteriaType.SourcingCompanyId, c.id, this.toString(c)));
            }
            else if (this.userForm.controls.emailDomain.value) {
                this.createNewCriteria(this.userForm.controls.userType.value!, CompromisedSourcingCompanyCriteriaType.EmailDomain, this.userForm.controls.emailDomain.value!, this.userForm.controls.emailDomain.value!);
            }
            else {
                this.userForm.controls.sourcingCompany.value.forEach((c: CampEntity) => this.createNewCriteria(this.userForm.controls.userType.value!, CompromisedSourcingCompanyCriteriaType.SourcingCompanyId, c.id, this.toString(c)));
            }
        }
        else {
            this.userForm.controls.sourcingCompany.value.forEach((c: CampEntity) => this.createNewCriteria(CyberEventUserType.Contractor, CompromisedSourcingCompanyCriteriaType.SourcingCompanyId, c.id, this.toString(c)));
            this.userForm.controls.sourcingCompany.value.forEach((c: CampEntity) => this.createNewCriteria(CyberEventUserType.Guest, CompromisedSourcingCompanyCriteriaType.SourcingCompanyId, c.id, this.toString(c)));
            if (this.userForm.controls.emailDomain.value) {
                this.createNewCriteria(CyberEventUserType.Guest, CompromisedSourcingCompanyCriteriaType.EmailDomain, this.userForm.controls.emailDomain.value!, this.userForm.controls.emailDomain.value!);
            }
        }
        this.clearForm();
    }

    deleteRow(criteria: DisplayCompromisedSourcingCompanyCriteria) {
        this.criteriaDataSource.data = this.criteriaDataSource.data.filter(c => c != criteria);
    }

    toString(c: CampEntity) {
       return `${c.id} - ${c.name} (${c.country})`;
    }

    resetForm() {
        this.clearForm();
        this.criteriaDataSource.data = [];
        this.usersDataSource.data = [];
        this.errorMessage = "";
        this.formErrorMessage = "";
        this.evaluating = false;
        this.submitErrorMessage = "";
    }
    
    onSubmit() {
        if (this.hasWritePermissions() && this.evaluating && (this.errorMessage === "" || this.errorMessage === "no accounts were found")) {
            Swal.fire({
                icon: 'warning',
                iconColor: '#97002E',
                title: '<h3>are you sure?</h3>',
                html:
                `<p>You are about to disable at least ${this.usersDataSource.data.length} accounts.` +
                "This could potentially disrupt many projects at Chevron. Proceed at your own risk</p>",
                showCancelButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                buttonsStyling: false,
                customClass: {
                confirmButton: 'button',
                cancelButton: 'button',
                },
            }).then((result: any) => {
                if (result.isConfirmed) {
                    this.disableUsers();
                }
            });
        }
    }

    private createNewCriteria(userType: CyberEventUserType, criteriatype: CompromisedSourcingCompanyCriteriaType, value: string, displayName: string) {
        this.criteriaDataSource.data = [...this.criteriaDataSource.data, new DisplayCompromisedSourcingCompanyCriteria(
            userType, criteriatype, value, displayName
        )]
    }
    private clearForm() {
        this.userForm.get('sourcingCompany')?.reset();
        this.userForm.get('sourcingCompany')?.markAsUntouched();
        this.userForm.get('emailDomain')?.reset();
        this.userForm.get('emailDomain')?.markAsUntouched();
        this.formErrorMessage = "";
    }

    search() {
        if (this.hasPermissions()) {
            this.gettingUsers = true;
            this.evaluating = true;
            let reqDetails = new CreateCyberEventCompromisedSourcingCompanyRequest(this.criteriaDataSource.data.map(c => new CompromisedSourcingCompanyCriteria(c.userType, c.criteriaType, c.value)));
            let req = new CyberEventRequest(CyberEventRequestType.CreateCompromisedSourcingCompany, reqDetails);
            this.cyberService.GetCyberEventCompromisedSourcingCompanyImpactedUsers(req).subscribe({
                next: (r) => {
                    this.usersDataSource.data = r.data
                },
                error: (e) => {
                    console.error(e);
                    this.errorMessage = `${e.error["errors"] ?? "encountered unexpected error while searching for accounts"}`;
                    this.gettingUsers = false;
                },
                complete: () => {
                    this.errorMessage = this.usersDataSource.data.length == 0 ? `no accounts were found` : "";
                    this.gettingUsers = false;
                }
            });
        }
        else {
            this.errorMessage = `only authorized users can deactivate accounts.`;
            this.gettingUsers = false;
        }
    }

    exportToCsv() {
        const csvData = csvHelper.ConvertToCSV(this.usersDataSource.data, ['id', ...this.displayCols]);
        csvHelper.DownloadFile(csvData, `impacted-users-compromised-company-event-idamp-${new Date().toISOString()}`);
    }

    private disableUsers() {
        this.isSubmitting = true;
        let reqDetails = new CreateCyberEventCompromisedSourcingCompanyRequest(this.criteriaDataSource.data.map(c => new CompromisedSourcingCompanyCriteria(c.userType, c.criteriaType, c.value)));
        let req = new CyberEventRequest(CyberEventRequestType.CreateCompromisedSourcingCompany, reqDetails);
        // submitting same request with whatIf false this time
        this.cyberService.CreateCyberEventCompromisedSourcingCompany(req).subscribe({
            next: (r: IGenericApiResponseWithWorkflowRequest<any>) => {
                this.submittedRequests = r.requests;
            },
            error: (e) => {
                console.error(e);
                this.submitErrorMessage = `${e.error["errors"] ?? "encountered unexpected error while executing request"}`;
                this.isSubmitting = false;
            },
            complete: () => {
                this.isSubmitting = false;
                this.submitErrorMessage = "";
            }
        });
    }

    public hasWritePermissions(): boolean {
        return this.userService.CheckIsCompromisedSourcingCompanyManager(this.authService.cvxClaimsPrincipal);
    }

    private hasReadPermissions(): boolean {
        return this.userService.CheckIsCompromisedSourcingCompanyReader(this.authService.cvxClaimsPrincipal);
    }
    
    public hasPermissions(): boolean {
        return this.hasWritePermissions() || this.hasReadPermissions();
    }
}
