import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { AppRoleConstants } from '../constants/AppRole.constants';

export const testerGuard : CanActivateFn = (route, state) => {
  const calService = inject(CalAngularService);
  // production is fine, but nonProd we need to check for App.Tester
  return environment.production || (
      calService.isUserSignedIn() && calService.cvxClaimsPrincipal.roles.some(r => r === AppRoleConstants.AppTester)
  )
}
