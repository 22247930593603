<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row" *ngIf="submittedRequests.length === 0">
            <div class="col col-12">                
                <h1>register client secret</h1>
                <fieldset class="pb-4">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>applications</strong></p>
                    </legend>                    
                    <div *ngIf="!gettingApps && appsDataSource.data.length == 0">
                        <display-http-error message="no applications owned by you were found" [errors]="[]"></display-http-error>
                    </div>
                    <div class="container" *ngIf="gettingApps">
                        <div class="row justify-content-center col-sm-12">
                            <p>searching for applications...</p>                               
                        </div>
                        <div class="row justify-content-center col-sm-12">
                            <mat-spinner></mat-spinner>                               
                        </div>                                
                    </div>
                    <div class="container" *ngIf="!gettingApps && appsDataSource.data.length > 0">
                        <form class="branded">
                            <filter-input (onKeyUp)="doFilter($event)"></filter-input>

                            <table id="applications" 
                            mat-table 
                            [dataSource]="appsDataSource" 
                            matSort
                            matSortActive="displayName" 
                            matSortDirection="asc"
                            aria-describedby="user owned apps" >
                        
                                <caption>applications you own</caption>

                                <tr mat-header-row *matHeaderRowDef="appsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: appsDisplayedColumns;"></tr>
                        
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                    <td mat-cell *matCellDef="let row">
                                        <input
                                            MatCheckbox
                                            type="checkbox"
                                            name="formcheckbox"
                                            (click)="$event.stopPropagation()" (change)="select(row)"
                                            [checked]="selection.isSelected(row)"
                                            />
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="appId">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> app id </th>
                                    <td mat-cell *matCellDef="let app">{{ app.appId }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="displayName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> display name </th>
                                    <td mat-cell *matCellDef="let app">{{ app.displayName }}</td>
                                </ng-container>

                            </table>
                        
                            <mat-paginator [length]="appsDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons></mat-paginator>
                        </form>
                    </div>
                </fieldset>

                <fieldset class="pb-4" *ngIf="!gettingApps && appsDataSource.data.length > 0">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>selected apps</strong></p></legend>
                    <generic-search-results 
                    [showHeader]="false" 
                    [records]="selection.selected" 
                    [showCounter]="false"
                    [title]="''" 
                    [caption]="'apps to register client secrets for'"
                    [displayedColumns]="selectedAppsDisplayedColumns" 
                    [selectableRecords]="false"
                    [deletableRecords]="true"
                    [showPagination]="false"
                    [allowLocalFilter]="false"
                    (recordDeleted)="select($event)">
                    </generic-search-results>
                </fieldset>

                <mat-divider></mat-divider>
                
                <display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>

                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="!gettingApps">
                    <mat-spinner [diameter]="30" *ngIf="isSubmitting" style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    <div class="btn-group mr-2" role="group" *ngIf="!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0">
                        <button class="button" type="submit" (click)="onSubmit()">create</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="submittedRequests.length > 0">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/applications/register-client-secret']" ><span class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>
    </section>
</main>