import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PageLayout } from "@cvx/nextpage";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";
import { DirectoryGroupMemberRequest, DirectoryGroupUpdateMemberRequest } from "src/app/models/directory/groups/DirectoryGroupUpdateMemberRequest";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";
import { DialogDirectorySearchComponent } from "../../_shared/dialog-directory-search/dialog-directory-search.component";
import { DialogGenericSearchResultsComponent } from "../../_shared/dialog-generic-search-results/dialog-generic-search-results.component";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { UserType } from "src/app/models/directory/enums/UserType.enum";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { UntypedFormControl, Validators } from "@angular/forms";
import { DialogBatchDirectorySearchComponent } from "../../_shared/dialog-batch-directory-search/dialog-batch-directory-search.component";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { IDialogGenericSearchResultsData } from "src/app/models/components/IDialogGenericSearchResultsData";
import { IDialogDirectoryGroupMemberSearchData } from "src/app/models/components/IDialogDirectoryGroupMemberSearchData";
import { DialogDirectoryGroupMemberSearchComponent } from "../../_shared/dialog-directory-group-member-search/dialog-directory-group-member-search.component";
import { ActivatedRoute, Router } from "@angular/router";
import { CalAngularService, ICvxClaimsPrincipal } from "@cvx/cal-angular";
import { firstValueFrom } from "rxjs";

@Component({
    selector: 'update-membership-group',
    templateUrl: './update-membership.component.html'
})

export class UpdateMembershipComponent implements OnInit {

    PageLayout = PageLayout;
    currentUserProfile: ICvxClaimsPrincipal;
    showOwnedSearch: boolean = false;
    submitErrorMessage = { message: '', errors: [] };

    addMemberErrorMessage: string = '';
    isAddMemberLoading: boolean = false;
    removeMemberErrorMessage: string = '';
    isRemoveMemberLoading: boolean = false;
    isCreating: boolean = false;
    createdRequests: IWorkflowRequest[] = [];
    allowBatch: boolean = false;

    selectedGroup?: DirectoryGroup;
    selectedMembers: DirectoryObjectBase[] = [];
    selectedRemoveMembers: DirectoryObjectBase[] = [];

    memberDisplayedColumns: string[] = ['displayName', 'mail', 'directoryObjectType'];
    memberDisplayedColumnsWithCheckboxes: string[] = ['select', ...this.memberDisplayedColumns]; //for adding checkbox use


    justification = new UntypedFormControl('');

    groupDisplayModel: any;
    allowedTypes: AllowedTypes;

    get GroupMemberType() {
        return GroupMemberType;
    }

    get UserType() {
        return UserType;
    }

    /**
     * Map between the group domain, and what domain to search for a particular member type
     */
    searchMap: any = {
        [DirectoryDomain.CT]: {
            [GroupMemberType.Group]: DirectoryDomain.CT,
            [GroupMemberType.User]: {
                [UserType.Admin]: DirectoryDomain.CT,
                [UserType.GroupMailbox]: DirectoryDomain.CT,
                [UserType.Primary]: DirectoryDomain.CT,
                [UserType.Service]: DirectoryDomain.CT,
                [UserType.SharedMailbox]: DirectoryDomain.CT
            }
        },
        [DirectoryDomain.S01]: {
            [GroupMemberType.Group]: DirectoryDomain.S01,
            [GroupMemberType.User]: {
                [UserType.Admin]: DirectoryDomain.S01,
                [UserType.Primary]: DirectoryDomain.CT,
                [UserType.Service]: DirectoryDomain.S01,
            }
        },
        [DirectoryDomain.S02]: {
            [GroupMemberType.Group]: DirectoryDomain.S02,
            [GroupMemberType.User]: {
                [UserType.Admin]: DirectoryDomain.S02,
                [UserType.Primary]: DirectoryDomain.CT,
                [UserType.Service]: DirectoryDomain.S02,
            }
        },
        [DirectoryDomain.Chevron]: {
            [GroupMemberType.Group]: DirectoryDomain.Chevron,
            [GroupMemberType.User]: {
                [UserType.Privilege]: DirectoryDomain.Chevron,
                [UserType.Primary]: DirectoryDomain.Chevron,
                [UserType.Service]: DirectoryDomain.Chevron,
                [UserType.GroupMailbox]: DirectoryDomain.Chevron,
                [UserType.SharedMailbox]: DirectoryDomain.Chevron
            }
        }
    }

    constructor(private dialog: MatDialog, private directoryGroupService: DirectoryGroupService, private route: ActivatedRoute, private authService: CalAngularService) {
    }

    async ngOnInit() {
        let domain = this.route.snapshot.paramMap.get('domain');
        let id = this.route.snapshot.paramMap.get('id');

        if (domain !== null && id !== null) {
            if (await firstValueFrom(this.authService.isUserSignedIn())) {
                this.currentUserProfile = this.authService.cvxClaimsPrincipal;

                if (domain !== null && id !== null) {
                    let groupResponse = await this.directoryGroupService.GetByIdAsync(id, domain);
                    let group = groupResponse.data;

                    // load the members and owners
                    let [ownersResponse, membersResponse] = await Promise.all([
                        this.directoryGroupService.GetOwnersAsync(group.id, group.domain),
                        this.directoryGroupService.GetMembersAsync(group.id, group.domain, 1000)
                    ]);
                    group.owners = ownersResponse.data;
                    group.members = membersResponse.data;

                    // double check current user is an owner (aad objectId or provisioningId for on-prem)
                    if (group.owners.some(x => x.id === this.currentUserProfile.objectId || x.provisioningId === this.currentUserProfile.provisioningId)) {
                        this.groupSelected(group);
                    }
                    else {
                        this.showOwnedSearch = true;
                    }
                }
            }
        }
        else {
            this.showOwnedSearch = true;
        }
    }

    groupSelected(data: DirectoryObjectBase) {
        this.selectedGroup = data as DirectoryGroup;
        if (this.selectedGroup?.settings?.requireMembershipJustification) {
            this.justification.addValidators([Validators.required]);
        }

        this.groupDisplayModel = {
            id: this.selectedGroup?.id ?? '',
            displayName: this.selectedGroup?.displayName ?? '',
            domain: this.selectedGroup?.domain ?? '',
            type: this.selectedGroup?.type ?? '',
            usersAllowed: this.selectedGroup?.settings?.usersAllowed ?? false, // 'yes' : 'no',
            groupsAllowed: this.selectedGroup?.settings?.groupsAllowed ?? false, // 'yes' : 'no',
            privilegedAccountsAllowed: this.selectedGroup?.settings?.privilegedAllowed ?? false, // 'yes' : 'no',
            serviceAccountsAllowed: this.selectedGroup?.settings?.serviceAllowed ?? false, // 'yes' : 'no',
            servicePrincipalsAllowed: this.selectedGroup?.settings?.servicePrincipalsAllowed ?? false, // 'yes' : 'no'
        };

        let domain = this.selectedGroup.domain;
        let map = this.searchMap[domain as DirectoryDomain];
        let allowedUserTypes = Object.keys(map[GroupMemberType.User]).map(x => x as UserType);

        this.allowedTypes = new AllowedTypes({
            groups: this.selectedGroup?.settings?.groupsAllowed ?? false,
            primaryUsers: this.selectedGroup?.settings?.usersAllowed ?? false,
            privilegedAccounts: (this.selectedGroup?.settings?.privilegedAllowed ?? false) && allowedUserTypes.indexOf(UserType.Privilege) >= 0,
            adminAccounts: (this.selectedGroup?.settings?.privilegedAllowed ?? false) && allowedUserTypes.indexOf(UserType.Admin) >= 0,
            serviceAccounts: this.selectedGroup?.settings?.serviceAllowed ?? false,
            servicePrincipals: this.selectedGroup?.settings?.servicePrincipalsAllowed ?? false,
            groupMailbox: this.selectedGroup?.settings?.usersAllowed && allowedUserTypes.indexOf(UserType.GroupMailbox) >= 0,
            sharedMailbox: this.selectedGroup?.settings?.usersAllowed && allowedUserTypes.indexOf(UserType.SharedMailbox) >= 0
        });
    }

    /**
     * merge two lists together, which recreates and triggers an update of the component, used for both the add/remove members tables
     * @param newMembers list of new members to add to the existing list
     * @param memberList list of the existing members
     * @returns creates a new list of unique merged members
     */
    addMemberToList(newMembers: DirectoryObjectBase[], memberList: DirectoryObjectBase[]): DirectoryObjectBase[] {
        let existingIds = memberList.map(x => x.id);
        let merged = memberList.concat(newMembers.filter((item) => existingIds.indexOf(item.id) < 0));
        return merged;
    }

    /**
     * remove members from a list
     * @param members list of members to remove from the existing list
     * @param memberList list of existing members
     * @returns creates a new list members with the removed set
     */
    removeMemberFromList(members: DirectoryObjectBase[], memberList: DirectoryObjectBase[]): DirectoryObjectBase[] {
        let removeIds = members.map(x => x.id);
        let result = [...memberList];
        return result.filter((m) => removeIds.indexOf(m.id) < 0);
    }

    /**
     * Opens the dialog to do a search for members to add to the group, restricted to specific types
     * @param memberType group member type
     * @param userType user type, when membertype == user
     */
    openMemberSearchDialog(memberType: GroupMemberType, userType?: UserType) {
        // technically this shouldn't even be clickable without the selectedGroup populated
        if (this.selectedGroup) {
            let map = this.searchMap[this.selectedGroup.domain as DirectoryDomain];
            let domain: DirectoryDomain = DirectoryDomain.Chevron;

            if (memberType === GroupMemberType.Group) {
                domain = map[GroupMemberType.Group];
            }
            else if (memberType === GroupMemberType.User && userType !== undefined) {
                domain = map[GroupMemberType.User][userType];
            }
            else if (memberType === GroupMemberType.ServicePrincipal) {
                domain = DirectoryDomain.Chevron;
            }

            const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
                disableClose: true,
                autoFocus: true,
                maxWidth: 1000,
                width: '100%',
                data: {
                    type: memberType,
                    domain: domain,
                    userType: userType,
                    filterGroupRemoveNotAvailableAsGroupMember: true,
                    filterGroupRemoveDynamicMembershipEnabled: false,
                    filterGroupOnlyManagedByIdamp: false
                } as IDialogDirectoryData
            });
            dialogRef.afterClosed().subscribe((result: DirectoryObjectBase) => {
                if (result) {
                    this.addMembersHandler([result]);
                }
            });
        }
    }

    /**
     * Opens a dialog to do a batch add of users by their emails/object ids
     */
    openBatchAddMembers(add: boolean) {
        if (this.selectedGroup !== undefined) {
            let map = this.searchMap[this.selectedGroup.domain as DirectoryDomain];
            let domain = map[GroupMemberType.User][UserType.Primary];

            let settings = {
                disableClose: true,
                autoFocus: true,
                maxWidth: 1000,
                width: '100%',
                data: {
                    type: GroupMemberType.User,
                    domain: domain,
                    userType: UserType.Primary
                }
            }

            const dialogRef = this.dialog.open(DialogBatchDirectorySearchComponent, settings);
            dialogRef.afterClosed().subscribe((result: DirectoryObjectBase[]) => {
                if (result) {
                    if (add) {
                        this.addMembersHandler(result);
                    }
                    else {
                        this.removeMembersHandler(result);
                    }
                }
            });
        }
    }

    /**
     * Logic to determine if a member should be added to the pending list of users to add to the group
     * @param results List of DirectoryObjectBase to attempt to add
     */
    async addMembersHandler(results: DirectoryObjectBase[]) {
        this.isAddMemberLoading = true;
        this.addMemberErrorMessage = '';
        let toAdd: DirectoryObjectBase[] = [];
        let namesNotBeingAdded: string[] = [];
        for (let m of results) {
            if (!await this.isAlreadyMember(m.id) && (m.id !== this.selectedGroup?.id)) {
                toAdd.push(m);
            }
            else {
                namesNotBeingAdded.push(m.displayName);
            }
        }

        if (namesNotBeingAdded.length !== 0) {
            this.addMemberErrorMessage = `${namesNotBeingAdded.join(', ')} ${namesNotBeingAdded.length === 1 ? 'is already a member' : 'are already members'} of the group and cannot be added`;
        }

        this.selectedMembers = this.addMemberToList(toAdd, this.selectedMembers);
        this.isAddMemberLoading = false;
    }

    /**
     * Handler to remove a member from the "new members" table
     * @param member 
     */
    removeFromNewMember(member: DirectoryObjectBase) {
        this.selectedMembers = this.removeMemberFromList([member], this.selectedMembers);
    }

    /**
     * Checks if a member is already in the group by making an API call
     * @param member 
     */
    async isAlreadyMember(memberId: string): Promise<boolean> {

        if (this.selectedGroup !== undefined) {

            let localMatch = this.selectedGroup.members.some(x => x.id == memberId);

            // didn't match locally and loaded members is the max, checking remotely might resolve anyone past the 1k limit
            if (!localMatch && this.selectedGroup.members.length === 1000) {
                const result = await this.directoryGroupService.CheckMemberAsync(this.selectedGroup.id, this.selectedGroup.domain as DirectoryDomain, memberId);
                return result !== null; // not null means in the group
            }

            return localMatch;
        }

        return false;
    }

    /**
     * Opens a dialog to select members to remove from the group, shows existing members
     */
    openAddToRemoveMembers() {
        let availableMembers = this.selectedGroup?.members ?? [];
        let selectedIds = this.selectedRemoveMembers.map((m) => m.id);
        availableMembers = availableMembers.filter(m => !selectedIds.includes(m.id));

        this.openNormalGroupRemoveMemberDialog(availableMembers);
    }

    /**
     * Logic to determine if a member should be added to the pending lis of users to remove from a group
     * @param results 
     */
    async removeMembersHandler(results: DirectoryObjectBase[]) {
        this.isRemoveMemberLoading = true;
        this.removeMemberErrorMessage = '';
        let toAdd: DirectoryObjectBase[] = [];
        let namesNotBeingAdded: string[] = [];

        for (let m of results) {
            if (await this.isAlreadyMember(m.id)) {
                toAdd.push(m);
            }
            else {
                namesNotBeingAdded.push(m.displayName);
            }
        }

        if (namesNotBeingAdded.length !== 0) {
            this.removeMemberErrorMessage = `${namesNotBeingAdded.join(', ')} ${namesNotBeingAdded.length === 1 ? 'is not a member' : 'are not members'} of the group and cannot be removed`;
        }

        this.selectedRemoveMembers = this.addMemberToList(toAdd, this.selectedRemoveMembers);
        this.isRemoveMemberLoading = false;
    }

    /**
     * Open dialog to remove members from a group, for groups with < 1k members
     */
    openNormalGroupRemoveMemberDialog(availableMembers: DirectoryObjectBase[]) {
        let settings = {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                dialogTitle: 'select group member to remove',
                title: 'member(s)',
                showCounter: true,
                selectableRecords: false, //false: row selection is not clickable
                searchResults: availableMembers,
                displayColumns: this.memberDisplayedColumnsWithCheckboxes,
                allowLocalFilter: true,
                emitRecordsChecked: true,
                sortable: false,
                deletableRecords: true
            } as IDialogGenericSearchResultsData<DirectoryObjectBase>
        };
        const dialogRef = this.dialog.open(DialogGenericSearchResultsComponent, settings);
        dialogRef.afterClosed().subscribe((result: DirectoryObjectBase | DirectoryObjectBase[]) => {
            if (result) {
                const membersArray = Array.isArray(result) ? result : [result];
                this.removeMembersHandler(membersArray);
            }
        });
    }

    /**
     * Handler to remove a member from the "remove members" table
     * @param member 
     */
    removeFromRemoveMembers(member: DirectoryObjectBase | DirectoryObjectBase[]): void {
        const membersArray = Array.isArray(member) ? member : [member];
        this.selectedRemoveMembers = this.removeMemberFromList(membersArray, this.selectedRemoveMembers);
    }

    async onSubmit() {

        // clear error messages
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];

        if (this.selectedGroup !== undefined && (this.selectedMembers.length > 0 || this.selectedRemoveMembers.length > 0) && this.justification.valid) {
            let payload = new DirectoryGroupUpdateMemberRequest();
            payload.businessJustification = this.justification.value;
            payload.addMembers = this.selectedMembers.map(m => new DirectoryGroupMemberRequest(m.id, m.directoryObjectType, m.domain));
            payload.removeMembers = this.selectedRemoveMembers.map(m => new DirectoryGroupMemberRequest(m.id, m.directoryObjectType, m.domain));

            this.isCreating = true;

            const observer = {
                next: (x: IGenericApiResponseWithWorkflowRequest<string>) => {
                    this.createdRequests = x.requests;
                },
                error: (err: any) => {
                    this.submitErrorMessage.message = err.statusText;
                    this.submitErrorMessage.errors = err?.error?.errors ?? [];
                    this.isCreating = false;
                },
                complete: () => {
                    this.isCreating = false;
                }
            };

            let requestCall = this.directoryGroupService.UpdateMembership(this.selectedGroup.id, this.selectedGroup.domain, payload);
            requestCall.subscribe(observer);
        }
        else if (this.justification.invalid) {
            this.submitErrorMessage.message = 'this group requires a business justification';
        }
    }

    resetForm() {
        this.selectedGroup = undefined;
        this.groupDisplayModel = undefined;
        this.allowedTypes = new AllowedTypes({});
        this.selectedMembers = [];
        this.selectedRemoveMembers = [];
        this.justification.clearValidators();
    }

}

class AllowedTypes {
    public constructor(init?: Partial<AllowedTypes>) {
        Object.assign(this, init);
    }

    groups: boolean = false;
    primaryUsers: boolean = false;
    serviceAccounts: boolean = false;
    guestAccounts: boolean = false;
    adminAccounts: boolean = false;        // admin accounts for AD domains (eg. CT,S01,S02)
    privilegedAccounts: boolean = false;   // admin accoounts for AAD
    groupMailbox: boolean = false;
    sharedMailbox: boolean = false;
    servicePrincipals: boolean = false;
}