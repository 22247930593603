<h1 *ngIf="type === RequestResultsType.Approver">My Approval Requests</h1>
<h1 *ngIf="type === RequestResultsType.Requestor">My Requests</h1>

<div class="container" *ngIf="screenMessage !== ''">
    <p class="text-danger text-center">{{screenMessage}}</p>
</div>

<div class="container" *ngIf="isSearching">
    <div class="row justify-content-center col-sm-12">
        <p>Loading requests...</p>
    </div>
    <div class="row justify-content-center col-sm-12">
        <mat-spinner></mat-spinner>                               
    </div>
</div>
<generic-search-results *ngIf="isSearching === false"
    [showHeader]="false"
    [records]="searchResults"
    [showCounter]="false"
    [title]="''"
    [displayedColumns]="displayColumns"
    [selectableRecords]="true"
    [sortable]="true"
    [defaultSortColumn]="'createDate'"
    [defaultSortAsc]="false"
    (recordSelected)="selected($event)"
></generic-search-results>