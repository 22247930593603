<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        @if (createdMailbox === undefined)
        {
        <div class="col col-12">
            <h1>create shared mailbox account</h1>

            <form class="branded" [formGroup]="mailboxForm">

                <mat-error></mat-error>
                <fieldset>
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>mailbox account information</strong></p>
                    </legend>

                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                matTooltip="Display Name">
                                <mat-label>display name</mat-label>
                                <input matInput formControlName="displayName" required>
                                @if(mailboxForm.controls.displayName.errors?.required ?? false)
                                {
                                <mat-error>
                                    Display Name is required
                                </mat-error>
                                }

                                @if(mailboxForm.controls.displayName.errors?.maxlength ?? false)
                                {
                                <mat-error>
                                    Display Name must be no more than {{
                                    mailboxForm.controls.displayName.errors?.maxlength.requiredLength }}
                                    characters
                                </mat-error>
                                }

                                @if(mailboxForm.controls.displayName.errors?.pattern ?? false)
                                {
                                <mat-error>
                                    Display Name is invalid
                                </mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                matTooltip="Mail Nickname should be the first part of the email address (prior to the &#64; sign)">
                                <mat-label>mail nickname</mat-label>
                                <input matInput formControlName="mailNickname" required>
                                @if(mailboxForm.controls.mailNickname.errors?.required ?? false)
                                {
                                <mat-error>
                                    Mail Nickname is required
                                </mat-error>
                                }

                                @if(mailboxForm.controls.mailNickname.errors?.maxlength ?? false)
                                {
                                <mat-error>
                                    Mail Nickname must be no more than {{
                                    mailboxForm.controls.mailNickname.errors?.maxlength.requiredLength }}
                                    characters
                                </mat-error>
                                }

                                @if(mailboxForm.controls.mailNickname.errors?.pattern ?? false)
                                {
                                <mat-error>
                                    Mail Nickname is invalid
                                </mat-error>
                                }

                            </mat-form-field>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                matTooltip="Mail Domain should be the second part of the email address (after the &#64; sign)">
                                <mat-label>mail domain</mat-label>
                                <mat-select formControlName="mailDomain">
                                    <mat-option *ngFor="let mailDomain of mailDomains" [value]="mailDomain">{{
                                        mailDomain }}</mat-option>
                                </mat-select>
                                @if (mailboxForm.controls.mailDomain.errors?.required ?? false)
                                {
                                <mat-error>Mail Domain is required</mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                matTooltip="Business Purpose">
                                <mat-label>business purpose</mat-label>
                                <input matInput formControlName="businessPurpose" required>

                                @if(mailboxForm.controls.businessPurpose.errors?.required ?? false)
                                {
                                <mat-error>
                                    Business Purpose is required
                                </mat-error>
                                }

                                @if(mailboxForm.controls.businessPurpose.errors?.maxlength ?? false)
                                {
                                <mat-error>
                                    Business Purpose must be no more than {{
                                    mailboxForm.controls.businessPurpose.errors?.maxlength.requiredLength }}
                                    characters
                                </mat-error>
                                }
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                matTooltip="Steward">
                                <mat-label>steward</mat-label>
                                <input matInput [readonly]="true" formControlName="steward"
                                    (click)="searchForPrimaryUser('steward')" required>
                                <button mat-icon-button matSuffix (click)="searchForPrimaryUser('steward')">
                                    <mat-icon>person_search</mat-icon>
                                </button>

                                @if(mailboxForm.controls.steward.errors?.required ?? false)
                                {
                                <mat-error>
                                    Steward is required
                                </mat-error>
                                }
                            </mat-form-field>
                            <display-http-error [message]="stewardErrorMessage" [errors]="[]"></display-http-error>
                        </div>
                        <div class="col-12 col-md-6 col-lg-6">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                matTooltip="Delegate">
                                <mat-label>delegate</mat-label>
                                <input matInput [readonly]="true" formControlName="delegate"
                                    (click)="searchForPrimaryUser('delegate')" required>
                                <button mat-icon-button matSuffix (click)="searchForPrimaryUser('delegate')">
                                    <mat-icon>person_search</mat-icon>
                                </button>

                                @if(mailboxForm.controls.delegate.errors?.required ?? false)
                                {
                                <mat-error>
                                    Delegate is required
                                </mat-error>
                                }
                            </mat-form-field>
                            <display-http-error [message]="delegateErrorMessage" [errors]="[]"></display-http-error>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>chain of commands</strong></p>
                    </legend>
                    <div class="mb-4">
                        <button mat-flat-button color="primary" (click)="searchForPrimaryUser('chainOfCommand')"
                            class="add-button" type="button"><mat-icon>add</mat-icon> chain of command</button>
                        @if (mailboxForm.controls.chainOfCommands.length<1) { <mat-label class="ml-2">1
                            minimum</mat-label>
                            }
                    </div>

                    <display-http-error [message]="chainOfCommandErrorMessage" [errors]="[]"></display-http-error>
                    <div class="row" *ngFor="let chainOfCommand of chainOfCommands.value; let i=index">
                        <div class="col-12">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic">
                                <mat-label>chain of command</mat-label>
                                <input matInput [readonly]="true" [value]="chainOfCommand.displayName">
                                <button mat-icon-button matSuffix (click)="removeChainOfCommand(i)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                </fieldset>
                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                    @if (isCreating)
                    {
                    <mat-spinner [diameter]="30"
                        style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    }
                    @else
                    {
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="submit" (click)="onSubmit()">create</button>
                    </div>
                    <div class="btn-group mr-2" role="group">
                        <button class="button" type="button" [disabled]="isCreating"
                            (click)="resetForm()">cancel</button>
                    </div>
                    }
                </div>
                <display-http-error [message]="createErrorMessage.message"
                    [errors]="createErrorMessage.errors"></display-http-error>
            </form>
        </div>
        }
        @else
        {
        <div class="col col-12">
            <a class="close-button" (click)="closeRequest()"><span class="icon-remove close-button"></span></a>
            <h1>request submitted</h1>
            <display-requests [requests]="createdMailbox.requests"></display-requests>
        </div>
        }
    </section>
</main>