import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { SsSecret } from 'src/app/models/secretServer/SsSecret';
import { SecretServerService } from 'src/app/services/secretServer/secretServer.service';
import Swal from 'sweetalert2';
import { matTableSelectionHelper } from 'src/app/helpers/matTableSelectionHelper';

@Component({
  selector: 'app-delete-password',
  templateUrl: './delete-password.component.html',
  styleUrls: ['./delete-password.component.css']
})
export class DeletePasswordComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.secretsDataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.secretsDataSource.sort = value;
  }

  PageLayout = PageLayout;
  currentUserProfile: ICvxClaimsPrincipal;
  gettingSecrets: boolean = true;
  isSubmitting: boolean = false;
  submittedRequests: IWorkflowRequest[] = [];
  submitErrorMessage = { message: '', errors: [] };
  selection = new SelectionModel<SsSecret>(true, []);
  retireResults: string[] = [];

  secretsDataSource = new MatTableDataSource<SsSecret>([]);
  secretsDisplayedColumns: string[] = ['select', 'name', 'folder'];
  selectedSecretsDisplayedColumns: string[] = ['name', 'folder'];

  constructor(private secretService: SecretServerService) { }

  async ngOnInit(): Promise<void> {
    this.secretsDataSource.paginator = this.paginator
    this.secretsDataSource.sort = this.sort

    this.secretService.GetUserOwnedPasswords("").subscribe({
      next: (v) => {
        this.secretsDataSource.data = v.filter(s => s.isDeletable)
      },
      error: (e) => {
        console.error(e);
        this.gettingSecrets = false;
      },
      complete: () => this.gettingSecrets = false
    });
  }

  selectPermission(row: SsSecret) {
    this.selection.toggle(row);
  }

  public doFilterPermissions = (value: any) => {
    this.secretsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
  }
  async onSubmit() {

    // clear error messages
    this.submitErrorMessage.message = '';
    this.submitErrorMessage.errors = [];

    if (this.selection.selected.length > 0) {

      let messageText = 'passwords';

      if (this.selection.selected.length === 1) {
        messageText = 'password';
      }

      Swal.fire({
        icon: 'warning',
        iconColor: '#97002E',
        title: '<h3>are you sure?</h3>',
        html:
          `<p>You are about to delete ${this.selection.selected.length} ${messageText}, ` +
          "this operation can't be undone.</p>",
        showCancelButton: true,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'button',
          cancelButton: 'button',
        },
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.retireSecrets(this.selection.selected);
        }
      });
    }
  }

  async retireSecrets(secrets: SsSecret[]) {
    this.isSubmitting = true;

    const observer = {
      next: (x: IGenericApiResponseWithWorkflowRequest<SsSecret[]>) => {
        this.submittedRequests = x.requests;
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.isSubmitting = false;
      }
    };

    let requestCall = this.secretService.DeletePasswordAsync(secrets);
    requestCall.subscribe(observer);
  }

  //for checkbox select all use
  isAllSelected() {
    return matTableSelectionHelper.isAllSelected(this.selection, this.secretsDataSource);
  }
  masterToggle() {
    return matTableSelectionHelper.toggleSelectAll(this.selection, this.secretsDataSource);
  }

}
