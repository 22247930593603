import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDialogAppSearchData } from "src/app/models/components/IDialogAppSearchData";
import { IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";

@Component({
    selector: 'dialog-app-search',
    templateUrl: './dialog-app-search.component.html'
})

export class DialogAppSearchComponent {

    constructor(private dialogRef: MatDialogRef<DialogAppSearchComponent>, @Inject(MAT_DIALOG_DATA) public data:IDialogAppSearchData) { }

    appSelected(data:IGraphApplicationPermissions) {
        this.dialogRef.close(data);
    }

    searchCancelled() {
        this.dialogRef.close();
    }

}