import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import { CalAngularService } from "@cvx/cal-angular";
import { firstValueFrom, lastValueFrom, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppConfigService } from "./AppConfigService";

@Injectable({providedIn: 'root'})

/**
 * Idamp Http Client.  Used solely for hitting the IDAMP backend api, cannot be used for 
 * connecting to any other api becuase of the url base and token retrieval
 */
export class IdampHttpClient {

    private readonly urlBase:string;

    constructor(private http: HttpClient, private authService: CalAngularService, private appConfigService: AppConfigService) {
        this.urlBase = this.appConfigService.getConfig().idampApiBaseUrl;
    }

    public async GetAsync<T>(path:string,query?:any):Promise<T> {
        const token = await this.GetToken();
        const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const queryParams = { ...query };
        const url = `${this.urlBase}/${path}`;
        return await firstValueFrom(this.http.get<T>(url, { params: queryParams, headers }));
    }

    public async PostAsync<T,TResult>(path:string,payload:T,query?:any):Promise<TResult>{
        const token = await this.GetToken();
        const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const queryParams = { ...query };
        const url = `${this.urlBase}/${path}`;
        return await firstValueFrom(this.http.post<TResult>(url,payload, { params: queryParams, headers }));
    }

    public async PatchAsync<T,TResult>(path:string,payload:T,query?:any):Promise<TResult>{
        const token = await this.GetToken();
        const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const queryParams = { ...query };
        const url = `${this.urlBase}/${path}`;
        return await firstValueFrom(this.http.patch<TResult>(url,payload, { params: queryParams, headers }));
    }

    public async DeleteAsync<TResult>(path:string,query?:any):Promise<TResult>{
        const token = await this.GetToken();
        const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const queryParams = { ...query };
        const url = `${this.urlBase}/${path}`;        
        return await firstValueFrom(this.http.delete<TResult>(url, { params: queryParams, headers }));
    }

    public Get<T>(url:string,token:string,query?:any):Observable<T>{
        const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const queryParams = { ...query };
        return this.http.get<T>(url, { params: queryParams, headers });
    }

    public Post<T,TResult>(url:string,token:string,payload:T,query?:any):Observable<TResult>{
        const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const queryParams = { ...query };
        return this.http.post<TResult>(url,payload, { params: queryParams, headers });
    }

    public Patch<T,TResult>(url:string,token:string,payload:T,query?:any):Observable<TResult>{
        const headers = new HttpHeaders().append('Authorization', `Bearer ${token}`);
        const queryParams = { ...query };
        return this.http.patch<TResult>(url,payload, { params: queryParams, headers });
    }

    private async GetToken():Promise<string> {
        let token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));
        return token as string;
    }

}