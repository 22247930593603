import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { PageLayout } from '@cvx/nextpage';
import { IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { GraphApplicationRoleAssignmentDisplayModel, GraphApplicationRoleAssignmentModel } from "src/app/models/graph/applications/GraphApplicationRoleAssignment";
import { GraphApplicationRoleAssignmentRequestModel } from "src/app/models/graph/applications/GraphApplicationRoleAssignmentRequest";
import { PrincipalType } from "src/app/models/graph/applications/enums/AppRoleAssignment/PrincipalType";
import { IGraphAppExposedPermissions } from "src/app/models/graph/applications/GraphAppExposedPermissions";
import { DialogDirectorySearchComponent } from "../../_shared/dialog-directory-search/dialog-directory-search.component";
import { GroupMemberType } from "src/app/models/directory/enums/GroupMemberType.enum";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { DirectoryGroup } from "src/app/models/directory/groups/DirectoryGroup";
import { AadGroupSearchScenario } from "src/app/models/directory/enums/AadGroupSearchScenario.enum";
import { AppRoleAllowedMemberType } from "src/app/models/graph/applications/enums/AppRoleAllowedMemberType";
import { ErrorMessageConstants } from "src/app/constants/ErrorMessage.constants";
import Swal from "sweetalert2";

@Component({
    selector: 'app-assign-app-role',
    templateUrl: './assign-app-role.component.html',
    styleUrls: ['./assign-app-role.component.css']
})
export class AssignAppRoleComponent {

    private appRoleType: string = "microsoft.graph.appRole";

    readonly privilegedAppRoleRequestLink = "https://go.chevron.com/IAM-Elevate-PrivilegedAppRole";

    selectedDomain = DirectoryDomain.Chevron;

    showUpdateApplication: boolean = false;
    PageLayout = PageLayout;
    submitErrorMessage = { message: '', errors: [] };
    isLoading: boolean = false;
    isCreating: boolean = false;
    isCompleted: boolean = false;

    appRoleAllowedMemberType: AppRoleAllowedMemberType = AppRoleAllowedMemberType.User

    clientApp?: GraphApplication;
    resourceApp?: IGraphApplicationPermissions;
    newAdminAppRoleOptions: IGraphAppExposedPermissions[] = [];
    approleOptions: IGraphAppExposedPermissions[] = [];
    approleAssignmentsDisplayedColumns: string[] = ['appRole', 'group', 'isPrivileged'];
    approleAssignments: GraphApplicationRoleAssignmentDisplayModel[] = [];
    approleAssignmentsErrorMessage: string;

    selectedAppRole: IGraphAppExposedPermissions | undefined = undefined;
    selectedAppRoleIsAdmin: boolean = false;
    selectedGroup: DirectoryGroup | undefined = undefined;

    displayClientApp: any;
    displayResourceApp: any;

    submittedRequests: IWorkflowRequest[] = [];

    ownedApps: GraphApplication[] = [];

    constructor(
        private dialog: MatDialog,
        private graphApplicationService: GraphApplicationService
    ) { }

    clientAppSelected(data: IGraphApplicationPermissions) {

        this.clientApp = data.application;
        this.showUpdateApplication = Number.isNaN(parseInt(this.clientApp.serviceId))

        this.displayClientApp = {
            id: data.application.id,
            appId: data.application.appId,
            name: data.application.displayName,
            serviceId: this.showUpdateApplication ? ErrorMessageConstants.MissingData : data.application.serviceId,
            uri: data.application.identifierUris[0]
        };

        // check if the app has approles
        if (!this.clientApp.exposedPermissions.some(x => x.type == this.appRoleType)) {
            this.approleAssignmentsErrorMessage = `${this.clientApp.displayName} has no approles defined`
            this.approleOptions = [];
        }
        else {
            this.approleOptions = this.clientApp.exposedPermissions.filter(x => x.type == this.appRoleType).sort((role1, role2) => {
                if (role1.value > role2.value) {
                    return 1;
                }
                if (role1.value < role2.value) {
                    return -1;
                }
                return 0;
            });
        }
    }

    updatedApplications(data: GraphApplication[]) {
        this.displayClientApp.serviceId = data[0].serviceId;
        this.displayClientApp = { ...this.displayClientApp };
        this.clientApp = data[0];
        this.showUpdateApplication = false;
    }


    searchForGroup() {
        const dialogRef = this.dialog.open(DialogDirectorySearchComponent, {
            disableClose: true,
            autoFocus: true,
            maxWidth: 1000,
            width: '100%',
            data: {
                type: GroupMemberType.Group,
                domain: this.selectedDomain,
                aadGroupSearchScenario: AadGroupSearchScenario.AppRoleAssignable,
                filterGroupRemoveNotAvailableAsGroupMember: false,
                filterGroupRemoveDynamicMembershipEnabled: false,
                filterGroupOnlyManagedByIdamp: false,
                filterGroupRemoveNonAzureGroups: true,
            } as IDialogDirectoryData
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.selectedGroup = result;
            }
        });
    }

    updateAppRoleOptions(appRoleId: string, isPrivileged: boolean) {
        var updatedApproleOptions: IGraphAppExposedPermissions[] = [];
        this.approleOptions.forEach(x => {
            if (x.id == appRoleId) {
                x.isPrivileged = isPrivileged;
            }
            updatedApproleOptions.push(x);
        });
        this.approleOptions = updatedApproleOptions;

        if (isPrivileged) {
            this.newAdminAppRoleOptions.push(this.approleOptions.find(x => x.id == appRoleId)!);
        }
        else {
            this.newAdminAppRoleOptions = this.newAdminAppRoleOptions.filter(x => x.id != appRoleId);
        }
    }

    addNewAppRoleAssignment() {
        if (this.selectedGroup == undefined) {
            // show error message
            this.approleAssignmentsErrorMessage = "please select a group";
            return;
        }

        if (this.selectedAppRole == undefined) {
            // show error message
            this.approleAssignmentsErrorMessage = "please select an appRole";
            return;
        }

        if (this.approleAssignments.findIndex(o => o.appRoleId == this.selectedAppRole!.id && o.principalId == this.selectedGroup!.id) > -1) {
            // show error message
            this.approleAssignmentsErrorMessage = "the assignment has already been added";
            return;
        }

        // reset error message
        this.approleAssignmentsErrorMessage = "";

        if (this.selectedAppRoleIsAdmin && this.newAdminAppRoleOptions.find(x => x.id == this?.selectedAppRole?.id) == undefined) {

            // new so add it to the list
            const appRole = this.selectedAppRole;
            const group = this.selectedGroup;
            Swal.fire({
                icon: 'warning',
                iconColor: '#97002E',
                title: '<h3>Privileged Role</h3>',
                html:
                    `<p>You are about to mark this app role as privileged. Users of the group ${group.displayName} will NOT be granted this role by default.<br /><br />Instead they will need to request and elevate to the role ` +
                    "for a limited period of time by going to <a href='" + this.privilegedAppRoleRequestLink + "' target='_blank'>" + this.privilegedAppRoleRequestLink + "</a></p>",
                showCancelButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button',
                    cancelButton: 'button',
                },
            }).then((result: any) => {
                if (result.isConfirmed) {
                    appRole.isPrivileged = true;
                    this.updateAppRoleOptions(appRole.id, this.selectedAppRoleIsAdmin);

                    // update the list incase the user add a role and then marked it as privileged later
                    this.approleAssignments = [...this.approleAssignments.map(x => { x.isPrivileged = x.appRoleId == appRole.id ? appRole.isPrivileged : x.isPrivileged; return x; })
                        ,
                    new GraphApplicationRoleAssignmentDisplayModel(
                        PrincipalType.Group,
                        group.id,
                        this.clientApp!.servicePrincipalId,
                        appRole.id,
                        group.displayName,
                        appRole.value,
                        this.displayResourceApp,
                        this.selectedDomain,
                        appRole.isPrivileged)
                    ];
                }
            });
        }
        else {
            this.approleAssignments = [...this.approleAssignments,
            new GraphApplicationRoleAssignmentDisplayModel(
                PrincipalType.Group,
                this.selectedGroup.id,
                this.clientApp!.servicePrincipalId,
                this.selectedAppRole.id,
                this.selectedGroup.displayName,
                this.selectedAppRole.value,
                this.displayResourceApp,
                this.selectedDomain,
                this.selectedAppRole.isPrivileged)
            ];
        }
    }

    appRoleSelectionChanged(event: any) {
        this.selectedAppRole = event.value;
        this.selectedAppRoleIsAdmin = event.value.isAdminAppRole;
    }
    changedAppRoleIsAdmin(event: any) {
        this.selectedAppRoleIsAdmin = event.target.checked;
    }

    async onSubmit() {
        // clear error messages
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];

        if (this.clientApp !== undefined && this.approleAssignments.length > 0) {
            let payload = new GraphApplicationRoleAssignmentRequestModel(this.selectedDomain, this.approleAssignments as GraphApplicationRoleAssignmentModel[]);

            const observer = {
                next: (x: IGenericApiResponseWithWorkflowRequest<any>) => {
                    this.submittedRequests = x.requests;
                },
                error: (err: any) => {
                    this.submitErrorMessage.message = err.statusText;
                    this.submitErrorMessage.errors = err?.error?.errors ?? [];
                    this.isCreating = false;
                },
                complete: () => {
                    this.isCreating = false;
                    this.isCompleted = true;
                }
            };

            this.isCreating = true;
            let createCall = this.graphApplicationService.CreateApplicationRoleAssignment(payload);
            createCall.subscribe(observer);
        }
    }

    removeAssignment(assignment: GraphApplicationRoleAssignmentDisplayModel) {
        // remove from the list
        this.approleAssignments = this.approleAssignments.filter(s => s.principalId != assignment.principalId || s.appRoleId != assignment.appRoleId)

        if (this.newAdminAppRoleOptions.find(x => x.id == assignment.appRoleId) != undefined
            && this.approleAssignments.findIndex(x => x.appRoleId == assignment.appRoleId) == -1) {
            // it is new and hasn't been saved, so update the options to reflect the change
            this.updateAppRoleOptions(assignment.appRoleId, false);
        }
    }

    resetForm() {
        this.clientApp = undefined;
        this.displayClientApp = undefined;
        this.resourceApp = undefined;
        this.displayResourceApp = undefined;
        this.approleAssignments = [];
        this.approleAssignmentsErrorMessage = "";
        this.approleOptions = [];
        this.selectedGroup = undefined;
        this.selectedAppRole = undefined;
        this.selectedAppRoleIsAdmin = false;
        this.newAdminAppRoleOptions = [];
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];
    }
}