export class ChevronLocation
{
    public countryCode: string = ""
    public country: string = ""
    public buildingName: string = ""
    public id: string = ""
    public propertyId: string = ""
    public street: string = ""
    public city: string = ""      
    public facilityCode: string = ""
    public postalCode: string = ""
    public state: string = ""
}