<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row" *ngIf="submittedRequests.length === 0">
            <div class="col col-12">
                <form class="branded" [formGroup]="passwordForm">
                    <h1>update password permissions</h1>
                    <fieldset class="pb-4">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>password</strong></p>
                        </legend>
                        <div *ngIf="!gettingSecrets && secretsDataSource.data.length == 0">
                            no passwords owned by you were found
                        </div>
                        <div class="container" *ngIf="gettingSecrets">
                            <div class="row justify-content-center col-sm-12">
                                <p>searching for passwords...</p>
                            </div>
                            <div class="row justify-content-center col-sm-12">
                                <mat-spinner></mat-spinner>
                            </div>
                        </div>
                        <div class="container" *ngIf="!gettingSecrets && secretsDataSource.data.length > 0">
                            <filter-input (onKeyUp)="doFilterPermissions($event)"
                                matTooltip="Privilege passwords cannot be shared"></filter-input>

                            <table id="secrets" mat-table [dataSource]="secretsDataSource" matSort matSortActive="name"
                                matSortDirection="asc" aria-describedby="user owned secrets">

                                <caption>available passwords</caption>
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <input type="checkbox" (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()" />
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <input type="checkbox" name="formcheckbox" (click)="$event.stopPropagation()"
                                            (change)="selectPermission(row)" [checked]="selection.isSelected(row)" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                                    <td mat-cell *matCellDef="let secret">{{ secret.id }}</td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> name </th>
                                    <td mat-cell *matCellDef="let secret">{{ secret.name }}</td>
                                </ng-container>

                                <ng-container matColumnDef="folder">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> folder </th>
                                    <td mat-cell *matCellDef="let secret">
                                        {{ secret.folder }}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="secretsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: secretsDisplayedColumns;"></tr>

                            </table>

                            <mat-paginator [length]="secretsDataSource.data.length"
                                [pageSizeOptions]="[5, 10, 20, 30, 40]" showFirstLastButtons>
                            </mat-paginator>
                        </div>
                    </fieldset>

                    <ng-container *ngIf="secretsDataSource.data.length > 0">
                        <fieldset class="pb-4" *ngIf="!gettingSecrets">
                            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                <p><strong>selected passwords to update permissions</strong></p>
                            </legend>
                            <generic-search-results [showHeader]="false" [records]="selection.selected"
                                [showCounter]="false" [title]="''" [caption]="'passwords to apply permissions'"
                                [displayedColumns]="selectedSecretsDisplayedColumns" [selectableRecords]="false"
                                [deletableRecords]="true" [showPagination]="false" [allowLocalFilter]="false"
                                (recordDeleted)="selectPermission($event)">
                            </generic-search-results>
                        </fieldset>

                        <fieldset>
                            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                <p><strong>owners</strong></p>
                            </legend>
                            <div style="margin-bottom: 10px;">
                                <button mat-flat-button color="primary" (click)="searchForPrimaryUser('owners')"
                                    class="add-button" type="button"><mat-icon>add</mat-icon> owner</button>
                            </div>
                            <display-http-error [message]="ownersErrorMessage" [errors]="[]"></display-http-error>
                            <div class="row" formGroupName="owners" *ngFor="let owner of owners.controls; let i=index">
                                <div class="col-12">
                                    <mat-form-field appearance="outline" class="w-100" [formGroupName]="i"
                                        subscriptSizing="dynamic">
                                        <mat-label>owner</mat-label>
                                        <input matInput formControlName="displayName" [readonly]="true">
                                        <button mat-icon-button matSuffix (click)="removeOwner(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                <p><strong>viewers</strong></p>
                            </legend>
                            <div style="margin-bottom: 10px;">
                                <button mat-flat-button color="primary" (click)="searchForPrimaryUser('viewers')"
                                    class="add-button" type="button"><mat-icon>add</mat-icon> viewer</button>
                            </div>
                            <display-http-error [message]="viewersErrorMessage" [errors]="[]"></display-http-error>
                            <div class="row" formGroupName="viewers"
                                *ngFor="let viewer of viewers.controls; let i=index">
                                <div class="col-12">
                                    <mat-form-field appearance="outline" class="w-100" [formGroupName]="i"
                                        subscriptSizing="dynamic">
                                        <mat-label>viewer</mat-label>
                                        <input matInput formControlName="displayName" [readonly]="true">
                                        <button mat-icon-button matSuffix (click)="removeViewer(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                <p><strong>revoke access</strong></p>
                            </legend>
                            <div style="margin-bottom: 10px;">
                                <button mat-flat-button color="primary" (click)="searchForPrimaryUser('revoke')"
                                    class="add-button" type="button"><mat-icon>add</mat-icon> revoke</button>
                            </div>
                            <display-http-error [message]="revokeErrorMessage" [errors]="[]"></display-http-error>
                            <div class="row" formGroupName="revokeUsers"
                                *ngFor="let revoke of revokeUsers.controls; let i=index">
                                <div class="col-12">
                                    <mat-form-field appearance="outline" class="w-100" [formGroupName]="i"
                                        subscriptSizing="dynamic">
                                        <mat-label>revoke access</mat-label>
                                        <input matInput formControlName="displayName" [readonly]="true">
                                        <button mat-icon-button matSuffix (click)="removeRevokeUsers(i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </fieldset>

                        <mat-divider></mat-divider>
                    </ng-container>
                </form>

                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>

                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="!gettingSecrets">
                    <mat-spinner [diameter]="30" *ngIf="isSubmitting"
                        style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    <div class="btn-group mr-2" role="group"
                        *ngIf="!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0 && (owners.length > 0 || viewers.length > 0 || revokeUsers.length > 0)">
                        <button class="button" type="submit" (click)="onSubmit()">submit</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="submittedRequests.length > 0">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/passwords/update-permissions']"><span
                        class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>
    </section>
</main>