import { IGraphUser } from "../../graphUser";
import { IGraphAppExposedPermissions } from "./GraphAppExposedPermissions";

/**
 * API interface for
 *   GET: /api/applications
 *   GET: /api/applications/{id}
 */
export interface IGraphApplication {
    id: string;
    appId: string;
    serviceId: string;
    servicePrincipalId: string;
    displayName: string;
    identifierUris: string[];
    owners: IGraphUser[];
    exposedPermissions: IGraphAppExposedPermissions[];
    numberOfExposedPermissions: number;
}

/**
 * API model for
 *   GET: /api/applications
 *   GET: /api/applications/{id}
 */
export class GraphApplication implements IGraphApplication {
    id: string = '';
    appId: string = '';
    serviceId: string = '';
    servicePrincipalId: string = '';
    displayName: string = '';
    identifierUris: string[] = [];
    owners: IGraphUser[] = [];
    exposedPermissions: IGraphAppExposedPermissions[] = [];
    numberOfExposedPermissions: number = 0;
}