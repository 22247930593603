<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row"  *ngIf="submittedRequests.length === 0">
            <div class="col col-12">
                <form class="branded">
                    <h1>request password permissions</h1>
                    <fieldset class="pb-4">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>password</strong></p></legend>
                        <div class="container" *ngIf="gettingSecrets">
                            <div class="row justify-content-center col-sm-12">
                                <p>searching for passwords...</p>                               
                            </div>
                            <div class="row justify-content-center col-sm-12">
                                <mat-spinner></mat-spinner>                               
                            </div>                                
                        </div>
                        <div class="container" *ngIf="!gettingSecrets">
                            <div class="d-flex flex-row">
                                <mat-form-field appearance="outline" class="w-50"
                                subscriptSizing="dynamic">
                                    <mat-label class="no-brand">search</mat-label>
                                    <mat-icon matPrefix>search</mat-icon>                                    
                                    <input matInput type="text"
                                    [matTooltip]="searchTermToolTip" 
                                    matTooltipPosition="above"
                                    [(ngModel)]="searchTerm"
                                    [ngModelOptions]="{standalone: true}"
                                    (keyup)="doFilterPermissions($event)"
                                    (keydown.enter)="$event.preventDefault()" 
                                    style="border:none; box-shadow:none; padding: 0px;">
                                </mat-form-field>
                            </div>
                        
                            <table id="secrets" 
                            mat-table #table
                            [dataSource]="secretsDataSource" 
                            aria-describedby="requestable passwords" >
                        
                                <caption>requestable passwords</caption>
                        
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef> id </th>
                                    <td mat-cell *matCellDef="let secret">{{ secret.id }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef> name </th>
                                    <td mat-cell *matCellDef="let secret">{{ secret.name }}</td>
                                </ng-container>
                        
                                <ng-container matColumnDef="folder">
                                    <th mat-header-cell *matHeaderCellDef> folder </th>
                                    <td mat-cell *matCellDef="let secret">
                                        {{ secret.folder }}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="selectPermission">
                                    <th mat-header-cell class="mat-header-center" *matHeaderCellDef>permission</th>
                                    <td mat-cell class="mat-cell-center" *matCellDef="let row">
                                        <mat-form-field appearance="outline" class="w-50 margin-top-5"
                                        subscriptSizing="dynamic">
                                            <mat-label>role</mat-label>
                                            <mat-select (selectionChange)="roleChanged($event)">
                                                <mat-option *ngFor="let role of requestableRoles" [value]="{role: role, secret: row}">{{ role }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </ng-container>
                        
                                <tr mat-header-row *matHeaderRowDef="secretsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: secretsDisplayedColumns;"></tr>
                        
                            </table>
                        
                            <mat-paginator #paginator 
                                [length]="currentPagedResponse?.totalItems"
                                [pageIndex]="(currentPagedResponse?.page ?? 1) - 1" 
                                [pageSizeOptions]="pageSizeOptions" 
                                (page)="pageChanged($event)"
                                showFirstLastButtons></mat-paginator>
                        </div>
                    </fieldset>

                    <fieldset class="pb-4">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>selected passwords to request owner permission</strong></p></legend>
                        <generic-search-results 
                        [showHeader]="false" 
                        [records]="requestOwner" 
                        [showCounter]="false"
                        [title]="''" 
                        [caption]="'passwords to apply permissions'"
                        [displayedColumns]="selectedSecretsDisplayedColumns" 
                        [selectableRecords]="false"
                        [deletableRecords]="true"
                        [showPagination]="false"
                        [allowLocalFilter]="false"
                        (recordDeleted)="removeOwner($event)">
                        </generic-search-results>
                    </fieldset>

                    <fieldset class="pb-4">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>selected passwords to request viewer permission</strong></p></legend>
                        <generic-search-results 
                        [showHeader]="false" 
                        [records]="requestViewer"
                        [showCounter]="false"
                        [title]="''" 
                        [caption]="'passwords to apply permissions'"
                        [displayedColumns]="selectedSecretsDisplayedColumns" 
                        [selectableRecords]="false"
                        [deletableRecords]="true"
                        [showPagination]="false"
                        [allowLocalFilter]="false"
                        (recordDeleted)="removeViewer($event)">
                        </generic-search-results>
                    </fieldset>

                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>justification</strong></p></legend>
                        <div class="row">
                            <div class="col-12">
                                <mat-form-field appearance="outline" class="w-100"  matTooltip="Justification for permissions request"
                                subscriptSizing="dynamic">
                                    <mat-label>justification</mat-label>
                                    <input matInput [formControl]="justification">
                                    <mat-error>Justification is required</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </fieldset>

                    <mat-divider></mat-divider>
                </form>
                
                <display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>

                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="!gettingSecrets">
                    <mat-spinner [diameter]="30" *ngIf="isSubmitting" style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    <div class="btn-group mr-2" role="group" *ngIf="!isSubmitting && submittedRequests.length == 0 && (requestOwner.length > 0 || requestViewer.length > 0) && justification.valid">
                        <button class="button" type="submit" (click)="onSubmit()">submit</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="submittedRequests.length > 0">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/passwords/request-permissions']" ><span class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>
    </section>
</main>