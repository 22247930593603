import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-pop-up-box',
  templateUrl: './dialog-pop-up-box.component.html',
  styleUrls: ['./dialog-pop-up-box.component.css']
})
export class DialogPopUpBoxComponent {
  message: string;
  button1: string;
  button2: string;
  constructor(private dialogRef: MatDialogRef<DialogPopUpBoxComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message || "";
    this.button1 = data.button1 || "close";
    this.button2 = data.button2 || '';
   }

}
