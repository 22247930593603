import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { DataFormatter } from "src/app/services/common/DataFormatter";
import { matTableSelectionHelper } from 'src/app/helpers/matTableSelectionHelper';
import { SelectionModel } from '@angular/cdk/collections';
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";

@Component({
    selector: 'generic-search-results',
    templateUrl: './generic-search-results.component.html',
    styleUrls: ['./generic-search-results.component.css']
})

export class GenericSearchResultsComponent<TData> implements OnInit, OnChanges, AfterViewInit {
    @Input() showHeader: boolean = false;
    @Input() headerText: string = 'search results';
    @Input() showCounter: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() title: string = 'records found';
    @Input() records: TData[] = [];
    @Input() displayedColumns: string[] = [];
    @Input() selectableRecords: boolean = true;
    @Input() deletableRecords: boolean = false;
    @Input() sortable: boolean = false;
    @Input() defaultSortColumn: string = '';
    @Input() defaultSortAsc: boolean = true;
    @Input() allowLocalFilter: boolean = true;
    @Input() emitRecordsChecked: boolean = false;
    @Input() caption: string = this.title;
    @Input() makeSticky: boolean = false;
    @Input() showPagination: boolean = true;
    @Input() isDialog: boolean = false;

    @Output() recordSelected = new EventEmitter<TData>();
    @Output() recordsChecked = new EventEmitter<TData[]>();
    @Output() recordDeleted = new EventEmitter<TData>();
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    @ViewChild('sortableTable', { static: false }) sortableTable!: MatTable<TData>;
    @ViewChild('unSortableTable', { static: false }) unSortableTable!: MatTable<TData>;

    copiedToClipboardId = -1;
    cols: string[];
    dataSource = new MatTableDataSource<TData>();
    defaultSortDirection: string;

    @ViewChild(MatSort) sort: MatSort;
    selection = new SelectionModel<DirectoryObjectBase>(true, []);
    selectedItems: any[] = [];


    constructor(private formatter: DataFormatter) {
    }

    ngOnInit(): void {

        this.dataSource.data = this.records;

        this.cols = [...this.displayedColumns];
        if (this.deletableRecords) {
            this.cols.push('del');
        }

        if (this.sortable) {
            this.defaultSortColumn = this.defaultSortColumn != '' ? this.defaultSortColumn : this.displayedColumns[0];
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            if (this.sortable) {
                this.sort.sort(({ id: this.defaultSortColumn, start: this.defaultSortAsc ? 'asc' : 'desc' }) as MatSortable);
                this.dataSource.sort = this.sort;
            }
            this.dataSource.paginator = this.paginator;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.dataSource.data = [...changes.records.currentValue];

        if (this.sortable && this.sortableTable != undefined) {
            this.dataSource = new MatTableDataSource<TData>(this.dataSource.data)
            this.sortableTable.dataSource = this.dataSource
            this.sortableTable.renderRows();
        }
        else if (this.unSortableTable != undefined) {
            this.dataSource = new MatTableDataSource<TData>(this.dataSource.data)
            this.unSortableTable.dataSource = this.dataSource
            this.unSortableTable.renderRows();
        }
    }

    selectRow(selected: TData) {
        if (this.selectableRecords) {
            this.recordSelected.emit(selected);
        }
    }

    deleteRow(selected: TData) {
        if (this.deletableRecords) {
            this.recordDeleted.emit(selected);
        }
    }

    public doFilter = (value: any) => {
        this.dataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    public onClipboardCopy(successful: boolean, id: number): void {
        if (successful) this.copiedToClipboardId = id;
    }

    public resetClipBoardStatus(): void {
        this.copiedToClipboardId = -1;
    }

    //for checkbox select all use
    isAllSelected() {
        return matTableSelectionHelper.isAllSelected(this.selection, this.dataSource);
    }
    masterToggle() {
        return matTableSelectionHelper.toggleSelectAll(this.selection, this.dataSource);
    }

    //used for checkbox, if it is checkall, set isAll=true
    onCheckboxChange(items: any | any[], event: any, isAll: boolean = false): void {
        if (Array.isArray(items)) {
            this.selectedItems = event.target.checked
                ? [...this.selectedItems, ...items]
                : this.selectedItems.filter(x => !items.includes(x));
        } else {
            this.selectedItems = event.target.checked
                ? [...this.selectedItems, items]
                : this.selectedItems.filter(i => i !== items);
        }

        if (isAll) {
            this.masterToggle();
        }
    }


    //send data back to parent component, sending multiple items selected
    emitSelectedItems() {
        this.recordsChecked.emit(this.selectedItems);
    }

}