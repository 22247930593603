import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPopUpBoxComponent } from '../../components/_shared/dialog-pop-up-box/dialog-pop-up-box.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  // icon list here: https://fonts.google.com/icons?selected=Material+Icons

  public OpenPopUpDialog(icon: string, message: string) {

    this.dialog.open(DialogPopUpBoxComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 500,
      width: '100%',
      data: {
        icon: icon,
        message: message
      },
    });

  }

  public OpenPopUpDialogWithColor(icon: string, color: string, message: string) {

    this.dialog.open(DialogPopUpBoxComponent, {
      disableClose: true,
      autoFocus: true,
      maxWidth: 500,
      width: '100%',
      data: {
        icon: icon,
        color: color,
        message: message
      },
    });

  }
}
