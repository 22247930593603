<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12">

                @if (!isCompleted) {
                    <h1>delete client secret</h1>
                    <!-- fieldset for displaying app selection / selected app -->
                    <fieldset class="pb-3">
                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white"><p><strong>client application</strong></p></legend>
                        @if (clientApp === undefined){
                            <app-search 
                                [ownedApps]="true" 
                                [searchPermissions]="false" 
                                [showTitle]="true"
                                (appSelected)="clientAppSelected($event)">
                            </app-search>
                        }
                        
                        @else {
                            <display-form [displayObject]="displayClientApp"></display-form>
                        }
                        
                    </fieldset>

                    <mat-divider></mat-divider>

                    @if (displayClientApp !== undefined) {

                        <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                            <p><strong>client secrets</strong></p>
                        </legend>

                        @if (!gettingSecrets && clientSecretsDataSource.data.length == 0) {
                            <div>
                                <display-http-error [message]="clientSecretsErrorMessage" [errors]="[]"></display-http-error>
                            </div>
                        }
                            
                        @else if (gettingSecrets) {
                            <div class="container">
                                <div class="row justify-content-center col-sm-12">
                                    <p>searching for client secrets...</p>
                                </div>
                                <div class="row justify-content-center col-sm-12">
                                    <mat-spinner></mat-spinner>
                                </div>
                            </div>
                        }
                            
                        @else {
                            <!-- fieldset for displaying client secret selection -->
                            <fieldset class="pb-4">
                                <div class="container">
                                    <form class="branded">
                                        <filter-input (onKeyUp)="filter($event)"></filter-input>
            
                                        <table id="client secrets" 
                                        [hidden]="gettingSecrets || clientSecretsDataSource.data.length == 0" 
                                        mat-table 
                                        [dataSource]="clientSecretsDataSource" 
                                        matSort 
                                        matSortActive="displayName"
                                        matSortDirection="asc" 
                                        aria-describedby="users you have access to">
            
                                            <caption>client secrets that can be deleted</caption>
            
                                            <ng-container matColumnDef="select">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                                                <td mat-cell *matCellDef="let row">
                                                    <input MatCheckbox type="checkbox" name="formcheckbox"
                                                        (click)="$event.stopPropagation()" (change)="select(row)"
                                                        [checked]="selection.isSelected(row)" />
            
                                                </td>
                                            </ng-container>
                                            
                                            @for (disCol of selectedDisplayCols; track disCol)
                                            {
                                                <div matColumnDef="{{disCol}}">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol | convertDisplayLabels}}</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        <text-formatter [content]="element[disCol]"></text-formatter>
                                                    </td>
                                                </div>
                                            }
                                            
            
                                            <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
            
                                        </table>
                                        
                                        <mat-paginator [length]="clientSecretsDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]" showFirstLastButtons></mat-paginator>
                                        
                                    </form>
                                </div>
                            </fieldset>

                            <!-- fieldset for displaying selected client secrets -->
                            <fieldset class="pb-4">
                                <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                                    <p><strong>selected client secrets to delete</strong></p>
                                </legend>
                                <generic-search-results [showHeader]="false" [records]="selection.selected" [showCounter]="false"
                                    [title]="''" caption="client secrets to delete"
                                    [displayedColumns]="selectedDisplayCols" [selectableRecords]="false" [deletableRecords]="true"
                                    [showPagination]="false" [allowLocalFilter]="false" (recordDeleted)="select($event)">
                                </generic-search-results>
                            </fieldset>

                            <display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>
                        }

                        <!-- buttons for controlling form  -->
                        <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
                            @if(!isDeleting && selection.selected.length > 0 && submittedRequests.length == 0) {
                                <div class="btn-group mr-2" role="group">
                                    <button class="button" type="submit" (click)="onSubmit()">delete</button>
                                </div>
                            }
                            @if (!isDeleting && !gettingSecrets) {
                                <div class="btn-group mr-2" role="group">
                                    <button class="button" type="button" (click)="resetForm()">reset</button>                                 
                                </div>
                            }
                            @else if(isDeleting) {
                                <mat-spinner [diameter]="30"></mat-spinner>
                            }
                            
                        </div>
                    }
                }
                
                @else {
                    <a class="close-button" [routerLink]="['/applications/delete-client-secret']" ><span class="icon-remove close-button"></span></a>
                    <h1>request submitted</h1>
                    <display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>
                    <display-requests [requests]="submittedRequests"></display-requests>
                }

            </div>
        </div>
    </section>
</main>