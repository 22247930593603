import { CocoUserTitle } from "./CocoUserTitle.enum";

/**
 * POST payload for endpoint /api/users/{domain}/createCoco
 */
export class CocoUserRequest {
    steward: string;
    mailNickname: string;
    stationNumber: string;
    givenName: string;
    surname: string;
    mobilePhone: string;
    telephoneNumber: string;
    title: CocoUserTitle;
}