<main class="container">
  <!-- <section> -->
  <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
    @if(invitedGuests === undefined){
    <div class="row">
      <div class="col col-12">
        <h1>invite guest</h1>
        @if(!hasPermission){
        <h5 class="text-center text-danger">
          Only Chevron employees and authorized users can be guest sponsors.
        </h5>}

        @if(hasPermission){
        <form [formGroup]="inviteUserForm" class="branded text-left" novalidate (ngSubmit)="onSubmit(inviteUserForm)">
          <mat-error></mat-error>
          <fieldset>
            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
              <p><strong>guest information</strong></p>
            </legend>
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <p style="margin: 0px">
                  <a target="_blank" rel="noopener" href="https://go.chevron.com/idampGuestSponsorResponsibilities">
                    Guest Sponsor Roles and Responsibilities
                  </a>
                </p>
                <div id="sponsorContainer">
                  <input id="accept" type="checkbox" name="responsibilitiesBox" formControlName="responsibilitiesBox"
                    class="form-control" />
                  <span id="acceptStatement" class="text-left">
                    I Accept the Roles and Responsibilities as sponsor for
                    this guest*</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline" class="w-100" matTooltip="Company Name" subscriptSizing="dynamic">
                  <mat-label>company name</mat-label>
                  <input name="companyName" formControlName="companyName" maxlength="64" matInput required
                     />
                  @if(inviteUserForm.controls.companyName.errors?.pattern ?? false){
                  <mat-error>
                    Company Name is invalid
                  </mat-error>}
                  @if(inviteUserForm.controls.companyName.errors?.required ?? false){
                  <mat-error>
                    Company Name is required
                  </mat-error>}
                </mat-form-field>
              </div>
              <div class="col-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline" class="w-100"
                  matTooltip="Permanent Legal Residence is the country where the person has a right to work without requirements for additional authorization such as a work visa or other temporary authorization. This country may be different from work location or where services are provided. Do not ask for citizenship."
                  subscriptSizing="dynamic">
                  <mat-label>permanent legal residence</mat-label>
                  <input name="residence_name" matInput required [formControl]="$any(inviteUserForm.get('residence_name'))"
                    [matAutocomplete]="auto" />
                  <input type="hidden" name="locationName" formControlName="locationName" />
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                    (optionSelected)="onValueSelected($event.option.value)">
                    <mat-option *ngFor="let address of filterOptions | async" [value]="address">{{ address.name
                      }}</mat-option>
                  </mat-autocomplete>
                  @if(inviteUserForm.controls.residence_name.errors?.required ?? false){
                  <mat-error>
                    Permanent Legal Residence is required
                  </mat-error>}
                  @if(inviteUserForm.controls.residence_name.errors?.pattern ?? false){
                  <mat-error>
                    Permanent Legal Residence is invalid
                  </mat-error>}
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100" matTooltip="Application URL"
                  subscriptSizing="dynamic">
                  <mat-label>application url</mat-label>
                  <input matInput formControlName="inviteRedirectUrl" />
                  @if(inviteUserForm.controls.inviteRedirectUrl.errors?.pattern ?? false){
                  <mat-error>
                    Application URL is invalid
                  </mat-error>}
                  @if(inviteUserForm.controls['inviteRedirectUrl'].hasError('maxlength')){
                  <mat-error>
                    Application URL must be no more than
                    {{
                    inviteUserForm.controls.inviteRedirectUrl.errors
                    ?.maxlength.requiredLength
                    }}
                    characters
                  </mat-error>}
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
              <p><strong>groups</strong></p>
            </legend>
            <div style="margin-bottom: 10px">
              <button mat-flat-button color="primary" (click)="searchForGroups()" class="add-button" type="button">
                <mat-icon>add</mat-icon> group
              </button>
            </div>

            <div class="row" formGroupName="groups" *ngFor="let group of groups.controls; let i = index">
              <div class="col-12">
                <mat-form-field appearance="outline" class="w-100" [formGroupName]="i" subscriptSizing="dynamic">
                  <mat-label>group</mat-label>
                  <input matInput formControlName="displayName" [readonly]="true" />
                  <button mat-icon-button matSuffix (click)="removeGroups(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>
          </fieldset>

          <fieldset>
            <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
              <p><strong>guest contact details</strong></p>
            </legend>
            <div class="mb-3">
              <button mat-flat-button color="primary" (click)="onAddGuest()" class="add-button" type="button">
                <mat-icon>add</mat-icon> guest
              </button>
              <button mat-flat-button color="primary" (click)="onBatchAddGuests()" class="add-button"
                style="margin-left: 15px" type="button">
                <mat-icon>add</mat-icon> batch add guests
              </button>
            </div>

            <div formGroupName="guests" *ngFor="let guest of guests.controls; let i = index">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="First Name" [formGroupName]="i"
                    subscriptSizing="dynamic">
                    <mat-label>first name</mat-label>
                    <input matInput formControlName="givenName" required />
                    @if(guest.get('givenName')?.errors?.required ?? false){
                    <mat-error>
                      First Name is required
                    </mat-error>}
                    @if(guest.get('givenName')?.errors?.pattern ?? false){
                    <mat-error>
                      First Name is invalid
                    </mat-error>}
                    @if(guest.get('givenName')?.hasError('maxlength')){
                    <mat-error>
                      First Name must be no more than {{
                      guest.get('givenName')?.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>}
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <mat-form-field appearance="outline" class="w-100" matTooltip="Last Name" [formGroupName]="i"
                    subscriptSizing="dynamic">
                    <mat-label>last name</mat-label>
                    <input matInput formControlName="surname" required />
                    @if(guest.get('surname')?.errors?.required ?? false){
                    <mat-error>
                      Last Name is required
                    </mat-error>}
                    @if(guest.get('surname')?.errors?.pattern ?? false){
                    <mat-error>
                      Last Name is invalid
                    </mat-error>}
                    @if(guest.get('surname')?.hasError('maxlength')){
                    <mat-error>
                      Last Name must be no more than {{
                      guest.get('surname')?.errors?.maxlength.requiredLength }}
                      characters
                    </mat-error>}
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <mat-form-field appearance="outline" class="w-100"
                    matTooltip="company authorized email address registered with the Microsoft account"
                    [formGroupName]="i" subscriptSizing="dynamic">
                    <mat-label>email</mat-label>
                    <input matInput formControlName="emailId" required />
                    @if(guest.get('emailId')?.errors?.required ?? false){
                    <mat-error>
                      Email is required
                    </mat-error>}
                    @if(guest.get('emailId')?.errors?.pattern ?? false){
                    <mat-error>
                      Email is invalid
                    </mat-error>
                    }
                    @if(guest.get('emailId')?.hasError('maxlength')){
                    <mat-error>
                      Email must be no more than {{ guest.get('emailId')?.errors?.maxlength.requiredLength
                      }}
                      characters
                    </mat-error>}
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <mat-icon class="mr-0 mt-2 mb-2 float-right" (click)="onRemoveGuest(i)">delete</mat-icon>
                </div>
              </div>

              <hr class="mb-3 mt-0" />
            </div>
            @if(isFormSubmitted && guests?.value?.length===0){
            <mat-error>
              Requires at least one guest
            </mat-error>
            }
          </fieldset>

          <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
            @if(isInviteProcessActive){
            <mat-spinner [diameter]="30"></mat-spinner>
            }
            @if(!isInviteProcessActive){
            <div class="btn-group mr-2" role="group">
              <button class="button" type="submit">invite</button>
            </div>
            <div class="btn-group mr-2" role="group">
              <button class="button" type="button" [disabled]="isInviteProcessActive" (click)="resetForm()">
                cancel
              </button>
            </div>}
          </div>

          <display-http-error [message]="createErrorMessage.message"
            [errors]="createErrorMessage.errors"></display-http-error>
        </form>
        }
      </div>
    </div>
    }
    @if(invitedGuests !== undefined){
    <div class="row">
      <div class="col col-12">
        <a class="close-button" (click)="resetForm(false)"><span class="icon-remove close-button"></span></a>
        <h1>guests invited</h1>

        <div *ngFor="let guest of displayInvitedGuests;">
          <display-form [displayObject]="guest"></display-form>
          <hr class="mt-3 mb-3" />
        </div>
        <display-requests [requests]="invitedGuests.requests"></display-requests>
      </div>
    </div>}
  </section>
</main>