import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CalAngularService } from "@cvx/cal-angular";
import { IDefaultApiResponse } from "../../models/defaultApiResponse";
import { AppConfigService } from "../common/AppConfigService";

@Injectable({
    providedIn: 'root'
})

export class CampService {

    readonly primaryUserApiBaseUrl: string;

    constructor(private authService: CalAngularService, private http: HttpClient, private configService: AppConfigService) {
        const config = configService.getConfig();
        this.primaryUserApiBaseUrl = config?.primaryUserApiBaseUrl || ''
    }

    public GetCampEntity(searchParams: string, token: any) {

        const fullApiEndPointUrl = `${this.primaryUserApiBaseUrl}/v1/camp?${searchParams}`;
        const headers = new HttpHeaders().append("Authorization", `Bearer ${token}`)

        return this.http.get<IDefaultApiResponse>(fullApiEndPointUrl, { headers: headers });
    }
}