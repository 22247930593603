import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CalAngularService } from '@cvx/cal-angular';
import { lastValueFrom } from 'rxjs';
import { countries } from 'src/app/data/country-data-store';
import { IDefaultApiResponse } from 'src/app/models/defaultApiResponse';
import { ChevronLocation } from 'src/app/models/locationModel';
import { LocationService } from 'src/app/services/locationService/locationService';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-location-search',
  templateUrl: './dialog-location-search.component.html',
  styleUrls: ['./dialog-location-search.component.css']
})
export class DialogLocationSearchComponent {

  @ViewChild('searchResultsPaginator') paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  dataSource = new MatTableDataSource<ChevronLocation>();
  displayedColumns: string[] = ['facilityCode', 'buildingName', 'city', 'country'];
  SearchResults: ChevronLocation[] = []
  screenMessage: string = ""
  IsSearching: boolean = false;

  public countries:any = countries.filter(item => (item.name !== "United States"));

  searchform = new UntypedFormGroup({
    name: new UntypedFormControl('', []),
    code: new UntypedFormControl('', []),
    country: new UntypedFormControl('', []),
    city: new UntypedFormControl('', [])

  });

  constructor(private authService: CalAngularService, private locationService: LocationService, private dialogRef: MatDialogRef<DialogLocationSearchComponent>) { }

  async search() {

    this.screenMessage = ""

    const name = this.searchform.get('name') as UntypedFormControl
    const code = this.searchform.get('code') as UntypedFormControl
    const country = this.searchform.get('country') as UntypedFormControl
    const city = this.searchform.get('city') as UntypedFormControl

    if (name.value === "" && code.value === "" && 
    country.value === "" && city.value === "") {

      this.screenMessage = "You have not entered any search parameters.";
      return
    }
    
    if (name.invalid || code.invalid || 
      country.invalid || city.invalid) {

        this.screenMessage = "You have entered invalid search parameters.";
        return
    } 
    
    this.IsSearching = true;
    let token = await lastValueFrom(this.authService.getAADToken(environment.tokenScopes));

    let searchParams = [];

    if (name.value !== "" && name.value !== null) {
      searchParams.push(`name=${name.value}`);
    }

    if (code.value !== "" && code.value !== null) {
      searchParams.push(`facilityCode=${code.value}`);
    }

    if (country.value !== "" && country.value !== null) {
      searchParams.push(`country=${country.value}`);
    }

    if (city.value !== "" && city.value !== null) {
      searchParams.push(`city=${city.value}`);
    }

    const observer = {
      next: (x: IDefaultApiResponse) => this.SearchResults = x.data as ChevronLocation[],
      error: (err: any) => {

        if (err.error.message !== undefined) {
          this.screenMessage = err.error.message;
        } else {
          this.screenMessage = err.message;
        }

        this.IsSearching = false;
      },
      complete: () =>  {
        this.dataSource.data = this.SearchResults;
        this.dataSource.data = this.SearchResults; 
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.IsSearching = false;  
      }
    };

    let apiCall = this.locationService.GetLocation(searchParams.join("&"), token);
    if(apiCall)
      apiCall.subscribe(observer);    
  }

  back() {
    this.SearchResults = [];
  }

  reset () {
    this.searchform.get('name')?.reset();
    this.searchform.get('code')?.reset();
    this.searchform.get('country')?.reset();
    this.searchform.get('city')?.reset();
   }

   selectRow(selected: ChevronLocation) {
    this.dialogRef.close(selected);
  }

  //setter method for dialogRef used for unit testing
  setDialogRef(dialogRef: MatDialogRef<DialogLocationSearchComponent>){
    this.dialogRef = dialogRef;
   }
}
