@if(!isSearching && searchResults.length === 0)
{
<!-- search form -->
<form class="branded">
    <div class="row">
        <div class="col col-12 col-sm-6 col-lg-6">
            <mat-form-field appearance="outline" [class]="isDialog ? 'w-100 padTop5' : 'w-100'"
                subscriptSizing="dynamic">
                <mat-label>service id or name</mat-label>
                <input matInput [formControl]="searchTerm" (keydown.enter)="search()" />
            </mat-form-field>
        </div>
    </div>
</form>
}

@if(screenMessage !== '')
{
<!-- display any error messages -->
<div class="container">
    <p class="text-danger text-center">{{screenMessage}}</p>
</div>
}

@if(isSearching)
{
<!-- display spinner while searching -->
<div class="container">
    <div class="row justify-content-center col-sm-12">
        @if(searchResults.length === 0)
        {
        <p>Searching for applications...</p>
        }
        @else
        {
        <p>Loading applications...</p>
        }
    </div>
    <div class="row justify-content-center col-sm-12">
        <mat-spinner></mat-spinner>
    </div>
</div>
}
@else
{

@if(searchResults.length > 0 && selectedApp === null)
{
<!-- display app search results -->

<div class="col col-12">
    <generic-search-results [makeSticky]="makeSticky" [showTitle]="showTitle" [title]="objectType + '(s) found'"
        [sortable]="sortable" [records]="searchResults" [displayedColumns]="displayedColumns"
        (recordSelected)="selectApp($event)"></generic-search-results>
</div>
}

@if(includeEnvironments && selectedApp !== null)
{
<!-- display application environments -->
<div class="col col-12">
    <h4 id="resultCount">Environments for {{ selectedApp.displayName }}({{ selectedApp.serviceId}})</h4>
    <div>
        <form [class]="makeSticky ? 'branded stickyHeader' : 'branded'">
            <filter-input (onKeyUp)="doFilterEnvironments($event)"></filter-input>

            <table [class]="makeSticky ? 'stickyTableHeaders' : ''" id="env-results" mat-table
                [dataSource]="environmentsDataSource" aria-describedby="environment results">

                <caption>available application environments</caption>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <input MatCheckbox type="checkbox" (click)="$event.stopPropagation()"
                            (change)="selectEnvironment(row)" [checked]="selection.isSelected(row)" />
                    </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> environment value </th>
                    <td mat-cell *matCellDef=" let env">{{ env.value }}</td>
                </ng-container>

                <ng-container matColumnDef="environmentName">
                    <th mat-header-cell *matHeaderCellDef> environment name </th>
                    <td mat-cell *matCellDef="let env">{{ env.environmentName }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="environmentsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: environmentsDisplayedColumns;"></tr>

            </table>

            <mat-paginator #environmentPaginator [pageSizeOptions]="[10, 20, 40]" showFirstLastButtons></mat-paginator>
        </form>
    </div>
</div>
}


<!-- display buttons -->
<div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar">

    @if(searchResults.length === 0 && !isSearching)
    {
    <!-- let the user search -->
    <div class="btn-group mr-2" role="group">
        <button class="button" type="button" (click)="search()">search</button>
    </div>
    }

    @if(selection.selected.length > 0 || (!includeEnvironments && selectedApp !== null))
    {
    <!-- let the user save the selected application -->
    <div class="btn-group mr-2" role="group">
        <button class="button" type="button" (click)="saveSelection()">add</button>
    </div>
    }

    @if(searchResults.length > 0)
    {
    <!-- let the user reset/restart the selection -->
    <div class="btn-group mr-2" role="group">
        <button class="button" type="button" (click)="reset()">reset</button>
    </div>
    }

    @if(isDialog)
    {
    <!-- let the user close the form -->
    <div class="btn-group mr-2" role="group" [hidden]="!isDialog">
        <button class="button" type="button" mat-dialog-close>close</button>
    </div>
    }

</div>
}