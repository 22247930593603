<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <div class="stickyHeader">
                <a class="close-button stickyHeader" mat-dialog-close style="float: right; padding-left:10px;"><span
                        class="icon-remove close-button"></span></a>
            </div>

            <div class="dialog-wrapper adjustStickyHeader">
                <details-requests [id]="workflowRequest.id" [type]="getWorkflowRequestType(workflowRequest.type)"></details-requests>
            </div>
        </div>
    </div>
</mat-dialog-content>
<div class="btn-toolbar d-flex justify-content-center pt-4" role="toolbar" style="position: sticky; bottom: 0; background: white; padding: 10px 0;">
    <div class="btn-group mr-2" role="group">
        <button class="button" type="button" mat-dialog-close>close</button>                                 
    </div>    
</div>