@if(submittedRequests.length === 0)
{
@if(!updatingPassedApplications)
{
<h1>update application</h1>
<fieldset class="pb-4">
    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
        <p><strong>{{ domain }} applications</strong></p>
    </legend>
    @if(gettingapplications)
    {
    <div class="container searching-container">
        <div class="row justify-content-center col-12">
            searching for applications...
        </div>
        <div class="row justify-content-center col-12">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    }
    @else if(!gettingapplications && applicationsDataSource.data.length == 0)
    {
    <div>
        <display-http-error message="no applications owned by you were found" [errors]="[]"></display-http-error>
    </div>
    }
    @else
    {
    <div class="container">
        <form class="branded">
            <filter-input (onKeyUp)="doFilter($event)"></filter-input>

            <table id="applications" mat-table [dataSource]="applicationsDataSource" matSort matSortActive="displayName"
                matSortDirection="asc" aria-describedby="user owned applications">

                <caption>applications you own</caption>

                <tr mat-header-row *matHeaderRowDef="applicationsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: applicationsDisplayedColumns;"></tr>

                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                    <td mat-cell *matCellDef="let row">
                        <input MatCheckbox type="checkbox" name="formcheckbox" (click)="$event.stopPropagation()"
                            (change)="select(row)" [checked]="selection.isSelected(row)" />
                    </td>
                </ng-container>

                <ng-container matColumnDef="displayName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> display name </th>
                    <td mat-cell *matCellDef="let application">{{ application.displayName }}</td>
                </ng-container>

                <ng-container matColumnDef="appId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> appId </th>
                    <td mat-cell *matCellDef="let application">{{ application.appId }}</td>
                </ng-container>

                <ng-container matColumnDef="serviceId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> service id </th>
                    <td mat-cell *matCellDef="let application">{{ application.serviceId }}</td>
                </ng-container>

            </table>

            <mat-paginator [length]="applicationsDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]"
                showFirstLastButtons></mat-paginator>
        </form>
    </div>
    }


</fieldset>
}

@if(applicationUpdatesDataSource.data.length > 0)
{
<fieldset class="pb-3">
    <legend
        [class]="updatingPassedApplications ? 'form-row p-2 pl-3 mb-4 missing-data-background-color text-white' : 'form-row p-2 pl-3 mb-4 bg-dark-blue text-white'">
        <p><strong>{{ applicationUpdateTitle }}</strong></p>
    </legend>
    <!-- bulk operation -->
    @if(applicationUpdatesDataSource.data.length > 1)
    {
    <form class="branded">
        <div class="row">
            <div class="col-4">
                <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                    matTooltip="new service Id for all selected applications">
                    <mat-label>bulk service id</mat-label>
                    <input matInput (click)="searchForChevronApplication()" [readonly]="true" [value]="chevronApp">
                    <button mat-icon-button matSuffix (click)="searchForChevronApplication()">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>
    </form>
    }

    <!-- display + form table -->
    <table aria-describedby="title" #table mat-table [dataSource]="applicationUpdatesDataSource" multiTemplateDataRows>
        <caption>applications to update</caption>
        @for(disCol of applicationsFormDisplayedColumns; track disCol; let colIndex = $index){
        <ng-container matColumnDef="{{disCol}}">
            <th mat-header-cell *matHeaderCellDef>{{disCol | convertDisplayLabels}}</th>
            <td mat-cell *matCellDef="let element; let rowIndex = index">
                {{element[disCol]}}
            </td>
        </ng-container>}

        <ng-container matColumnDef="del">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" style="text-align:center;">
                @if(!updatingPassedApplications)
                {
                <button mat-icon-button matSuffix (click)="deleteRow(element)">
                    <mat-icon>delete</mat-icon>
                </button>
                }
            </td>
        </ng-container>

        <!-- Secondary Column -->
        <ng-container matColumnDef="secondary">
            <td mat-cell [attr.colspan]="applicationsFormDataColumns.length"
                *matCellDef="let element; let rowIndex = dataIndex">
                <form class="branded padTop5">
                    <div class="row">
                        <!-- service id -->
                        <div class="col-4">
                            <mat-form-field appearance="outline" class="w-100" subscriptSizing="dynamic"
                                matTooltip="new service Id">
                                <mat-label>new service id</mat-label>
                                <input required matInput (click)="searchForChevronApplication(element.id)" [readonly]="true"
                                    [value]="element.chevronApp">
                                <button mat-icon-button matSuffix (click)="searchForChevronApplication(element.id)">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </mat-form-field>
                            @if(!isValid(element))
                            {
                                <div class="indent-20px mat-mdc-form-field-error mat-mdc-form-field-bottom-align ng-tns-c3736059725-9 ng-star-inserted">ServiceId is required</div>    
                            }
                        </div>
                    </div>
                </form>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="applicationsFormDataColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: applicationsFormDataColumns;"></tr>
        <tr mat-row *matRowDef="let row; columns: ['secondary']"></tr>
    </table>

</fieldset>
}

<mat-divider></mat-divider>

<display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>

@if(!gettingapplications)
{
<div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar">
    @if(isSubmitting)
    {
    <mat-spinner [diameter]="30" *ngIf="isSubmitting"
        style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
    }
    @else if(!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0)
    {
    <div class="btn-group mr-2" role="group">
        <button class="button" type="submit" (click)="onSubmit()">update</button>
    </div>
    }
</div>
}
}
@else
{

<a class="close-button" [routerLink]="[requestSubmittedEndRoute]"><span class="icon-remove close-button"></span></a>
<h1>request submitted</h1>
<display-http-error [message]="submitErrorMessage.message" [errors]="submitErrorMessage.errors"></display-http-error>
<display-requests [requests]="submittedRequests"></display-requests>

}