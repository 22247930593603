
/**
 * PATCH payload for endpoint /api/groups/{domain}/{id}/members
 */
export class DirectoryGroupUpdateMemberRequest {

    /**
     * these two properties are passed in via the route and not the payload
     * however when retrieving it comes back in the payload
     */
    groupId?:string;
    groupDomain?:string;

    businessJustification?:string;

    addMembers:DirectoryGroupMemberRequest[] = [];
    removeMembers:DirectoryGroupMemberRequest[] = [];
}

export class DirectoryGroupMemberRequest {
    constructor(id:string,type:string,domain:string) {
        this.id = id;
        this.type = type;
        this.domain = domain;
    }

    id:string;
    type:string;
    domain:string;
}