import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { CreateSecretRequest } from "src/app/models/secretServer/CreateSecretRequest";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-secret-create',
    templateUrl: './request-details-secret-create.component.html'
})

export class RequestDetailsSecretCreate implements OnInit {

    @Input() requestData:CreateSecretRequest;
    @Output() doneLoading = new EventEmitter<boolean>();

    displayObject:{folder: string,
        username: string
        resource: string
        owners: string[]
        viewers: string[]
    };

    constructor(
        private directoryUserService: DirectoryUserService
        ) {}

    async ngOnInit(): Promise<void> {
        this.displayObject = {
            folder: `${this.requestData.folder}`,
            username: `${this.requestData.secretFields.UserName}`,
            resource:`${this.requestData.secretFields.Resource}`,
            owners: [],
            viewers: [],
        } ;

        await this.loadUsers(this.requestData.owners);
        await this.loadUsers(this.requestData.viewers, false);
        
        this.isDoneLoading();
    }

    async loadUsers(ids:string[],isOwners: boolean = true) {
        for(let id of ids) {
            let user = await this.directoryUserService.GetByIdAsync({id:id,domain:DirectoryDomain.Chevron});
            if(isOwners) this.displayObject.owners.push(user.displayName);
            else this.displayObject.viewers.push(user.displayName);
        }
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}