import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
    selector: 'display-form',
    templateUrl: './display-form.component.html',
    styleUrls: ['./display-form.component.css']
})

export class DisplayFormComponent implements OnInit, OnChanges {
    ngOnInit(): void {
        this.updateDisplayModels();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateDisplayModels();
    }

    @Input() displayFields:string[] = [];    // ordered list of fields from the object to display
    @Input() displayObject:any;

    displayValues:DisplayItem[] = [];

    updateDisplayModels() {
        if (this.displayObject !== undefined) {
            this.displayValues = [];

            if (this.displayFields.length > 0) {
                this.displayFields.forEach(key=>{
                    let val = this.displayObject[key];
                    if (val !== undefined) {
                        this.displayValues.push(new DisplayItem(key,val));
                    }
                });
            } else {
                Object.entries(this.displayObject).forEach(([key,value])=>{
                    this.displayValues.push(new DisplayItem(key,value));
                });
            }
        }
    }
}

class DisplayItem {

    constructor(key:string,value:any){
        this.key = key;
        this.value = value;
        this.type = typeof(value);
    }

    key:string;
    value:any;
    type:string;
}