import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { ChevronApplicationService } from "src/app/services/application/ChevronApplication.service";
import { EnvironmentOption, IIdmApplication } from "src/app/models/idm/applications/IdmApplication";

@Component({
    selector: 'app-search-chevron',
    templateUrl: './app-search-chevron.component.html',
    styleUrls: ['./app-search-chevron.component.css']
})

export class AppSearchChevronComponent implements OnInit, OnChanges {

    private environmentPaginator!: MatPaginator;

    constructor(private chevronAppService: ChevronApplicationService) { }
    ngOnChanges(changes: SimpleChanges): void {
        this.includeEnvironments = changes.includeEnvironments.currentValue;
    }

    @ViewChild('environmentPaginator') set matPaginator(mp: MatPaginator) {
        this.environmentPaginator = mp;
        this.setDataSourceAttributes();
    }
    setDataSourceAttributes() {
        this.environmentsDataSource.paginator = this.environmentPaginator;
        this.environmentsDataSource.sort = this.sort;

        if (this.environmentPaginator && this.sort) {
            this.doFilterEnvironments('');
        }
    }
    @ViewChild(MatSort) sort!: MatSort;
    @Input() showTitle: boolean = true;
    @Input() isDialog: boolean = false;
    @Input() includeEnvironments: boolean = false;
    @Input() sortable: boolean = false;
    @Input() makeSticky: boolean = false;

    @Output() appSelected = new EventEmitter<IIdmApplication>();
    @Output() searchCancelled = new EventEmitter<{ searchCancelled: boolean }>();

    isSearching: boolean = false;
    screenMessage: string = '';
    objectType: string = '';

    searchTerm = new UntypedFormControl('');
    searchResults: IIdmApplication[] = [];
    displayedColumns: string[] = ['serviceId', 'displayName'];

    // todo: nice to have(n2h): when includeEnvironments is true, show the number of environments

    environmentsDataSource = new MatTableDataSource<EnvironmentOption>();
    environmentsDisplayedColumns: string[] = ['select', 'value', 'environmentName'];

    selectedApp: IIdmApplication | null = null;
    selection = new SelectionModel<EnvironmentOption>(true, []);

    appSearchObserver = {
        next: (x: IGenericApiResponse<IIdmApplication[]>) => {
            this.searchResults = x.data;
        },
        error: (err: any) => {
            this.screenMessage = err.statusText;
            this.isSearching = false;
        },
        complete: () => {
            this.screenMessage = this.searchResults.length === 0 ? 'no results found' : '';
            this.isSearching = false;
        }
    };

    async ngOnInit(): Promise<void> {
        this.objectType = 'chevron application';
    }

    async search() {
        this.isSearching = true;
        this.screenMessage = ''; //hide the no records found

        let apiCall = this.chevronAppService.SearchApplication(this.searchTerm.value, this.includeEnvironments);

        apiCall.subscribe(this.appSearchObserver);
    }

    selectApp(selected: IIdmApplication) {

        if (!this.includeEnvironments) {
            this.appSelected.emit(selected);
        }
        else {
            this.selectedApp = selected;
            // display the environments options
            var environmentOptions: EnvironmentOption[] = selected.environments.map(env => ({ value: env, environmentName: env } as EnvironmentOption));
            if (environmentOptions.length == 0) {
                // add a default environment option
                environmentOptions.push({ value: 'prod', environmentName: 'prod (no environments found)' } as EnvironmentOption);
            }

            this.environmentsDataSource.paginator = this.environmentPaginator;

            this.environmentsDataSource.data = this.environmentsDataSource.data = environmentOptions;
        }
    }

    selectEnvironment(row: EnvironmentOption) {
        this.selection.toggle(row);
    }

    public doFilterEnvironments = (value: any) => {
        this.environmentsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    reset() {
        this.searchTerm.setValue('');
        this.searchResults = [];
        this.selectedApp = null;
        this.selection.clear();
        this.environmentsDataSource.data = [];
    }

    saveSelection() {
        if (this.selectedApp != null) {
            this.appSelected.emit({
                serviceId: this.selectedApp?.serviceId,
                displayName: this.selectedApp?.displayName,
                isActive: this.selectedApp?.isActive,
                availabilityRating: this.selectedApp?.availabilityRating,
                confidentialityRating: this.selectedApp?.confidentialityRating,
                dataIntegrityRating: this.selectedApp?.dataIntegrityRating,
                environments: this.selection.selected.map(env => env.value)
            } as IIdmApplication);
        }
        this.reset();
    }
}