import { Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: 'filter-input',
    templateUrl: './filter-input.component.html',
    styleUrls: ['./filter-input.component.css']
})
export class FilterInputComponent {
    @Output() onKeyUp = new EventEmitter();

    public onInputKeyUp(event: any) {
        this.onKeyUp.emit(event);
    }
}