<generic-search-results
        [showHeader]="false"
        [showCounter]="false"
        [title]="''"
        [records]="secretsUpdated"
        [displayedColumns]="secretColumns"
        [selectableRecords]="false"
        [allowLocalFilter]="false"
></generic-search-results>

<generic-search-results
    [showHeader]="false"
    [showCounter]="false"
    [title]="'permission changes'"
    [records]="userPermissionChanges"
    [displayedColumns]="userPermissionColumns"
    [selectableRecords]="false"
    [allowLocalFilter]="false"
></generic-search-results>