import { Component } from '@angular/core';
import {
  NavigationLocation,
  PageLayout
} from '@cvx/nextpage';

@Component({
  selector: 'app-bulk-create-primary-user',
  templateUrl: './bulk-create-primary-user.component.html'
})
export class BulkCreatePrimaryUserComponent {

  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;

}
