import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { appRegDomains } from "src/app/data/app-reg-domains";

export function AppRegDomainValidator(): ValidatorFn {

    return (control:AbstractControl): ValidationErrors | null => {

        const val = control.value;

        // empty string
        if (!val) { return null; }

        // only localhost allows http, this is allowed from MSFT
        if (val === 'http://localhost' || val.startsWith('http://localhost:')) { return null; }

        // all other urls must start with https
        if (val.startsWith('https://')) {
            try
            {
                let url = new URL(val);
                let hostname = url.hostname;

                // localhost is valid
                if (hostname === 'localhost' || hostname.startsWith('localhost:')) {
                    return null;
                }
                else {
                    // check if the domain is a valid Chevron domain
                    return !appRegDomains.some((domain)=> url.hostname.endsWith(domain)) ? {invalidDomain:true} : null;
                }
            }
            catch(error) {
                // string wasn't a parsable domain
                return {invalidDomainFormat:true};
            }
        }

        // invalid format (eg. needs to start with http or https)
        return { invalidDomainFormat: true};

    }
}