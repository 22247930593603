<!-- Header -->
<np-header>

  <!-- Search -->
  <np-search [searchType]="SearchType.Global"></np-search>
  <!-- Logged in user-->
  <div class="float-right d-none d-sm-block mt-4 mr-4">{{ username }}</div>

  <!-- Global (top menu) Navigation -->
  <np-menu [location]="NavigationLocation.Global"></np-menu>
</np-header>
<!-- Site-Specific Navigation -->
<np-menu [location]="NavigationLocation.SiteSpecific"></np-menu>

@if(showProdAlertWarning)
{
  <np-alert>
    <p>This is the production version of the IdAMP, all changes will be made to production identities.</p>
  </np-alert>
}

@if(alertInfoMessage.length > 0)
{
  <np-alert [class]="alertInfoMessageClass">
    <p>
      {{ alertInfoMessage }}
    </p>
  </np-alert>
}


<!-- Main Content -->
<router-outlet></router-outlet>

<!-- Footer -->
<np-footer
  [contentContactName]="'IAM Governance'"
  [contentContactUrl]="'https://dev.azure.com/chevron/IT-FoundationPlatform/_wiki/wikis/IT-DigitalPlatform.wiki/34967/IAM-Gov-The-Force'"
  [technicalContactName]="'IAM Governance'"
  [technicalContactUrl]="'https://dev.azure.com/chevron/IT-FoundationPlatform/_wiki/wikis/IT-DigitalPlatform.wiki/34967/IAM-Gov-The-Force'">
</np-footer>