import { Component, OnInit, ViewChild } from "@angular/core";
import { GraphApplication } from "src/app/models/graph/applications/GraphApplication";
import { GraphApplicationService } from "src/app/services/graph/GraphApplication.service";
import { PageLayout } from '@cvx/nextpage';
import { IGraphApplicationPermissions } from "src/app/models/graph/applications/GraphApplicationPermissions";
import { IGenericApiResponseWithWorkflowRequest } from "src/app/models/common/GenericApiResponseWithWorkflowRequest";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { MatTableDataSource } from "@angular/material/table";
import { DisplayClientSecret } from "src/app/models/graph/applications/ClientSecret";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import Swal from "sweetalert2";
import { IWorkflowRequest } from "src/app/models/requests/WorkflowRequest";
import { ErrorMessageConstants } from "src/app/constants/ErrorMessage.constants";

@Component({
    selector: 'app-delete-client-secret',
    templateUrl: './delete-client-secret.component.html'
})
export class DeleteClientSecretComponent implements OnInit {
    @ViewChild(MatPaginator, { static: false })
    set paginator(value: MatPaginator) {
        this.clientSecretsDataSource.paginator = value;
    }
    @ViewChild(MatSort, { static: false })
    set sort(value: MatSort) {
        this.clientSecretsDataSource.sort = value;
    }
    PageLayout = PageLayout;
    submitErrorMessage = { message: '', errors: [] };
    clientSecretsErrorMessage: string;
    isDeleting: boolean = false;
    isCompleted: boolean = false;
    gettingSecrets: boolean = false;
    selectedDisplayCols: string[] = ['description', 'expires', 'value', 'secretId'];
    displayColumns: string[] = ['select', ...this.selectedDisplayCols];
    clientSecretsDataSource = new MatTableDataSource<DisplayClientSecret>([]);
    selection = new SelectionModel<DisplayClientSecret>(true, []);
    submittedRequests: IWorkflowRequest[] = [];
    showUpdateApplication: boolean = false;

    constructor(
        private graphApplicationService: GraphApplicationService
    ) { }

    clientApp?: GraphApplication;
    displayClientApp: any;

    ngOnInit(): void {
        this.clientSecretsDataSource.paginator = this.paginator;
        this.clientSecretsDataSource.sort = this.sort;
    }

    clientAppSelected(data: IGraphApplicationPermissions) {
        this.clientApp = data.application;
        this.showUpdateApplication = Number.isNaN(parseInt(this.clientApp.serviceId))
        this.displayClientApp = {
            id: data.application.id,
            appId: data.application.appId,
            name: data.application.displayName,
            serviceId: this.showUpdateApplication ? ErrorMessageConstants.MissingData : data.application.serviceId,
            uri: data.application.identifierUris[0]
        };

        this.getClientSecrets(data.application.id);
        this.clientSecretsErrorMessage = `No client secrets found for application ${data.application.displayName}`
    }

    getClientSecrets(appObjectId: string) {
        this.gettingSecrets = true;
        this.graphApplicationService.GetClientSecrets(DirectoryDomain.Chevron, appObjectId).subscribe({
            next: (r) => {
                let displayVals = r.data.map(d => {
                    return new DisplayClientSecret(d);
                });
                this.clientSecretsDataSource.data = displayVals;
            },
            error: (e) => {
                console.error(e);
                this.gettingSecrets = false;
            },
            complete: () => {
                this.gettingSecrets = false;
            }
        });
    }

    select(row: DisplayClientSecret) {
        this.selection.toggle(row);
    }

    public filter = (value: any) => {
        this.clientSecretsDataSource.filter = value.target.value.trim().toLocaleLowerCase();
    }

    async onSubmit() {

        // clear error messages
        // clear error messages
        this.submitErrorMessage.message = '';
        this.submitErrorMessage.errors = [];

        if (this.selection.selected.length > 0) {
            Swal.fire({
                icon: 'warning',
                iconColor: '#97002E',
                title: '<h3>are you sure?</h3>',
                html:
                    `<p>You are about to delete ${this.selection.selected.length} client secrets, ` +
                    "this operation can't be undone.</p>",
                showCancelButton: true,
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'button',
                    cancelButton: 'button',
                },
            }).then((result: any) => {
                if (result.isConfirmed) {
                    this.deleteClientSecrets(this.selection.selected);
                }
            });
        }
    }

    async deleteClientSecrets(clientSecrets: DisplayClientSecret[]) {
        this.isDeleting = true;
        let appClientSecrets = clientSecrets.map(s => s.secretId);

        const observer = {
            next: (x: IGenericApiResponseWithWorkflowRequest<any>) => {
                this.submittedRequests = x.requests;
            },
            error: (err: any) => {
                this.submitErrorMessage.message = err.statusText;
                this.submitErrorMessage.errors = err?.error?.errors ?? [];
                this.isDeleting = false;
            },
            complete: () => {
                this.isDeleting = false;
                this.isCompleted = true;
            }
        };

        let requestCall = this.graphApplicationService.DeleteClientSecrets(DirectoryDomain.Chevron, this.clientApp!.id, appClientSecrets);
        requestCall.subscribe(observer);
    }

    resetForm() {
        this.clientApp = undefined;
        this.displayClientApp = undefined;
        this.clientSecretsDataSource = new MatTableDataSource<DisplayClientSecret>([]);
        this.selection = new SelectionModel<DisplayClientSecret>(true, []);
        this.isCompleted = false;
        this.isDeleting = false;
        this.submittedRequests = [];
        this.submitErrorMessage = { message: '', errors: [] };
    }


    updatedApplications(data: GraphApplication[]) {
        this.displayClientApp.serviceId = data[0].serviceId;
        this.displayClientApp = { ...this.displayClientApp };
        this.clientApp = data[0];
        this.showUpdateApplication = false;
    }
}
