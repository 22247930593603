import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CalAngularService } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { PrimaryUserService } from 'src/app/services/primaryUser/primaryUser.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-auth-code-validation',
  templateUrl: './auth-code-validation.component.html',
})
export class AuthCodeValidationComponent {

  hide = true;
  IsChecking = false;
  resultMessage = "";
  resultMessageColor = "";

  constructor(private authService: CalAngularService, private userService: PrimaryUserService, private dialogService: DialogService) { }

  PageLayout = PageLayout;

  searchForm = new FormGroup({
    email: new FormControl(null, Validators.email),
    provisioningid: new FormControl(null, Validators.pattern("^[0-9]*$")),
    authcode: new FormControl(null, Validators.required)
  }, { validators: this.checkSingleFieldPopulatedValidator() });

  public passwordHide=()=>{
    this.hide = !this.hide;
   }

   onSubmit() {
    let formValue: any = this.searchForm.value
    Object.keys(formValue).forEach((key) => (formValue[key] == null || formValue[key] == "") && delete formValue[key]);

    this.IsChecking = true;

    const observer = {
      next: (x: boolean) => {
        if (x) {
          this.dialogService.OpenPopUpDialogWithColor("done", "text-medium-green", "authorization code is valid")
        } else {
          this.dialogService.OpenPopUpDialogWithColor("clear", "text-light-red", "authorization code is not valid")        
        }
        this.IsChecking = false;
      },
      error: (err: any) => {
        if (err.status == 400) {
          this.dialogService.OpenPopUpDialogWithColor("clear", "text-light-red", "invalid search data provided")
        } else if (err.status == 401) {
          this.dialogService.OpenPopUpDialogWithColor("lock", "text-light-red", "you are not authorized to validate auth codes")
        } else if (err.status == 404) {
          this.dialogService.OpenPopUpDialogWithColor("search_off", "text-medium-orange", "no user was found with the provided data")
        } else {
          this.dialogService.OpenPopUpDialogWithColor("question_mark", "text-light-red", "an unknown error occured, please log a service now request provided the exact search parameters used.")
        }
        this.IsChecking = false;
      },
    };

    this.authService.getAADToken(environment.tokenScopes).subscribe(token => {
      this.userService.ValidateAuthCode(formValue, token).subscribe(observer);
    });
  }

  	//#region	form getter functions
    get email() { return this.searchForm.get('email'); }
    get provisioningid() { return this.searchForm.get('provisioningid'); }
    get authcode() { return this.searchForm.get('authcode'); }
    //#endregion

    //#region form validation    
    checkSingleFieldPopulatedValidator(): ValidatorFn {
      return (control:AbstractControl) : ValidationErrors | null => {
        
        const email = control.get('email')?.value
        const provisioningid = control.get('provisioningid')?.value;

        let count = 0

        if (email) count++
        if (provisioningid) count++

        if (count === 0) return {checkSingleFieldPopulated:true};
        if (count > 1) return {checkSingleFieldPopulated:true};

        return null;
      }
    }
    //#endregion
}

