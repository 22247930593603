import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { NavigationLocation, PageLayout } from '@cvx/nextpage';
import { Observable, Subscription, forkJoin, map } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { IDirectoryUser } from 'src/app/models/directory/users/DirectoryUser';
import { IGenericApiResponseWithWorkflowRequest } from 'src/app/models/common/GenericApiResponseWithWorkflowRequest';
import { IWorkflowRequest } from 'src/app/models/requests/WorkflowRequest';
import { GraphUserService } from 'src/app/services/graph/GraphUser.service';
import { SelectionModel } from '@angular/cdk/collections';
import { IGenericApiResponse } from 'src/app/models/common/GenericApiResponse';
import { DirectoryDomain } from 'src/app/models/directory/enums/DirectoryDomain.enum';

@Component({
  selector: 'app-unlock-users',
  templateUrl: './unlock-users.component.html',
})
export class UnlockUsersComponent implements OnInit, OnDestroy {
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  currentUserProfile: any = null;
  usersToUnlock: IDirectoryUser[] = [];
  isDataLoading: boolean = false;
  dataSource = new MatTableDataSource<IDirectoryUser>();

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  displayedColumns: string[] = ["checkBox", 'userPrincipalName', 'type', 'domain'];
  navSubscription = new Subscription();
  constructor(
    private authService: CalAngularService,
    private graphUserService: GraphUserService
  ) {
  }

  isSubmitting: boolean = false;
  submittedRequests: IWorkflowRequest[] = [];
  submitErrorMessage = { message: '', errors: [] };

  selectedUserDisplayedColumns: string[] = ['userPrincipalName', 'type', 'domain'];

  unlockAccounts(accountsToUnlock: IDirectoryUser[]) {
    this.isSubmitting = true;
    let response: IWorkflowRequest[] = [];
    const observer = {
      next: (x: IWorkflowRequest[]) => {
        if (x) {
          response = x;
        }
      },
      error: (err: any) => {
        this.submitErrorMessage.message = err.statusText;
        this.submitErrorMessage.errors = err?.error?.errors ?? [];
        this.isSubmitting = false;
      },
      complete: () => {
        this.submittedRequests = response;
        this.isSubmitting = false;
      }
    };

    const requests: Observable<IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>>[] = [];
    const accountsByDomain = this.groupAccountsByDomain(accountsToUnlock);
    for (let accountDomain in accountsByDomain) {
      const accounts: IDirectoryUser[] = accountsByDomain[accountDomain]
      const request = this.graphUserService.UnlockAccounts(accountDomain as DirectoryDomain, accounts.map(x => x.id));
      requests.push(request);
    }

    if (requests.length > 0) {
      forkJoin(requests)
        .pipe(map(data => data.reduce((result: IWorkflowRequest[], apiResponse: IGenericApiResponseWithWorkflowRequest<IDirectoryUser[]>) => {
          return apiResponse.requests ? [...result, ...apiResponse.requests] : result;
        }, [])))
        .subscribe(observer);
    }
  }

  private groupAccountsByDomain(accounts: IDirectoryUser[]) {
    return accounts.reduce((groups: any, item: IDirectoryUser) => {
      const val = item.domain;
      groups[val] = groups[val] || [];
      groups[val].push(item);
      return groups;
    }, {});
  }

  selection = new SelectionModel<IDirectoryUser>(true, []);
  selectUser(row: IDirectoryUser) {
    this.selection.toggle(row);
  }

  public doFilter = (event: any) => {
    const { value } = event.target
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  onUnlockClick() {
    this.submittedRequests = [];
    this.submitErrorMessage = { message: '', errors: [] };
    if (this.selection.selected.length > 0) {
      this.unlockAccounts(this.selection.selected);
    }
  }

  async ngOnInit() {
    if (this.authService.isUserSignedIn()) {
      this.getAllAccounts()
    } else {
      console.log('user not yet signed in');
    }
  }

  getAllAccounts() {
    this.isDataLoading = true;
    const observer = {
      next: (x: IDirectoryUser[]) => {
        this.isDataLoading = false;
        this.usersToUnlock = x;
      },
      error: (err: any) => {
        this.isDataLoading = false;
        console.log(err);
      },
      complete: () => {
        this.dataSource.data = this.usersToUnlock;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    };

    const requests: Observable<IGenericApiResponse<IDirectoryUser[]>>[] = [
      this.graphUserService.GetLockedAccounts(DirectoryDomain.CT, this.authService.cvxClaimsPrincipal.objectId),
      this.graphUserService.GetLockedAccounts(DirectoryDomain.S01, this.authService.cvxClaimsPrincipal.objectId),
      this.graphUserService.GetLockedAccounts(DirectoryDomain.S02, this.authService.cvxClaimsPrincipal.objectId),
    ];

    forkJoin(requests)
      .pipe(map(data => data.reduce((result: IDirectoryUser[], apiResponse: IGenericApiResponse<IDirectoryUser[]>) => [...result, ...apiResponse.data], [])))
      .subscribe(observer);
  }

  ngOnDestroy(): void {
    this.navSubscription.unsubscribe();
  }
}