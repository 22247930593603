import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { IDialogDirectoryData } from "src/app/models/components/IDialogDirectoryData";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";

@Component({
    selector: 'dialog-directory-search',
    templateUrl: './dialog-directory-search.component.html',
    styleUrls: ['./dialog-directory-search.component.css']
})

export class DialogDirectorySearchComponent {

    constructor(private dialogRef: MatDialogRef<DialogDirectorySearchComponent>, 
        @Inject(MAT_DIALOG_DATA) public data:IDialogDirectoryData,
        ){}

    objectSelected(data:DirectoryObjectBase) {
        this.dialogRef.close(data);
    }

    searchCancelled() {
        this.dialogRef.close();
    }

}

