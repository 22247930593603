<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row">
            <div class="col col-12">
                <h1>my profile</h1>

                <div class="container" *ngIf="!PrimaryUser && !ErrorMessage">
                    <div class="row justify-content-center col-sm-12">
                        <p>Getting your profile, please wait.</p>                               
                    </div>
                    <div class="row justify-content-center col-sm-12">
                        <mat-spinner></mat-spinner>                               
                    </div>                                
                </div>
                
                <div class="container" *ngIf="ErrorMessage">
                    <p class="text-center">{{ ErrorMessage }}</p>
                    <p class="text-center">Please copy this error and log a request in ServiceNow</p>                           
                </div>

                <div *ngIf="PrimaryUser !== undefined">
                    
                    <div class="form-row p-2 mb-4 bg-light w-100">
                        <p>Please use this page to update your Chevron account with your most up to date profile data. Should you choose to, you may also provide emergency contact information.</p>
                        <p>Some of the fields below will be set as read only based on your employment status with the company.</p>
                        <p>Any fields denoted with a <strong>*</strong> are required fields and need to be completed before the form can be submitted.</p>
                    </div>

                    <app-primary-user-details [user]="PrimaryUser" [isAllowedToEditName]="isAllowedToEditName" [operationType]="'myProfile'" (cancelMe)='formCancelled()'></app-primary-user-details>
                </div>

                

                
            </div>
        </div>
    </section>
</main>
