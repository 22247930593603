export class AppRoleConstants {
    public static readonly GuestUserInviteAll: string = "GuestUser.Invite.All";
    public static readonly NonPrimaryUserManageAll: string = "NonPrimaryUser.Manage.All";
    public static readonly AppTester: string = "App.Tester";
    public static readonly CocoUserManageAll: string = "COCOUser.Manage.All";
    public static readonly PrimaryUserGlobalAdmin = "PrimaryUser.Admin.Global";
    public static readonly PrimaryUserDelegatedAdminPrefix = "PrimaryUser.DelegatedAdmin";
    public static readonly NonChevronApplicationPermissionReadAll: string = "NonChevronApplicationPermission.Read.All";
    public static readonly NonChevronApplicationPermissionManageAll: string = "NonChevronApplicationPermission.Manage.All";
    public static readonly CyberEventCompromisedSourcingCompanyReadAll = "CyberEventCompromisedSourcingCompany.Read.All";
    public static readonly CyberEventCompromisedSourcingCompanyManageAll = "CyberEventCompromisedSourcingCompany.Manage.All";
}