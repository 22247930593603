<mat-dialog-content>
    <div class="row">
        <div class="col-12">
            <h2>group member search</h2>
    
            <directory-group-member-search
                [isDialog]="true"
                [groupId]="data.groupId"
                [domain]="data.domain"
                [localCache]="data.localCache"
                (objectSelected)="objectSelected($event)" 
                (searchCancelled)="searchCancelled()"
            ></directory-group-member-search>
    
        </div>
    </div>
</mat-dialog-content>