import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { PageLayout } from '@cvx/nextpage';
import { PrimaryUser } from 'src/app/models/primaryUser';
import { PrimaryUserAuthService } from 'src/app/services/primaryUserAuth/primaryUserAuth.service';
@Component({
  selector: 'app-manage-primary-user',
  templateUrl: './manage-primary-user.component.html'
})
export class ManagePrimaryUserComponent implements OnInit{

  constructor(private authService: CalAngularService, private primaryUserAuthService: PrimaryUserAuthService) { }

  PageLayout = PageLayout;
  searchResults: PrimaryUser[] = [];
  selectedUser?: PrimaryUser;

  IsAuthorised = false;

  async ngOnInit(): Promise<void> {
    const cvxClaimsPrincipal = this.authService.cvxClaimsPrincipal;
    this.IsAuthorised = this.primaryUserAuthService.GetPageAuthorization(cvxClaimsPrincipal);
  }

  whenSearchComplete(eventData: { searchResults: PrimaryUser[] }) {
    this.searchResults = eventData.searchResults;
  }

  whenUserSelected(eventData: { selectedUser: PrimaryUser }) {
    this.selectedUser = eventData.selectedUser
  }

  cancel() {
    delete this.selectedUser;
    this.searchResults = [];
  }
}
