/**
 * POST payload for endpoint /api/secrets/bulkpermissionsrequest
 */
export class RequestSecretPermissionsRequest {
    
    constructor(requestOwner: number[],
        requestViewer: number[],
        justification: string = ""
        ) {
        this.requestOwner = requestOwner;
        this.requestViewer = requestViewer;
        this.justification = justification
    }

    requestOwner: number[] = []
    requestViewer: number[] = []
    justification: string = ""
}