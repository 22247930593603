import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-mfa-reset',
    templateUrl: './request-details-mfa-reset.component.html'
})

export class RequestDetailsMfaReset implements OnChanges {
    @Input() requestData: string[];
    @Output() doneLoading = new EventEmitter<boolean>();

    displayObject: any;

    userColumns: string[] = ['displayName', 'mail', 'type', 'domain', 'copyId'];
    mfaResetUsers: IDirectoryUser[] = [];

    constructor(
        private directoryUserService: DirectoryUserService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData?.length > 0) {
            const mfaResetUsers = await this.directoryUserService.GetByIdsAsync({ domain: DirectoryDomain.Chevron, ids: this.requestData })

            this.mfaResetUsers = mfaResetUsers.map((x: any) => {
                x.copyId = x.id;
                return x;
            });
            this.isDoneLoading();
        }
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}