<main class="container">
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="row" *ngIf="submittedRequests.length === 0">
            <div class="col col-12">
                <h1>delete password</h1>
                <fieldset class="pb-4">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>password</strong></p>
                    </legend>
                    <div *ngIf="!gettingSecrets && secretsDataSource.data.length == 0">
                        no passwords owned by you were found
                    </div>
                    <div *ngIf="gettingSecrets">
                        <div class="row justify-content-center col-sm-12">
                            <p>searching for passwords...</p>
                        </div>
                        <div class="row justify-content-center col-sm-12">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                    <div>
                        <form class="branded">
                            <filter-input (onKeyUp)="doFilterPermissions($event)"
                                *ngIf="!gettingSecrets && secretsDataSource.data.length > 0"></filter-input>

                            <table id="secrets" [hidden]="gettingSecrets || secretsDataSource.data.length == 0"
                                mat-table [dataSource]="secretsDataSource" matSort matSortActive="name"
                                matSortDirection="asc" aria-describedby="user owned secrets">
                                <caption>passwords that can be deleted</caption>
                                <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <input type="checkbox" (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()" />
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <input type="checkbox" name="formcheckbox" (click)="$event.stopPropagation()"
                                            (change)="selectPermission(row)" [checked]="selection.isSelected(row)" />
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> id </th>
                                    <td mat-cell *matCellDef="let secret">{{ secret.id }}</td>
                                </ng-container>

                                <ng-container matColumnDef="name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> name </th>
                                    <td mat-cell *matCellDef="let secret">{{ secret.name }}</td>
                                </ng-container>

                                <ng-container matColumnDef="folder">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> folder </th>
                                    <td mat-cell *matCellDef="let secret">
                                        {{ secret.folder }}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="secretsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: secretsDisplayedColumns;"></tr>

                            </table>
                            <mat-paginator [length]="secretsDataSource.data.length" [pageSizeOptions]="[10, 20, 30, 40]"
                                showFirstLastButtons></mat-paginator>
                        </form>
                    </div>
                </fieldset>

                <fieldset class="pb-4" *ngIf="!gettingSecrets && secretsDataSource.data.length > 0">
                    <legend class="form-row p-2 pl-3 mb-4 bg-dark-blue text-white">
                        <p><strong>selected passwords to delete</strong></p>
                    </legend>
                    <generic-search-results [showHeader]="false" [records]="selection.selected" [showCounter]="false"
                        [title]="''" [caption]="'passwords to delete'"
                        [displayedColumns]="selectedSecretsDisplayedColumns" [selectableRecords]="false"
                        [deletableRecords]="true" [showPagination]="false" [allowLocalFilter]="false"
                        (recordDeleted)="selectPermission($event)">
                    </generic-search-results>
                </fieldset>

                <mat-divider></mat-divider>

                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>

                <div class="btn-toolbar d-flex justify-content-center pt-4 pb-4" role="toolbar" *ngIf="!gettingSecrets">
                    <mat-spinner [diameter]="30" *ngIf="isSubmitting"
                        style="display:inline-block; margin-left: 10px; top: 10px;"></mat-spinner>
                    <div class="btn-group mr-2" role="group"
                        *ngIf="!isSubmitting && selection.selected.length > 0 && submittedRequests.length == 0">
                        <button class="button" type="submit" (click)="onSubmit()">delete</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="submittedRequests.length > 0">
            <div class="col col-12">
                <a class="close-button" [routerLink]="['/passwords/delete']"><span
                        class="icon-remove close-button"></span></a>
                <h1>request submitted</h1>
                <display-http-error [message]="submitErrorMessage.message"
                    [errors]="submitErrorMessage.errors"></display-http-error>
                <display-requests [requests]="submittedRequests"></display-requests>
            </div>
        </div>
    </section>
</main>