import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { IDirectoryUser } from "src/app/models/directory/users/DirectoryUser";
import { UpdateServiceAccountRequest } from "src/app/models/servicePrivilegeAccounts/UpdateServiceAccountRequest";
import { DirectoryUserService } from "src/app/services/directory/DirectoryUser.service";

@Component({
    selector: 'request-details-service-account-update',
    templateUrl: './request-details-service-account-update.component.html'
})

export class RequestDetailsServiceAccountUpdate implements OnChanges {
    @Input() requestData: UpdateServiceAccountRequest;
    @Output() doneLoading = new EventEmitter<boolean>();

    displayObject: any;
    sharedRequestDetails: any;

    userColumns: string[] = ['userPrincipalName', 'type', 'domain', 'steward', 'serviceId'];
    
    usersUpdateDetails: any[] = [];

    constructor(
        private directoryUserService: DirectoryUserService,
    ) { }

    async ngOnChanges(): Promise<void> {
        if (this.requestData) {
            // add columns
            if(this.requestData.usingAdminRole)
            {
                this.sharedRequestDetails = {
                    supportJustification: this.requestData.adminJustification
                }
            }
            this.userColumns = [...this.userColumns, 'copyId'];

            const serviceAccounts = await this.loadUpdatedServiceAccounts(this.requestData.serviceAccounts.map(x => x.userId));
            const stewards = this.requestData.serviceAccounts
                .filter(x => x.stewardUserId).map(x => x.stewardUserId);
            const uniqueStewardIds = [... new Set(stewards)]

            let stewardUsers: IDirectoryUser[] = []
            if (uniqueStewardIds && uniqueStewardIds.length > 0) {
                stewardUsers = await this.getStewardUsers(uniqueStewardIds as string[])
            }

            this.usersUpdateDetails = [...this.requestData.serviceAccounts.map((user: any) => {
                const updatedUserInfo = serviceAccounts.find(x => x.id === user.userId);
                if (updatedUserInfo) {
                    user.id = updatedUserInfo.id;
                    user.userPrincipalName = updatedUserInfo.userPrincipalName;
                    user.domain = updatedUserInfo.domain;
                    user.type = updatedUserInfo.type;
                    // more to come once serviceId has been added
                }
                else {
                    user.userPrincipalName = user.userId;
                    user.domain = DirectoryDomain.Chevron;
                    user.type = "Service Account";
                }
                const stewardUser = stewardUsers.find(steward => steward.id === user.stewardUserId);
                if (stewardUser) {
                    user.steward = stewardUser.displayName;
                }

                // adjust for copyId
                return {
                    userPrincipalName: user.userPrincipalName,
                    type: user.type,
                    supportJustification: this.requestData.adminJustification,
                    copyId: user.id,
                    domain: user.domain,
                    serviceId: user.applicationId,
                    steward: user.steward
                };
            })];
            this.isDoneLoading();
        }
    }

    async loadUpdatedServiceAccounts(ids: string[]) {
        return await this.directoryUserService.GetByIdsAsync({ ids, domain: DirectoryDomain.Chevron });
    }

    async getStewardUsers(stewardIds: string[]) {
        return await this.directoryUserService.GetByIdsAsync({ ids: stewardIds, domain: DirectoryDomain.Chevron });
    }

    isDoneLoading() {
        this.doneLoading.emit(true);
    }
}