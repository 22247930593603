import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { CaseInsensitiveComparer } from "src/app/helpers/caseInsensitiveComparer";
import { DirectoryObjectBase } from "src/app/models/directory/DirectoryObjectBase";
import { DirectoryDomain } from "src/app/models/directory/enums/DirectoryDomain.enum";
import { DirectoryGroupService } from "src/app/services/directory/DirectoryGroup.service";

@Component({
    selector: 'directory-group-member-search',
    templateUrl: './directory-group-member-search.component.html'
})
export class DirectoryGroupMemberSearchComponent {

    @Input() isDialog:boolean = false;
    @Input() groupId:string = '';
    @Input() domain:DirectoryDomain;
    @Input() localCache:DirectoryObjectBase[] = [];

    @Output() objectSelected = new EventEmitter<DirectoryObjectBase>();

    isSearching:boolean = false;
    screenMessage:string = '';

    searchTerm = new FormControl<string>('');
    searchResults:DirectoryObjectBase[] = [];
    displayColumns:string[] = ['displayName','domain','directoryObjectType'];

    constructor(private directoryGroupService:DirectoryGroupService) {}

    async search() {
        if (this.searchTerm.value !== null && this.searchTerm.value !== '')
        {
            this.isSearching = true;
            this.screenMessage = '';

            this.searchResults = await this.executeSearch(this.searchTerm.value);

            if (this.searchResults.length === 0) {
                this.screenMessage = `No match for member using term '${this.searchTerm.value}'`;
            }
            this.isSearching = false;
        }
    }

    async executeSearch(term:string):Promise<DirectoryObjectBase[]>
    {
        let results:DirectoryObjectBase[] = [];

        // search the local cache
        if (this.localCache.length > 0) {
            let cacheResults = this.localCache.filter((member:DirectoryObjectBase) => 
                CaseInsensitiveComparer(member.id, term) || 
                CaseInsensitiveComparer(member.displayName, term) || 
                CaseInsensitiveComparer(member.mail, term)
            );

            results = results.concat(cacheResults);
        }

        // hit the api
        if (results.length === 0) {
            results = await this.directoryGroupService.SearchGroupMembersAsync(this.groupId, this.domain, term);
        }

        return results;
    }

    async selectObject(directoryObject:DirectoryObjectBase) {
        this.objectSelected.emit(directoryObject);
        this.isSearching = false;
    }

    reset() {
        this.searchTerm.setValue('');
        this.searchResults = [];
    }
}