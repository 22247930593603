/**
 * API model for:
 *   POST: /api/applications/{{domain}}/clientSecrets
 */
export class GraphApplicationClientSecretRequestModel {

    constructor(domain: string, applications: string[]){
        this.domain = domain;
        this.applications = applications;
    }

    domain: string;
    applications: string[];
}