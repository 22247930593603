import { Component, OnInit } from '@angular/core';
import { NavigationLocation, NextPageService, SearchType, SliderType } from '@cvx/nextpage';
import { CalAngularService } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './services/common/AppConfigService';
import { AppRoleConstants } from './constants/AppRole.constants';
import { AppEnvironment } from './constants/AppEnvironment.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {  
  NavigationLocation = NavigationLocation;
  SearchType = SearchType;
  SliderType = SliderType;
  currentUserProfile: any = null;
  loggedIn : boolean = false;
  username: string = '';
  showProdAlertWarning: boolean = false;
  alertInfoMessage = "";
  alertInfoMessageClass = "alert-information";

  constructor(private NP: NextPageService, private authService: CalAngularService, configService: AppConfigService) {
      const config = configService.getConfig();
      this.NP.options.sitename = 'identity and access management portal';
      this.alertInfoMessage = `${config?.alertInfoMessage}`;
      switch (environment.name)  {
        case AppEnvironment.Local:
          this.alertInfoMessage = `${environment.name}: ${this.alertInfoMessage}`;
          this.alertInfoMessageClass = "alert-information";
          break;
        case AppEnvironment.Dev:
          this.alertInfoMessage = `${environment.name}: ${this.alertInfoMessage}`;
          this.alertInfoMessageClass = "alert-success";
          break;
        case AppEnvironment.Test:
          this.alertInfoMessage = `${environment.name}: ${this.alertInfoMessage}`;
          this.alertInfoMessageClass = "alert-warning";
          break;
        default:
          this.alertInfoMessageClass = "alert-information";
      }

      this.showProdAlertWarning = environment.production && 
          authService.isUserSignedIn() && 
          authService.cvxClaimsPrincipal.roles.some(r => r.toLowerCase() == AppRoleConstants.AppTester.toLowerCase())
  }

  ngOnInit() {
    if (this.authService.isUserSignedIn()) {
      this.username = this.authService.cvxClaimsPrincipal.userName;
    } else {
      this.username = "you are not signed in!!"
    }
  }
}