import { DirectoryDomain } from "../../directory/enums/DirectoryDomain.enum";
import { ServicePrincipalPermissionRole } from "./enums/ServicePrincipalPermissionRole";

export class GraphAppServicePrincipalRevokeRequestModel {
    constructor(domain: DirectoryDomain,
        servicePrincipalId: string,
        servicePrincipalRole: ServicePrincipalPermissionRole,
        approleAssignmentIds: string[],
        scopePermissions: RevokeScopePermission[] = []) {
        this.domain = domain;
        this.servicePrincipalId = servicePrincipalId;
        this.servicePrincipalRole = servicePrincipalRole;
        this.approleAssignmentIds = approleAssignmentIds;
        this.scopePermissions = scopePermissions;
    }

    domain: DirectoryDomain;
    servicePrincipalId: string;
    servicePrincipalRole: ServicePrincipalPermissionRole;
    approleAssignmentIds: string[] = [];
    scopePermissions: RevokeScopePermission[] = [];
}

export class RevokeScopePermission {
    constructor(scopePermissionId: string, scopes: string[]) {
        this.scopePermissionId = scopePermissionId;
        this.scopes = scopes ?? [];
    }

    scopePermissionId: string;
    scopes: string[] = [];
}